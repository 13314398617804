const nouns = [
  'ATM',
  'CD',
  'SUV',
  'TV',
  'aardvark',
  'abacus',
  'abbey',
  'abbreviation',
  'abdomen',
  'ability',
  'abnormality',
  'abolishment',
  'abortion',
  'abrogation',
  'absence',
  'abundance',
  'abuse',
  'academics',
  'academy',
  'accelerant',
  'accelerator',
  'accent',
  'acceptance',
  'access',
  'accessory',
  'accident',
  'accommodation',
  'accompanist',
  'accomplishment',
  'accord',
  'accordance',
  'bother',
  'bottle',
  'bottling',
  'bottom',
  'bottom-line',
  'boudoir',
  'bough',
  'boulder',
  'boulevard',
  'boundary',
  'bouquet',
  'bourgeoisie',
  'bout',
  'boutique',
  'bow',
  'bower',
  'bowl',
  'bowler',
  'bowling',
  'bowtie',
  'box',
  'boxer',
  'boxspring',
  'boy',
  'boycott',
  'boyfriend',
  'boyhood',
  'boysenberry',
  'bra',
  'brace',
  'bracelet',
  'bracket',
  'brain',
  'brake',
  'bran',
  'branch',
  'brand',
  'brandy',
  'brass',
  'brassiere',
  'bratwurst',
  'bread',
  'breadcrumb',
  'breadfruit',
  'break',
  'breakdown',
  'breakfast',
  'breakpoint',
  'breakthrough',
  'breast',
  'breastplate',
  'breath',
  'breeze',
  'brewer',
  'bribery',
  'brick',
  'bricklaying',
  'bride',
  'bridge',
  'brief',
  'briefing',
  'briefly',
  'briefs',
  'brilliant',
  'brink',
  'brisket',
  'broad',
  'broadcast',
  'broccoli',
  'brochure',
  'brocolli',
  'broiler',
  'broker',
  'bronchitis',
  'bronco',
  'bronze',
  'brooch',
  'brood',
  'brook',
  'broom',
  'brother',
  'brother-in-law',
  'brow',
  'brown',
  'brownie',
  'browser',
  'browsing',
  'brunch',
  'brush',
  'brushfire',
  'brushing',
  'bubble',
  'buck',
  'bucket',
  'buckle',
  'buckwheat',
  'bud',
  'buddy',
  'budget',
  'buffalo',
  'buffer',
  'buffet',
  'bug',
  'buggy',
  'bugle',
  'builder',
  'building',
  'bulb',
  'bulk',
  'bull',
  'bull-fighter',
  'bulldozer',
  'bullet',
  'bump',
  'bumper',
  'bun',
  'bunch',
  'bungalow',
  'bunghole',
  'bunkhouse',
  'burden',
  'bureau',
  'burglar',
  'burial',
  'burlesque',
  'burn',
  'burn-out',
  'burning',
  'burrito',
  'burro',
  'burrow',
  'burst',
  'bus',
  'bush',
  'business',
  'businessman',
  'bust',
  'bustle',
  'butane',
  'butcher',
  'butler',
  'butter',
  'butterfly',
  'button',
  'buy',
  'buyer',
  'buying',
  'buzz',
  'buzzard',
  'c-clamp',
  'cabana',
  'cabbage',
  'cabin',
  'cabinet',
  'cable',
  'caboose',
  'cacao',
  'cactus',
  'caddy',
  'cadet',
  'cafe',
  'caffeine',
  'caftan',
  'cage',
  'cake',
  'calcification',
  'calculation',
  'calculator',
  'calculus',
  'calendar',
  'calf',
  'caliber',
  'calibre',
  'calico',
  'call',
  'calm',
  'calorie',
  'camel',
  'cameo',
  'camera',
  'camp',
  'campaign',
  'campaigning',
  'campanile',
  'camper',
  'campus',
  'can',
  'canal',
  'cancer',
  'candelabra',
  'candidacy',
  'candidate',
  'candle',
  'candy',
  'cane',
  'cannibal',
  'cannon',
  'canoe',
  'canon',
  'canopy',
  'cantaloupe',
  'canteen',
  'canvas',
  'cap',
  'capability',
  'capacity',
  'cape',
  'caper',
  'capital',
  'capitalism',
  'capitulation',
  'capon',
  'cappelletti',
  'cappuccino',
  'captain',
  'caption',
  'captor',
  'car',
  'carabao',
  'caramel',
  'caravan',
  'carbohydrate',
  'carbon',
  'carboxyl',
  'card',
  'cardboard',
  'cardigan',
  'care',
  'career',
  'cargo',
  'caribou',
  'carload',
  'carnation',
  'carnival',
  'carol',
  'carotene',
  'carp',
  'carpenter',
  'carpet',
  'carpeting',
  'carport',
  'carriage',
  'carrier',
  'carrot',
  'carry',
  'cart',
  'cartel',
  'carter',
  'cartilage',
  'cartload',
  'cartoon',
  'cartridge',
  'carving',
  'cascade',
  'case',
  'casement',
  'cash',
  'cashew',
  'cashier',
  'casino',
  'casket',
  'cassava',
  'casserole',
  'cassock',
  'cast',
  'castanet',
  'castle',
  'casualty',
  'cat',
  'catacomb',
  'catalogue',
  'catalysis',
  'catalyst',
  'catamaran',
  'catastrophe',
  'catch',
  'catcher',
  'category',
  'caterpillar',
  'cathedral',
  'cation',
  'catsup',
  'cattle',
  'cauliflower',
  'causal',
  'cause',
  'causeway',
  'caution',
  'cave',
  'caviar',
  'cayenne',
  'ceiling',
  'celebration',
  'celebrity',
  'celeriac',
  'celery',
  'cell',
  'cellar',
  'cello',
  'celsius',
  'cement',
  'cemetery',
  'cenotaph',
  'census',
  'cent',
  'center',
  'centimeter',
  'centre',
  'centurion',
  'century',
  'cephalopod',
  'ceramic',
  'ceramics',
  'cereal',
  'ceremony',
  'certainty',
  'certificate',
  'certification',
  'cesspool',
  'chafe',
  'chain',
  'chainstay',
  'chair',
  'chairlift',
  'chairman',
  'chairperson',
  'chaise',
  'chalet',
  'chalice',
  'chalk',
  'challenge',
  'chamber',
  'champagne',
  'champion',
  'championship',
  'chance',
  'chandelier',
  'change',
  'channel',
  'chaos',
  'chap',
  'chapel',
  'chaplain',
  'chapter',
  'character',
  'characteristic',
  'characterization',
  'chard',
  'charge',
  'charger',
  'charity',
  'charlatan',
  'charm',
  'charset',
  'chart',
  'charter',
  'chasm',
  'chassis',
  'chastity',
  'chasuble',
  'chateau',
  'chatter',
  'chauffeur',
  'chauvinist',
  'check',
  'checkbook',
  'checking',
  'checkout',
  'checkroom',
  'cheddar',
  'cheek',
  'cheer',
  'cheese',
  'cheesecake',
  'cheetah',
  'chef',
  'chem',
  'chemical',
  'chemistry',
  'chemotaxis',
  'cheque',
  'cherry',
  'chess',
  'chest',
  'chestnut',
  'chick',
  'chicken',
  'chicory',
  'chief',
  'chiffonier',
  'child',
  'childbirth',
  'childhood',
  'chili',
  'chill',
  'chime',
  'chimpanzee',
  'chin',
  'chinchilla',
  'chino',
  'chip',
  'chipmunk',
  'chit-chat',
  'chivalry',
  'chive',
  'chives',
  'chocolate',
  'choice',
  'choir',
  'choker',
  'cholesterol',
  'choosing',
  'chop',
  'chops',
  'chopstick',
  'chopsticks',
  'chord',
  'chorus',
  'chow',
  'chowder',
  'chrome',
  'chromolithograph',
  'chronicle',
  'chronograph',
  'chronometer',
  'chrysalis',
  'chub',
  'chuck',
  'chug',
  'church',
  'churn',
  'chutney',
  'cicada',
  'cigarette',
  'cilantro',
  'cinder',
  'cinema',
  'cinnamon',
  'circadian',
  'circle',
  'circuit',
  'circulation',
  'circumference',
  'circumstance',
  'cirrhosis',
  'cirrus',
  'citizen',
  'citizenship',
  'citron',
  'citrus',
  'city',
  'civilian',
  'civilisation',
  'civilization',
  'claim',
  'clam',
  'clamp',
  'clan',
  'clank',
  'clapboard',
  'clarification',
  'clarinet',
  'clarity',
  'clasp',
  'class',
  'classic',
  'classification',
  'classmate',
  'classroom',
  'clause',
  'clave',
  'clavicle',
  'clavier',
  'claw',
  'clay',
  'cleaner',
  'clearance',
  'clearing',
  'cleat',
  'cleavage',
  'clef',
  'cleft',
  'clergyman',
  'cleric',
  'clerk',
  'click',
  'client',
  'cliff',
  'climate',
  'climb',
  'clinic',
  'clip',
  'clipboard',
  'clipper',
  'cloak',
  'cloakroom',
  'clock',
  'clockwork',
  'clogs',
  'cloister',
  'clone',
  'close',
  'closet',
  'closing',
  'closure',
  'cloth',
  'clothes',
  'clothing',
  'cloud',
  'cloudburst',
  'clove',
  'clover',
  'cloves',
  'club',
  'clue',
  'cluster',
  'clutch',
  'co-producer',
  'coach',
  'coal',
  'coalition',
  'coast',
  'coaster',
  'coat',
  'cob',
  'cobbler',
  'cobweb',
  'cock',
  'cockpit',
  'cockroach',
  'cocktail',
  'cocoa',
  'coconut',
  'cod',
  'code',
  'codepage',
  'codling',
  'codon',
  'codpiece',
  'coevolution',
  'cofactor',
  'coffee',
  'coffin',
  'cohesion',
  'cohort',
  'coil',
  'coin',
  'coincidence',
  'coinsurance',
  'coke',
  'cold',
  'coleslaw',
  'coliseum',
  'collaboration',
  'collagen',
  'collapse',
  'collar',
  'collard',
  'collateral',
  'colleague',
  'collection',
  'collectivisation',
  'collectivization',
  'collector',
  'college',
  'collision',
  'colloquy',
  'colon',
  'colonial',
  'colonialism',
  'colonisation',
  'colonization',
  'colony',
  'color',
  'colorlessness',
  'colt',
  'column',
  'columnist',
  'comb',
  'combat',
  'combination',
  'combine',
  'comeback',
  'comedy',
  'comestible',
  'comfort',
  'comfortable',
  'comic',
  'comics',
  'comma',
  'command',
  'commander',
  'commandment',
  'comment',
  'commerce',
  'commercial',
  'commission',
  'commitment',
  'committee',
  'commodity',
  'common',
  'commonsense',
  'commotion',
  'communicant',
  'communication',
  'communion',
  'communist',
  'community',
  'commuter',
  'company',
  'comparison',
  'compass',
  'compassion',
  'compassionate',
  'compensation',
  'competence',
  'competition',
  'competitor',
  'complaint',
  'complement',
  'completion',
  'complex',
  'complexity',
  'compliance',
  'complication',
  'complicity',
  'compliment',
  'component',
  'comportment',
  'composer',
  'composite',
  'composition',
  'compost',
  'comprehension',
  'compress',
  'compromise',
  'comptroller',
  'compulsion',
  'computer',
  'comradeship',
  'con',
  'concentrate',
  'concentration',
  'concept',
  'conception',
  'concern',
  'concert',
  'conclusion',
  'concrete',
  'condition',
  'conditioner',
  'condominium',
  'condor',
  'conduct',
  'conductor',
  'cone',
  'confectionery',
  'conference',
  'confidence',
  'confidentiality',
  'configuration',
  'confirmation',
  'conflict',
  'conformation',
  'confusion',
  'conga',
  'congo',
  'congregation',
  'congress',
  'congressman',
  'congressperson',
  'conifer',
  'connection',
  'connotation',
  'conscience',
  'consciousness',
  'consensus',
  'consent',
  'consequence',
  'conservation',
  'conservative',
  'consideration',
  'consignment',
  'consist',
  'consistency',
  'console',
  'consonant',
  'conspiracy',
  'conspirator',
  'constant',
  'constellation',
  'constitution',
  'constraint',
  'construction',
  'consul',
  'consulate',
  'consulting',
  'consumer',
  'consumption',
  'contact',
  'contact lens',
  'contagion',
  'container',
  'content',
  'contention',
  'contest',
  'context',
  'continent',
  'contingency',
  'continuity',
  'contour',
  'contract',
  'contractor',
  'contrail',
  'contrary',
  'contrast',
  'contribution',
  'contributor',
  'control',
  'controller',
  'controversy',
  'convection',
  'convenience',
  'convention',
  'conversation',
  'conversion',
  'convert',
  'convertible',
  'conviction',
  'cook',
  'cookbook',
  'cookie',
  'cooking',
  'coonskin',
  'cooperation',
  'coordination',
  'coordinator',
  'cop',
  'cop-out',
  'cope',
  'copper',
  'copy',
  'copying',
  'copyright',
  'copywriter',
  'coral',
  'cord',
  'corduroy',
  'core',
  'cork',
  'cormorant',
  'corn',
  'corner',
  'cornerstone',
  'cornet',
  'cornflakes',
  'cornmeal',
  'corporal',
  'corporation',
  'corporatism',
  'corps',
  'corral',
  'correspondence',
  'correspondent',
  'corridor',
  'corruption',
  'corsage',
  'cosset',
  'cost',
  'costume',
  'cot',
  'cottage',
  'cotton',
  'couch',
  'cougar',
  'cough',
  'council',
  'councilman',
  'councilor',
  'councilperson',
  'counsel',
  'counseling',
  'counselling',
  'counsellor',
  'counselor',
  'count',
  'counter',
  'counter-force',
  'counterpart',
  'counterterrorism',
  'countess',
  'country',
  'countryside',
  'county',
  'couple',
  'coupon',
  'courage',
  'course',
  'court',
  'courthouse',
  'courtroom',
  'cousin',
  'covariate',
  'cover',
  'coverage',
  'coverall',
  'cow',
  'cowbell',
  'cowboy',
  'coyote',
  'crab',
  'crack',
  'cracker',
  'crackers',
  'cradle',
  'craft',
  'craftsman',
  'cranberry',
  'crane',
  'cranky',
  'crap',
  'crash',
  'crate',
  'cravat',
  'craw',
  'crawdad',
  'crayfish',
  'crayon',
  'crazy',
  'cream',
  'creation',
  'creationism',
  'creationist',
  'creative',
  'creativity',
  'creator',
  'creature',
  'creche',
  'credential',
  'credenza',
  'credibility',
  'credit',
  'creditor',
  'creek',
  'creme brulee',
  'crepe',
  'crest',
  'crew',
  'crewman',
  'crewmate',
  'crewmember',
  'crewmen',
  'cria',
  'crib',
  'cribbage',
  'cricket',
  'cricketer',
  'crime',
  'criminal',
  'crinoline',
  'crisis',
  'crisp',
  'criteria',
  'criterion',
  'critic',
  'criticism',
  'crocodile',
  'crocus',
  'croissant',
  'crook',
  'crop',
  'cross',
  'cross-contamination',
  'cross-stitch',
  'crotch',
  'croup',
  'crow',
  'crowd',
  'crown',
  'crucifixion',
  'crude',
  'cruelty',
  'cruise',
  'crumb',
  'crunch',
  'crusader',
  'crush',
  'crust',
  'cry',
  'crystal',
  'crystallography',
  'cub',
  'cube',
  'cuckoo',
  'cucumber',
  'cue',
  'cuff-link',
  'cuisine',
  'cultivar',
  'cultivator',
  'culture',
  'culvert',
  'cummerbund',
  'cup',
  'cupboard',
  'cupcake',
  'cupola',
  'curd',
  'cure',
  'curio',
  'curiosity',
  'curl',
  'curler',
  'currant',
  'currency',
  'current',
  'curriculum',
  'curry',
  'curse',
  'cursor',
  'curtailment',
  'curtain',
  'curve',
  'cushion',
  'custard',
  'custody',
  'custom',
  'customer',
  'cut',
  'cuticle',
  'cutlet',
  'cutover',
  'cutting',
  'cyclamen',
  'cycle',
  'cyclone',
  'cyclooxygenase',
  'cygnet',
  'cylinder',
  'cymbal',
  'cynic',
  'cyst',
  'cytokine',
  'cytoplasm',
  'dad',
  'daddy',
  'daffodil',
  'dagger',
  'dahlia',
  'daikon',
  'daily',
  'dairy',
  'daisy',
  'dam',
  'damage',
  'dame',
  'damn',
  'dance',
  'dancer',
  'dancing',
  'dandelion',
  'danger',
  'dare',
  'dark',
  'darkness',
  'darn',
  'dart',
  'dash',
  'dashboard',
  'data',
  'database',
  'date',
  'daughter',
  'dawn',
  'day',
  'daybed',
  'daylight',
  'dead',
  'deadline',
  'deal',
  'dealer',
  'dealing',
  'dearest',
  'death',
  'deathwatch',
  'debate',
  'debris',
  'debt',
  'debtor',
  'decade',
  'decadence',
  'decency',
  'decimal',
  'decision',
  'decision-making',
  'deck',
  'declaration',
  'declination',
  'decline',
  'decoder',
  'decongestant',
  'decoration',
  'decrease',
  'decryption',
  'dedication',
  'deduce',
  'deduction',
  'deed',
  'deep',
  'deer',
  'default',
  'defeat',
  'defendant',
  'defender',
  'defense',
  'deficit',
  'definition',
  'deformation',
  'degradation',
  'degree',
  'delay',
  'deliberation',
  'delight',
  'delivery',
  'demand',
  'democracy',
  'democrat',
  'demon',
  'demur',
  'den',
  'denim',
  'denominator',
  'density',
  'dentist',
  'deodorant',
  'department',
  'departure',
  'dependency',
  'dependent',
  'deployment',
  'deposit',
  'deposition',
  'depot',
  'depression',
  'depressive',
  'depth',
  'deputy',
  'derby',
  'derivation',
  'derivative',
  'derrick',
  'descendant',
  'descent',
  'description',
  'desert',
  'design',
  'designation',
  'designer',
  'desire',
  'desk',
  'desktop',
  'dessert',
  'destination',
  'destiny',
  'destroyer',
  'destruction',
  'detail',
  'detainee',
  'detainment',
  'detection',
  'detective',
  'detector',
  'detention',
  'determination',
  'detour',
  'devastation',
  'developer',
  'developing',
  'development',
  'developmental',
  'deviance',
  'deviation',
  'device',
  'devil',
  'dew',
  'dhow',
  'diabetes',
  'diadem',
  'diagnosis',
  'diagram',
  'dial',
  'dialect',
  'dialogue',
  'diam',
  'diamond',
  'diaper',
  'diaphragm',
  'diarist',
  'diary',
  'dibble',
  'dick',
  'dickey',
  'dictaphone',
  'dictator',
  'diction',
  'dictionary',
  'die',
  'diesel',
  'diet',
  'difference',
  'differential',
  'difficulty',
  'diffuse',
  'dig',
  'digestion',
  'digestive',
  'digger',
  'digging',
  'digit',
  'dignity',
  'dilapidation',
  'dill',
  'dilution',
  'dime',
  'dimension',
  'dimple',
  'diner',
  'dinghy',
  'dining',
  'dinner',
  'dinosaur',
  'dioxide',
  'dip',
  'diploma',
  'diplomacy',
  'dipstick',
  'direction',
  'directive',
  'director',
  'directory',
  'dirndl',
  'dirt',
  'disability',
  'disadvantage',
  'disagreement',
  'disappointment',
  'disarmament',
  'disaster',
  'discharge',
  'discipline',
  'disclaimer',
  'disclosure',
  'disco',
  'disconnection',
  'discount',
  'discourse',
  'discovery',
  'discrepancy',
  'discretion',
  'discrimination',
  'discussion',
  'disdain',
  'disease',
  'disembodiment',
  'disengagement',
  'disguise',
  'disgust',
  'dish',
  'dishwasher',
  'disk',
  'disparity',
  'dispatch',
  'displacement',
  'display',
  'disposal',
  'disposer',
  'disposition',
  'dispute',
  'disregard',
  'disruption',
  'dissemination',
  'dissonance',
  'distance',
  'distinction',
  'distortion',
  'distribution',
  'distributor',
  'district',
  'divalent',
  'divan',
  'diver',
  'diversity',
  'divide',
  'dividend',
  'divider',
  'divine',
  'diving',
  'division',
  'divorce',
  'doc',
  'dock',
  'doctor',
  'doctorate',
  'doctrine',
  'document',
  'documentary',
  'documentation',
  'doe',
  'dog',
  'doggie',
  'dogsled',
  'dogwood',
  'doing',
  'doll',
  'dollar',
  'dollop',
  'dolman',
  'dolor',
  'dolphin',
  'domain',
  'dome',
  'domination',
  'donation',
  'donkey',
  'donor',
  'donut',
  'door',
  'doorbell',
  'doorknob',
  'doorpost',
  'doorway',
  'dory',
  'dose',
  'dot',
  'double',
  'doubling',
  'doubt',
  'doubter',
  'dough',
  'doughnut',
  'down',
  'downfall',
  'downforce',
  'downgrade',
  'download',
  'downstairs',
  'downtown',
  'downturn',
  'dozen',
  'draft',
  'drag',
  'dragon',
  'dragonfly',
  'dragonfruit',
  'dragster',
  'drain',
  'drainage',
  'drake',
  'drama',
  'dramaturge',
  'drapes',
  'draw',
  'drawbridge',
  'drawer',
  'drawing',
  'dream',
  'dreamer',
  'dredger',
  'dress',
  'dresser',
  'dressing',
  'drill',
  'drink',
  'drinking',
  'drive',
  'driver',
  'driveway',
  'driving',
  'drizzle',
  'dromedary',
  'drop',
  'drudgery',
  'drug',
  'drum',
  'drummer',
  'drunk',
  'dryer',
  'duck',
  'duckling',
  'dud',
  'dude',
  'due',
  'duel',
  'dueling',
  'duffel',
  'dugout',
  'dulcimer',
  'dumbwaiter',
  'dump',
  'dump truck',
  'dune',
  'dune buggy',
  'dungarees',
  'dungeon',
  'duplexer',
  'duration',
  'durian',
  'dusk',
  'dust',
  'dust storm',
  'duster',
  'duty',
  'dwarf',
  'dwell',
  'dwelling',
  'dynamics',
  'dynamite',
  'dynamo',
  'dynasty',
  'dysfunction',
  'e-book',
  'e-mail',
  'e-reader',
  'eagle',
  'eaglet',
  'ear',
  'eardrum',
  'earmuffs',
  'earnings',
  'earplug',
  'earring',
  'earrings',
  'earth',
  'earthquake',
  'earthworm',
  'ease',
  'easel',
  'east',
  'eating',
  'eaves',
  'eavesdropper',
  'ecclesia',
  'echidna',
  'eclipse',
  'ecliptic',
  'ecology',
  'economics',
  'economy',
  'ecosystem',
  'ectoderm',
  'ectodermal',
  'ecumenist',
  'eddy',
  'edge',
  'edger',
  'edible',
  'editing',
  'edition',
  'editor',
  'editorial',
  'education',
  'eel',
  'effacement',
  'effect',
  'effective',
  'effectiveness',
  'effector',
  'efficacy',
  'efficiency',
  'effort',
  'egg',
  'egghead',
  'eggnog',
  'eggplant',
  'ego',
  'eicosanoid',
  'ejector',
  'elbow',
  'elderberry',
  'election',
  'electricity',
  'electrocardiogram',
  'electronics',
  'element',
  'elephant',
  'elevation',
  'elevator',
  'eleventh',
  'elf',
  'elicit',
  'eligibility',
  'elimination',
  'elite',
  'elixir',
  'elk',
  'ellipse',
  'elm',
  'elongation',
  'elver',
  'email',
  'emanate',
  'embarrassment',
  'embassy',
  'embellishment',
  'embossing',
  'embryo',
  'emerald',
  'emergence',
  'emergency',
  'emergent',
  'emery',
  'emission',
  'emitter',
  'emotion',
  'emphasis',
  'empire',
  'employ',
  'employee',
  'employer',
  'employment',
  'empowerment',
  'emu',
  'enactment',
  'encirclement',
  'enclave',
  'enclosure',
  'encounter',
  'encouragement',
  'encyclopedia',
  'end',
  'endive',
  'endoderm',
  'endorsement',
  'endothelium',
  'endpoint',
  'enemy',
  'energy',
  'enforcement',
  'engagement',
  'engine',
  'engineer',
  'engineering',
  'enigma',
  'enjoyment',
  'enquiry',
  'enrollment',
  'enterprise',
  'entertainment',
  'enthusiasm',
  'entirety',
  'entity',
  'entrance',
  'entree',
  'entrepreneur',
  'entry',
  'envelope',
  'environment',
  'envy',
  'enzyme',
  'epauliere',
  'epee',
  'ephemera',
  'ephemeris',
  'ephyra',
  'epic',
  'episode',
  'epithelium',
  'epoch',
  'eponym',
  'epoxy',
  'equal',
  'equality',
  'equation',
  'equinox',
  'equipment',
  'equity',
  'equivalent',
  'era',
  'eraser',
  'erection',
  'erosion',
  'error',
  'escalator',
  'escape',
  'escort',
  'espadrille',
  'espalier',
  'essay',
  'essence',
  'essential',
  'establishment',
  'estate',
  'estimate',
  'estrogen',
  'estuary',
  'eternity',
  'ethernet',
  'ethics',
  'ethnicity',
  'ethyl',
  'euphonium',
  'eurocentrism',
  'evaluation',
  'evaluator',
  'evaporation',
  'eve',
  'evening',
  'evening-wear',
  'event',
  'everybody',
  'everyone',
  'everything',
  'eviction',
  'evidence',
  'evil',
  'evocation',
  'evolution',
  'ex-husband',
  'ex-wife',
  'exaggeration',
  'exam',
  'examination',
  'examiner',
  'example',
  'exasperation',
  'excellence',
  'exception',
  'excerpt',
  'excess',
  'exchange',
  'excitement',
  'exclamation',
  'excursion',
  'excuse',
  'execution',
  'executive',
  'executor',
  'exercise',
  'exhaust',
  'exhaustion',
  'exhibit',
  'exhibition',
  'exile',
  'existence',
  'exit',
  'exocrine',
  'expansion',
  'expansionism',
  'expectancy',
  'expectation',
  'expedition',
  'expense',
  'experience',
  'experiment',
  'experimentation',
  'expert',
  'expertise',
  'explanation',
  'exploration',
  'explorer',
  'explosion',
  'export',
  'expose',
  'exposition',
  'exposure',
  'expression',
  'extension',
  'extent',
  'exterior',
  'external',
  'extinction',
  'extreme',
  'extremist',
  'eye',
  'eyeball',
  'eyebrow',
  'eyebrows',
  'eyeglasses',
  'eyelash',
  'eyelashes',
  'eyelid',
  'eyelids',
  'eyeliner',
  'eyestrain',
  'eyrie',
  'fabric',
  'face',
  'facelift',
  'facet',
  'facility',
  'facsimile',
  'fact',
  'factor',
  'factory',
  'faculty',
  'fahrenheit',
  'fail',
  'failure',
  'fairness',
  'fairy',
  'faith',
  'faithful',
  'fall',
  'fallacy',
  'falling-out',
  'fame',
  'familiar',
  'familiarity',
  'family',
  'fan',
  'fang',
  'fanlight',
  'fanny',
  'fanny-pack',
  'fantasy',
  'farm',
  'farmer',
  'farming',
  'farmland',
  'farrow',
  'fascia',
  'fashion',
  'fat',
  'fate',
  'father',
  'father-in-law',
  'fatigue',
  'fatigues',
  'faucet',
  'fault',
  'fav',
  'fava',
  'favor',
  'favorite',
  'fawn',
  'fax',
  'fear',
  'feast',
  'feather',
  'feature',
  'fedelini',
  'federation',
  'fedora',
  'fee',
  'feed',
  'feedback',
  'feeding',
  'feel',
  'feeling',
  'fellow',
  'felony',
  'female',
  'fen',
  'fence',
  'fencing',
  'fender',
  'feng',
  'fennel',
  'ferret',
  'ferry',
  'ferryboat',
  'fertilizer',
  'festival',
  'fetus',
  'few',
  'fiber',
  'fiberglass',
  'fibre',
  'fibroblast',
  'fibrosis',
  'ficlet',
  'fiction',
  'fiddle',
  'field',
  'fiery',
  'fiesta',
  'fifth',
  'fig',
  'fight',
  'fighter',
  'figure',
  'figurine',
  'file',
  'filing',
  'fill',
  'fillet',
  'filly',
  'film',
  'filter',
  'filth',
  'final',
  'finance',
  'financing',
  'finding',
  'fine',
  'finer',
  'finger',
  'fingerling',
  'fingernail',
  'finish',
  'finisher',
  'fir',
  'fire',
  'fireman',
  'fireplace',
  'firewall',
  'firm',
  'first',
  'fish',
  'fishbone',
  'fisherman',
  'fishery',
  'fishing',
  'fishmonger',
  'fishnet',
  'fisting',
  'fit',
  'fitness',
  'fix',
  'fixture',
  'flag',
  'flair',
  'flame',
  'flan',
  'flanker',
  'flare',
  'flash',
  'flat',
  'flatboat',
  'flavor',
  'flax',
  'fleck',
  'fledgling',
  'fleece',
  'flesh',
  'flexibility',
  'flick',
  'flicker',
  'flight',
  'flint',
  'flintlock',
  'flip-flops',
  'flock',
  'flood',
  'floodplain',
  'floor',
  'floozie',
  'flour',
  'flow',
  'flower',
  'flu',
  'flugelhorn',
  'fluke',
  'flume',
  'flung',
  'flute',
  'fly',
  'flytrap',
  'foal',
  'foam',
  'fob',
  'focus',
  'fog',
  'fold',
  'folder',
  'folk',
  'folklore',
  'follower',
  'following',
  'fondue',
  'font',
  'food',
  'foodstuffs',
  'fool',
  'foot',
  'footage',
  'football',
  'footnote',
  'footprint',
  'footrest',
  'footstep',
  'footstool',
  'footwear',
  'forage',
  'forager',
  'foray',
  'force',
  'ford',
  'forearm',
  'forebear',
  'forecast',
  'forehead',
  'foreigner',
  'forelimb',
  'forest',
  'forestry',
  'forever',
  'forgery',
  'fork',
  'form',
  'formal',
  'formamide',
  'format',
  'formation',
  'former',
  'formicarium',
  'formula',
  'fort',
  'forte',
  'fortnight',
  'fortress',
  'fortune',
  'forum',
  'foundation',
  'founder',
  'founding',
  'fountain',
  'fourths',
  'fowl',
  'fox',
  'foxglove',
  'fraction',
  'fragrance',
  'frame',
  'framework',
  'fratricide',
  'fraud',
  'fraudster',
  'freak',
  'freckle',
  'freedom',
  'freelance',
  'freezer',
  'freezing',
  'freight',
  'freighter',
  'frenzy',
  'freon',
  'frequency',
  'fresco',
  'friction',
  'fridge',
  'friend',
  'friendship',
  'fries',
  'frigate',
  'fright',
  'fringe',
  'fritter',
  'frock',
  'frog',
  'front',
  'frontier',
  'frost',
  'frosting',
  'frown',
  'fruit',
  'frustration',
  'fry',
  'fuck',
  'fuel',
  'fugato',
  'fulfillment',
  'full',
  'fun',
  'function',
  'functionality',
  'fund',
  'funding',
  'fundraising',
  'funeral',
  'fur',
  'furnace',
  'furniture',
  'furry',
  'fusarium',
  'futon',
  'future',
  'gadget',
  'gaffe',
  'gaffer',
  'gain',
  'gaiters',
  'gale',
  'gall-bladder',
  'gallery',
  'galley',
  'gallon',
  'galoshes',
  'gambling',
  'game',
  'gamebird',
  'gaming',
  'gamma-ray',
  'gander',
  'gang',
  'gap',
  'garage',
  'garb',
  'garbage',
  'garden',
  'garlic',
  'garment',
  'garter',
  'gas',
  'gasket',
  'gasoline',
  'gasp',
  'gastronomy',
  'gastropod',
  'gate',
  'gateway',
  'gather',
  'gathering',
  'gator',
  'gauge',
  'gauntlet',
  'gavel',
  'gazebo',
  'gazelle',
  'gear',
  'gearshift',
  'geek',
  'gel',
  'gelatin',
  'gelding',
  'gem',
  'gemsbok',
  'gender',
  'gene',
  'general',
  'generation',
  'generator',
  'generosity',
  'genetics',
  'genie',
  'genius',
  'genocide',
  'genre',
  'gentleman',
  'geography',
  'geology',
  'geometry',
  'geranium',
  'gerbil',
  'gesture',
  'geyser',
  'gherkin',
  'ghost',
  'giant',
  'gift',
  'gig',
  'gigantism',
  'giggle',
  'ginger',
  'gingerbread',
  'ginseng',
  'giraffe',
  'girdle',
  'girl',
  'girlfriend',
  'git',
  'glacier',
  'gladiolus',
  'glance',
  'gland',
  'glass',
  'glasses',
  'glee',
  'glen',
  'glider',
  'gliding',
  'glimpse',
  'globe',
  'glockenspiel',
  'gloom',
  'glory',
  'glove',
  'glow',
  'glucose',
  'glue',
  'glut',
  'glutamate',
  'gnat',
  'gnu',
  'go-kart',
  'goal',
  'goat',
  'gobbler',
  'god',
  'goddess',
  'godfather',
  'godmother',
  'godparent',
  'goggles',
  'going',
  'gold',
  'goldfish',
  'golf',
  'gondola',
  'gong',
  'good',
  'good-bye',
  'goodbye',
  'goodie',
  'goodness',
  'goodnight',
  'goodwill',
  'goose',
  'gopher',
  'gorilla',
  'gosling',
  'gossip',
  'governance',
  'government',
  'governor',
  'gown',
  'grab-bag',
  'grace',
  'grade',
  'gradient',
  'graduate',
  'graduation',
  'graffiti',
  'graft',
  'grain',
  'gram',
  'grammar',
  'gran',
  'grand',
  'grandchild',
  'granddaughter',
  'grandfather',
  'grandma',
  'grandmom',
  'grandmother',
  'grandpa',
  'grandparent',
  'grandson',
  'granny',
  'granola',
  'grant',
  'grape',
  'grapefruit',
  'graph',
  'graphic',
  'grasp',
  'grass',
  'grasshopper',
  'grassland',
  'gratitude',
  'gravel',
  'gravitas',
  'gravity',
  'gravy',
  'gray',
  'grease',
  'great-grandfather',
  'great-grandmother',
  'greatness',
  'greed',
  'green',
  'greenhouse',
  'greens',
  'grenade',
  'grey',
  'grid',
  'grief',
  'grill',
  'grin',
  'grip',
  'gripper',
  'grit',
  'grocery',
  'ground',
  'group',
  'grouper',
  'grouse',
  'grove',
  'growth',
  'grub',
  'guacamole',
  'guarantee',
  'guard',
  'guava',
  'guerrilla',
  'guess',
  'guest',
  'guestbook',
  'guidance',
  'guide',
  'guideline',
  'guilder',
  'guilt',
  'guilty',
  'guinea',
  'guitar',
  'guitarist',
  'gum',
  'gumshoe',
  'gun',
  'gunpowder',
  'gutter',
  'guy',
  'gym',
  'gymnast',
  'gymnastics',
  'gynaecology',
  'gyro',
  'habit',
  'habitat',
  'hacienda',
  'hacksaw',
  'hackwork',
  'hail',
  'hair',
  'haircut',
  'hake',
  'half',
  'half-brother',
  'half-sister',
  'halibut',
  'hall',
  'halloween',
  'hallway',
  'halt',
  'ham',
  'hamburger',
  'hammer',
  'hammock',
  'hamster',
  'hand',
  'hand-holding',
  'handball',
  'handful',
  'handgun',
  'handicap',
  'handle',
  'handlebar',
  'handmaiden',
  'handover',
  'handrail',
  'handsaw',
  'hanger',
  'happening',
  'happiness',
  'harald',
  'harbor',
  'harbour',
  'hard-hat',
  'hardboard',
  'hardcover',
  'hardening',
  'hardhat',
  'hardship',
  'hardware',
  'hare',
  'harm',
  'harmonica',
  'harmonise',
  'harmonize',
  'harmony',
  'harp',
  'harpooner',
  'harpsichord',
  'harvest',
  'harvester',
  'hash',
  'hashtag',
  'hassock',
  'haste',
  'hat',
  'hatbox',
  'hatchet',
  'hatchling',
  'hate',
  'hatred',
  'haunt',
  'haven',
  'haversack',
  'havoc',
  'hawk',
  'hay',
  'haze',
  'hazel',
  'hazelnut',
  'head',
  'headache',
  'headlight',
  'headline',
  'headphones',
  'headquarters',
  'headrest',
  'health',
  'health-care',
  'hearing',
  'hearsay',
  'heart',
  'heart-throb',
  'heartache',
  'heartbeat',
  'hearth',
  'hearthside',
  'heartwood',
  'heat',
  'heater',
  'heating',
  'heaven',
  'heavy',
  'hectare',
  'hedge',
  'hedgehog',
  'heel',
  'heifer',
  'height',
  'heir',
  'heirloom',
  'helicopter',
  'helium',
  'hell',
  'hellcat',
  'hello',
  'helmet',
  'helo',
  'help',
  'hemisphere',
  'hemp',
  'hen',
  'hepatitis',
  'herb',
  'herbs',
  'heritage',
  'hermit',
  'hero',
  'heroine',
  'heron',
  'herring',
  'hesitation',
  'heterosexual',
  'hexagon',
  'heyday',
  'hiccups',
  'hide',
  'hierarchy',
  'high',
  'high-rise',
  'highland',
  'highlight',
  'highway',
  'hike',
  'hiking',
  'hill',
  'hint',
  'hip',
  'hippodrome',
  'hippopotamus',
  'hire',
  'hiring',
  'historian',
  'history',
  'hit',
  'hive',
  'hobbit',
  'hobby',
  'hockey',
  'hoe',
  'hog',
  'hold',
  'holder',
  'hole',
  'holiday',
  'home',
  'homeland',
  'homeownership',
  'hometown',
  'homework',
  'homicide',
  'homogenate',
  'homonym',
  'homosexual',
  'homosexuality',
  'honesty',
  'honey',
  'honeybee',
  'honeydew',
  'honor',
  'honoree',
  'hood',
  'hoof',
  'hook',
  'hop',
  'hope',
  'hops',
  'horde',
  'horizon',
  'hormone',
  'horn',
  'hornet',
  'horror',
  'horse',
  'horseradish',
  'horst',
  'hose',
  'hosiery',
  'hospice',
  'hospital',
  'hospitalisation',
  'hospitality',
  'hospitalization',
  'host',
  'hostel',
  'hostess',
  'hotdog',
  'hotel',
  'hound',
  'hour',
  'hourglass',
  'house',
  'houseboat',
  'household',
  'housewife',
  'housework',
  'housing',
  'hovel',
  'hovercraft',
  'howard',
  'howitzer',
  'hub',
  'hubcap',
  'hubris',
  'hug',
  'hugger',
  'hull',
  'human',
  'humanity',
  'humidity',
  'hummus',
  'humor',
  'humour',
  'hunchback',
  'hundred',
  'hunger',
  'hunt',
  'hunter',
  'hunting',
  'hurdle',
  'hurdler',
  'hurricane',
  'hurry',
  'hurt',
  'husband',
  'hut',
  'hutch',
  'hyacinth',
  'hybridisation',
  'hybridization',
  'hydrant',
  'hydraulics',
  'hydrocarb',
  'hydrocarbon',
  'hydrofoil',
  'hydrogen',
  'hydrolyse',
  'hydrolysis',
  'hydrolyze',
  'hydroxyl',
  'hyena',
  'hygienic',
  'hype',
  'hyphenation',
  'hypochondria',
  'hypothermia',
  'hypothesis',
  'ice',
  'ice-cream',
  'iceberg',
  'icebreaker',
  'icecream',
  'icicle',
  'icing',
  'icon',
  'icy',
  'id',
  'idea',
  'ideal',
  'identification',
  'identity',
  'ideology',
  'idiom',
  'idiot',
  'igloo',
  'ignorance',
  'ignorant',
  'ikebana',
  'illegal',
  'illiteracy',
  'illness',
  'illusion',
  'illustration',
  'image',
  'imagination',
  'imbalance',
  'imitation',
  'immigrant',
  'immigration',
  'immortal',
  'impact',
  'impairment',
  'impala',
  'impediment',
  'implement',
  'implementation',
  'implication',
  'import',
  'importance',
  'impostor',
  'impress',
  'impression',
  'imprisonment',
  'impropriety',
  'improvement',
  'impudence',
  'impulse',
  'in-joke',
  'in-laws',
  'inability',
  'inauguration',
  'inbox',
  'incandescence',
  'incarnation',
  'incense',
  'incentive',
  'inch',
  'incidence',
  'incident',
  'incision',
  'inclusion',
  'income',
  'incompetence',
  'inconvenience',
  'increase',
  'incubation',
  'independence',
  'independent',
  'index',
  'indication',
  'indicator',
  'indigence',
  'individual',
  'industrialisation',
  'industrialization',
  'industry',
  'inequality',
  'inevitable',
  'infancy',
  'infant',
  'infarction',
  'infection',
  'infiltration',
  'infinite',
  'infix',
  'inflammation',
  'inflation',
  'influence',
  'influx',
  'info',
  'information',
  'infrastructure',
  'infusion',
  'inglenook',
  'ingrate',
  'ingredient',
  'inhabitant',
  'inheritance',
  'inhibition',
  'inhibitor',
  'initial',
  'initialise',
  'initialize',
  'initiative',
  'injunction',
  'injury',
  'injustice',
  'ink',
  'inlay',
  'inn',
  'innervation',
  'innocence',
  'innocent',
  'innovation',
  'input',
  'inquiry',
  'inscription',
  'insect',
  'insectarium',
  'insert',
  'inside',
  'insight',
  'insolence',
  'insomnia',
  'inspection',
  'inspector',
  'inspiration',
  'installation',
  'instance',
  'instant',
  'instinct',
  'institute',
  'institution',
  'instruction',
  'instructor',
  'instrument',
  'instrumentalist',
  'instrumentation',
  'insulation',
  'insurance',
  'insurgence',
  'insurrection',
  'integer',
  'integral',
  'integration',
  'integrity',
  'intellect',
  'intelligence',
  'intensity',
  'intent',
  'intention',
  'intentionality',
  'interaction',
  'interchange',
  'interconnection',
  'intercourse',
  'interest',
  'interface',
  'interferometer',
  'interior',
  'interject',
  'interloper',
  'internet',
  'interpretation',
  'interpreter',
  'interval',
  'intervenor',
  'intervention',
  'interview',
  'interviewer',
  'intestine',
  'introduction',
  'intuition',
  'invader',
  'invasion',
  'invention',
  'inventor',
  'inventory',
  'inverse',
  'inversion',
  'investigation',
  'investigator',
  'investment',
  'investor',
  'invitation',
  'invite',
  'invoice',
  'involvement',
  'iridescence',
  'iris',
  'iron',
  'ironclad',
  'irony',
  'irrigation',
  'ischemia',
  'island',
  'isogloss',
  'isolation',
  'issue',
  'item',
  'itinerary',
  'ivory',
  'jack',
  'jackal',
  'jacket',
  'jackfruit',
  'jade',
  'jaguar',
  'jail',
  'jailhouse',
  'jalapeÃ±o',
  'jam',
  'jar',
  'jasmine',
  'jaw',
  'jazz',
  'jealousy',
  'jeans',
  'jeep',
  'jelly',
  'jellybeans',
  'jellyfish',
  'jerk',
  'jet',
  'jewel',
  'jeweller',
  'jewellery',
  'jewelry',
  'jicama',
  'jiffy',
  'job',
  'jockey',
  'jodhpurs',
  'joey',
  'jogging',
  'joint',
  'joke',
  'jot',
  'journal',
  'journalism',
  'journalist',
  'journey',
  'joy',
  'judge',
  'judgment',
  'judo',
  'jug',
  'juggernaut',
  'juice',
  'julienne',
  'jumbo',
  'jump',
  'jumper',
  'jumpsuit',
  'jungle',
  'junior',
  'junk',
  'junker',
  'junket',
  'jury',
  'justice',
  'justification',
  'jute',
  'kale',
  'kamikaze',
  'kangaroo',
  'karate',
  'kayak',
  'kazoo',
  'kebab',
  'keep',
  'keeper',
  'kendo',
  'kennel',
  'ketch',
  'ketchup',
  'kettle',
  'kettledrum',
  'key',
  'keyboard',
  'keyboarding',
  'keystone',
  'kick',
  'kick-off',
  'kid',
  'kidney',
  'kielbasa',
  'kill',
  'killer',
  'killing',
  'kilogram',
  'kilometer',
  'kilt',
  'kimono',
  'kinase',
  'kind',
  'kindness',
  'king',
  'kingdom',
  'kingfish',
  'kiosk',
  'kiss',
  'kit',
  'kitchen',
  'kite',
  'kitsch',
  'kitten',
  'kitty',
  'kiwi',
  'knee',
  'kneejerk',
  'knickers',
  'knife',
  'knife-edge',
  'knight',
  'knitting',
  'knock',
  'knot',
  'know-how',
  'knowledge',
  'knuckle',
  'koala',
  'kohlrabi',
  'kumquat',
  'lab',
  'label',
  'labor',
  'laboratory',
  'laborer',
  'labour',
  'labourer',
  'lace',
  'lack',
  'lacquerware',
  'lad',
  'ladder',
  'ladle',
  'lady',
  'ladybug',
  'lag',
  'lake',
  'lamb',
  'lambkin',
  'lament',
  'lamp',
  'lanai',
  'land',
  'landform',
  'landing',
  'landmine',
  'landscape',
  'lane',
  'language',
  'lantern',
  'lap',
  'laparoscope',
  'lapdog',
  'laptop',
  'larch',
  'lard',
  'larder',
  'lark',
  'larva',
  'laryngitis',
  'lasagna',
  'lashes',
  'last',
  'latency',
  'latex',
  'lathe',
  'latitude',
  'latte',
  'latter',
  'laugh',
  'laughter',
  'laundry',
  'lava',
  'law',
  'lawmaker',
  'lawn',
  'lawsuit',
  'lawyer',
  'lay',
  'layer',
  'layout',
  'lead',
  'leader',
  'leadership',
  'leading',
  'leaf',
  'league',
  'leaker',
  'leap',
  'learning',
  'leash',
  'leather',
  'leave',
  'leaver',
  'lecture',
  'leek',
  'leeway',
  'left',
  'leg',
  'legacy',
  'legal',
  'legend',
  'legging',
  'legislation',
  'legislator',
  'legislature',
  'legitimacy',
  'legume',
  'leisure',
  'lemon',
  'lemonade',
  'lemur',
  'lender',
  'lending',
  'length',
  'lens',
  'lentil',
  'leopard',
  'leprosy',
  'leptocephalus',
  'lesbian',
  'lesson',
  'letter',
  'lettuce',
  'level',
  'lever',
  'leverage',
  'leveret',
  'liability',
  'liar',
  'liberty',
  'libido',
  'library',
  'licence',
  'license',
  'licensing',
  'licorice',
  'lid',
  'lie',
  'lieu',
  'lieutenant',
  'life',
  'lifestyle',
  'lifetime',
  'lift',
  'ligand',
  'light',
  'lighting',
  'lightning',
  'lightscreen',
  'ligula',
  'likelihood',
  'likeness',
  'lilac',
  'lily',
  'limb',
  'lime',
  'limestone',
  'limit',
  'limitation',
  'limo',
  'line',
  'linen',
  'liner',
  'linguist',
  'linguistics',
  'lining',
  'link',
  'linkage',
  'linseed',
  'lion',
  'lip',
  'lipid',
  'lipoprotein',
  'lipstick',
  'liquid',
  'liquidity',
  'liquor',
  'list',
  'listening',
  'listing',
  'literate',
  'literature',
  'litigation',
  'litmus',
  'litter',
  'littleneck',
  'liver',
  'livestock',
  'living',
  'lizard',
  'llama',
  'load',
  'loading',
  'loaf',
  'loafer',
  'loan',
  'lobby',
  'lobotomy',
  'lobster',
  'local',
  'locality',
  'location',
  'lock',
  'locker',
  'locket',
  'locomotive',
  'locust',
  'lode',
  'loft',
  'log',
  'loggia',
  'logic',
  'login',
  'logistics',
  'logo',
  'loincloth',
  'lollipop',
  'loneliness',
  'longboat',
  'longitude',
  'look',
  'lookout',
  'loop',
  'loophole',
  'loquat',
  'lord',
  'loss',
  'lot',
  'lotion',
  'lottery',
  'lounge',
  'louse',
  'lout',
  'love',
  'lover',
  'lox',
  'loyalty',
  'luck',
  'luggage',
  'lumber',
  'lumberman',
  'lunch',
  'luncheonette',
  'lunchmeat',
  'lunchroom',
  'lung',
  'lunge',
  'lust',
  'lute',
  'luxury',
  'lychee',
  'lycra',
  'lye',
  'lymphocyte',
  'lynx',
  'lyocell',
  'lyre',
  'lyrics',
  'lysine',
  'mRNA',
  'macadamia',
  'macaroni',
  'macaroon',
  'macaw',
  'machine',
  'machinery',
  'macrame',
  'macro',
  'macrofauna',
  'madam',
  'maelstrom',
  'maestro',
  'magazine',
  'maggot',
  'magic',
  'magnet',
  'magnitude',
  'maid',
  'maiden',
  'mail',
  'mailbox',
  'mailer',
  'mailing',
  'mailman',
  'main',
  'mainland',
  'mainstream',
  'maintainer',
  'maintenance',
  'maize',
  'major',
  'major-league',
  'majority',
  'makeover',
  'maker',
  'makeup',
  'making',
  'male',
  'malice',
  'mall',
  'mallard',
  'mallet',
  'malnutrition',
  'mama',
  'mambo',
  'mammoth',
  'man',
  'manacle',
  'management',
  'manager',
  'manatee',
  'mandarin',
  'mandate',
  'mandolin',
  'mangle',
  'mango',
  'mangrove',
  'manhunt',
  'maniac',
  'manicure',
  'manifestation',
  'manipulation',
  'mankind',
  'manner',
  'manor',
  'mansard',
  'manservant',
  'mansion',
  'mantel',
  'mantle',
  'mantua',
  'manufacturer',
  'manufacturing',
  'many',
  'map',
  'maple',
  'mapping',
  'maracas',
  'marathon',
  'marble',
  'march',
  'mare',
  'margarine',
  'margin',
  'mariachi',
  'marimba',
  'marines',
  'marionberry',
  'mark',
  'marker',
  'market',
  'marketer',
  'marketing',
  'marketplace',
  'marksman',
  'markup',
  'marmalade',
  'marriage',
  'marsh',
  'marshland',
  'marshmallow',
  'marten',
  'marxism',
  'mascara',
  'mask',
  'masonry',
  'mass',
  'massage',
  'mast',
  'master',
  'masterpiece',
  'mastication',
  'mastoid',
  'mat',
  'match',
  'matchmaker',
  'mate',
  'material',
  'maternity',
  'math',
  'mathematics',
  'matrix',
  'matter',
  'mattock',
  'mattress',
  'max',
  'maximum',
  'maybe',
  'mayonnaise',
  'mayor',
  'meadow',
  'meal',
  'mean',
  'meander',
  'meaning',
  'means',
  'meantime',
  'measles',
  'measure',
  'measurement',
  'meat',
  'meatball',
  'meatloaf',
  'mecca',
  'mechanic',
  'mechanism',
  'med',
  'medal',
  'media',
  'median',
  'medication',
  'medicine',
  'medium',
  'meet',
  'meeting',
  'melatonin',
  'melody',
  'melon',
  'member',
  'membership',
  'membrane',
  'meme',
  'memo',
  'memorial',
  'memory',
  'men',
  'menopause',
  'menorah',
  'mention',
  'mentor',
  'menu',
  'merchandise',
  'merchant',
  'mercury',
  'meridian',
  'meringue',
  'merit',
  'mesenchyme',
  'mess',
  'message',
  'messenger',
  'messy',
  'metabolite',
  'metal',
  'metallurgist',
  'metaphor',
  'meteor',
  'meteorology',
  'meter',
  'methane',
  'method',
  'methodology',
  'metric',
  'metro',
  'metronome',
  'mezzanine',
  'microlending',
  'micronutrient',
  'microphone',
  'microwave',
  'mid-course',
  'midden',
  'middle',
  'middleman',
  'midline',
  'midnight',
  'midwife',
  'might',
  'migrant',
  'migration',
  'mile',
  'mileage',
  'milepost',
  'milestone',
  'military',
  'milk',
  'milkshake',
  'mill',
  'millennium',
  'millet',
  'millimeter',
  'million',
  'millisecond',
  'millstone',
  'mime',
  'mimosa',
  'min',
  'mincemeat',
  'mind',
  'mine',
  'mineral',
  'mineshaft',
  'mini',
  'mini-skirt',
  'minibus',
  'minimalism',
  'minimum',
  'mining',
  'minion',
  'minister',
  'mink',
  'minnow',
  'minor',
  'minor-league',
  'minority',
  'mint',
  'minute',
  'miracle',
  'mirror',
  'miscarriage',
  'miscommunication',
  'misfit',
  'misnomer',
  'misogyny',
  'misplacement',
  'misreading',
  'misrepresentation',
  'miss',
  'missile',
  'mission',
  'missionary',
  'mist',
  'mistake',
  'mister',
  'misunderstand',
  'miter',
  'mitten',
  'mix',
  'mixer',
  'mixture',
  'moai',
  'moat',
  'mob',
  'mobile',
  'mobility',
  'mobster',
  'moccasins',
  'mocha',
  'mochi',
  'mode',
  'model',
  'modeling',
  'modem',
  'modernist',
  'modernity',
  'modification',
  'molar',
  'molasses',
  'molding',
  'mole',
  'molecule',
  'mom',
  'moment',
  'monastery',
  'monasticism',
  'money',
  'monger',
  'monitor',
  'monitoring',
  'monk',
  'monkey',
  'monocle',
  'monopoly',
  'monotheism',
  'monsoon',
  'monster',
  'month',
  'monument',
  'mood',
  'moody',
  'moon',
  'moonlight',
  'moonscape',
  'moonshine',
  'moose',
  'mop',
  'morale',
  'morbid',
  'morbidity',
  'morning',
  'moron',
  'morphology',
  'morsel',
  'mortal',
  'mortality',
  'mortgage',
  'mortise',
  'mosque',
  'mosquito',
  'most',
  'motel',
  'moth',
  'mother',
  'mother-in-law',
  'motion',
  'motivation',
  'motive',
  'motor',
  'motorboat',
  'motorcar',
  'motorcycle',
  'mound',
  'mountain',
  'mouse',
  'mouser',
  'mousse',
  'moustache',
  'mouth',
  'mouton',
  'movement',
  'mover',
  'movie',
  'mower',
  'mozzarella',
  'mud',
  'muffin',
  'mug',
  'mukluk',
  'mule',
  'multimedia',
  'murder',
  'muscat',
  'muscatel',
  'muscle',
  'musculature',
  'museum',
  'mushroom',
  'music',
  'music-box',
  'music-making',
  'musician',
  'muskrat',
  'mussel',
  'mustache',
  'mustard',
  'mutation',
  'mutt',
  'mutton',
  'mycoplasma',
  'mystery',
  'myth',
  'mythology',
  'nail',
  'name',
  'naming',
  'nanoparticle',
  'napkin',
  'narrative',
  'nasal',
  'nation',
  'nationality',
  'native',
  'naturalisation',
  'nature',
  'navigation',
  'necessity',
  'neck',
  'necklace',
  'necktie',
  'nectar',
  'nectarine',
  'need',
  'needle',
  'neglect',
  'negligee',
  'negotiation',
  'neighbor',
  'neighborhood',
  'neighbour',
  'neighbourhood',
  'neologism',
  'neon',
  'neonate',
  'nephew',
  'nerve',
  'nest',
  'nestling',
  'nestmate',
  'net',
  'netball',
  'netbook',
  'netsuke',
  'network',
  'networking',
  'neurobiologist',
  'neuron',
  'neuropathologist',
  'neuropsychiatry',
  'news',
  'newsletter',
  'newspaper',
  'newsprint',
  'newsstand',
  'nexus',
  'nibble',
  'nicety',
  'niche',
  'nick',
  'nickel',
  'nickname',
  'niece',
  'night',
  'nightclub',
  'nightgown',
  'nightingale',
  'nightlife',
  'nightlight',
  'nightmare',
  'ninja',
  'nit',
  'nitrogen',
  'nobody',
  'nod',
  'node',
  'noir',
  'noise',
  'nonbeliever',
  'nonconformist',
  'nondisclosure',
  'nonsense',
  'noodle',
  'noodles',
  'noon',
  'norm',
  'normal',
  'normalisation',
  'normalization',
  'north',
  'nose',
  'notation',
  'note',
  'notebook',
  'notepad',
  'nothing',
  'notice',
  'notion',
  'notoriety',
  'nougat',
  'noun',
  'nourishment',
  'novel',
  'nucleotidase',
  'nucleotide',
  'nudge',
  'nuke',
  'number',
  'numeracy',
  'numeric',
  'numismatist',
  'nun',
  'nurse',
  'nursery',
  'nursing',
  'nurture',
  'nut',
  'nutmeg',
  'nutrient',
  'nutrition',
  'nylon',
  'nymph',
  'oak',
  'oar',
  'oasis',
  'oat',
  'oatmeal',
  'oats',
  'obedience',
  'obesity',
  'obi',
  'object',
  'objection',
  'objective',
  'obligation',
  'oboe',
  'observation',
  'observatory',
  'obsession',
  'obsidian',
  'obstacle',
  'occasion',
  'occupation',
  'occurrence',
  'ocean',
  'ocelot',
  'octagon',
  'octave',
  'octavo',
  'octet',
  'octopus',
  'odometer',
  'odyssey',
  'oeuvre',
  'off-ramp',
  'offence',
  'offense',
  'offer',
  'offering',
  'office',
  'officer',
  'official',
  'offset',
  'oil',
  'okra',
  'oldie',
  'oleo',
  'olive',
  'omega',
  'omelet',
  'omission',
  'omnivore',
  'oncology',
  'onion',
  'online',
  'onset',
  'opening',
  'opera',
  'operating',
  'operation',
  'operator',
  'ophthalmologist',
  'opinion',
  'opium',
  'opossum',
  'opponent',
  'opportunist',
  'opportunity',
  'opposite',
  'opposition',
  'optimal',
  'optimisation',
  'optimist',
  'optimization',
  'option',
  'orange',
  'orangutan',
  'orator',
  'orchard',
  'orchestra',
  'orchid',
  'order',
  'ordinary',
  'ordination',
  'ore',
  'oregano',
  'organ',
  'organisation',
  'organising',
  'organization',
  'organizing',
  'orient',
  'orientation',
  'origin',
  'original',
  'originality',
  'ornament',
  'osmosis',
  'osprey',
  'ostrich',
  'other',
  'otter',
  'ottoman',
  'ounce',
  'outback',
  'outcome',
  'outfielder',
  'outfit',
  'outhouse',
  'outlaw',
  'outlay',
  'outlet',
  'outline',
  'outlook',
  'output',
  'outrage',
  'outrigger',
  'outrun',
  'outset',
  'outside',
  'oval',
  'ovary',
  'oven',
  'overcharge',
  'overclocking',
  'overcoat',
  'overexertion',
  'overflight',
  'overhead',
  'overheard',
  'overload',
  'overnighter',
  'overshoot',
  'oversight',
  'overview',
  'overweight',
  'owl',
  'owner',
  'ownership',
  'ox',
  'oxford',
  'oxygen',
  'oyster',
  'ozone',
  'pace',
  'pacemaker',
  'pack',
  'package',
  'packaging',
  'packet',
  'pad',
  'paddle',
  'paddock',
  'pagan',
  'page',
  'pagoda',
  'pail',
  'pain',
  'paint',
  'painter',
  'painting',
  'paintwork',
  'pair',
  'pajamas',
  'palace',
  'palate',
  'palm',
  'pamphlet',
  'pan',
  'pancake',
  'pancreas',
  'panda',
  'panel',
  'panic',
  'pannier',
  'panpipe',
  'pansy',
  'panther',
  'panties',
  'pantologist',
  'pantology',
  'pantry',
  'pants',
  'pantsuit',
  'panty',
  'pantyhose',
  'papa',
  'papaya',
  'paper',
  'paperback',
  'paperwork',
  'parable',
  'parachute',
  'parade',
  'paradise',
  'paragraph',
  'parallelogram',
  'paramecium',
  'paramedic',
  'parameter',
  'paranoia',
  'parcel',
  'parchment',
  'pard',
  'pardon',
  'parent',
  'parenthesis',
  'parenting',
  'park',
  'parka',
  'parking',
  'parliament',
  'parole',
  'parrot',
  'parser',
  'parsley',
  'parsnip',
  'part',
  'participant',
  'participation',
  'particle',
  'particular',
  'partner',
  'partnership',
  'partridge',
  'party',
  'pass',
  'passage',
  'passbook',
  'passenger',
  'passing',
  'passion',
  'passive',
  'passport',
  'password',
  'past',
  'pasta',
  'paste',
  'pastor',
  'pastoralist',
  'pastry',
  'pasture',
  'pat',
  'patch',
  'pate',
  'patent',
  'patentee',
  'path',
  'pathogenesis',
  'pathology',
  'pathway',
  'patience',
  'patient',
  'patina',
  'patio',
  'patriarch',
  'patrimony',
  'patriot',
  'patrol',
  'patroller',
  'patrolling',
  'patron',
  'pattern',
  'patty',
  'pattypan',
  'pause',
  'pavement',
  'pavilion',
  'paw',
  'pawnshop',
  'pay',
  'payee',
  'payment',
  'payoff',
  'pea',
  'peace',
  'peach',
  'peacoat',
  'peacock',
  'peak',
  'peanut',
  'pear',
  'pearl',
  'peasant',
  'pecan',
  'pecker',
  'pedal',
  'peek',
  'peen',
  'peer',
  'peer-to-peer',
  'pegboard',
  'pelican',
  'pelt',
  'pen',
  'penalty',
  'pence',
  'pencil',
  'pendant',
  'pendulum',
  'penguin',
  'penicillin',
  'peninsula',
  'penis',
  'pennant',
  'penny',
  'pension',
  'pentagon',
  'peony',
  'people',
  'pepper',
  'pepperoni',
  'percent',
  'percentage',
  'perception',
  'perch',
  'perennial',
  'perfection',
  'performance',
  'perfume',
  'period',
  'periodical',
  'peripheral',
  'permafrost',
  'permission',
  'permit',
  'perp',
  'perpendicular',
  'persimmon',
  'person',
  'personal',
  'personality',
  'personnel',
  'perspective',
  'pest',
  'pet',
  'petal',
  'petition',
  'petitioner',
  'petticoat',
  'pew',
  'pharmacist',
  'pharmacopoeia',
  'phase',
  'pheasant',
  'phenomenon',
  'phenotype',
  'pheromone',
  'philanthropy',
  'philosopher',
  'philosophy',
  'phone',
  'phosphate',
  'photo',
  'photodiode',
  'photograph',
  'photographer',
  'photography',
  'photoreceptor',
  'phrase',
  'phrasing',
  'physical',
  'physics',
  'physiology',
  'pianist',
  'piano',
  'piccolo',
  'pick',
  'pickax',
  'pickaxe',
  'picket',
  'pickle',
  'pickup',
  'picnic',
  'picture',
  'picturesque',
  'pie',
  'piece',
  'pier',
  'piety',
  'pig',
  'pigeon',
  'piglet',
  'pigpen',
  'pigsty',
  'pike',
  'pilaf',
  'pile',
  'pilgrim',
  'pilgrimage',
  'pill',
  'pillar',
  'pillbox',
  'pillow',
  'pilot',
  'pimp',
  'pimple',
  'pin',
  'pinafore',
  'pince-nez',
  'pine',
  'pineapple',
  'pinecone',
  'ping',
  'pink',
  'pinkie',
  'pinot',
  'pinstripe',
  'pint',
  'pinto',
  'pinworm',
  'pioneer',
  'pipe',
  'pipeline',
  'piracy',
  'pirate',
  'piss',
  'pistol',
  'pit',
  'pita',
  'pitch',
  'pitcher',
  'pitching',
  'pith',
  'pizza',
  'place',
  'placebo',
  'placement',
  'placode',
  'plagiarism',
  'plain',
  'plaintiff',
  'plan',
  'plane',
  'planet',
  'planning',
  'plant',
  'plantation',
  'planter',
  'planula',
  'plaster',
  'plasterboard',
  'plastic',
  'plate',
  'platelet',
  'platform',
  'platinum',
  'platter',
  'platypus',
  'play',
  'player',
  'playground',
  'playroom',
  'playwright',
  'plea',
  'pleasure',
  'pleat',
  'pledge',
  'plenty',
  'plier',
  'pliers',
  'plight',
  'plot',
  'plough',
  'plover',
  'plow',
  'plowman',
  'plug',
  'plugin',
  'plum',
  'plumber',
  'plume',
  'plunger',
  'plywood',
  'pneumonia',
  'pocket',
  'pocket-watch',
  'pocketbook',
  'pod',
  'podcast',
  'poem',
  'poet',
  'poetry',
  'poignance',
  'point',
  'poison',
  'poisoning',
  'poker',
  'polarisation',
  'polarization',
  'pole',
  'polenta',
  'police',
  'policeman',
  'policy',
  'polish',
  'politician',
  'politics',
  'poll',
  'polliwog',
  'pollutant',
  'pollution',
  'polo',
  'polyester',
  'polyp',
  'pomegranate',
  'pomelo',
  'pompom',
  'poncho',
  'pond',
  'pony',
  'pool',
  'poor',
  'pop',
  'popcorn',
  'poppy',
  'popsicle',
  'popularity',
  'population',
  'populist',
  'porcelain',
  'porch',
  'porcupine',
  'pork',
  'porpoise',
  'port',
  'porter',
  'portfolio',
  'porthole',
  'portion',
  'portrait',
  'position',
  'possession',
  'possibility',
  'possible',
  'post',
  'postage',
  'postbox',
  'poster',
  'posterior',
  'postfix',
  'pot',
  'potato',
  'potential',
  'pottery',
  'potty',
  'pouch',
  'poultry',
  'pound',
  'pounding',
  'poverty',
  'powder',
  'power',
  'practice',
  'practitioner',
  'prairie',
  'praise',
  'pray',
  'prayer',
  'precedence',
  'precedent',
  'precipitation',
  'precision',
  'predecessor',
  'preface',
  'preference',
  'prefix',
  'pregnancy',
  'prejudice',
  'prelude',
  'premeditation',
  'premier',
  'premise',
  'premium',
  'preoccupation',
  'preparation',
  'prescription',
  'presence',
  'present',
  'presentation',
  'preservation',
  'preserves',
  'presidency',
  'president',
  'press',
  'pressroom',
  'pressure',
  'pressurisation',
  'pressurization',
  'prestige',
  'presume',
  'pretzel',
  'prevalence',
  'prevention',
  'prey',
  'price',
  'pricing',
  'pride',
  'priest',
  'priesthood',
  'primary',
  'primate',
  'prince',
  'princess',
  'principal',
  'principle',
  'print',
  'printer',
  'printing',
  'prior',
  'priority',
  'prison',
  'prisoner',
  'privacy',
  'private',
  'privilege',
  'prize',
  'prizefight',
  'probability',
  'probation',
  'probe',
  'problem',
  'procedure',
  'proceedings',
  'process',
  'processing',
  'processor',
  'proctor',
  'procurement',
  'produce',
  'producer',
  'product',
  'production',
  'productivity',
  'profession',
  'professional',
  'professor',
  'profile',
  'profit',
  'progenitor',
  'program',
  'programme',
  'programming',
  'progress',
  'progression',
  'prohibition',
  'project',
  'proliferation',
  'promenade',
  'promise',
  'promotion',
  'prompt',
  'pronoun',
  'pronunciation',
  'proof',
  'proof-reader',
  'propaganda',
  'propane',
  'property',
  'prophet',
  'proponent',
  'proportion',
  'proposal',
  'proposition',
  'proprietor',
  'prose',
  'prosecution',
  'prosecutor',
  'prospect',
  'prosperity',
  'prostacyclin',
  'prostanoid',
  'prostrate',
  'protection',
  'protein',
  'protest',
  'protocol',
  'providence',
  'provider',
  'province',
  'provision',
  'prow',
  'proximal',
  'proximity',
  'prune',
  'pruner',
  'pseudocode',
  'pseudoscience',
  'psychiatrist',
  'psychoanalyst',
  'psychologist',
  'psychology',
  'ptarmigan',
  'pub',
  'public',
  'publication',
  'publicity',
  'publisher',
  'publishing',
  'pudding',
  'puddle',
  'puffin',
  'pug',
  'puggle',
  'pulley',
  'pulse',
  'puma',
  'pump',
  'pumpernickel',
  'pumpkin',
  'pumpkinseed',
  'pun',
  'punch',
  'punctuation',
  'punishment',
  'pup',
  'pupa',
  'pupil',
  'puppet',
  'puppy',
  'purchase',
  'puritan',
  'purity',
  'purple',
  'purpose',
  'purr',
  'purse',
  'pursuit',
  'push',
  'pusher',
  'put',
  'puzzle',
  'pyramid',
  'pyridine',
  'quadrant',
  'quail',
  'qualification',
  'quality',
  'quantity',
  'quart',
  'quarter',
  'quartet',
  'quartz',
  'queen',
  'query',
  'quest',
  'question',
  'questioner',
  'questionnaire',
  'quiche',
  'quicksand',
  'quiet',
  'quill',
  'quilt',
  'quince',
  'quinoa',
  'quit',
  'quiver',
  'quota',
  'quotation',
  'quote',
  'rabbi',
  'rabbit',
  'raccoon',
  'race',
  'racer',
  'racing',
  'racism',
  'racist',
  'rack',
  'radar',
  'radiator',
  'radio',
  'radiosonde',
  'radish',
  'raffle',
  'raft',
  'rag',
  'rage',
  'raid',
  'rail',
  'railing',
  'railroad',
  'railway',
  'raiment',
  'rain',
  'rainbow',
  'raincoat',
  'rainmaker',
  'rainstorm',
  'rainy',
  'raise',
  'raisin',
  'rake',
  'rally',
  'ram',
  'rambler',
  'ramen',
  'ramie',
  'ranch',
  'rancher',
  'randomisation',
  'randomization',
  'range',
  'ranger',
  'rank',
  'rap',
  'rape',
  'raspberry',
  'rat',
  'rate',
  'ratepayer',
  'rating',
  'ratio',
  'rationale',
  'rations',
  'raven',
  'ravioli',
  'rawhide',
  'ray',
  'rayon',
  'razor',
  'reach',
  'reactant',
  'reaction',
  'read',
  'reader',
  'readiness',
  'reading',
  'real',
  'reality',
  'realization',
  'realm',
  'reamer',
  'rear',
  'reason',
  'reasoning',
  'rebel',
  'rebellion',
  'reboot',
  'recall',
  'recapitulation',
  'receipt',
  'receiver',
  'reception',
  'receptor',
  'recess',
  'recession',
  'recipe',
  'recipient',
  'reciprocity',
  'reclamation',
  'recliner',
  'recognition',
  'recollection',
  'recommendation',
  'reconsideration',
  'record',
  'recorder',
  'recording',
  'recovery',
  'recreation',
  'recruit',
  'rectangle',
  'red',
  'redesign',
  'redhead',
  'redirect',
  'rediscovery',
  'reduction',
  'reef',
  'refectory',
  'reference',
  'referendum',
  'reflection',
  'reform',
  'refreshments',
  'refrigerator',
  'refuge',
  'refund',
  'refusal',
  'refuse',
  'regard',
  'regime',
  'region',
  'regionalism',
  'register',
  'registration',
  'registry',
  'regret',
  'regulation',
  'regulator',
  'rehospitalisation',
  'rehospitalization',
  'reindeer',
  'reinscription',
  'reject',
  'relation',
  'relationship',
  'relative',
  'relaxation',
  'relay',
  'release',
  'reliability',
  'relief',
  'religion',
  'relish',
  'reluctance',
  'remains',
  'remark',
  'reminder',
  'remnant',
  'remote',
  'removal',
  'renaissance',
  'rent',
  'reorganisation',
  'reorganization',
  'repair',
  'reparation',
  'repayment',
  'repeat',
  'replacement',
  'replica',
  'replication',
  'reply',
  'report',
  'reporter',
  'reporting',
  'repository',
  'representation',
  'representative',
  'reprocessing',
  'republic',
  'republican',
  'reputation',
  'request',
  'requirement',
  'resale',
  'rescue',
  'research',
  'researcher',
  'resemblance',
  'reservation',
  'reserve',
  'reservoir',
  'reset',
  'residence',
  'resident',
  'residue',
  'resist',
  'resistance',
  'resolution',
  'resolve',
  'resort',
  'resource',
  'respect',
  'respite',
  'response',
  'responsibility',
  'rest',
  'restaurant',
  'restoration',
  'restriction',
  'restroom',
  'restructuring',
  'result',
  'resume',
  'retailer',
  'retention',
  'rethinking',
  'retina',
  'retirement',
  'retouching',
  'retreat',
  'retrospect',
  'retrospective',
  'retrospectivity',
  'return',
  'reunion',
  'revascularisation',
  'revascularization',
  'reveal',
  'revelation',
  'revenant',
  'revenge',
  'revenue',
  'reversal',
  'reverse',
  'review',
  'revitalisation',
  'revitalization',
  'revival',
  'revolution',
  'revolver',
  'reward',
  'rhetoric',
  'rheumatism',
  'rhinoceros',
  'rhubarb',
  'rhyme',
  'rhythm',
  'rib',
  'ribbon',
  'rice',
  'riddle',
  'ride',
  'rider',
  'ridge',
  'riding',
  'rifle',
  'right',
  'rim',
  'ring',
  'ringworm',
  'riot',
  'rip',
  'ripple',
  'rise',
  'riser',
  'risk',
  'rite',
  'ritual',
  'river',
  'riverbed',
  'rivulet',
  'road',
  'roadway',
  'roar',
  'roast',
  'robe',
  'robin',
  'robot',
  'robotics',
  'rock',
  'rocker',
  'rocket',
  'rocket-ship',
  'rod',
  'role',
  'roll',
  'roller',
  'romaine',
  'romance',
  'roof',
  'room',
  'roommate',
  'rooster',
  'root',
  'rope',
  'rose',
  'rosemary',
  'roster',
  'rostrum',
  'rotation',
  'round',
  'roundabout',
  'route',
  'router',
  'routine',
  'row',
  'rowboat',
  'rowing',
  'rubber',
  'rubbish',
  'rubric',
  'ruby',
  'ruckus',
  'rudiment',
  'ruffle',
  'rug',
  'rugby',
  'ruin',
  'rule',
  'ruler',
  'ruling',
  'rum',
  'rumor',
  'run',
  'runaway',
  'runner',
  'running',
  'runway',
  'rush',
  'rust',
  'rutabaga',
  'rye',
  'sabre',
  'sac',
  'sack',
  'saddle',
  'sadness',
  'safari',
  'safe',
  'safeguard',
  'safety',
  'saffron',
  'sage',
  'sail',
  'sailboat',
  'sailing',
  'sailor',
  'saint',
  'sake',
  'salad',
  'salami',
  'salary',
  'sale',
  'salesman',
  'salmon',
  'salon',
  'saloon',
  'salsa',
  'salt',
  'salute',
  'samovar',
  'sampan',
  'sample',
  'samurai',
  'sanction',
  'sanctity',
  'sanctuary',
  'sand',
  'sandal',
  'sandbar',
  'sandpaper',
  'sandwich',
  'sanity',
  'sardine',
  'sari',
  'sarong',
  'sash',
  'satellite',
  'satin',
  'satire',
  'satisfaction',
  'sauce',
  'saucer',
  'sauerkraut',
  'sausage',
  'savage',
  'savannah',
  'saving',
  'savings',
  'savior',
  'saviour',
  'savory',
  'saw',
  'saxophone',
  'scaffold',
  'scale',
  'scallion',
  'scallops',
  'scalp',
  'scam',
  'scanner',
  'scarecrow',
  'scarf',
  'scarification',
  'scenario',
  'scene',
  'scenery',
  'scent',
  'schedule',
  'scheduling',
  'schema',
  'scheme',
  'schizophrenic',
  'schnitzel',
  'scholar',
  'scholarship',
  'school',
  'schoolhouse',
  'schooner',
  'science',
  'scientist',
  'scimitar',
  'scissors',
  'scooter',
  'scope',
  'score',
  'scorn',
  'scorpion',
  'scotch',
  'scout',
  'scow',
  'scrambled',
  'scrap',
  'scraper',
  'scratch',
  'screamer',
  'screen',
  'screening',
  'screenwriting',
  'screw',
  'screw-up',
  'screwdriver',
  'scrim',
  'scrip',
  'script',
  'scripture',
  'scrutiny',
  'sculpting',
  'sculptural',
  'sculpture',
  'sea',
  'seabass',
  'seafood',
  'seagull',
  'seal',
  'seaplane',
  'search',
  'seashore',
  'seaside',
  'season',
  'seat',
  'seaweed',
  'second',
  'secrecy',
  'secret',
  'secretariat',
  'secretary',
  'secretion',
  'section',
  'sectional',
  'sector',
  'security',
  'sediment',
  'seed',
  'seeder',
  'seeker',
  'seep',
  'segment',
  'seizure',
  'selection',
  'self',
  'self-confidence',
  'self-control',
  'self-esteem',
  'seller',
  'selling',
  'semantics',
  'semester',
  'semicircle',
  'semicolon',
  'semiconductor',
  'seminar',
  'senate',
  'senator',
  'sender',
  'senior',
  'sense',
  'sensibility',
  'sensitive',
  'sensitivity',
  'sensor',
  'sentence',
  'sentencing',
  'sentiment',
  'sepal',
  'separation',
  'septicaemia',
  'sequel',
  'sequence',
  'serial',
  'series',
  'sermon',
  'serum',
  'serval',
  'servant',
  'server',
  'service',
  'servitude',
  'sesame',
  'session',
  'set',
  'setback',
  'setting',
  'settlement',
  'settler',
  'severity',
  'sewer',
  'sex',
  'sexuality',
  'shack',
  'shackle',
  'shade',
  'shadow',
  'shadowbox',
  'shakedown',
  'shaker',
  'shallot',
  'shallows',
  'shame',
  'shampoo',
  'shanty',
  'shape',
  'share',
  'shareholder',
  'shark',
  'shaw',
  'shawl',
  'shear',
  'shearling',
  'sheath',
  'shed',
  'sheep',
  'sheet',
  'shelf',
  'shell',
  'shelter',
  'sherbet',
  'sherry',
  'shield',
  'shift',
  'shin',
  'shine',
  'shingle',
  'ship',
  'shipper',
  'shipping',
  'shipyard',
  'shirt',
  'shirtdress',
  'shit',
  'shoat',
  'shock',
  'shoe',
  'shoe-horn',
  'shoehorn',
  'shoelace',
  'shoemaker',
  'shoes',
  'shoestring',
  'shofar',
  'shoot',
  'shootdown',
  'shop',
  'shopper',
  'shopping',
  'shore',
  'shoreline',
  'short',
  'shortage',
  'shorts',
  'shortwave',
  'shot',
  'shoulder',
  'shout',
  'shovel',
  'show',
  'show-stopper',
  'shower',
  'shred',
  'shrimp',
  'shrine',
  'shutdown',
  'sibling',
  'sick',
  'sickness',
  'side',
  'sideboard',
  'sideburns',
  'sidecar',
  'sidestream',
  'sidewalk',
  'siding',
  'siege',
  'sigh',
  'sight',
  'sightseeing',
  'sign',
  'signal',
  'signature',
  'signet',
  'significance',
  'signify',
  'signup',
  'silence',
  'silica',
  'silicon',
  'silk',
  'silkworm',
  'sill',
  'silly',
  'silo',
  'silver',
  'similarity',
  'simple',
  'simplicity',
  'simplification',
  'simvastatin',
  'sin',
  'singer',
  'singing',
  'singular',
  'sink',
  'sinuosity',
  'sip',
  'sir',
  'sister',
  'sister-in-law',
  'sitar',
  'site',
  'situation',
  'size',
  'skate',
  'skating',
  'skean',
  'skeleton',
  'ski',
  'skiing',
  'skill',
  'skin',
  'skirt',
  'skull',
  'skullcap',
  'skullduggery',
  'skunk',
  'sky',
  'skylight',
  'skyline',
  'skyscraper',
  'skywalk',
  'slang',
  'slapstick',
  'slash',
  'slate',
  'slave',
  'slavery',
  'slaw',
  'sled',
  'sledge',
  'sleep',
  'sleepiness',
  'sleeping',
  'sleet',
  'sleuth',
  'slice',
  'slide',
  'slider',
  'slime',
  'slip',
  'slipper',
  'slippers',
  'slope',
  'slot',
  'sloth',
  'slump',
  'smell',
  'smelting',
  'smile',
  'smith',
  'smock',
  'smog',
  'smoke',
  'smoking',
  'smolt',
  'smuggling',
  'snack',
  'snail',
  'snake',
  'snakebite',
  'snap',
  'snarl',
  'sneaker',
  'sneakers',
  'sneeze',
  'sniffle',
  'snob',
  'snorer',
  'snow',
  'snowboarding',
  'snowflake',
  'snowman',
  'snowmobiling',
  'snowplow',
  'snowstorm',
  'snowsuit',
  'snuck',
  'snug',
  'snuggle',
  'soap',
  'soccer',
  'socialism',
  'socialist',
  'society',
  'sociology',
  'sock',
  'socks',
  'soda',
  'sofa',
  'softball',
  'softdrink',
  'softening',
  'software',
  'soil',
  'soldier',
  'sole',
  'solicitation',
  'solicitor',
  'solidarity',
  'solidity',
  'soliloquy',
  'solitaire',
  'solution',
  'solvency',
  'sombrero',
  'somebody',
  'someone',
  'someplace',
  'somersault',
  'something',
  'somewhere',
  'son',
  'sonar',
  'sonata',
  'song',
  'songbird',
  'sonnet',
  'soot',
  'sophomore',
  'soprano',
  'sorbet',
  'sorghum',
  'sorrel',
  'sorrow',
  'sort',
  'soul',
  'soulmate',
  'sound',
  'soundness',
  'soup',
  'source',
  'sourwood',
  'sousaphone',
  'south',
  'southeast',
  'souvenir',
  'sovereignty',
  'sow',
  'soy',
  'soybean',
  'space',
  'spacing',
  'spade',
  'spaghetti',
  'span',
  'spandex',
  'spank',
  'sparerib',
  'spark',
  'sparrow',
  'spasm',
  'spat',
  'spatula',
  'spawn',
  'speaker',
  'speakerphone',
  'speaking',
  'spear',
  'spec',
  'special',
  'specialist',
  'specialty',
  'species',
  'specification',
  'spectacle',
  'spectacles',
  'spectrograph',
  'spectrum',
  'speculation',
  'speech',
  'speed',
  'speedboat',
  'spell',
  'spelling',
  'spelt',
  'spending',
  'sphere',
  'sphynx',
  'spice',
  'spider',
  'spiderling',
  'spike',
  'spill',
  'spinach',
  'spine',
  'spiral',
  'spirit',
  'spiritual',
  'spirituality',
  'spit',
  'spite',
  'spleen',
  'splendor',
  'split',
  'spokesman',
  'spokeswoman',
  'sponge',
  'sponsor',
  'sponsorship',
  'spool',
  'spoon',
  'spork',
  'sport',
  'sportsman',
  'spot',
  'spotlight',
  'spouse',
  'sprag',
  'sprat',
  'spray',
  'spread',
  'spreadsheet',
  'spree',
  'spring',
  'sprinkles',
  'sprinter',
  'sprout',
  'spruce',
  'spud',
  'spume',
  'spur',
  'spy',
  'spyglass',
  'square',
  'squash',
  'squatter',
  'squeegee',
  'squid',
  'squirrel',
  'stab',
  'stability',
  'stable',
  'stack',
  'stacking',
  'stadium',
  'staff',
  'stag',
  'stage',
  'stain',
  'stair',
  'staircase',
  'stake',
  'stalk',
  'stall',
  'stallion',
  'stamen',
  'stamina',
  'stamp',
  'stance',
  'stand',
  'standard',
  'standardisation',
  'standardization',
  'standing',
  'standoff',
  'standpoint',
  'star',
  'starboard',
  'start',
  'starter',
  'state',
  'statement',
  'statin',
  'station',
  'station-wagon',
  'statistic',
  'statistics',
  'statue',
  'status',
  'statute',
  'stay',
  'steak',
  'stealth',
  'steam',
  'steamroller',
  'steel',
  'steeple',
  'stem',
  'stench',
  'stencil',
  'step',
  'step-aunt',
  'step-brother',
  'step-daughter',
  'step-father',
  'step-grandfather',
  'step-grandmother',
  'step-mother',
  'step-sister',
  'step-son',
  'step-uncle',
  'stepdaughter',
  'stepmother',
  'stepping-stone',
  'stepson',
  'stereo',
  'stew',
  'steward',
  'stick',
  'sticker',
  'stiletto',
  'still',
  'stimulation',
  'stimulus',
  'sting',
  'stinger',
  'stir-fry',
  'stitch',
  'stitcher',
  'stock',
  'stock-in-trade',
  'stockings',
  'stole',
  'stomach',
  'stone',
  'stonework',
  'stool',
  'stop',
  'stopsign',
  'stopwatch',
  'storage',
  'store',
  'storey',
  'storm',
  'story',
  'story-telling',
  'storyboard',
  'stot',
  'stove',
  'strait',
  'strand',
  'stranger',
  'strap',
  'strategy',
  'straw',
  'strawberry',
  'strawman',
  'stream',
  'street',
  'streetcar',
  'strength',
  'stress',
  'stretch',
  'strife',
  'strike',
  'string',
  'strip',
  'stripe',
  'strobe',
  'stroke',
  'structure',
  'strudel',
  'struggle',
  'stucco',
  'stud',
  'student',
  'studio',
  'study',
  'stuff',
  'stumbling',
  'stump',
  'stupidity',
  'sturgeon',
  'sty',
  'style',
  'styling',
  'stylus',
  'sub',
  'subcomponent',
  'subconscious',
  'subcontractor',
  'subexpression',
  'subgroup',
  'subject',
  'submarine',
  'submitter',
  'subprime',
  'subroutine',
  'subscription',
  'subsection',
  'subset',
  'subsidence',
  'subsidiary',
  'subsidy',
  'substance',
  'substitution',
  'subtitle',
  'suburb',
  'subway',
  'success',
  'succotash',
  'suck',
  'sucker',
  'suede',
  'suet',
  'suffocation',
  'sugar',
  'suggestion',
  'suicide',
  'suit',
  'suitcase',
  'suite',
  'sulfur',
  'sultan',
  'sum',
  'summary',
  'summer',
  'summit',
  'sun',
  'sunbeam',
  'sunbonnet',
  'sundae',
  'sunday',
  'sundial',
  'sunflower',
  'sunglasses',
  'sunlamp',
  'sunlight',
  'sunrise',
  'sunroom',
  'sunset',
  'sunshine',
  'superiority',
  'supermarket',
  'supernatural',
  'supervision',
  'supervisor',
  'supper',
  'supplement',
  'supplier',
  'supply',
  'support',
  'supporter',
  'suppression',
  'supreme',
  'surface',
  'surfboard',
  'surge',
  'surgeon',
  'surgery',
  'surname',
  'surplus',
  'surprise',
  'surround',
  'surroundings',
  'surrounds',
  'survey',
  'survival',
  'survivor',
  'sushi',
  'suspect',
  'suspenders',
  'suspension',
  'sustainment',
  'sustenance',
  'swallow',
  'swamp',
  'swan',
  'swanling',
  'swath',
  'sweat',
  'sweater',
  'sweatshirt',
  'sweatshop',
  'sweatsuit',
  'sweets',
  'swell',
  'swim',
  'swimming',
  'swimsuit',
  'swine',
  'swing',
  'switch',
  'switchboard',
  'switching',
  'swivel',
  'sword',
  'swordfight',
  'swordfish',
  'sycamore',
  'symbol',
  'symmetry',
  'sympathy',
  'symptom',
  'syndicate',
  'syndrome',
  'synergy',
  'synod',
  'synonym',
  'synthesis',
  'syrup',
  'system',
  't-shirt',
  'tab',
  'tabby',
  'tabernacle',
  'table',
  'tablecloth',
  'tablet',
  'tabletop',
  'tachometer',
  'tackle',
  'taco',
  'tactics',
  'tactile',
  'tadpole',
  'tag',
  'tail',
  'tailbud',
  'tailor',
  'tailspin',
  'take-out',
  'takeover',
  'tale',
  'talent',
  'talk',
  'talking',
  "tam-o'-shanter",
  'tamale',
  'tambour',
  'tambourine',
  'tan',
  'tandem',
  'tangerine',
  'tank',
  'tank-top',
  'tanker',
  'tankful',
  'tap',
  'tape',
  'tapioca',
  'target',
  'taro',
  'tarragon',
  'tart',
  'task',
  'tassel',
  'taste',
  'tatami',
  'tattler',
  'tattoo',
  'tavern',
  'tax',
  'taxi',
  'taxicab',
  'taxpayer',
  'tea',
  'teacher',
  'teaching',
  'team',
  'teammate',
  'teapot',
  'tear',
  'tech',
  'technician',
  'technique',
  'technologist',
  'technology',
  'tectonics',
  'teen',
  'teenager',
  'teepee',
  'telephone',
  'telescreen',
  'teletype',
  'television',
  'tell',
  'teller',
  'temp',
  'temper',
  'temperature',
  'temple',
  'tempo',
  'temporariness',
  'temporary',
  'temptation',
  'temptress',
  'tenant',
  'tendency',
  'tender',
  'tenement',
  'tenet',
  'tennis',
  'tenor',
  'tension',
  'tensor',
  'tent',
  'tentacle',
  'tenth',
  'tepee',
  'teriyaki',
  'term',
  'terminal',
  'termination',
  'terminology',
  'termite',
  'terrace',
  'terracotta',
  'terrapin',
  'terrarium',
  'territory',
  'terror',
  'terrorism',
  'terrorist',
  'test',
  'testament',
  'testimonial',
  'testimony',
  'testing',
  'text',
  'textbook',
  'textual',
  'texture',
  'thanks',
  'thaw',
  'theater',
  'theft',
  'theism',
  'theme',
  'theology',
  'theory',
  'therapist',
  'therapy',
  'thermals',
  'thermometer',
  'thermostat',
  'thesis',
  'thickness',
  'thief',
  'thigh',
  'thing',
  'thinking',
  'thirst',
  'thistle',
  'thong',
  'thongs',
  'thorn',
  'thought',
  'thousand',
  'thread',
  'threat',
  'threshold',
  'thrift',
  'thrill',
  'throat',
  'throne',
  'thrush',
  'thrust',
  'thug',
  'thumb',
  'thump',
  'thunder',
  'thunderbolt',
  'thunderhead',
  'thunderstorm',
  'thyme',
  'tiara',
  'tic',
  'tick',
  'ticket',
  'tide',
  'tie',
  'tiger',
  'tights',
  'tile',
  'till',
  'tilt',
  'timbale',
  'timber',
  'time',
  'timeline',
  'timeout',
  'timer',
  'timetable',
  'timing',
  'timpani',
  'tin',
  'tinderbox',
  'tinkle',
  'tintype',
  'tip',
  'tire',
  'tissue',
  'titanium',
  'title',
  'toad',
  'toast',
  'toaster',
  'tobacco',
  'today',
  'toe',
  'toenail',
  'toffee',
  'tofu',
  'tog',
  'toga',
  'toilet',
  'tolerance',
  'tolerant',
  'toll',
  'tom-tom',
  'tomatillo',
  'tomato',
  'tomb',
  'tomography',
  'tomorrow',
  'ton',
  'tonality',
  'tone',
  'tongue',
  'tonic',
  'tonight',
  'tool',
  'toot',
  'tooth',
  'toothbrush',
  'toothpaste',
  'toothpick',
  'top',
  'top-hat',
  'topic',
  'topsail',
  'toque',
  'toreador',
  'tornado',
  'torso',
  'torte',
  'tortellini',
  'tortilla',
  'tortoise',
  'tosser',
  'total',
  'tote',
  'touch',
  'tough-guy',
  'tour',
  'tourism',
  'tourist',
  'tournament',
  'tow-truck',
  'towel',
  'tower',
  'town',
  'townhouse',
  'township',
  'toy',
  'trace',
  'trachoma',
  'track',
  'tracking',
  'tracksuit',
  'tract',
  'tractor',
  'trade',
  'trader',
  'trading',
  'tradition',
  'traditionalism',
  'traffic',
  'trafficker',
  'tragedy',
  'trail',
  'trailer',
  'trailpatrol',
  'train',
  'trainer',
  'training',
  'trait',
  'tram',
  'tramp',
  'trance',
  'transaction',
  'transcript',
  'transfer',
  'transformation',
  'transit',
  'transition',
  'translation',
  'transmission',
  'transom',
  'transparency',
  'transplantation',
  'transport',
  'transportation',
  'trap',
  'trapdoor',
  'trapezium',
  'trapezoid',
  'trash',
  'travel',
  'traveler',
  'tray',
  'treasure',
  'treasury',
  'treat',
  'treatment',
  'treaty',
  'tree',
  'trek',
  'trellis',
  'tremor',
  'trench',
  'trend',
  'triad',
  'trial',
  'triangle',
  'tribe',
  'tributary',
  'trick',
  'trigger',
  'trigonometry',
  'trillion',
  'trim',
  'trinket',
  'trip',
  'tripod',
  'tritone',
  'triumph',
  'trolley',
  'trombone',
  'troop',
  'trooper',
  'trophy',
  'trouble',
  'trousers',
  'trout',
  'trove',
  'trowel',
  'truck',
  'trumpet',
  'trunk',
  'trust',
  'trustee',
  'truth',
  'try',
  'tsunami',
  'tub',
  'tuba',
  'tube',
  'tuber',
  'tug',
  'tugboat',
  'tuition',
  'tulip',
  'tumbler',
  'tummy',
  'tuna',
  'tune',
  'tune-up',
  'tunic',
  'tunnel',
  'turban',
  'turf',
  'turkey',
  'turmeric',
  'turn',
  'turning',
  'turnip',
  'turnover',
  'turnstile',
  'turret',
  'turtle',
  'tusk',
  'tussle',
  'tutu',
  'tuxedo',
  'tweet',
  'tweezers',
  'twig',
  'twilight',
  'twine',
  'twins',
  'twist',
  'twister',
  'twitter',
  'type',
  'typeface',
  'typewriter',
  'typhoon',
  'ukulele',
  'ultimatum',
  'umbrella',
  'unblinking',
  'uncertainty',
  'uncle',
  'underclothes',
  'underestimate',
  'underground',
  'underneath',
  'underpants',
  'underpass',
  'undershirt',
  'understanding',
  'understatement',
  'undertaker',
  'underwear',
  'underweight',
  'underwire',
  'underwriting',
  'unemployment',
  'unibody',
  'uniform',
  'uniformity',
  'union',
  'unique',
  'unit',
  'unity',
  'universe',
  'university',
  'update',
  'upgrade',
  'uplift',
  'upper',
  'upstairs',
  'upward',
  'urge',
  'urgency',
  'urn',
  'usage',
  'use',
  'user',
  'usher',
  'usual',
  'utensil',
  'utilisation',
  'utility',
  'utilization',
  'vacation',
  'vaccine',
  'vacuum',
  'vagrant',
  'valance',
  'valentine',
  'validate',
  'validity',
  'valley',
  'valuable',
  'value',
  'vampire',
  'van',
  'vanadyl',
  'vane',
  'vanilla',
  'vanity',
  'variability',
  'variable',
  'variant',
  'variation',
  'variety',
  'vascular',
  'vase',
  'vault',
  'vaulting',
  'veal',
  'vector',
  'vegetable',
  'vegetarian',
  'vegetarianism',
  'vegetation',
  'vehicle',
  'veil',
  'vein',
  'veldt',
  'vellum',
  'velocity',
  'velodrome',
  'velvet',
  'vendor',
  'veneer',
  'vengeance',
  'venison',
  'venom',
  'venti',
  'venture',
  'venue',
  'veranda',
  'verb',
  'verdict',
  'verification',
  'vermicelli',
  'vernacular',
  'verse',
  'version',
  'vertigo',
  'verve',
  'vessel',
  'vest',
  'vestment',
  'vet',
  'veteran',
  'veterinarian',
  'veto',
  'viability',
  'vibe',
  'vibraphone',
  'vibration',
  'vibrissae',
  'vice',
  'vicinity',
  'victim',
  'victory',
  'video',
  'view',
  'viewer',
  'vignette',
  'villa',
  'village',
  'vine',
  'vinegar',
  'vineyard',
  'vintage',
  'vintner',
  'vinyl',
  'viola',
  'violation',
  'violence',
  'violet',
  'violin',
  'virginal',
  'virtue',
  'virus',
  'visa',
  'viscose',
  'vise',
  'vision',
  'visit',
  'visitor',
  'visor',
  'vista',
  'visual',
  'vitality',
  'vitamin',
  'vitro',
  'vivo',
  'vixen',
  'vodka',
  'vogue',
  'voice',
  'void',
  'vol',
  'volatility',
  'volcano',
  'volleyball',
  'volume',
  'volunteer',
  'volunteering',
  'vomit',
  'vote',
  'voter',
  'voting',
  'voyage',
  'vulture',
  'wad',
  'wafer',
  'waffle',
  'wage',
  'wagon',
  'waist',
  'waistband',
  'wait',
  'waiter',
  'waiting',
  'waitress',
  'waiver',
  'wake',
  'walk',
  'walker',
  'walking',
  'walkway',
  'wall',
  'wallaby',
  'wallet',
  'walnut',
  'walrus',
  'wampum',
  'wannabe',
  'want',
  'war',
  'warden',
  'wardrobe',
  'warfare',
  'warlock',
  'warlord',
  'warm-up',
  'warming',
  'warmth',
  'warning',
  'warrant',
  'warren',
  'warrior',
  'wasabi',
  'wash',
  'washbasin',
  'washcloth',
  'washer',
  'washtub',
  'wasp',
  'waste',
  'wastebasket',
  'wasting',
  'watch',
  'watcher',
  'watchmaker',
  'water',
  'waterbed',
  'watercress',
  'waterfall',
  'waterfront',
  'watermelon',
  'waterskiing',
  'waterspout',
  'waterwheel',
  'wave',
  'waveform',
  'wax',
  'way',
  'weakness',
  'wealth',
  'weapon',
  'wear',
  'weasel',
  'weather',
  'web',
  'webinar',
  'webmail',
  'webpage',
  'website',
  'wedding',
  'wedge',
  'weed',
  'weeder',
  'weedkiller',
  'week',
  'weekend',
  'weekender',
  'weight',
  'weird',
  'welcome',
  'welfare',
  'well',
  'well-being',
  'west',
  'western',
  'wet-bar',
  'wetland',
  'wetsuit',
  'whack',
  'whale',
  'wharf',
  'wheat',
  'wheel',
  'whelp',
  'whey',
  'whip',
  'whirlpool',
  'whirlwind',
  'whisker',
  'whiskey',
  'whisper',
  'whistle',
  'white',
  'whole',
  'wholesale',
  'wholesaler',
  'whorl',
  'wick',
  'widget',
  'widow',
  'width',
  'wife',
  'wifi',
  'wild',
  'wildebeest',
  'wilderness',
  'wildlife',
  'will',
  'willingness',
  'willow',
  'win',
  'wind',
  'wind-chime',
  'windage',
  'window',
  'windscreen',
  'windshield',
  'wine',
  'winery',
  'wing',
  'wingman',
  'wingtip',
  'wink',
  'winner',
  'winter',
  'wire',
  'wiretap',
  'wiring',
  'wisdom',
  'wiseguy',
  'wish',
  'wisteria',
  'wit',
  'witch',
  'witch-hunt',
  'withdrawal',
  'witness',
  'wok',
  'wolf',
  'woman',
  'wombat',
  'wonder',
  'wont',
  'wood',
  'woodchuck',
  'woodland',
  'woodshed',
  'woodwind',
  'wool',
  'woolens',
  'word',
  'wording',
  'work',
  'workbench',
  'worker',
  'workforce',
  'workhorse',
  'working',
  'workout',
  'workplace',
  'workshop',
  'world',
  'worm',
  'worry',
  'worship',
  'worshiper',
  'worth',
  'wound',
  'wrap',
  'wraparound',
  'wrapper',
  'wrapping',
  'wreck',
  'wrecker',
  'wren',
  'wrench',
  'wrestler',
  'wriggler',
  'wrinkle',
  'wrist',
  'writer',
  'writing',
  'wrong',
  'xylophone',
  'yacht',
  'yahoo',
  'yak',
  'yam',
  'yang',
  'yard',
  'yarmulke',
  'yarn',
  'yawl',
  'year',
  'yeast',
  'yellow',
  'yellowjacket',
  'yesterday',
  'yew',
  'yin',
  'yoga',
  'yogurt',
  'yoke',
  'yolk',
  'young',
  'youngster',
  'yourself',
  'youth',
  'yoyo',
  'yurt',
  'zampone',
  'zebra',
  'zebrafish',
  'zen',
  'zephyr',
  'zero',
  'ziggurat',
  'zinc',
  'zipper',
  'zither',
  'zombie',
  'zone',
  'zoo',
  'zoologist',
  'zoology',
  'zoot-suit',
  'zucchini',
];

const adjectives = [
  'A-OK',
  'AWOL',
  'Aaronic',
  'Aaronical',
  'Abbevillian',
  'Aberdeen',
  'Abyssinian',
  'Acadian',
  'Accadian',
  'Achaean',
  'Acheulean',
  'Acheulian',
  'Achillean',
  'Adam',
  'Adamic',
  'Adamitic',
  'Adamitical',
  'Adonic',
  'Adriatic',
  'Aegean',
  'Aeneolithic',
  'Aeolic',
  'Aesculapian',
  'Afric',
  'African',
  'Afro-American',
  'Afro-Asian',
  'Akkadian',
  'Alabaman',
  'Alabamian',
  'Alaskan',
  'Albanian',
  'Albigensian',
  'Alcaic',
  'Alcibiadean',
  'Aldine',
  'Alemannic',
  'Alexandrian',
  'Algerian',
  'Algonkian',
  'Algonkin',
  'Algonquian',
  'Algonquin',
  'Alhambresque',
  'Alice-in-Wonderland',
  'Alsatian',
  'Altaic',
  'American',
  'Amerindian',
  'Amerindic',
  'Amharic',
  'Amish',
  'Anacreontic',
  'Anatolian',
  'Andalusian',
  'Andean',
  'Andorra',
  'Andorran',
  'Angevin',
  'Anglian',
  'Anglican',
  'Anglo-American',
  'Anglo-Catholic',
  'Anglo-French',
  'Anglo-Indian',
  'Anglo-Irish',
  'Anglo-Norman',
  'Anglo-Saxon',
  'Anglophobiac',
  'Antarctic',
  'Antiguan',
  'Antiochian',
  'Apollonian',
  'Appalachian',
  'Aquarius',
  'Arabian',
  'Arabic',
  'Aragon',
  'Aramaic',
  'Aran',
  'Arawakan',
  'Arcadian',
  'Archaean',
  'Archilochian',
  'Archimedean',
  'Arctogaean',
  'Areopagitic',
  'Argentine',
  'Argive',
  'Arian',
  'Aristophanic',
  'Aristotelian',
  'Arizonan',
  'Arizonian',
  'Arkansan',
  'Armenian',
  'Armenoid',
  'Arminian',
  'Armorican',
  'Arthurian',
  'Aryan',
  'Asclepiadean',
  'Asian',
  'Asianic',
  'Asiatic',
  'Assamese',
  'Assyrian',
  'Athanasian',
  'Athenian',
  'Atlantean',
  'Atlantic',
  'Aubusson',
  'Augean',
  'Augustan',
  'Augustinian',
  'Aurignacian',
  'Australasian',
  'Australian',
  'Australoid',
  'Australopithecine',
  'Austrian',
  'Austroasiatic',
  'Austronesian',
  'Avestan',
  'Aymaran',
  'Azilian',
  'Aztec',
  'Aztecan',
  'Babist',
  'Babylonian',
  'Babylonish',
  'Bacchic',
  'Baconian',
  'Bactrian',
  'Bahai',
  'Bahamian',
  'Balaamitical',
  'Balinese',
  'Balkan',
  'Baltic',
  'Baluchi',
  'Bantu',
  'Barbadian',
  'Barmecidal',
  'Barmecide',
  'Bathonian',
  'Bavarian',
  'Belgian',
  'Belgic',
  'Belgravian',
  'Belorussian',
  'Bengalese',
  'Bengali',
  'Bentham',
  'Berber',
  'Bergsonian',
  'Berkeleian',
  'Bermuda',
  'Bermudan',
  'Bermudian',
  'Bernardine',
  'Bessarabian',
  'Bhutan',
  'Biafran',
  'Biedermeier',
  'Bihari',
  'Bloomsbury',
  'Boeotian',
  'Bohemian',
  'Bolivian',
  'Bonapartean',
  'Bordelaise',
  'Bosnian',
  'Boswellian',
  'Brahmanic',
  'Brahmanical',
  'Brahminic',
  'Brahminical',
  'Brazilian',
  'Briarean',
  'Britannic',
  'British',
  'Brittonic',
  'Brummagem',
  'Brythonic',
  'Buddhism',
  'Buddhist',
  'Buddhistic',
  'Bulgarian',
  'Burgundian',
  'Burman',
  'Burmese',
  'Byelorussian',
  'Byronic',
  'Byzantine',
  'Caenozoic',
  'Caesarean',
  'Cainozoic',
  'Cairene',
  'Calabrian',
  'Caledonian',
  'Californian',
  'Calvinism',
  'Calvinist',
  'Calvinistic',
  'Calvinistical',
  'Cambodian',
  'Cambrian',
  'Cameronian',
  'Canadian',
  'Canarese',
  'Canopic',
  'Cantabrigian',
  'Cantonese',
  'Capetian',
  'Capricorn',
  'Capsian',
  'Caribbean',
  'Carlovingian',
  'Carolean',
  'Caroline',
  'Carolingian',
  'Carolinian',
  'Cartesian',
  'Carthaginian',
  'Caspian',
  'Castalian',
  'Castilian',
  'Catalan',
  'Caucasian',
  'Caucasoid',
  'Celsius',
  'Celtic',
  'Cenozoic',
  'Cesarean',
  'Ceylonese',
  'Chadic',
  'Chaldaic',
  'Chaldean',
  'Chantilly',
  'Charybdian',
  'Chasidic',
  'Chaucerian',
  'Chautauqua',
  'Chekhovian',
  'Chellean',
  'Chian',
  'Chilean',
  'Chinese',
  'Chomsky',
  'Christadelphian',
  'Christian',
  'Christianlike',
  'Christianly',
  'Christless',
  'Christlike',
  'Christly',
  'Christocentric',
  'Christological',
  'Ciceronian',
  'Cimmerian',
  'Cingalese',
  'Circassian',
  'Circean',
  'Clactonian',
  'Colombian',
  'Columban',
  'Columbian',
  'Comtian',
  'Comtist',
  'Confucian',
  'Congolese',
  'Constantinian',
  'Copernican',
  'Coptic',
  'Corinthian',
  'Cornish',
  'Corsican',
  'Cossack',
  'Cretan',
  'Crimean',
  'Croat',
  'Croatian',
  'Cromwellian',
  'Cuban',
  'Cufic',
  'Cushitic',
  'Cymric',
  'Cypriot',
  'Cypriote',
  'Cyrenaic',
  'Cyrillic',
  'Cytherean',
  'Czech',
  'Czechoslovak',
  'Czechoslovakian',
  'Dada',
  'Dadaistic',
  'Daedalian',
  'Dalmatian',
  'Daltonian',
  'Damoclean',
  'Danish',
  'Dantean',
  'Dantesque',
  'Dardic',
  'Darwinian',
  'Darwinism',
  'Darwinist',
  'Delian',
  'Delphi',
  'Delphian',
  'Delphic',
  'Deuteronomic',
  'Devonian',
  'Dickensian',
  'Diogenic',
  'Dionysiac',
  'Dionysian',
  'Directoire',
  'Dodonaean',
  'Dominican',
  'Dorian',
  'Doric',
  'Dravidian',
  'Dresden',
  'Drusian',
  'Ecuadoran',
  'Ecuadorian',
  'Eddic',
  'Edenic',
  'Edwardian',
  'Egyptian',
  'Egyptological',
  'Einsteinian',
  'Elamite',
  'Eleatic',
  'Eleusinian',
  'Elizabethan',
  'Elohistic',
  'Elysian',
  'Elzevir',
  'English',
  'Eocene',
  'Eolian',
  'Eolic',
  'Eozoic',
  'Ephesian',
  'Epicurean',
  'Erastian',
  'Eritrean',
  'Erse',
  'Esculapian',
  'Eskimo',
  'Esperanto',
  'Esquimau',
  'Esthonian',
  'Estonian',
  'Ethiop',
  'Ethiopian',
  'Ethiopic',
  'Etonian',
  'Etruscan',
  'Euclidean',
  'Eurasian',
  'Euro-American',
  'Eurocommunism',
  'European',
  'Euterpean',
  'Eyetie',
  'Fabian',
  'Fabianism',
  'Faeroese',
  'Fahrenheit',
  'Falange',
  'Falernian',
  'Falstaffian',
  'Faroese',
  'Fauve',
  'Fenian',
  'Fescennine',
  'Fijian',
  'Filipino',
  'Finnic',
  'Finnish',
  'Finno-Ugrian',
  'Finno-Ugric',
  'Flemish',
  'Fourierism',
  'Fourieristic',
  'Frankish',
  'French-Canadian',
  'Frenchy',
  'Freudian',
  'Friesian',
  'Froebelian',
  'Fulani',
  'Gadarene',
  'Gadhelic',
  'Gaelic',
  'Galatian',
  'Galenic',
  'Galician',
  'Galilean',
  'Gallic',
  'Gallican',
  'Galwegian',
  'Gambia',
  'Gandhian',
  'Gaulish',
  'Gaullist',
  'Genesiac',
  'Genesitic',
  'Genevan',
  'Genevese',
  'Genoese',
  'Genovese',
  'Georgian',
  'Germanic',
  'Ghanaian',
  'Gilbertian',
  'Girondist',
  'Glagolitic',
  'Glaswegian',
  'Gobelin',
  'Goidelic',
  'Gongoristic',
  'Gordian',
  'Gothic',
  'Graeco-Roman',
  'Gravettian',
  'Grecian',
  'Greco-Roman',
  'Greek',
  'Greekish',
  'Gregorian',
  'Grenada',
  'Grenadian',
  'Grolier',
  'Grotian',
  'Guam',
  'Guatemalan',
  'Guelfic',
  'Guinean',
  'Gujarati',
  'Haitian',
  'Hallstatt',
  'Hamiltonian',
  'Hamitic',
  'Hanoverian',
  'Hanseatic',
  'Harrovian',
  'Hasidic',
  'Hawaiian',
  'Heath-Robinson',
  'Hebraic',
  'Hebraistic',
  'Hebrew',
  'Hebridean',
  'Hebrides',
  'Hegelian',
  'Heliconian',
  'Helladic',
  'Hellenic',
  'Hellenistic',
  'Helvetian',
  'Helvetic',
  'Hepplewhite',
  'Heraclean',
  'Heraclidan',
  'Heraclitean',
  'Herbartian',
  'Hercynian',
  'Hertzian',
  'Hesperian',
  'Hibernian',
  'Hieronymic',
  'Himalayan',
  'Himyarite',
  'Himyaritic',
  'Hindoo',
  'Hindu',
  'Hindustani',
  'Hippocratic',
  'Hispanic',
  'Hitlerite',
  'Hittite',
  'Hobbes',
  'Hobbesian',
  'Hobbistical',
  'Holarctic',
  'Holocene',
  'Homeric',
  'Honduran',
  'Horatian',
  'Hudibrastic',
  'Huguenot',
  'Hungarian',
  'Hunnish',
  'Hussite',
  'Hygeian',
  'Iberian',
  'Ibsenian',
  'Icarian',
  'Icelandic',
  'Idaean',
  'Idahoan',
  'Illinoian',
  'Illinois',
  'Illyrian',
  'Incan',
  'Indian',
  'Indic',
  'Indo-Aryan',
  'Indo-European',
  'Indo-Germanic',
  'Indo-Iranian',
  'Indo-Pacific',
  'Indonesian',
  'Ionian',
  'Iranian',
  'Iraqi',
  'Irish',
  'Iroquoian',
  'Iroquois',
  'Ishmaelitish',
  'Isiac',
  'Isidorian',
  'Islamic',
  'Israeli',
  'Israelitish',
  'Italian',
  'Italianate',
  'Ithaca',
  'Jacksonian',
  'Jacobean',
  'Jacobethan',
  'Jacobin',
  'Jacobinic',
  'Jacobinical',
  'Jacobitic',
  'Jacobitical',
  'Jain',
  'Jainism',
  'Jamaica',
  'Jamaican',
  'Jamesian',
  'Jansenism',
  'Janus-faced',
  'Japanese',
  'Japanesque',
  'Japhetic',
  'Java',
  'Jebusitic',
  'Jeffersonian',
  'Jehovist',
  'Jehovistic',
  'Jewish',
  'Johannine',
  'Johnsonian',
  'Jonsonian',
  'Jovian',
  'Joyce',
  'Joycean',
  'Judaean',
  'Judaic',
  'Judean',
  'Jugoslav',
  'Jugoslavian',
  'Julian',
  'Jungian',
  'Junoesque',
  'Jurassic',
  'Juvenalian',
  'Kafka',
  'Kantian',
  'Karaite',
  'Karoo',
  'Keltic',
  'Kentish',
  'Kenyan',
  'Keplerian',
  'Khmer',
  'Koranic',
  'Korean',
  'Kufic',
  'Kurdish',
  'Kuwaiti',
  'Kwa',
  'Laconia',
  'Laconian',
  'Lamaism',
  'Lamarckian',
  'Lancastrian',
  'Languedocian',
  'Lao',
  'Laodicean',
  'Laos',
  'Laotian',
  'Lapp',
  'Lappish',
  'Laputan',
  'Latin',
  'Latin-American',
  'Latinate',
  'Latvian',
  'Laurentian',
  'Lawrentian',
  'Leibnitzian',
  'Leibnizian',
  'Lemnian',
  'Leninism',
  'Leninist',
  'Lettic',
  'Lettish',
  'Levantine',
  'Liassic',
  'Liberian',
  'Libyan',
  'Ligurian',
  'Linnean',
  'Lithuanian',
  'Liverpudlian',
  'Locrian',
  'Londonish',
  'Londony',
  'Lucan',
  'Lucullan',
  'Lucullean',
  'Lucullian',
  'Luddite',
  'Lupercalian',
  'Lusatian',
  'Lusitanian',
  'Lutheran',
  'Lydian',
  'Maccabean',
  'Macedonian',
  'Machiavellian',
  'Madagascan',
  'Madagascar',
  'Magdalenian',
  'Magian',
  'Maglemosian',
  'Magyar',
  'Mahdi',
  'Mahometan',
  'Mahratta',
  'Majorcan',
  'Malagasy',
  'Malay',
  'Malpighian',
  'Maltese',
  'Malthusian',
  'Manchu',
  'Manchurian',
  'Manchus',
  'Mancunian',
  'Mandaean',
  'Mande',
  'Manichean',
  'Mantuan',
  'Manx',
  'Maoism',
  'Maori',
  'Marathi',
  'Marathonian',
  'Marcan',
  'Marian',
  'Mariolatrous',
  'Marquesan',
  'Martian',
  'Martinique',
  'Marxian',
  'Marxist',
  'Masoretic',
  'Massoretic',
  'Mauretanian',
  'Mauritania',
  'Mauritanian',
  'Mauritian',
  'Mayan',
  'McCarthyism',
  'Mede',
  'Medicean',
  'Melanesian',
  'Melbourne',
  'Memnonian',
  'Memphian',
  'Memphite',
  'Mendelian',
  'Menshevist',
  'Mephistophelean',
  'Mephistophelian',
  'Mercian',
  'Merovingian',
  'Mesolithic',
  'Mesopotamia',
  'Mesopotamian',
  'Mesozoic',
  'Mexican',
  'Micawberish',
  'Micronesian',
  'Midian',
  'Milanese',
  'Milesian',
  'Miltonic',
  'Minoan',
  'Miocene',
  'Mishnaic',
  'Mishnic',
  'Mississippian',
  'Missouri',
  'Mithraism',
  'Mohammedan',
  'Monaco',
  'Monarchian',
  'Monegasque',
  'Mongolian',
  'Moravian',
  'Moresco',
  'Moresque',
  'Morisco',
  'Mormon',
  'Moroccan',
  'Moslem',
  'Mousterian',
  'Mozarabic',
  'Mozart',
  'Mozartean',
  'Mozartian',
  'Muhammadan',
  'Muscovite',
  'Muscovitic',
  'Muslim',
  'Mycenaean',
  'Napierian',
  'Napoleonic',
  'Nasmyth',
  'Nazarene',
  'Nazi',
  'Neanderthal',
  'Neanderthaloid',
  'Neapolitan',
  'Nearctic',
  'Negro',
  'Neo-Catholic',
  'Neo-Darwinian',
  'Neo-Gothic',
  'Neo-Impressionist',
  'Neo-Kantian',
  'Neo-Lamarckian',
  'Neogaean',
  'Neogene',
  'Neolithic',
  'Neotropical',
  'Neozoic',
  'Nepalese',
  'Nepali',
  'Neptunian',
  'Nestorianism',
  'Netherlandic',
  'Nicaean',
  'Nicene',
  'Nietzschean',
  'Niger-Congo',
  'Nilotic',
  'Niobean',
  'Noachian',
  'Nordic',
  'Norman-French',
  'Normanesque',
  'Norse',
  'Northumbrian',
  'Norwegian',
  'Notogaea',
  'Nubian',
  'Numidia',
  'Numidian',
  "OK'd",
  'Occidentalist',
  'Oceanian',
  'Oedipean',
  'Ogygian',
  'Oligocene',
  'Olympian',
  'Olympic',
  'Orcadian',
  'Ordovician',
  'Origenistic',
  'Orphean',
  'Orphic',
  'Oscan',
  'Osmanli',
  'Ossianic',
  'Ossie',
  'Ostrogothic',
  'Ottoman',
  'Ovidian',
  'Oxonian',
  'Paduan',
  'Pakistan',
  'Palaearctic',
  'Palaeocene',
  'Palaeogene',
  'Palaeozoic',
  'Palladian',
  'Pan-African',
  'Pan-American',
  'Pan-Arab',
  'Pan-Arabic',
  'Pan-German',
  'Pan-Slav',
  'Pan-Slavic',
  'Panamanian',
  'Panathenaic',
  'Pandean',
  'Panjabi',
  'Pantagruelian',
  'Paphian',
  'Papuan',
  'Paracelsian',
  'Parian',
  'Paris',
  'Parnassian',
  'Parnell',
  'Parsee',
  'Parthia',
  'Pashto',
  'Pasteurian',
  'Pauline',
  'Paulinistic',
  'Pecksniffian',
  'Pekingese',
  'Pelagian',
  'Pelagius',
  'Pelasgian',
  'Peloponnesian',
  'Pennsylvanian',
  'Pentecostal',
  'Pentelic',
  'Pentelican',
  'Periclean',
  'Perigordian',
  'Permian',
  'Peronist',
  'Persian',
  'Peruvian',
  'Pestalozzian',
  'Petrarchan',
  'Petrine',
  'Phanerozoic',
  'Philippian',
  'Phlegethontic',
  'Phoebean',
  'Phoenician',
  'Phrygian',
  'Pickwickian',
  'Pierian',
  'Pindaric',
  'Pleiocene',
  'Pleistocene',
  'Pliocene',
  'Plutonian',
  'Polaroid',
  'Polynesian',
  'Pomeranian',
  'Pompeian',
  'Portuguese',
  'Praxitelean',
  'Pre-Raphaelite',
  'Presbyterian',
  'Procrustean',
  'Proustian',
  'Prussian',
  'Ptolemaic',
  'Punic',
  'Punjabi',
  'Puranic',
  'Puseyism',
  'Puseyistical',
  'Pushto',
  'Pyrenean',
  'Pyrrho',
  'Pythagorean',
  'Pythian',
  'Quaker',
  'Quechuan',
  'Queen-Anne',
  'Rabelaisian',
  'Rankine',
  'Rastafarian',
  'Rembrandtesque',
  'Rembrandtish',
  'Rhaetian',
  'Rhaetic',
  'Rhemish',
  'Rhenish',
  'Rhodesian',
  'Rhodian',
  'Riemannian',
  'Ripuarian',
  'Romaic',
  'Romanesque',
  'Romanian',
  'Romanic',
  'Romansh',
  'Romish',
  'Rommany',
  'Roscian',
  'Rosicrucian',
  'Rotarian',
  'Rumanian',
  'Russ',
  'Russian',
  'Russky',
  'Ruthenian',
  'Sabaean',
  'Sabbatarian',
  'Sabbathless',
  'Sabean',
  'Sabellian',
  'Sabine',
  'Sadducean',
  'Sagittarius',
  'Saharan',
  'Saint-Simonianism',
  'Saiva',
  'Salian',
  'Salique',
  'Salishan',
  'Salopian',
  'Samaritan',
  'Samian',
  'Samnite',
  'Samoa',
  'Samoan',
  'Samoyedic',
  'Sanskritic',
  'Sapphic',
  'Saracen',
  'Sardinian',
  'Sarmatia',
  'Sarmatian',
  'Saturnalian',
  'Saturnian',
  'Savoyard',
  'Saxon',
  'Saxonian',
  'Saxonic',
  'Scandinavian',
  'Scillonian',
  'Scotch-Irish',
  'Scotism',
  'Scotistic',
  'Scots',
  'Scottish',
  'Scriabin',
  'Scythian',
  'Seleucid',
  'Seljuk',
  'Semitic',
  'Senecan',
  'Sephardic',
  'Septuagintal',
  'Serb',
  'Serbian',
  'Serbo-Croatian',
  'Serbonian',
  'Servian',
  'Shakespearean',
  'Shang',
  'Shavian',
  'Sheraton',
  'Shiah',
  'Shiite',
  'Shinto',
  'Shintoist',
  'Shivaistic',
  'Siberia',
  'Siberian',
  'Sicanian',
  'Sicilian',
  'Sienese',
  'Sikh',
  'Silurian',
  'Singhalese',
  'Sinhalese',
  'Sistine',
  'Sisyphean',
  'Sivaistic',
  'Slavic',
  'Slavonic',
  'Slavophile',
  'Slovak',
  'Slovakian',
  'Slovene',
  'Slovenian',
  'Socinian',
  'Socratic',
  'Solomonic',
  'Solonian',
  'Solutrean',
  'Somali',
  'Somalia',
  'Somalian',
  'Sophoclean',
  'Sorbian',
  'Sothic',
  'Spanish',
  'Spartan',
  'Spencerian',
  'Spenserian',
  'Spinozistic',
  'Stalinism',
  'Stalinist',
  'Stygian',
  'Sudanese',
  'Sufistic',
  'Sumatran',
  'Sumerian',
  'Sunday-go-to-meeting',
  'Suprematism',
  'Swadeshi',
  'Swedenborgian',
  'Swedenborgianism',
  'Swedish',
  'Swiss',
  'Syrian',
  'Tagalog',
  'Tahitian',
  'Tamil',
  'Taoism',
  'Taoist',
  'Taoistic',
  'Tardenoisian',
  'Targumic',
  'Tartarean',
  'Tartarian',
  'Tatar',
  'Tatarian',
  'Taurus',
  'Telugu',
  'Teucrian',
  'Teuton',
  'Teutonic',
  'Thai',
  'Thebaic',
  'Theban',
  'Theocritean',
  'Thessalonian',
  'Thomism',
  'Thomist',
  'Thomistic',
  'Thracian',
  'Tibetan',
  'Tirolean',
  'Titanesque',
  'Titianesque',
  'Titoism',
  'Titoist',
  'Togolese',
  'Toltec',
  'Tongan',
  'Torricellian',
  'Tory',
  'Toryish',
  'Tridentine',
  'Trinacrian',
  'Trinidadian',
  'Trinitarian',
  'Trojan',
  'Trollopean',
  'Trollopian',
  'Trotskyism',
  'Trotskyite',
  'Tudor',
  'Tungusic',
  'Tunisian',
  'Tupian',
  'Turanian',
  'Turki',
  'Turkish',
  'Turkmenian',
  'Turko-Tatar',
  'Turkoman',
  'Tuscan',
  'Typhoean',
  'Typhonian',
  'Tyrian',
  'Tyrolean',
  'Tyrolese',
  'U-shaped',
  'Ugandan',
  'Ugrian',
  'Ugric',
  'Ugro-Finnic',
  'Ukrainian',
  'Umbrian',
  'Uniat',
  'Unitarian',
  'Ural-Altaic',
  'Uralian',
  'Uralic',
  'Uranian',
  'Uruguayan',
  'Uto-Aztecan',
  'V-shaped',
  'Veddoid',
  'Vedic',
  'Vendean',
  'Venetian',
  'Venetianed',
  'Venezuelan',
  'Venusian',
  'Vergilian',
  'Victorian',
  'Villanovan',
  'Vincentian',
  'Virgilian',
  'Virginian',
  'Vishnu',
  'Visigothic',
  'Vitruvian',
  'Volscian',
  'Voltairean',
  'Voltairian',
  'Wafd',
  'Walachian',
  'Waldenses',
  'Waldensian',
  'Wallachian',
  'Walloon',
  'Waltonian',
  'Wedgwood',
  'Wendish',
  'Wernerian',
  'Wertherian',
  'Wesleyan',
  'Westphalian',
  'Whiggish',
  'Whitsun',
  'Wordsworthian',
  'Wycliffite',
  'Yankee',
  'Yemen',
  'Yorkist',
  'Yoruban',
  'Yugoslav',
  'Yugoslavian',
  'Yugoslavic',
  'Zairean',
  'Zarathustrian',
  'Zarathustric',
  'Zionism',
  'Zionist',
  'Zoroastrian',
  'Zwinglian',
  'Zyrian',
  'abactinal',
  'abandoned',
  'abased',
  'abashed',
  'abatable',
  'abating',
  'abaxial',
  'abbatial',
  'abbreviated',
  'abdicable',
  'abdicant',
  'abdominal',
  'abdominous',
  'abducent',
  'abducted',
  'abducting',
  'abecedarian',
  'aberrant',
  'aberrational',
  'abessive',
  'abeyant',
  'abhominable',
  'abhorrent',
  'abiding',
  'abiogenetic',
  'abiotic',
  'abject',
  'ablated',
  'ablatival',
  'ablative',
  'ablaze',
  'able',
  'able-bodied',
  'abler',
  'ablest',
  'abloom',
  'ablush',
  'ablutionary',
  'abnormal',
  'abolishable',
  'abolition',
  'abolitionary',
  'abomasal',
  'abominable',
  'aboral',
  'aboriginal',
  'aborning',
  'abortifacient',
  'abortional',
  'abortive',
  'abounding',
  'about',
  'above',
  'above-board',
  'above-mentioned',
  'above-named',
  'abradant',
  'abraded',
  'abranchial',
  'abranchiate',
  'abrasive',
  'abreast',
  'abridgable',
  'abridgeable',
  'abridged',
  'abroach',
  'abroad',
  'abrogative',
  'abrupt',
  'abscessed',
  'absent',
  'absent-minded',
  'absolute',
  'absolutist',
  'absolutory',
  'absolved',
  'absonant',
  'absorbable',
  'absorbed',
  'absorbefacient',
  'absorbent',
  'absorbing',
  'absorptive',
  'abstemious',
  'abstentious',
  'abstergent',
  'abstersive',
  'abstinent',
  'abstract',
  'abstracted',
  'abstractional',
  'abstractionist',
  'abstractive',
  'abstruse',
  'absurd',
  'abundant',
  'abused',
  'abusive',
  'abutting',
  'abuzz',
  'abysmal',
  'abyssal',
  'academic',
  'academical',
  'acanthaceous',
  'acanthine',
  'acanthocephalan',
  'acanthoid',
  'acanthopterygian',
  'acanthous',
  'acarid',
  'acaridan',
  'acarine',
  'acaroid',
  'acarpellous',
  'acarpelous',
  'acarpous',
  'acatalectic',
  'acaudal',
  'acaudate',
  'acaulescent',
  'accelerando',
  'accelerated',
  'accelerating',
  'accelerative',
  'acceleratory',
  'accented',
  'accentual',
  'acceptable',
  'acceptant',
  'accepted',
  'accepting',
  'acceptive',
  'accessible',
  'accessorial',
  'accessory',
  'accident-prone',
  'accidental',
  'accipitrine',
  'acclamatory',
  'acclimatisable',
  'acclimatizable',
  'acclivitous',
  'acclivous',
  'accommodable',
  'accommodating',
  'accommodative',
  'accompanied',
  'accompanying',
  'accomplishable',
  'accomplished',
  'accordable',
  'accordant',
  'according',
  'accordion',
  'accostable',
  'accosted',
  'accountable',
  'accoutered',
  'accoutred',
  'accredited',
  'accrescent',
  'accrete',
  'accretive',
  'accrued',
  'accumbent',
  'accumulated',
  'accumulative',
  'accurate',
  'accursed',
  'accurst',
  'accusable',
  'accusatival',
  'accusative',
  'accusatorial',
  'accusatory',
  'accusing',
  'accustomed',
  'ace',
  'acellular',
  'acephalous',
  'acerate',
  'acerb',
  'acerbic',
  'acerose',
  'acerous',
  'acervate',
  'acescent',
  'acetabular',
  'acetic',
  'acetose',
  'acetous',
  'achenial',
  'achievable',
  'aching',
  'achlamydeous',
  'achondroplastic',
  'achromatic',
  'achromatous',
  'achy',
  'acicular',
  'aciculate',
  'acid',
  'acid-fast',
  'acidic',
  'acidifiable',
  'acidulated',
  'acidulent',
  'acidulous',
  'aciform',
  'acinaceous',
  'acinaciform',
  'aciniform',
  'acinose',
  'acinous',
  'acknowledgeable',
  'acknowledged',
  'acock',
  'acold',
  'aconitic',
  'acorned',
  'acotyledonous',
  'acoustic',
  'acoustical',
  'acquainted',
  'acquiescent',
  'acquirable',
  'acquired',
  'acquisitive',
  'acquitted',
  'acred',
  'acrid',
  'acrimonious',
  'acrobatic',
  'acrocentric',
  'acrogenic',
  'acrogenous',
  'acrolithic',
  'acromegalic',
  'acronical',
  'acronychal',
  'acronymic',
  'acronymous',
  'acropetal',
  'acrophonic',
  'across',
  'across-the-board',
  'acroterial',
  'acrylic',
  'actable',
  'actinal',
  'acting',
  'actinian',
  'actinic',
  'actinoid',
  'actinomorphic',
  'actionable',
  'activated',
  'activating',
  'active',
  'activist',
  'actual',
  'actualist',
  'actualized',
  'actuarial',
  'actuated',
  'actuating',
  'aculeate',
  'aculeated',
  'acuminate',
  'acuminous',
  'acute',
  'acyclic',
  'ad-lib',
  'adactylous',
  'adagio',
  'adamant',
  'adamantine',
  'adaptable',
  'adaptative',
  'adapted',
  'adaptive',
  'adaxial',
  'added',
  'addicted',
  'addictive',
  'additional',
  'additive',
  'addle',
  'addled',
  'addorsed',
  'addressable',
  'addressed',
  'adducent',
  'adducible',
  'adducting',
  'adductive',
  'adenoid',
  'adenoidal',
  'adenomatous',
  'adept',
  'adequate',
  'adessive',
  'adherent',
  'adhesive',
  'adiabatic',
  'adiaphoristic',
  'adiaphorous',
  'adipose',
  'adjacent',
  'adjectival',
  'adjective',
  'adjoining',
  'adjudicative',
  'adjunct',
  'adjunctive',
  'adjuratory',
  'adjustable',
  'adjusted',
  'adjuvant',
  'adminicular',
  'administrable',
  'administrant',
  'administrative',
  'admirable',
  'admirative',
  'admired',
  'admiring',
  'admissible',
  'admissive',
  'admittable',
  'admitted',
  'admonished',
  'admonishing',
  'admonitory',
  'adnate',
  'adnominal',
  'adolescent',
  'adopted',
  'adoptive',
  'adorable',
  'adored',
  'adoring',
  'adorned',
  'adpressed',
  'adrenal',
  'adrenergic',
  'adrenocorticotrophic',
  'adrenocorticotropic',
  'adrift',
  'adroit',
  'adscititious',
  'adscript',
  'adsorbable',
  'adsorbate',
  'adsorbed',
  'adsorbent',
  'adulatory',
  'adult',
  'adulterant',
  'adulterate',
  'adulterated',
  'adulterating',
  'adulterine',
  'adulterous',
  'adumbrative',
  'adunc',
  'adust',
  'advance',
  'advanced',
  'advancing',
  'advantaged',
  'advantageous',
  'adventitious',
  'adventive',
  'adventuresome',
  'adventuristic',
  'adventurous',
  'adverbial',
  'adversative',
  'adverse',
  'advertent',
  'advertised',
  'advisable',
  'advised',
  'advisory',
  'advocatory',
  'adynamic',
  'aeneous',
  'aeolian',
  'aeolotropic',
  'aeonian',
  'aerated',
  'aerial',
  'aeriform',
  'aerobatic',
  'aerobic',
  'aerobiological',
  'aerobiosis',
  'aerobiotic',
  'aerodynamic',
  'aerodynamical',
  'aeroelastic',
  'aerolitic',
  'aerological',
  'aerometric',
  'aeronautic',
  'aeronautical',
  'aerophobic',
  'aerostatic',
  'aerotropic',
  'aeruginous',
  'aery',
  'aesthetic',
  'aesthetical',
  'aestival',
  'aetiological',
  'afeard',
  'afeared',
  'affable',
  'affected',
  'affecting',
  'affectional',
  'affectionate',
  'affectioned',
  'affective',
  'afferent',
  'affettuoso',
  'affianced',
  'affiliable',
  'affiliated',
  'affine',
  'affined',
  'affinitive',
  'affirmable',
  'affirmative',
  'affirmatory',
  'affixed',
  'afflated',
  'afflicted',
  'afflictive',
  'affluent',
  'affordable',
  'affricative',
  'affronted',
  'affrontive',
  'afghan',
  'afghani',
  'afire',
  'aflame',
  'afloat',
  'aflutter',
  'afoot',
  'aforementioned',
  'aforesaid',
  'aforethought',
  'afoul',
  'afraid',
  'aft',
  'after',
  'after-dinner',
  'aftermost',
  'afternoon',
  'aftmost',
  'agamic',
  'agamid',
  'agamous',
  'agape',
  'agaze',
  'age-old',
  'aged',
  'agee',
  'ageing',
  'ageless',
  'agelong',
  'agential',
  'agentive',
  'agglomerate',
  'agglomerated',
  'agglomerative',
  'agglutinable',
  'agglutinant',
  'agglutinate',
  'agglutinative',
  'aggravated',
  'aggravating',
  'aggregate',
  'aggregately',
  'aggregative',
  'aggressive',
  'aggrieved',
  'aghast',
  'agile',
  'aging',
  'agitated',
  'agitating',
  'agitative',
  'agitato',
  'agleam',
  'agley',
  'aglimmer',
  'aglitter',
  'aglow',
  'agnate',
  'agnatic',
  'agnatical',
  'agnominal',
  'agnostic',
  'ago',
  'agog',
  'agone',
  'agonic',
  'agonistic',
  'agonistical',
  'agonized',
  'agonizing',
  'agoraphobic',
  'agraphic',
  'agrarian',
  'agravic',
  'agreeable',
  'agreed',
  'agreeing',
  'agrestal',
  'agrestic',
  'agricultural',
  'agrobiological',
  'agrological',
  'agronomic',
  'agronomical',
  'agrostological',
  'aground',
  'aguish',
  'ahead',
  'ahistorical',
  'ahorse',
  'ahorseback',
  'ahull',
  'ahungered',
  'aided',
  'aidful',
  'aidless',
  'ailing',
  'ailurophilic',
  'ailurophobic',
  'aimless',
  'ain',
  'air',
  'air-conditioned',
  'air-conditioning',
  'air-cooled',
  'air-mail',
  'air-minded',
  'air-raid',
  'air-to-air',
  'airborne',
  'aired',
  'airier',
  'airiest',
  'airless',
  'airsick',
  'airtight',
  'airworthy',
  'airy',
  'airy-fairy',
  'aisled',
  'ajar',
  'akimbo',
  'akin',
  'alabaster',
  'alabastrine',
  'alar',
  'alarmed',
  'alarming',
  'alarmist',
  'alary',
  'alate',
  'alated',
  'albescent',
  'albinic',
  'albinistic',
  'albinotic',
  'albitic',
  'albuminoid',
  'albuminous',
  'alburnous',
  'alchemic',
  'alchemical',
  'alcoholic',
  'alcyonarian',
  'aldermanic',
  'aldermanly',
  'aleatory',
  'alembicated',
  'alert',
  'alexic',
  'alexipharmic',
  'alfresco',
  'algal',
  'algebraic',
  'algebraical',
  'algid',
  'algoid',
  'algological',
  'algorithmic',
  'alicyclic',
  'alien',
  'alienable',
  'alienated',
  'alienating',
  'aliform',
  'alight',
  'aligned',
  'aligning',
  'alike',
  'alimental',
  'alimentary',
  'alimentative',
  'alined',
  'aliped',
  'aliphatic',
  'aliquant',
  'aliquot',
  'aliunde',
  'alive',
  'alkalescent',
  'alkaline',
  'all',
  'all-American',
  'all-day',
  'all-fired',
  'all-important',
  'all-in',
  'all-inclusive',
  'all-night',
  'all-out',
  'all-over',
  'all-powerful',
  'all-purpose',
  'all-round',
  'all-star',
  'all-time',
  'all-weather',
  'allantoic',
  'allantoid',
  'allargando',
  'allative',
  'alleged',
  'allegiant',
  'allegoric',
  'allegorical',
  'allegretto',
  'allegro',
  'allelomorphic',
  'allergenic',
  'allergic',
  'alleviated',
  'alleviative',
  'alleviatory',
  'alliaceous',
  'allied',
  'alliterative',
  'allocable',
  'allocatable',
  'allochthonous',
  'allodial',
  'allogamous',
  'allometric',
  'allonymous',
  'allopathic',
  'allopatric',
  'allophonic',
  'allotriomorphic',
  'allotropic',
  'allotted',
  'allowable',
  'allowed',
  'alloyed',
  'alluring',
  'allusive',
  'alluvial',
  'almighty',
  'almond-eyed',
  'alodial',
  'aloetic',
  'alone',
  'aloof',
  'alpha',
  'alphabetic',
  'alphabetical',
  'alphabetized',
  'alphameric',
  'alphamerical',
  'alphanumeric',
  'alphanumerical',
  'alpine',
  'alright',
  'alt',
  'alterable',
  'alterant',
  'alterative',
  'altered',
  'altern',
  'alternant',
  'alternate',
  'alternating',
  'alternative',
  'altimetrical',
  'altissimo',
  'altitudinal',
  'altitudinous',
  'alto',
  'altricial',
  'altruistic',
  'aluminiferous',
  'aluminous',
  'aluminum',
  'alveated',
  'alveolar',
  'alveolate',
  'alvine',
  'amalgamate',
  'amalgamated',
  'amalgamative',
  'amandine',
  'amaranthaceous',
  'amaranthine',
  'amaryllidaceous',
  'amassable',
  'amassed',
  'amateur',
  'amateurish',
  'amative',
  'amatory',
  'amaurotic',
  'amazed',
  'amazing',
  'ambagious',
  'ambassadorial',
  'amber',
  'amberous',
  'ambery',
  'ambidexter',
  'ambidextrous',
  'ambient',
  'ambiguous',
  'ambilateral',
  'ambisexual',
  'ambitionless',
  'ambitious',
  'ambivalent',
  'ambrosial',
  'ambrosian',
  'ambulacral',
  'ambulant',
  'ambulatory',
  'amebic',
  'ameboid',
  'ameliorating',
  'ameliorative',
  'amenable',
  'amendable',
  'amendatory',
  'amended',
  'amentaceous',
  'amental',
  'amentiferous',
  'amerceable',
  'amerciable',
  'ametabolic',
  'ametabolous',
  'amethyst',
  'amethystine',
  'amiable',
  'amicable',
  'amiss',
  'amitotic',
  'ammoniac',
  'ammoniacal',
  'ammoniated',
  'ammophilous',
  'amnesiac',
  'amnesic',
  'amniotic',
  'amoebaean',
  'amoebic',
  'amoeboid',
  'amok',
  'amoral',
  'amoroso',
  'amorous',
  'amorphous',
  'amort',
  'amphibian',
  'amphibious',
  'amphibolic',
  'amphibological',
  'amphibolous',
  'amphibrachic',
  'amphictyonic',
  'amphipod',
  'amphiprotic',
  'amphisbaenic',
  'amphitheatric',
  'amphitheatrical',
  'amphitropous',
  'amphoric',
  'amphoteric',
  'ample',
  'ampler',
  'amplest',
  'amplexicaul',
  'amuck',
  'amusable',
  'amused',
  'amusing',
  'amusive',
  'amygdalaceous',
  'amygdaloid',
  'amygdaloidal',
  'amylaceous',
  'amyloid',
  'amyloidal',
  'an-end',
  'anabatic',
  'anabiotic',
  'anabolic',
  'anacardiaceous',
  'anachronic',
  'anachronistic',
  'anachronous',
  'anaclastic',
  'anacrustic',
  'anadromous',
  'anaemic',
  'anaerobic',
  'anaerobiotic',
  'anaesthetic',
  'anaesthetized',
  'anaglyphic',
  'anaglyptic',
  'anagogic',
  'anagogical',
  'anagrammatic',
  'anagrammatical',
  'anal',
  'analectic',
  'analeptic',
  'analgesic',
  'analog',
  'analogical',
  'analogous',
  'analogue',
  'analphabetic',
  'analysable',
  'analytic',
  'analytical',
  'analyzable',
  'analyzed',
  'anamnestic',
  'anamorphic',
  'anandrous',
  'ananthous',
  'anapaestic',
  'anaphoric',
  'anaphrodisiac',
  'anaphylactic',
  'anaplastic',
  'anaptyctic',
  'anarchic',
  'anarchical',
  'anarchistic',
  'anarthrous',
  'anastigmatic',
  'anastomotic',
  'anatomic',
  'anatomical',
  'anatropous',
  'ancestral',
  'anchoretic',
  'anchoritic',
  'anchorless',
  'ancient',
  'ancillary',
  'ancipital',
  'andante',
  'andantino',
  'andesitic',
  'androcentric',
  'androdioecious',
  'androecial',
  'androgenic',
  'androgenous',
  'androgynous',
  'android',
  'andromonoecious',
  'ane',
  'anecdotal',
  'anecdotical',
  'anechoic',
  'anemic',
  'anemographic',
  'anemometric',
  'anemometrical',
  'anemophilous',
  'anencephalic',
  'aneroid',
  'anesthetic',
  'anesthetized',
  'aneuploid',
  'aneurismal',
  'aneurysmal',
  'anfractuous',
  'angelic',
  'angelical',
  'angered',
  'anginal',
  'angiocarpous',
  'angiospermous',
  'angled',
  'anglophilic',
  'anglophobic',
  'angrier',
  'angriest',
  'angry',
  'anguilliform',
  'anguine',
  'anguished',
  'angular',
  'angulate',
  'anharmonic',
  'anhedonic',
  'anhydrous',
  'aniconic',
  'anile',
  'animal',
  'animalcular',
  'animalic',
  'animate',
  'animated',
  'animating',
  'animist',
  'animistic',
  'anionic',
  'anisodactylous',
  'anisomerous',
  'anisophyllous',
  'anisotropic',
  'annalistic',
  'annealed',
  'annectent',
  'annelid',
  'annihilated',
  'annihilating',
  'annihilative',
  'anniversary',
  'announced',
  'annoyed',
  'annoying',
  'annual',
  'annular',
  'annulate',
  'annulated',
  'annulose',
  'annunciative',
  'anodal',
  'anodic',
  'anodyne',
  'anoestrous',
  'anoetic',
  'anomalistic',
  'anomalous',
  'anomic',
  'anonymous',
  'anopheline',
  'anorectal',
  'anorectic',
  'anorexic',
  'anorthic',
  'another',
  'anourous',
  'anoxic',
  'ansate',
  'anserine',
  'answerable',
  'answering',
  'answerless',
  'ant',
  'antacid',
  'antagonistic',
  'antagonizing',
  'antarthritic',
  'antasthmatic',
  'ante',
  'ante-Nicene',
  'ante-bellum',
  'antecedent',
  'antediluvial',
  'antediluvian',
  'antefixal',
  'antemeridian',
  'antemundane',
  'antenatal',
  'antennal',
  'antennary',
  'antenniform',
  'antenuptial',
  'anteorbital',
  'antepenultimate',
  'anteprandial',
  'anterior',
  'anthelminthic',
  'anthelmintic',
  'anthocarpous',
  'anthophilous',
  'anthracitic',
  'anthracoid',
  'anthropic',
  'anthropical',
  'anthropocentric',
  'anthropogenic',
  'anthropoid',
  'anthropoidal',
  'anthropological',
  'anthropometric',
  'anthropomorphic',
  'anthropomorphous',
  'anthropopathic',
  'anthropophagous',
  'anthroposophical',
  'anti',
  'anti-Semitic',
  'antibacterial',
  'antiballistic',
  'antibilious',
  'antibiotic',
  'antic',
  'anticholinergic',
  'antichristian',
  'anticipant',
  'anticipated',
  'anticipative',
  'anticipatory',
  'anticivic',
  'anticlerical',
  'anticlimactic',
  'anticlinal',
  'anticlockwise',
  'anticoagulant',
  'anticonvulsant',
  'anticorrosive',
  'anticyclone',
  'anticyclonic',
  'antidepressant',
  'antidiuretic',
  'antidotal',
  'antidromic',
  'antifouling',
  'antifriction',
  'antigenic',
  'antiknock',
  'antimalarial',
  'antimicrobial',
  'antimodernist',
  'antimonarchical',
  'antimonarchist',
  'antimonial',
  'antimonic',
  'antimonious',
  'antinodal',
  'antinoise',
  'antinomian',
  'antinomic',
  'antinomical',
  'antiodontalgic',
  'antipapal',
  'antiparallel',
  'antipathetic',
  'antipathetical',
  'antiperiodic',
  'antiperistaltic',
  'antiperspirant',
  'antipetalous',
  'antiphlogistic',
  'antiphonal',
  'antiphonary',
  'antiphonic',
  'antiphrastic',
  'antiphrastical',
  'antipodal',
  'antipodean',
  'antipruritic',
  'antipyretic',
  'antiquarian',
  'antiquated',
  'antique',
  'antirachitic',
  'antirust',
  'antiscorbutic',
  'antiscriptural',
  'antisepalous',
  'antiseptic',
  'antiskid',
  'antisocial',
  'antispasmodic',
  'antistatic',
  'antistrophic',
  'antisubmarine',
  'antitank',
  'antithetic',
  'antithetical',
  'antitoxic',
  'antitrade',
  'antitussive',
  'antitypic',
  'antitypical',
  'antiviral',
  'antivirus',
  'antiwar',
  'antlered',
  'antliate',
  'antlike',
  'antonymous',
  'antrorse',
  'anucleate',
  'anurous',
  'anxiolytic',
  'anxious',
  'any',
  'aoristic',
  'aortal',
  'aortic',
  'apart',
  'apartmental',
  'apatetic',
  'apathetic',
  'apeak',
  'aperient',
  'aperiodic',
  'aperitive',
  'apetalous',
  'aphasic',
  'aphelian',
  'apheliotropic',
  'aphetic',
  'aphidian',
  'aphidious',
  'aphonic',
  'aphoristic',
  'aphotic',
  'aphrodisiac',
  'aphyllous',
  'apian',
  'apiarian',
  'apical',
  'apiculate',
  'apish',
  'apivorous',
  'aplacental',
  'aplanatic',
  'aplastic',
  'apocalyptic',
  'apocalyptical',
  'apocarpous',
  'apochromatic',
  'apocrine',
  'apocryphal',
  'apocynaceous',
  'apodal',
  'apodeictic',
  'apodictic',
  'apodous',
  'apogamic',
  'apogamous',
  'apogeal',
  'apogean',
  'apogeotropic',
  'apolitical',
  'apologetic',
  'apomictic',
  'apomictical',
  'aponeurotic',
  'apopemptic',
  'apophthegmatic',
  'apophthegmatical',
  'apoplectic',
  'aposematic',
  'aposiopetic',
  'apostate',
  'apostolic',
  'apostolical',
  'apostrophic',
  'apothecial',
  'apothegmatic',
  'apothegmatical',
  'apotropaic',
  'appalled',
  'appalling',
  'apparent',
  'apparitional',
  'appassionato',
  'appealable',
  'appealing',
  'appeasable',
  'appeasing',
  'appellant',
  'appellate',
  'appellative',
  'appendant',
  'appendicular',
  'appendiculate',
  'apperceptive',
  'appetent',
  'appetitive',
  'appetizing',
  'applausive',
  'appliable',
  'applicable',
  'applicative',
  'applicatory',
  'applied',
  'appointed',
  'appointive',
  'apportioned',
  'apposite',
  'appositely',
  'appositional',
  'appositive',
  'appraisable',
  'appraising',
  'appraisive',
  'appreciable',
  'appreciated',
  'appreciative',
  'appreciatory',
  'apprehensible',
  'apprehensive',
  'apprentice',
  'apprenticed',
  'appressed',
  'approachable',
  'approaching',
  'approbative',
  'approbatory',
  'appropriate',
  'appropriated',
  'appropriative',
  'approvable',
  'approved',
  'approving',
  'approximal',
  'approximate',
  'approximative',
  'appurtenant',
  'apropos',
  'apsidal',
  'apt',
  'apteral',
  'apterous',
  'apterygial',
  'apyretic',
  'aqua',
  'aquaphobic',
  'aquarian',
  'aquatic',
  'aqueous',
  'aquiline',
  'arabesque',
  'arable',
  'araceous',
  'arachnidan',
  'arachnoid',
  'araeostyle',
  'araeosystyle',
  'araliaceous',
  'arbitrable',
  'arbitral',
  'arbitrary',
  'arboraceous',
  'arboreal',
  'arboreous',
  'arborescent',
  'arboricultural',
  'arborous',
  'arc',
  'arcane',
  'arced',
  'arch',
  'archaeological',
  'archaic',
  'archaistic',
  'archangelic',
  'archducal',
  'arched',
  'archegonial',
  'archegoniate',
  'archetypal',
  'archetypical',
  'archidiaconal',
  'archiepiscopal',
  'arching',
  'archipelagic',
  'architectonic',
  'architectural',
  'architraved',
  'archival',
  'arctic',
  'arcuate',
  'ardent',
  'arduous',
  'areal',
  'arenaceous',
  'arenicolous',
  'areolar',
  'areolate',
  'areostyle',
  'argent',
  'argentiferous',
  'argillaceous',
  'arguable',
  'arguing',
  'argumentative',
  'argus-eyed',
  'argyle',
  'arhythmic',
  'arid',
  'arillate',
  'arilloid',
  'aristate',
  'aristocratic',
  'aristocratical',
  'arithmetic',
  'arithmetical',
  'armchair',
  'armed',
  'armigeral',
  'armigerous',
  'armillary',
  'armipotent',
  'armless',
  'armor-plated',
  'armored',
  'armorial',
  'armour-clad',
  'armour-plated',
  'armoured',
  'aroid',
  'aromatic',
  'around-the-clock',
  'aroused',
  'arow',
  'arpeggiated',
  'arranged',
  'arrant',
  'arrased',
  'arrayed',
  'arrestable',
  'arrested',
  'arresting',
  'arrestive',
  'arrhythmic',
  'arriving',
  'arrogant',
  'arrogated',
  'arrowy',
  'arsenic',
  'arsenical',
  'arsenious',
  'artefactual',
  'arterial',
  'arteriosclerotic',
  'artful',
  'arthralgic',
  'arthritic',
  'arthropodal',
  'articled',
  'articulable',
  'articular',
  'articulate',
  'articulated',
  'articulating',
  'articulatory',
  'artier',
  'artiest',
  'artificial',
  'artiodactyl',
  'artisanal',
  'artistic',
  'artless',
  'arty',
  'arty-crafty',
  'arundinaceous',
  'aryballoid',
  'arytenoid',
  'asbestine',
  'asbestous',
  'ascendable',
  'ascendant',
  'ascendent',
  'ascendible',
  'ascending',
  'ascensional',
  'ascensive',
  'ascertainable',
  'ascertained',
  'ascetic',
  'ascetical',
  'ascidian',
  'ascitic',
  'ascitical',
  'asclepiadaceous',
  'ascribable',
  'asepalous',
  'aseptic',
  'asexual',
  'ashake',
  'ashamed',
  'ashen',
  'ashier',
  'ashiest',
  'ashy',
  'asinine',
  'askance',
  'askant',
  'askew',
  'asking',
  'aslant',
  'asleep',
  'aslope',
  'asocial',
  'aspectual',
  'aspen',
  'aspersive',
  'asphalt',
  'asphaltic',
  'aspheric',
  'asphyxial',
  'asphyxiant',
  'asphyxiated',
  'asphyxiating',
  'aspirant',
  'aspirate',
  'aspiratory',
  'aspiring',
  'asprawl',
  'asquint',
  'assailable',
  'assassinated',
  'assault',
  'assaulted',
  'assayable',
  'assembled',
  'assentient',
  'assenting',
  'assentive',
  'assertable',
  'asserted',
  'asserting',
  'assertive',
  'assertory',
  'assessable',
  'assessorial',
  'assiduous',
  'assignable',
  'assigned',
  'assimilable',
  'assimilating',
  'assimilative',
  'assistant',
  'assisted',
  'associable',
  'associate',
  'associated',
  'associative',
  'assonant',
  'assonantal',
  'assortative',
  'assorted',
  'assuasive',
  'assumable',
  'assumed',
  'assuming',
  'assumptive',
  'assured',
  'assurgent',
  'assuring',
  'astable',
  'astatic',
  'asteriated',
  'asterisked',
  'asteroid',
  'asteroidal',
  'asthenic',
  'asthmatic',
  'astigmatic',
  'astir',
  'astomatous',
  'astonied',
  'astonished',
  'astonishing',
  'astounded',
  'astounding',
  'astral',
  'astrictive',
  'astringent',
  'astrological',
  'astronomic',
  'astronomical',
  'astrophysical',
  'astucious',
  'astute',
  'astylar',
  'asunder',
  'aswarm',
  'asymmetric',
  'asymmetrical',
  'asymptomatic',
  'asymptotic',
  'asynchronous',
  'asyndetic',
  'asyntactic',
  'at-home',
  'atactic',
  'ataractic',
  'ataraxic',
  'atavistic',
  'ataxic',
  'atelectatic',
  'atheism',
  'atheist',
  'atheistic',
  'atheistical',
  'athematic',
  'atherine',
  'athermanous',
  'atheromatous',
  'atherosclerotic',
  'athetoid',
  'athetosic',
  'athirst',
  'athletic',
  'athrill',
  'atilt',
  'atingle',
  'atmospheric',
  'atmospherical',
  'atomic',
  'atomism',
  'atomistic',
  'atonal',
  'atonic',
  'atrabilious',
  'atrial',
  'atrip',
  'atrocious',
  'atrophied',
  'attachable',
  'attached',
  'attack',
  'attackable',
  'attacking',
  'attainable',
  'attained',
  'attemptable',
  'attempted',
  'attendant',
  'attended',
  'attending',
  'attent',
  'attentional',
  'attentive',
  'attenuant',
  'attenuate',
  'attenuated',
  'attestable',
  'attestative',
  'attested',
  'attic',
  'attired',
  'attitudinal',
  'attractable',
  'attractive',
  'attrahent',
  'attributable',
  'attributive',
  'attrite',
  'attritional',
  'attuned',
  'atwitter',
  'atypical',
  'auburn',
  'auctionary',
  'auctorial',
  'audacious',
  'audible',
  'audient',
  'audile',
  'audiometric',
  'audiovisual',
  'auditive',
  'auditory',
  'augitic',
  'augmentable',
  'augmentative',
  'augmented',
  'augmenting',
  'augural',
  'august',
  'auld',
  'aulic',
  'aural',
  'aureate',
  'auric',
  'auricled',
  'auricular',
  'auriculate',
  'auriculated',
  'auriferous',
  'auriform',
  'auroral',
  'aurorean',
  'aurous',
  'auscultatory',
  'auspicious',
  'austenitic',
  'austere',
  'austral',
  'autarchic',
  'autarchical',
  'autarkic',
  'autarkical',
  'autecologic',
  'autecological',
  'authentic',
  'authentical',
  'authenticated',
  'authorial',
  'authorisable',
  'authorised',
  'authoritarian',
  'authoritative',
  'authorizable',
  'authorized',
  'authorless',
  'autistic',
  'autobiographic',
  'autobiographical',
  'autocatalytic',
  'autocephalous',
  'autochthonous',
  'autocratic',
  'autodidactic',
  'autoerotic',
  'autogamic',
  'autogamous',
  'autogenic',
  'autogenous',
  'autographed',
  'autographic',
  'autokinetic',
  'autologous',
  'autolytic',
  'automated',
  'automatic',
  'automorphic',
  'automotive',
  'autonomic',
  'autonomous',
  'autoplastic',
  'autoradiograph',
  'autoradiographic',
  'autosomal',
  'autotelic',
  'autotrophic',
  'autumn',
  'autumnal',
  'auxetic',
  'auxiliary',
  'available',
  'avant-garde',
  'avaricious',
  'avascular',
  'avenaceous',
  'avengeful',
  'avenging',
  'average',
  'averse',
  'aversive',
  'avertable',
  'avertible',
  'avian',
  'avid',
  'avionic',
  'avocado',
  'avoidable',
  'avowable',
  'avowed',
  'avulsed',
  'avuncular',
  'awaited',
  'awake',
  'awakened',
  'awakening',
  'aware',
  'awash',
  'away',
  'awe-inspiring',
  'aweary',
  'awed',
  'aweless',
  'awesome',
  'awestricken',
  'awestruck',
  'awful',
  'awheel',
  'awing',
  'awkward',
  'awned',
  'awnless',
  'awny',
  'awry',
  'axial',
  'axile',
  'axillary',
  'axiological',
  'axiomatic',
  'axiomatical',
  'axonometric',
  'azeotropic',
  'azimuthal',
  'azoic',
  'azonal',
  'azonic',
  'azotic',
  'azure',
  'azygos',
  'azygous',
  'babbling',
  'baboonish',
  'baby',
  'babyish',
  'baccate',
  'bacchanal',
  'bacchanalian',
  'bacciferous',
  'bacciform',
  'baccivorous',
  'bacillar',
  'bacillary',
  'bacilliform',
  'back',
  'back-to-back',
  'backboned',
  'backboneless',
  'backbreaking',
  'backed',
  'backhand',
  'backhanded',
  'backless',
  'backmost',
  'backstage',
  'backstair',
  'backstairs',
  'backswept',
  'backward',
  'backwoods',
  'bacterial',
  'bactericidal',
  'bacterioid',
  'bacteriological',
  'bacteriolytic',
  'bacteriostatic',
  'bacteroid',
  'baculiform',
  'baculine',
  'bad',
  'bad-tempered',
  'baddish',
  'badgerly',
  'baffled',
  'baffling',
  'baggier',
  'baggiest',
  'baggy',
  'bailable',
  'bairnly',
  'baked',
  'baking',
  'baking-hot',
  'balanced',
  'balconied',
  'bald',
  'bald-headed',
  'balding',
  'baldish',
  'baldpated',
  'baleful',
  'balked',
  'balkier',
  'balkiest',
  'balking',
  'balky',
  'ball-bearing',
  'balled',
  'balletic',
  'ballistic',
  'ballooning',
  'bally',
  'balmier',
  'balmiest',
  'balmy',
  'balneal',
  'balsamic',
  'balsamiferous',
  'balsamy',
  'baluster',
  'balustered',
  'balustraded',
  'bamboo',
  'banal',
  'bananas',
  'banausic',
  'bandaged',
  'banded',
  'bandoleered',
  'bandoliered',
  'bandy',
  'bandy-legged',
  'baneful',
  'bang-up',
  'banging',
  'bangled',
  'bankable',
  'bankrupt',
  'banned',
  'banner',
  'bannered',
  'bantam',
  'bantering',
  'baptismal',
  'baptist',
  'bar',
  'barbarian',
  'barbaric',
  'barbarous',
  'barbate',
  'barbecued',
  'barbed',
  'barbellate',
  'barbituric',
  'bardic',
  'bardy',
  'bare',
  'bareback',
  'barebacked',
  'bared',
  'barefaced',
  'barefoot',
  'barefooted',
  'barehanded',
  'bareheaded',
  'bareknuckle',
  'barelegged',
  'barer',
  'barest',
  'bargain',
  'bargain-basement',
  'baric',
  'baring',
  'barish',
  'baritone',
  'barkier',
  'barkiest',
  'barkless',
  'barky',
  'barmier',
  'barmiest',
  'barmy',
  'barnacled',
  'barometric',
  'barometrical',
  'baronetical',
  'baronial',
  'baroque',
  'barratrous',
  'barred',
  'barrel-chested',
  'barrel-vaulted',
  'barrelled',
  'barren',
  'barricaded',
  'barristerial',
  'bartizaned',
  'barytic',
  'barytone',
  'basal',
  'basaltic',
  'base',
  'based',
  'baseless',
  'baser',
  'basest',
  'bashful',
  'basic',
  'basidial',
  'basidiomycetous',
  'basifixed',
  'basilar',
  'basilican',
  'basipetal',
  'basophil',
  'basophilic',
  'bass',
  'bassy',
  'bastard',
  'bastardized',
  'bastardly',
  'bastioned',
  'bated',
  'bathetic',
  'batholithic',
  'batholitic',
  'bathyal',
  'bathymetric',
  'bathymetrical',
  'bathypelagic',
  'batrachian',
  'bats',
  'bats-in-the-belfry',
  'battailous',
  'battered',
  'battier',
  'battiest',
  'battle-scarred',
  'battled',
  'battlemented',
  'battological',
  'batty',
  'batwing',
  'bausond',
  'bauxitic',
  'bawdier',
  'bawdiest',
  'bawdy',
  'bawling',
  'bay',
  'beached',
  'beachy',
  'beaded',
  'beadier',
  'beadiest',
  'beady',
  'beady-eyed',
  'beaked',
  'beaky',
  'beamier',
  'beamiest',
  'beaming',
  'beamish',
  'beamless',
  'beamy',
  'bearable',
  'bearded',
  'beardless',
  'bearing',
  'bearish',
  'bearlike',
  'beastlier',
  'beastliest',
  'beastlike',
  'beastly',
  'beat',
  'beat-up',
  'beatable',
  'beaten',
  'beatific',
  'beatified',
  'beating',
  'beauish',
  'beaut',
  'beauteous',
  'beautiful',
  'becalmed',
  'becoming',
  'bedaubed',
  'beddable',
  'bedded',
  'bedecked',
  'bedewed',
  'bedfast',
  'bedight',
  'bedimmed',
  'bedraggled',
  'bedrid',
  'bedridden',
  'beechen',
  'beef-witted',
  'beefier',
  'beefiest',
  'beefy',
  'beerier',
  'beeriest',
  'beery',
  'beetle',
  'beetle-browed',
  'beetling',
  'befitting',
  'befogged',
  'beforehand',
  'befouled',
  'befuddled',
  'beggarly',
  'beginning',
  'begotten',
  'begrimed',
  'begrudging',
  'beguiled',
  'beguiling',
  'behavioral',
  'behaviorist',
  'behavioural',
  'behaviourist',
  'beheaded',
  'behind',
  'beholden',
  'beige',
  'bejeweled',
  'bejewelled',
  'belated',
  'believable',
  'belittled',
  'belittling',
  'bell-bottomed',
  'belletristic',
  'bellicose',
  'bellied',
  'belligerent',
  'bellying',
  'beloved',
  'belted',
  'bemazed',
  'bemused',
  'ben',
  'bended',
  'bending',
  'bendwise',
  'bendy',
  'benedictional',
  'benedictive',
  'benedictory',
  'benefic',
  'beneficed',
  'beneficent',
  'beneficial',
  'beneficiary',
  'benevolent',
  'benighted',
  'benign',
  'benignant',
  'bent',
  'benthic',
  'benthonic',
  'benumbed',
  'benzal',
  'benzoic',
  'bequeathable',
  'berberidaceous',
  'bereaved',
  'bereft',
  'beribboned',
  'berried',
  'berserk',
  'beseeching',
  'besetting',
  'besieged',
  'besmeared',
  'besmirched',
  'besotted',
  'bespangled',
  'bespattered',
  'bespectacled',
  'bespoke',
  'bespoken',
  'besprent',
  'best',
  'best-ball',
  'best-selling',
  'bestead',
  'bested',
  'bestial',
  'bestowed',
  'betraying',
  'betrothed',
  'better',
  'bettering',
  'betting',
  'bevel',
  'beveled',
  'bewhiskered',
  'bewildered',
  'bewildering',
  'bewitched',
  'bewitching',
  'biannual',
  'bias',
  'biased',
  'biaxal',
  'biaxial',
  'bibbed',
  'biblical',
  'bibliographic',
  'bibliographical',
  'bibliolatrous',
  'bibliological',
  'bibliomania',
  'bibliomaniacal',
  'bibliopegic',
  'bibliopolic',
  'bibliopolical',
  'bibulous',
  'bicameral',
  'bicentenary',
  'bicentennial',
  'bicephalous',
  'bicipital',
  'biconcave',
  'biconvex',
  'bicorn',
  'bicuspid',
  'bicuspidate',
  'biddable',
  'bidentate',
  'bidirectional',
  'biennial',
  'bifacial',
  'bifarious',
  'bifid',
  'bifilar',
  'bifocal',
  'bifold',
  'bifoliate',
  'bifoliolate',
  'biform',
  'bifurcate',
  'bifurcated',
  'big',
  'big-bellied',
  'big-league',
  'big-name',
  'big-ticket',
  'big-time',
  'bigamous',
  'bigeneric',
  'bigger',
  'biggest',
  'biggish',
  'bigheaded',
  'bighearted',
  'bignoniaceous',
  'bigoted',
  'bijou',
  'bilabial',
  'bilabiate',
  'bilateral',
  'bilgier',
  'bilgiest',
  'bilgy',
  'biliary',
  'bilingual',
  'bilious',
  'biliteral',
  'billed',
  'billion',
  'billionth',
  'billowier',
  'billowiest',
  'billowing',
  'billowy',
  'bilobate',
  'bilobed',
  'bilocular',
  'bimanous',
  'bimanual',
  'bimestrial',
  'bimetallic',
  'bimillenary',
  'bimodal',
  'bimolecular',
  'bimonthly',
  'binary',
  'binate',
  'binaural',
  'binding',
  'binocular',
  'binomial',
  'binominal',
  'biobibliographical',
  'biochemical',
  'biodegradable',
  'biodynamic',
  'biogenetic',
  'biogenic',
  'biogenous',
  'biogeochemical',
  'biogeographical',
  'biographic',
  'biographical',
  'biological',
  'bioluminescent',
  'biomedical',
  'biometric',
  'biomorphic',
  'bionic',
  'bionomic',
  'biophysical',
  'bioplasmic',
  'biosynthetic',
  'biosystematic',
  'biotechnological',
  'biotic',
  'biparous',
  'bipartisan',
  'bipartite',
  'biped',
  'bipedal',
  'bipetalous',
  'bipinnate',
  'bipolar',
  'biquadratic',
  'biracial',
  'biramous',
  'birch',
  'birchen',
  "bird's-eye",
  'bird-brained',
  'birdlike',
  'birefringent',
  'birk',
  'birken',
  'birthing',
  'biserial',
  'biserrate',
  'bisexual',
  'bissextile',
  'bistable',
  'bistred',
  'bisulcate',
  'bit',
  'bitchier',
  'bitchiest',
  'bitchy',
  'biting',
  'bitless',
  'bitonal',
  'bitten',
  'bitter',
  'bitterish',
  'bittersweet',
  'bitty',
  'bituminous',
  'bivalent',
  'bivalve',
  'bivalvular',
  'bivariate',
  'biyearly',
  'bizarre',
  'bizonal',
  'black',
  'black-a-vised',
  'black-and-blue',
  'black-and-tan',
  'black-and-white',
  'black-coated',
  'black-figure',
  'black-hearted',
  'black-letter',
  'blackened',
  'blackguardly',
  'blackish',
  'bladdery',
  'bladed',
  'blae',
  'blah',
  'blamable',
  'blame',
  'blameable',
  'blamed',
  'blameful',
  'blameless',
  'blameworthy',
  'blanched',
  'bland',
  'blank',
  'blanket',
  'blankety',
  'blankety-blank',
  'blaring',
  'blasphemous',
  'blasted',
  'blasting',
  'blastoderm',
  'blastular',
  'blatant',
  'blate',
  'blathering',
  'blazing',
  'bleached',
  'bleak',
  'blear',
  'blear-eyed',
  'blearier',
  'bleariest',
  'bleary',
  'bleary-eyed',
  'bleeding',
  'blemished',
  'blended',
  'blending',
  'blessed',
  'blest',
  'blighted',
  'blightingly',
  'blimpish',
  'blind',
  'blinded',
  'blindfold',
  'blindfolded',
  'blinding',
  'blinking',
  'blissful',
  'blissless',
  'blistered',
  'blistering',
  'blistery',
  'blithe',
  'blithering',
  'blithesome',
  'blizzardly',
  'blizzardy',
  'bloated',
  'blockaded',
  'blockading',
  'blocked',
  'blockish',
  'blocky',
  'blond',
  'blonde',
  'blood-and-thunder',
  'blood-red',
  'blooded',
  'bloodied',
  'bloodier',
  'bloodiest',
  'bloodless',
  'bloodshot',
  'bloodstained',
  'bloodsucking',
  'bloodthirsty',
  'bloody',
  'bloody-minded',
  'bloodying',
  'bloomed',
  'bloomier',
  'bloomiest',
  'blooming',
  'bloomless',
  'bloomy',
  'blossomy',
  'blotched',
  'blotchier',
  'blotchiest',
  'blotchy',
  'blotto',
  'blotty',
  'blow-by-blow',
  'blowhard',
  'blowier',
  'blowiest',
  'blown',
  'blowsier',
  'blowsiest',
  'blowsy',
  'blowy',
  'blowzed',
  'blowzier',
  'blowziest',
  'blowzy',
  'blubber',
  'blubbery',
  'blue',
  'blue-black',
  'blue-blooded',
  'blue-collar',
  'blue-eyed',
  'blue-sky',
  'bluer',
  'bluest',
  'bluff',
  'bluish',
  'blunt',
  'blunted',
  'blurred',
  'blurry',
  'blushful',
  'blushing',
  'blushless',
  'blustering',
  'blusterous',
  'blustery',
  'boarish',
  'boastful',
  'boastless',
  'bobbery',
  'bobs',
  'bobtail',
  'bobtailed',
  'bodacious',
  'bodger',
  'bodied',
  'bodiless',
  'bodily',
  'boding',
  'body-line',
  'boggy',
  'bogus',
  'boiled',
  'boiling',
  'boisterous',
  'bold',
  'bold-faced',
  'bolometric',
  'bolshevist',
  'bolshie',
  'bolshy',
  'bolted',
  'bomb',
  'bombacaceous',
  'bombastic',
  'bombproof',
  'bombycid',
  'bond',
  'bonded',
  'bone',
  'bone-dry',
  'bone-idle',
  'boned',
  'boneheaded',
  'boneless',
  'bonhomous',
  'bonier',
  'boniest',
  'bonism',
  'bonkers',
  'bonnie',
  'bonnier',
  'bonniest',
  'bonny',
  'bony',
  'bonzer',
  'boobyish',
  'book-learned',
  'bookable',
  'booked',
  'bookish',
  'bookless',
  'bookmaking',
  'booming',
  'boon',
  'boorish',
  'booted',
  'bootleg',
  'bootless',
  'bootlicking',
  'boozier',
  'booziest',
  'boozy',
  'boracic',
  'boraginaceous',
  'bordered',
  'bordering',
  'borderless',
  'borderline',
  'boreal',
  'bored',
  'boric',
  'boring',
  'born',
  'born-again',
  'bosker',
  'boskier',
  'boskiest',
  'bosky',
  'bosom',
  'bosomed',
  'bosomy',
  'boss',
  'boss-eyed',
  'bossier',
  'bossiest',
  'bossy',
  'botanic',
  'botanical',
  'botched',
  'botchier',
  'botchiest',
  'botchy',
  'both',
  'bothered',
  'bothersome',
  'botryoid',
  'botryoidal',
  'botryose',
  'bottle-fed',
  'bottle-green',
  'bottle-nosed',
  'bottom',
  'bottom-up',
  'bottomed',
  'bottomless',
  'bottommost',
  'bouffant',
  'bought',
  'boughten',
  'bouilli',
  'boulle',
  'bouncing',
  'bouncy',
  'bound',
  'bounded',
  'bounden',
  'bounding',
  'boundless',
  'bounteous',
  'bountiful',
  'bourgeois',
  'boustrophedon',
  'bousy',
  'bovid',
  'bovine',
  'bow',
  'bow-windowed',
  'bowed',
  'bowery',
  'bowing',
  'box-office',
  'boxed',
  'boxlike',
  'boxy',
  'boy-meets-girl',
  'boyish',
  'braced',
  'brachial',
  'brachiate',
  'brachiopod',
  'brachycephalic',
  'brachydactylic',
  'brachydactylous',
  'brachypterous',
  'brachyurous',
  'bracing',
  'brackish',
  'bracteal',
  'bracteate',
  'bracteolate',
  'bractless',
  'brag',
  'braggart',
  'bragging',
  'braided',
  'brainier',
  'brainiest',
  'brainish',
  'brainless',
  'brainsick',
  'brainwashed',
  'brainy',
  'braised',
  'brakeless',
  'braky',
  'braless',
  'bramblier',
  'brambliest',
  'brambly',
  'bran-new',
  'branched',
  'branchial',
  'branchiate',
  'branching',
  'branchiopod',
  'branchless',
  'branchlike',
  'branchy',
  'brand-new',
  'branded',
  'brannier',
  'branniest',
  'branny',
  'brash',
  'brashier',
  'brashiest',
  'brashy',
  'brassier',
  'brassiest',
  'brassy',
  'brattish',
  'bratty',
  'brave',
  'braver',
  'bravest',
  'braving',
  'braw',
  'brawling',
  'brawny',
  'brazen',
  'brazen-faced',
  'breached',
  'bread-and-butter',
  'breakable',
  'breakaway',
  'breaking',
  'breakneck',
  'breast-fed',
  'breasted',
  'breathable',
  'breathed',
  'breathier',
  'breathiest',
  'breathing',
  'breathless',
  'breathtaking',
  'breathy',
  'breeched',
  'breechless',
  'breechloading',
  'breeding',
  'breezeless',
  'breezier',
  'breeziest',
  'breezy',
  'bregmatic',
  'brevipennate',
  'bribable',
  'bribeable',
  'brick',
  'brick-red',
  'brickier',
  'brickiest',
  'brickle',
  'bricky',
  'bridal',
  'bridgeable',
  'bridgeless',
  'brief',
  'briefless',
  'briery',
  'bright',
  'brilliant',
  'brilliant-cut',
  'brimful',
  'brimless',
  'brimming',
  'brimstony',
  'brinded',
  'brindle',
  'brindled',
  'brinier',
  'briniest',
  'brinish',
  'briny',
  'brisk',
  'bristled',
  'bristly',
  'brittle',
  'broached',
  'broad',
  'broad-gauge',
  'broad-leaved',
  'broad-minded',
  'broadband',
  'broadcast',
  'broadish',
  'broadloom',
  'broadside',
  'brocaded',
  'broch',
  'broguish',
  'broiled',
  'broke',
  'broken',
  'broken-backed',
  'broken-down',
  'broken-hearted',
  'broken-in',
  'broken-winded',
  'bromeliaceous',
  'bromic',
  'bromidic',
  'bronchial',
  'bronchitic',
  'bronchoscopic',
  'bronze',
  'bronzed',
  'bronzy',
  'brood',
  'broodier',
  'broodiest',
  'brooding',
  'broody',
  'broomy',
  'brother',
  'brotherlike',
  'brotherly',
  'browbeaten',
  'browless',
  'brown',
  'browned',
  'brownish',
  'browny',
  'bruised',
  'bruising',
  'brumal',
  'brumous',
  'brunet',
  'brunette',
  'brush-fire',
  'brushed',
  'brushless',
  'brushy',
  'brusque',
  'brut',
  'brutal',
  'brute',
  'brutelike',
  'brutish',
  'bryological',
  'bubaline',
  'bubblier',
  'bubbliest',
  'bubbling',
  'bubbly',
  'bubonic',
  'buccal',
  'buccaneerish',
  'buccinatory',
  'buck',
  'bucked',
  'buckish',
  'buckram',
  'buckshee',
  'buckskin',
  'bucktooth',
  'bucktoothed',
  'bucolic',
  'budding',
  'buddy-buddy',
  'budgetary',
  'budless',
  'buff',
  'buffeted',
  'bug-eyed',
  'bugged',
  'buggy',
  'bughouse',
  'bugs',
  'buhl',
  'building',
  'built',
  'built-in',
  'built-up',
  'buirdly',
  'bulbar',
  'bulbed',
  'bulbiferous',
  'bulbous',
  'bulging',
  'bulgy',
  'bulimic',
  'bulk',
  'bulkier',
  'bulkiest',
  'bulky',
  'bull',
  'bull-headed',
  'bull-necked',
  'bull-nosed',
  'bullate',
  'bulldog',
  'bullet-headed',
  'bullied',
  'bullish',
  'bullocky',
  'bully',
  'bullying',
  'bum',
  'bumbling',
  'bumper',
  'bumper-to-bumper',
  'bumpier',
  'bumpiest',
  'bumpkinish',
  'bumptious',
  'bumpy',
  'bunched',
  'bunchier',
  'bunchiest',
  'bunchy',
  'bung',
  'bungaloid',
  'bungled',
  'bungling',
  'bunodont',
  'bunted',
  'buoyant',
  'buprestid',
  'bur-reed',
  'burbling',
  'burdened',
  'burdensome',
  'bureaucratic',
  'burghal',
  'burglarious',
  'buried',
  'burked',
  'burled',
  'burlesque',
  'burlier',
  'burliest',
  'burly',
  'burned',
  'burning',
  'burnished',
  'burnt',
  'burred',
  'burrier',
  'burriest',
  'burry',
  'bursal',
  'bursarial',
  'burseraceous',
  'bursiform',
  'burst',
  'bursting',
  'bush',
  'bushed',
  'bushier',
  'bushiest',
  'bushwhacking',
  'bushy',
  'busied',
  'busier',
  'busiest',
  'business',
  'busked',
  'buskined',
  'bust',
  'busted',
  'bustier',
  'bustiest',
  'bustled',
  'bustling',
  'busty',
  'busy',
  'busying',
  'butch',
  'butcherly',
  'buttery',
  'buttocked',
  'button-down',
  'buttoned',
  'buttony',
  'buttressed',
  'butyraceous',
  'butyric',
  'buxom',
  'buyable',
  'buzzing',
  'by-past',
  'bye',
  'bygone',
  'byssaceous',
  'byssal',
  'byssoid',
  'cabalistic',
  'cabbagy',
  'cabbalistic',
  'cabbalistical',
  'cabinet',
  'cable-laid',
  'caboched',
  'caboshed',
  'cacciatore',
  'cachectic',
  'cachectical',
  'cachinnatory',
  'cack-handed',
  'cacodylic',
  'cacographic',
  'cacographical',
  'cacophonic',
  'cacophonous',
  'cactaceous',
  'cacuminal',
  'cadastral',
  'cadaveric',
  'cadaverous',
  'caddish',
  'cade',
  'cadenced',
  'cadent',
  'cadential',
  'cadgy',
  'caducean',
  'caducous',
  'caecal',
  'caecilian',
  'caesalpiniaceous',
  'caespitose',
  'caesural',
  'cagey',
  'cagier',
  'cagiest',
  'cagy',
  'cairned',
  'caitiff',
  'calamitous',
  'calando',
  'calcaneal',
  'calcanean',
  'calcareous',
  'calced',
  'calceiform',
  'calceolate',
  'calcic',
  'calcicolous',
  'calciferous',
  'calcific',
  'calcifugous',
  'calcinable',
  'calculable',
  'calculated',
  'calculating',
  'calculational',
  'calculative',
  'calculous',
  'calefacient',
  'calefactive',
  'calefactory',
  'calendered',
  'calendric',
  'calendrical',
  'calfless',
  'calibered',
  'calibred',
  'calico',
  'caliginous',
  'caliphal',
  'calisthenic',
  'callable',
  'called',
  'called-for',
  'caller',
  'calligraphic',
  'calligraphical',
  'callisthenic',
  'callous',
  'callow',
  'calm',
  'calmative',
  'calming',
  'calmy',
  'caloric',
  'calorific',
  'calumniatory',
  'calumnious',
  'calved',
  'calyciform',
  'calycinal',
  'calycine',
  'calycled',
  'calyculate',
  'calyptrate',
  'cambial',
  'camera-shy',
  'cameral',
  'camouflaged',
  'camp',
  'campanological',
  'campanulaceous',
  'campanular',
  'campanulate',
  'campestral',
  'camphoraceous',
  'camphorated',
  'camphoric',
  'campodeid',
  'campodeiform',
  'campy',
  'campylotropous',
  'can-do',
  'canalicular',
  'canaliculate',
  'canaliculated',
  'canary',
  'cancellate',
  'cancellated',
  'cancelled',
  'cancellous',
  'cancerous',
  'cancrine',
  'cancrizans',
  'cancroid',
  'candent',
  'candescent',
  'candid',
  'candied',
  'candy-striped',
  'canescent',
  'canicular',
  'canine',
  'cankered',
  'cankerous',
  'cannabic',
  'canned',
  'cannibalistic',
  'cannier',
  'canniest',
  'cannonball',
  'cannular',
  'canny',
  'canonic',
  'canonical',
  'canonist',
  'canonistic',
  'canonized',
  'canopied',
  'canorous',
  'cant',
  'cantabile',
  'cantankerous',
  'canted',
  'cantering',
  'cantharidal',
  'cantharidian',
  'cantonal',
  'cantorial',
  'cantoris',
  'canty',
  'cany',
  'capable',
  'capacious',
  'caparisoned',
  'caped',
  'capeskin',
  'capillaceous',
  'capillary',
  'capital',
  'capitalist',
  'capitalistic',
  'capitate',
  'capitular',
  'capitulary',
  'capitulatory',
  'capparidaceous',
  'capped',
  'capreolate',
  'capricious',
  'caprifoliaceous',
  'caprine',
  'caprylic',
  'capsizable',
  'capsular',
  'capsulate',
  'captious',
  'captivated',
  'captivating',
  'captive',
  'carabid',
  'caramel',
  'carangid',
  'carangoid',
  'carapacial',
  'carbocyclic',
  'carbolic',
  'carbonaceous',
  'carbonated',
  'carbonic',
  'carboniferous',
  'carbonyl',
  'carboxyl',
  'carboxylic',
  'carbuncled',
  'carbuncular',
  'carcinogenic',
  'carcinomatous',
  'card-carrying',
  'cardboard',
  'cardiac',
  'cardinal',
  'cardiological',
  'cardiopulmonary',
  'cardiorespiratory',
  'cardiovascular',
  'carefree',
  'careful',
  'careless',
  'caressing',
  'caressive',
  'careworn',
  'carinate',
  'caring',
  'cariogenic',
  'carious',
  'carking',
  'carlish',
  'carminative',
  'carmine',
  'carnal',
  'carnassial',
  'carnation',
  'carneous',
  'carnivalesque',
  'carnivorous',
  'carnose',
  'carotenoid',
  'carotid',
  'carousing',
  'carpal',
  'carpellary',
  'carpellate',
  'carpetbag',
  'carpeted',
  'carping',
  'carpophagous',
  'carroty',
  'carsick',
  'cartelist',
  'cartilaginous',
  'cartographic',
  'cartographical',
  'caruncular',
  'carunculate',
  'carunculous',
  'carved',
  'carvel-built',
  'carven',
  'caryatidal',
  'caryophyllaceous',
  'case-hardened',
  'casebook',
  'cased',
  'casemated',
  'casemented',
  'caseous',
  'cash-and-carry',
  'cashed',
  'cassocked',
  'cast',
  'cast-iron',
  'cast-off',
  'castaway',
  'casteless',
  'castellated',
  'castigatory',
  'castled',
  'castor',
  'castrated',
  'casual',
  'casuistic',
  'casuistical',
  'cat-and-dog',
  'cat-eyed',
  'catabolic',
  'catacaustic',
  'catachrestic',
  'catachrestical',
  'cataclysmal',
  'cataclysmic',
  'catacumbal',
  'catadioptric',
  'catadromous',
  'catalectic',
  'cataleptic',
  'catalogued',
  'catalytic',
  'catalytical',
  'catamenial',
  'cataphractic',
  'cataphyllary',
  'cataplexy',
  'catapultic',
  'catarrhal',
  'catarrhine',
  'catarrhous',
  'catastrophic',
  'catatonic',
  'catch-as-catch-can',
  'catchable',
  'catchier',
  'catchiest',
  'catching',
  'catchpenny',
  'catchweight',
  'catchy',
  'catechetic',
  'catechetical',
  'catechismal',
  'catechistic',
  'catechistical',
  'catechumenical',
  'categorial',
  'categoric',
  'categorical',
  'categorized',
  'catenary',
  'catenate',
  'catercorner',
  'caterpillar',
  'cathartic',
  'cathectic',
  'cathedral',
  'cathodic',
  'catholic',
  'catoptric',
  'cattish',
  'catty',
  'catty-cornered',
  'caudal',
  'caudate',
  'caudated',
  'cauld',
  'caulescent',
  'caulicolous',
  'cauliform',
  'cauline',
  'caulked',
  'causal',
  'causative',
  'causeless',
  'caustic',
  'cauterant',
  'cautionary',
  'cautious',
  'cavalier',
  'cavalierly',
  'cavernous',
  'cavicorn',
  'caviling',
  'cavitied',
  'cayenned',
  'ceaseless',
  'cecal',
  'cedar',
  'cedarn',
  'ceilinged',
  'celebrated',
  'celebratory',
  'celestial',
  'celiac',
  'celibate',
  'cellular',
  'celluloid',
  'cellulosic',
  'cementitious',
  'censored',
  'censorial',
  'censorian',
  'censorious',
  'censual',
  'censurable',
  'censured',
  'centaurian',
  'centenarian',
  'centenary',
  'centennial',
  'center',
  'centered',
  'centesimal',
  'centigrade',
  'centillionth',
  'central',
  'central-fire',
  'centralism',
  'centralist',
  'centralized',
  'centralizing',
  'centre-fire',
  'centred',
  'centric',
  'centrical',
  'centrifugal',
  'centripetal',
  'centrist',
  'centrobaric',
  'centroclinal',
  'centroidal',
  'centrosome',
  'centum',
  'centuple',
  'centuplicate',
  'centurial',
  'cephalalgic',
  'cephalate',
  'cephalic',
  'cephalochordate',
  'cephalopod',
  'cephalous',
  'ceraceous',
  'ceramic',
  'cerated',
  'ceratoid',
  'cercal',
  'cercarian',
  'cereal',
  'cerebellar',
  'cerebral',
  'cerebric',
  'cerebrospinal',
  'cerebrotonic',
  'cerebrovascular',
  'cered',
  'ceremonial',
  'ceremonious',
  'cereous',
  'ceric',
  'ceriferous',
  'cerise',
  'cernuous',
  'cerographic',
  'cerographical',
  'ceroplastic',
  'cerous',
  'certain',
  'certifiable',
  'certificated',
  'certificatory',
  'certified',
  'cerulean',
  'ceruminous',
  'cervical',
  'cervid',
  'cervine',
  'cespitose',
  'cestoid',
  'cesural',
  'cetacean',
  'cetaceous',
  'chad',
  'chafed',
  'chaffier',
  'chaffiest',
  'chaffless',
  'chaffy',
  'chagrined',
  'chain-driven',
  'chained',
  'chainless',
  'chairborne',
  'chalcedonic',
  'chalcographic',
  'chalcographical',
  'chalcolithic',
  'chaliced',
  'chalkier',
  'chalkiest',
  'chalky',
  'challengeable',
  'challenging',
  'chalybeate',
  'chambered',
  'chameleonic',
  'chameleonlike',
  'champertous',
  'champion',
  'chance',
  'chanceful',
  'chanceless',
  'chancier',
  'chanciest',
  'chancroid',
  'chancroidal',
  'chancrous',
  'chancy',
  'changeable',
  'changed',
  'changeful',
  'changeless',
  'changing',
  'chanted',
  'chaotic',
  'chapeless',
  'chapfallen',
  'chapleted',
  'chapped',
  'characterful',
  'characteristic',
  'characterized',
  'characterless',
  'charcoal',
  'chargeable',
  'charged',
  'chargeful',
  'chargeless',
  'charier',
  'chariest',
  'charismatic',
  'charitable',
  'charlatanic',
  'charlatanical',
  'charmed',
  'charming',
  'charmless',
  'charnel',
  'charrier',
  'charriest',
  'charry',
  'chartaceous',
  'chartered',
  'chartless',
  'chary',
  'chasmal',
  'chasmed',
  'chasmic',
  'chasmogamic',
  'chasmy',
  'chasseur',
  'chaste',
  'chastened',
  'chaster',
  'chastest',
  'chastisable',
  'chastised',
  'chatoyant',
  'chattering',
  'chattier',
  'chattiest',
  'chatty',
  'chauvinistic',
  'cheap',
  'cheap-jack',
  'cheating',
  'checked',
  'checkered',
  'checky',
  'cheek',
  'cheeked',
  'cheekier',
  'cheekiest',
  'cheeky',
  'cheerful',
  'cheerier',
  'cheeriest',
  'cheering',
  'cheerless',
  'cheerly',
  'cheery',
  'cheese-head',
  'cheesed',
  'cheesy',
  'chelate',
  'chelated',
  'chelicerate',
  'cheliferous',
  'cheliform',
  'chelonian',
  'chemic',
  'chemical',
  'chemoreceptive',
  'chemotactic',
  'chemotropic',
  'chemurgic',
  'chemurgical',
  'chenopodiaceous',
  'chequered',
  'chequy',
  'cherished',
  'cherry',
  'cherty',
  'cherubic',
  'cherubical',
  'chestier',
  'chestiest',
  'chestnut',
  'chesty',
  'chevroned',
  'chewable',
  'chewier',
  'chewiest',
  'chewy',
  'chiastic',
  'chic',
  'chichi',
  'chicken',
  'chicken-hearted',
  'chicken-livered',
  'chief',
  'chiefless',
  'chiefly',
  'chiffon',
  'childbearing',
  'childing',
  'childish',
  'childless',
  'childlike',
  'childly',
  'chiliastic',
  'chill',
  'chilled',
  'chillier',
  'chilliest',
  'chilling',
  'chilly',
  'chilopod',
  'chimeric',
  'chimerical',
  'chin',
  'china',
  'chinked',
  'chinless',
  'chintzier',
  'chintziest',
  'chintzy',
  'chipped',
  'chipper',
  'chippy',
  'chirk',
  'chiromantic',
  'chiromantical',
  'chiropodial',
  'chiropteran',
  'chirpier',
  'chirpiest',
  'chirpy',
  'chirrupy',
  'chiseled',
  'chiselled',
  'chitinoid',
  'chitinous',
  'chivalric',
  'chivalrous',
  'chlamydate',
  'chlamydeous',
  'chloric',
  'chloritic',
  'chlorotic',
  'chlorous',
  'chock-a-block',
  'chock-full',
  'chocker',
  'chocolate',
  'chocolaty',
  'choice',
  'choicer',
  'choicest',
  'choke-full',
  'choked',
  'chokey',
  'chokier',
  'chokiest',
  'choking',
  'choky',
  'cholagogue',
  'choleraic',
  'choleric',
  'cholinergic',
  'chondral',
  'chondritic',
  'choosey',
  'choosier',
  'choosiest',
  'choosy',
  'chopfallen',
  'chopped',
  'choppier',
  'choppiest',
  'chopping',
  'choppy',
  'choragic',
  'choral',
  'chordal',
  'chordate',
  'choreic',
  'choreographic',
  'chorial',
  'choriambic',
  'choric',
  'chorioid',
  'chorionic',
  'chorographic',
  'chorographical',
  'choroid',
  'chosen',
  'chrematistic',
  'chrestomathic',
  'chrismal',
  'chromatic',
  'chromatographic',
  'chromic',
  'chromophil',
  'chromosomal',
  'chronic',
  'chronological',
  'chronometric',
  'chronometrical',
  'chrysalid',
  'chryselephantine',
  'chthonian',
  'chthonic',
  'chubbier',
  'chubbiest',
  'chubby',
  'chuck-full',
  'chuffier',
  'chuffiest',
  'chuffy',
  'chummier',
  'chummiest',
  'chummy',
  'chunderous',
  'chunkier',
  'chunkiest',
  'chunky',
  'churchier',
  'churchiest',
  'churchless',
  'churchly',
  'churchward',
  'churchy',
  'churlish',
  'churning',
  'churrigueresque',
  'chylaceous',
  'chyliferous',
  'chymous',
  'ci-devant',
  'cichlid',
  'cigar-shaped',
  'ciliary',
  'ciliate',
  'ciliated',
  'ciliolate',
  'cinchonic',
  'cindery',
  'cinematic',
  'cinematographic',
  'cinerary',
  'cinereous',
  'cinnabarine',
  'cinnamic',
  'cinnamonic',
  'circadian',
  'circinate',
  'circling',
  'circuital',
  'circuitous',
  'circulable',
  'circular',
  'circulating',
  'circulative',
  'circulatory',
  'circumambient',
  'circumferential',
  'circumflex',
  'circumfluent',
  'circumfluous',
  'circumgyratory',
  'circumjacent',
  'circumlocutional',
  'circumlocutionary',
  'circumlocutory',
  'circumlunar',
  'circumnavigable',
  'circumnutatory',
  'circumpolar',
  'circumscissile',
  'circumscribable',
  'circumscribed',
  'circumscriptive',
  'circumsolar',
  'circumspect',
  'circumspective',
  'circumstantial',
  'circumventive',
  'cirrate',
  'cirrhotic',
  'cirriform',
  'cirriped',
  'cirripede',
  'cirrose',
  'cirsoid',
  'cislunar',
  'cismontane',
  'cissoid',
  'cissy',
  'cistaceous',
  'cisted',
  'cistic',
  'citable',
  'citatory',
  'citeable',
  'citified',
  'citreous',
  'citric',
  'citrous',
  'citrus',
  'cityfied',
  'civic',
  'civil',
  'civilian',
  'civilisable',
  'civilizable',
  'civilized',
  'clad',
  'claimable',
  'clairvoyant',
  'clamant',
  'clammy',
  'clamorous',
  'clandestine',
  'clanging',
  'clangorous',
  'clanking',
  'clankless',
  'clannish',
  'clarified',
  'clarifying',
  'clarino',
  'clarion',
  'clashing',
  'clasping',
  'class-conscious',
  'classable',
  'classic',
  'classical',
  'classier',
  'classiest',
  'classifiable',
  'classificatory',
  'classified',
  'classless',
  'classy',
  'clastic',
  'clathrate',
  'clattering',
  'clattery',
  'clausal',
  'claustral',
  'claustrophobic',
  'clausular',
  'clavate',
  'clavicorn',
  'clavicular',
  'claviform',
  'clawed',
  'clawless',
  'clayey',
  'clayish',
  'clean',
  'clean-cut',
  'clean-limbed',
  'clean-living',
  'clean-shaven',
  'cleanable',
  'cleaned',
  'cleanlier',
  'cleanliest',
  'cleanly',
  'cleansable',
  'cleansed',
  'cleansing',
  'clear',
  'clear-cut',
  'clear-eyed',
  'clear-headed',
  'clear-sighted',
  'cleared',
  'cleavable',
  'cleft',
  'cleidoic',
  'cleistogamic',
  'cleistogamous',
  'clement',
  'clenched',
  'clerical',
  'clerkish',
  'clerklier',
  'clerkliest',
  'clerkly',
  'clever',
  'clever-clever',
  'cleverish',
  'cliental',
  'cliffier',
  'cliffiest',
  'cliffy',
  'climacteric',
  'climactic',
  'climactical',
  'climatic',
  'climatical',
  'climatological',
  'climbable',
  'climbing',
  'clinched',
  'clincher-built',
  'clingier',
  'clingiest',
  'clingy',
  'clinical',
  'clinker-built',
  'clinking',
  'clinometric',
  'clinquant',
  'clip-fed',
  'clip-on',
  'clipped',
  'clipping',
  'cliquey',
  'cliquish',
  'cliquy',
  'clithral',
  'clitic',
  'clitoral',
  'cloacal',
  'cloak-and-dagger',
  'cloaked',
  'clockwise',
  'cloddish',
  'cloddy',
  'clodhopping',
  'clogged',
  'clogging',
  'cloggy',
  'cloistered',
  'cloistral',
  'clonal',
  'clonic',
  'close',
  'close-cropped',
  'close-fisted',
  'close-fitting',
  'close-grained',
  'close-hauled',
  'close-knit',
  'close-lipped',
  'close-mouthed',
  'close-reefed',
  'closed',
  'closed-circuit',
  'closed-door',
  'closer',
  'closest',
  'closet',
  'closing',
  'clostridial',
  'cloth-eared',
  'clothed',
  'clotted',
  'clotty',
  'clouded',
  'cloudier',
  'cloudiest',
  'cloudless',
  'cloudy',
  'cloven',
  'cloven-hoofed',
  'clovered',
  'clovery',
  'clownish',
  'cloying',
  'clubable',
  'clubbable',
  'clubbish',
  'clubby',
  'clucky',
  'clueless',
  'clumpy',
  'clumsier',
  'clumsiest',
  'clumsy',
  'clunky',
  'clupeid',
  'clupeoid',
  'clustered',
  'clustery',
  'cluttered',
  'clypeal',
  'clypeate',
  'cnemial',
  'cnidarian',
  'co-optative',
  'co-ordinal',
  'coach-built',
  'coactive',
  'coadjutant',
  'coadunate',
  'coagulable',
  'coagulate',
  'coagulated',
  'coagulatory',
  'coal-black',
  'coal-tar',
  'coalesced',
  'coalescent',
  'coalier',
  'coalitional',
  'coaly',
  'coarctate',
  'coarse',
  'coarse-grained',
  'coarsened',
  'coarser',
  'coarsest',
  'coastal',
  'coastward',
  'coastwise',
  'coated',
  'coatless',
  'coaxial',
  'coaxing',
  'cobaltic',
  'cobblestone',
  'cobwebby',
  'coccal',
  'coccoid',
  'coccygeal',
  'cochlear',
  'cochleate',
  'cock-a-hoop',
  'cockamamie',
  'cockeyed',
  'cockfighting',
  'cockier',
  'cockiest',
  'cockney',
  'cockneyish',
  'cocksure',
  'cocky',
  'coconscious',
  'coconut',
  'cod',
  'coddled',
  'codicillary',
  'codified',
  'coeducational',
  'coelanaglyphic',
  'coelenterate',
  'coeliac',
  'coelomate',
  'coenobitic',
  'coenobitical',
  'coequal',
  'coercible',
  'coercive',
  'coetaneous',
  'coeternal',
  'coeval',
  'coffered',
  'cogent',
  'cogged',
  'cogitable',
  'cogitative',
  'cognate',
  'cognisable',
  'cognisant',
  'cognitional',
  'cognitive',
  'cognizable',
  'cognizant',
  'cognominal',
  'cognoscible',
  'coherent',
  'cohesive',
  'cohortative',
  'coiled',
  'coiling',
  'coincident',
  'coincidental',
  'coinciding',
  'coital',
  'coky',
  'cold',
  'cold-blooded',
  'cold-drawn',
  'cold-hearted',
  'cold-short',
  'coldish',
  'coleopteran',
  'coleopterous',
  'colicky',
  'coliform',
  'collaborative',
  'collapsable',
  'collapsed',
  'collapsible',
  'collatable',
  'collateral',
  'collative',
  'collect',
  'collectable',
  'collected',
  'collectible',
  'collective',
  'collectivist',
  'collectivized',
  'collegial',
  'collegiate',
  'collembolan',
  'collenchymatous',
  'colligative',
  'collinear',
  'colloid',
  'colloidal',
  'colloquial',
  'collotypic',
  'collusive',
  'colly',
  'colonial',
  'colonialism',
  'colonialist',
  'colonic',
  'colonized',
  'colonnaded',
  'color',
  'color-blind',
  'colorable',
  'coloratura',
  'colored',
  'colorfast',
  'colorful',
  'colorific',
  'colorless',
  'colory',
  'colossal',
  'colour',
  'colour-blind',
  'colourable',
  'coloured',
  'colourful',
  'colourless',
  'coltish',
  'colubrid',
  'colubrine',
  'columbine',
  'columnar',
  'columned',
  'comal',
  'comate',
  'comatose',
  'combatable',
  'combatant',
  'combative',
  'combed',
  'combinable',
  'combinative',
  'combinatorial',
  'combinatory',
  'combined',
  'combining',
  'combless',
  'combust',
  'combustible',
  'combustion',
  'combustive',
  'come-at-able',
  'come-hither',
  'comedic',
  'comelier',
  'comeliest',
  'comely',
  'comestible',
  'cometary',
  'comether',
  'cometic',
  'comfier',
  'comfiest',
  'comfortable',
  'comforted',
  'comforting',
  'comfortless',
  'comfy',
  'comic',
  'comical',
  'coming',
  'comitative',
  'commanding',
  'commeasurable',
  'commemorable',
  'commemorating',
  'commemorative',
  'commemoratory',
  'commendable',
  'commendatory',
  'commensal',
  'commensurable',
  'commensurate',
  'commentatorial',
  'commercial',
  'commercialized',
  'commie',
  'comminative',
  'comminatory',
  'commiserable',
  'commiserative',
  'commissarial',
  'commissioned',
  'commissural',
  'committed',
  'commo',
  'commodious',
  'common',
  'common-law',
  'commonable',
  'commonplace',
  'commonsense',
  'commonsensical',
  'commorant',
  'commotional',
  'communal',
  'communicable',
  'communicant',
  'communicative',
  'communicatory',
  'communist',
  'communistic',
  'community',
  'commutable',
  'commutative',
  'commutual',
  'comose',
  'compact',
  'companionable',
  'companionate',
  'companionless',
  'comparable',
  'comparative',
  'compartmental',
  'compartmentalized',
  'compassable',
  'compassionate',
  'compatible',
  'compatriotic',
  'compellable',
  'compelled',
  'compelling',
  'compendious',
  'compensated',
  'compensational',
  'compensative',
  'compensatory',
  'competent',
  'competing',
  'competitive',
  'compilatory',
  'complacent',
  'complaining',
  'complaisant',
  'complanate',
  'compleat',
  'complected',
  'complemental',
  'complementary',
  'complemented',
  'completable',
  'complete',
  'completed',
  'completing',
  'completive',
  'complex',
  'complexional',
  'complexioned',
  'complexionless',
  'compliable',
  'compliant',
  'complicate',
  'complicated',
  'complicative',
  'complimentary',
  'complying',
  'compo',
  'component',
  'componental',
  'componential',
  'compony',
  'composed',
  'composite',
  'compositional',
  'compositive',
  'compossible',
  'compotatory',
  'compound',
  'compoundable',
  'compounded',
  'comprehended',
  'comprehensible',
  'comprehensive',
  'compressed',
  'compressible',
  'compressional',
  'compressive',
  'comprisable',
  'compromising',
  'compulsive',
  'compulsory',
  'compunctious',
  'compurgatorial',
  'compurgatory',
  'computable',
  'computational',
  'computative',
  'computerized',
  'comradely',
  'conative',
  'concatenate',
  'concave',
  'concavo-concave',
  'concavo-convex',
  'concealable',
  'concealed',
  'concealing',
  'conceded',
  'conceding',
  'conceited',
  'conceivable',
  'conceived',
  'concentrated',
  'concentrative',
  'concentric',
  'concentrical',
  'conceptional',
  'conceptive',
  'conceptual',
  'conceptualistic',
  'concerned',
  'concerning',
  'concertante',
  'concerted',
  'concessible',
  'concessionary',
  'concessive',
  'conchal',
  'conchate',
  'conched',
  'conchiferous',
  'conchoidal',
  'conchological',
  'conciliable',
  'conciliar',
  'conciliating',
  'conciliative',
  'conciliatory',
  'concinnous',
  'concise',
  'concluded',
  'concluding',
  'conclusive',
  'concoctive',
  'concomitant',
  'concordant',
  'concrete',
  'concretionary',
  'concretive',
  'concubinary',
  'concubine',
  'concupiscent',
  'concupiscible',
  'concurrent',
  'concurring',
  'concussive',
  'concyclic',
  'condemnable',
  'condemnatory',
  'condemned',
  'condemning',
  'condensable',
  'condensed',
  'condescending',
  'condign',
  'conditional',
  'conditioned',
  'condolatory',
  'condolent',
  'condonable',
  'conducible',
  'conducive',
  'conducted',
  'conductible',
  'conductive',
  'conduplicate',
  'condylar',
  'condyloid',
  'condylomatous',
  'confabulatory',
  'confarreate',
  'confectionary',
  'confederate',
  'confederative',
  'conferential',
  'conferrable',
  'conferred',
  'conferva',
  'confervoid',
  'confessed',
  'confessional',
  'confessionary',
  'confident',
  'confidential',
  'confiding',
  'configurational',
  'configured',
  'confinable',
  'confined',
  'confineless',
  'confining',
  'confirmable',
  'confirmative',
  'confirmatory',
  'confirmed',
  'confirming',
  'confiscable',
  'confiscate',
  'confiscated',
  'confiscatory',
  'conflagrant',
  'conflicting',
  'conflictive',
  'confluent',
  'confocal',
  'conformable',
  'conformal',
  'conforming',
  'conformist',
  'confounded',
  'confounding',
  'confrontational',
  'confusable',
  'confused',
  'confusing',
  'confutable',
  'confutative',
  'congealable',
  'congealed',
  'congeneric',
  'congenerical',
  'congenial',
  'congenital',
  'congested',
  'congestible',
  'congestive',
  'conglobate',
  'conglomerate',
  'conglomeratic',
  'conglutinant',
  'conglutinative',
  'congratulant',
  'congratulatory',
  'congregate',
  'congregational',
  'congressional',
  'congruent',
  'congruous',
  'conic',
  'conical',
  'conidial',
  'coniferous',
  'conirostral',
  'conjecturable',
  'conjectural',
  'conjoined',
  'conjoint',
  'conjugal',
  'conjugate',
  'conjugated',
  'conjugational',
  'conjugative',
  'conjunct',
  'conjunctional',
  'conjunctival',
  'conjunctive',
  'conjuring',
  'connate',
  'connatural',
  'connectable',
  'connected',
  'connectible',
  'connecting',
  'connective',
  'connivent',
  'conniving',
  'connotative',
  'connotive',
  'connubial',
  'conoid',
  'conquerable',
  'conquered',
  'conquering',
  'consanguine',
  'consanguineous',
  'conscience-smitten',
  'conscience-stricken',
  'conscienceless',
  'conscientious',
  'conscionable',
  'conscious',
  'conscriptional',
  'consecrate',
  'consecrated',
  'consecrative',
  'consecratory',
  'consecutive',
  'consensual',
  'consentaneous',
  'consentient',
  'consenting',
  'consequent',
  'consequential',
  'conservable',
  'conservant',
  'conservational',
  'conservative',
  'conservatory',
  'conserved',
  'conserving',
  'considerable',
  'considerate',
  'considered',
  'consignable',
  'consistent',
  'consistorial',
  'consistorian',
  'consociate',
  'consolable',
  'consolatory',
  'consolidated',
  'consolidative',
  'consoling',
  'consolingly',
  'consolute',
  'consonant',
  'consonantal',
  'conspecific',
  'conspicuous',
  'conspiratorial',
  'constabulary',
  'constant',
  'constellatory',
  'constipated',
  'constipating',
  'constituent',
  'constitutional',
  'constitutive',
  'constrainable',
  'constrained',
  'constraining',
  'constricted',
  'constricting',
  'constrictive',
  'constringent',
  'construable',
  'constructible',
  'constructional',
  'constructive',
  'consubstantial',
  'consuetudinary',
  'consular',
  'consultative',
  'consultatory',
  'consulting',
  'consultive',
  'consumable',
  'consumed',
  'consumerism',
  'consuming',
  'consummate',
  'consummated',
  'consummative',
  'consummatory',
  'consumptive',
  'contactual',
  'contagious',
  'containable',
  'contained',
  'containerized',
  'contaminable',
  'contaminate',
  'contaminated',
  'contaminating',
  'contaminative',
  'contemnible',
  'contemplable',
  'contemplative',
  'contemporaneous',
  'contemporary',
  'contemptible',
  'contemptuous',
  'contending',
  'content',
  'contented',
  'contentious',
  'conterminous',
  'contestable',
  'contextual',
  'contiguous',
  'continent',
  'continental',
  'contingent',
  'continuable',
  'continual',
  'continuant',
  'continuate',
  'continuative',
  'continued',
  'continuing',
  'continuous',
  'contorted',
  'contortional',
  'contortive',
  'contraband',
  'contrabass',
  'contraceptive',
  'contractable',
  'contracted',
  'contractible',
  'contractile',
  'contractional',
  'contractive',
  'contractual',
  'contradictable',
  'contradictious',
  'contradictive',
  'contradictory',
  'contradistinctive',
  'contralateral',
  'contralto',
  'contrapositive',
  'contrapuntal',
  'contrarious',
  'contrary',
  'contrasting',
  'contrastive',
  'contrasty',
  'contrasuggestible',
  'contrate',
  'contributable',
  'contributing',
  'contributive',
  'contributory',
  'contrite',
  'contrivable',
  'contrived',
  'controllable',
  'controlled',
  'controlling',
  'controversial',
  'controvertible',
  'contumacious',
  'contumelious',
  'contused',
  'contusive',
  'convalescence',
  'convalescent',
  'convectional',
  'convective',
  'convenable',
  'convenient',
  'conventional',
  'conventionalized',
  'conventual',
  'convergent',
  'converging',
  'conversable',
  'conversant',
  'conversational',
  'converse',
  'converted',
  'convertible',
  'convex',
  'convexo-concave',
  'convexo-convex',
  'conveyable',
  'conveyed',
  'convict',
  'convicted',
  'convictive',
  'convinced',
  'convincible',
  'convincing',
  'convivial',
  'convocational',
  'convolute',
  'convoluted',
  'convolvulaceous',
  'convulsant',
  'convulsible',
  'convulsionary',
  'convulsive',
  'cooing',
  'cookable',
  'cooked',
  'cooking',
  'cool',
  'cool-headed',
  'cooled',
  'cooling',
  'cooling-off',
  'coolish',
  'cooperative',
  'coordinate',
  'coordinated',
  'coordinating',
  'coordinative',
  'copacetic',
  'copepod',
  'copesettic',
  'copied',
  'copious',
  'coplanar',
  'copper-bottomed',
  'coppery',
  'coppiced',
  'coprolaliac',
  'coprolitic',
  'coprophagous',
  'coprophilous',
  'copular',
  'copulative',
  'copulatory',
  'copyright',
  'copyrightable',
  'copyrighted',
  'coquettish',
  'coraciiform',
  'coral',
  'coralliferous',
  'coralline',
  'coralloid',
  'corbelled',
  'corbiculate',
  'cordate',
  'corded',
  'cordial',
  'cordiform',
  'cordless',
  'corduroy',
  'coreferential',
  'coreless',
  'coriaceous',
  'cork-tipped',
  'corked',
  'corkier',
  'corkiest',
  'corking',
  'corky',
  'cormophytic',
  'cormous',
  'corn-fed',
  'cornaceous',
  'corneal',
  'corned',
  'corneous',
  'cornered',
  'corniculate',
  'cornier',
  'corniest',
  'cornual',
  'cornucopian',
  'cornute',
  'corny',
  'corollaceous',
  'corollary',
  'coronal',
  'coronary',
  'coronate',
  'coroneted',
  'corporal',
  'corporate',
  'corporatist',
  'corporative',
  'corporeal',
  'corpulent',
  'corpuscular',
  'correct',
  'correctable',
  'corrected',
  'correctible',
  'correctional',
  'corrective',
  'correlatable',
  'correlate',
  'correlated',
  'correlative',
  'correspondent',
  'corresponding',
  'corresponsive',
  'corrigible',
  'corroborant',
  'corroborate',
  'corroborated',
  'corroborative',
  'corroboratory',
  'corroded',
  'corrodible',
  'corrosive',
  'corrugate',
  'corrugated',
  'corrupt',
  'corrupted',
  'corruptible',
  'corrupting',
  'corruptive',
  'cortical',
  'corticate',
  'corticolous',
  'coruscant',
  'corvine',
  'corybantic',
  'corymbose',
  'coseismal',
  'coseismic',
  'cosher',
  'cosier',
  'cosies',
  'cosiest',
  'cosmetic',
  'cosmic',
  'cosmogonic',
  'cosmogonical',
  'cosmographic',
  'cosmographical',
  'cosmological',
  'cosmopolitan',
  'cosmoramic',
  'cost-effective',
  'cost-plus',
  'costal',
  'costate',
  'costive',
  'costlier',
  'costliest',
  'costly',
  'costume',
  'costumed',
  'cosy',
  'cotemporaneous',
  'coterminous',
  'cottaged',
  'cotton-picking',
  'cottony',
  'cotyledonary',
  'cotyledonous',
  'cotyloid',
  'couchant',
  'councilmanic',
  'counsellable',
  'countable',
  'counter',
  'counter-passant',
  'counter-revolutionary',
  'counteractive',
  'counterbalanced',
  'counterbalancing',
  'counterclockwise',
  'counterfeit',
  'countermandable',
  'counterpoised',
  'countervailing',
  'countless',
  'countrified',
  'country',
  'countryfied',
  'countrywide',
  'county',
  'couped',
  'coupled',
  'courageous',
  'courant',
  'coursed',
  'courteous',
  'courtlier',
  'courtliest',
  'courtly',
  'cousinly',
  'couth',
  'couthie',
  'couthy',
  'covalent',
  'covariant',
  'coverable',
  'covered',
  'covering',
  'coverless',
  'covert',
  'covetable',
  'coveted',
  'covetous',
  'covinous',
  'coward',
  'cowardly',
  'cowed',
  'cowering',
  'cowled',
  'coxal',
  'coxcombic',
  'coxcombical',
  'coy',
  'coyish',
  'cozier',
  'coziest',
  'cozy',
  'crabbed',
  'crabbier',
  'crabbiest',
  'crabby',
  'crablike',
  'crabwise',
  'crack',
  'crackajack',
  'crackbrained',
  'cracked',
  'cracker-barrel',
  'crackerjack',
  'crackers',
  'cracking',
  'crackjaw',
  'crackle',
  'crackled',
  'cracklier',
  'crackliest',
  'crackling',
  'crackly',
  'crackpot',
  'craftier',
  'craftiest',
  'craftless',
  'crafty',
  'cragged',
  'craggier',
  'craggiest',
  'craggy',
  'cram-full',
  'cramoisy',
  'cramped',
  'crane-fly',
  'cranial',
  'craniate',
  'craniological',
  'crank',
  'crankier',
  'crankiest',
  'cranky',
  'crannied',
  'crapulent',
  'crapulous',
  'crash',
  'crashing',
  'crass',
  'crassulaceous',
  'craterous',
  'craved',
  'craven',
  'crawlier',
  'crawliest',
  'crawling',
  'crawly',
  'crazed',
  'crazier',
  'craziest',
  'crazy',
  'creakier',
  'creakiest',
  'creaking',
  'creaky',
  'cream',
  'creamier',
  'creamiest',
  'creamlaid',
  'creamy',
  'crease-resistant',
  'creased',
  'creasy',
  'creatable',
  'creational',
  'creative',
  'creatural',
  'creaturely',
  'credal',
  'credent',
  'credential',
  'credible',
  'creditable',
  'credited',
  'creditworthy',
  'credulous',
  'creedal',
  'creepier',
  'creepiest',
  'creeping',
  'creepy',
  'creepy-crawly',
  'crematory',
  'crenate',
  'crenelate',
  'crenelated',
  'crenellate',
  'crenellated',
  'crenulate',
  'crenulated',
  'creole',
  'creolized',
  'creophagous',
  'crepitant',
  'crepuscular',
  'crescendo',
  'crescent',
  'crescentic',
  'crescive',
  'cressy',
  'crested',
  'crestfallen',
  'crestless',
  'cresylic',
  'cretaceous',
  'cretinoid',
  'cretinous',
  'crew-necked',
  'cribriform',
  'cricoid',
  'crimeless',
  'criminal',
  'criminative',
  'criminatory',
  'crimpier',
  'crimpiest',
  'crimpy',
  'crimson',
  'crinal',
  'crinated',
  'crined',
  'cringing',
  'crinite',
  'crinkled',
  'crinklier',
  'crinkliest',
  'crinkly',
  'crinoid',
  'crinose',
  'criollo',
  'crippled',
  'crippling',
  'crisp',
  'crispate',
  'crispier',
  'crispiest',
  'crispy',
  'crisscross',
  'crisscrossed',
  'cristate',
  'critical',
  'criticisable',
  'criticizable',
  'croakier',
  'croakiest',
  'croaking',
  'croaky',
  'crocked',
  'crocodilian',
  'crocus',
  'cronk',
  'crook',
  'crookback',
  'crookbacked',
  'crooked',
  'crop-eared',
  'cropped',
  'crosiered',
  'cross',
  'cross-armed',
  'cross-country',
  'cross-cultural',
  'cross-eyed',
  'cross-grained',
  'cross-legged',
  'cross-ply',
  'cross-ratio',
  'cross-section',
  'cross-sectional',
  'crossbanded',
  'crossbred',
  'crosscut',
  'crossed',
  'crosshatched',
  'crossing',
  'crossopterygian',
  'crosstown',
  'crosswise',
  'crotched',
  'crotchety',
  'crouched',
  'crouching',
  'croupiest',
  'croupous',
  'croupy',
  'crouse',
  'crowded',
  'crowing',
  'crowned',
  'crowning',
  'crownless',
  'crucial',
  'cruciate',
  'cruciferous',
  'cruciform',
  'crude',
  'cruder',
  'crudest',
  'cruel',
  'crumb',
  'crumbier',
  'crumbiest',
  'crumbled',
  'crumblier',
  'crumbliest',
  'crumbly',
  'crumby',
  'crummier',
  'crummies',
  'crummiest',
  'crummy',
  'crumpled',
  'crunched',
  'crunchier',
  'crunchiest',
  'crunchy',
  'crural',
  'crushable',
  'crushed',
  'crushing',
  'crustacean',
  'crustaceous',
  'crustal',
  'crusted',
  'crustier',
  'crustiest',
  'crustless',
  'crusty',
  'crutched',
  'crying',
  'cryogenic',
  'cryophilic',
  'cryoscopic',
  'cryptal',
  'cryptic',
  'cryptical',
  'cryptocrystalline',
  'cryptogamic',
  'cryptogamous',
  'cryptogenic',
  'cryptographic',
  'cryptonymous',
  'cryptorchid',
  'crystal',
  'crystal-clear',
  'crystalline',
  'crystallisable',
  'crystallizable',
  'crystallized',
  'crystallographic',
  'crystalloid',
  'ctenoid',
  'ctenophoran',
  'cubbish',
  'cubic',
  'cubical',
  'cubiform',
  'cubist',
  'cubistic',
  'cubital',
  'cuboid',
  'cuboidal',
  'cuckoo',
  'cucullate',
  'cucumiform',
  'cucurbitaceous',
  'cuddlesome',
  'cuddly',
  'cuffed',
  'culicid',
  'culinary',
  'culmiferous',
  'culminant',
  'culpable',
  'cultic',
  'cultish',
  'cultivable',
  'cultivatable',
  'cultivated',
  'cultrate',
  'cultural',
  'cultured',
  'cultureless',
  'cumberless',
  'cumbersome',
  'cumbrous',
  'cumulate',
  'cumulative',
  'cumuliform',
  'cunctatious',
  'cunctatory',
  'cuneal',
  'cuneate',
  'cuneatic',
  'cuneiform',
  'cunning',
  'cup-tied',
  'cupidinous',
  'cupolated',
  'cupped',
  'cupreous',
  'cupric',
  'cupriferous',
  'cuprous',
  'cupular',
  'cupulate',
  'cur',
  'curable',
  'curative',
  'curatorial',
  'curbable',
  'curbed',
  'curbless',
  'curdier',
  'curdiest',
  'curdled',
  'curdling',
  'curdy',
  'cured',
  'cureless',
  'curious',
  'curled',
  'curlier',
  'curliest',
  'curling',
  'curly',
  'curmudgeonly',
  'current',
  'curricular',
  'currish',
  'cursed',
  'cursing',
  'cursive',
  'cursorial',
  'cursory',
  'curst',
  'curt',
  'curtained',
  'curtal',
  'curtate',
  'curule',
  'curvaceous',
  'curved',
  'curvier',
  'curviest',
  'curvilineal',
  'curvilinear',
  'curving',
  'curvy',
  'cushier',
  'cushiest',
  'cushioned',
  'cushiony',
  'cushy',
  'cuspate',
  'cusped',
  'cuspidal',
  'cuspidate',
  'cuspidated',
  'cussed',
  'custodial',
  'custom',
  'custom-built',
  'custom-made',
  'customable',
  'customary',
  'cut',
  'cut-off',
  'cut-out',
  'cut-price',
  'cut-rate',
  'cut-up',
  'cutaneous',
  'cutcha',
  'cute',
  'cuter',
  'cutest',
  'cuticular',
  'cutting',
  'cutty',
  'cyan',
  'cyanic',
  'cyanophyte',
  'cyanotic',
  'cyathiform',
  'cybernetic',
  'cycadaceous',
  'cyclamen',
  'cyclic',
  'cyclical',
  'cycloid',
  'cycloidal',
  'cyclone',
  'cyclonic',
  'cyclopean',
  'cyclopedic',
  'cyclostome',
  'cyclothymic',
  'cylindraceous',
  'cylindric',
  'cylindrical',
  'cylindroid',
  'cymbiform',
  'cymoid',
  'cymose',
  'cymotrichous',
  'cynic',
  'cynical',
  'cyperaceous',
  'cyprian',
  'cyprinid',
  'cyprinoid',
  'cystic',
  'cystoid',
  'cytogenetic',
  'cytoid',
  'cytological',
  'cytoplasmic',
  'cytotoxic',
  'czarist',
  'dabbled',
  'dactylic',
  'daedal',
  'daemonic',
  'daffier',
  'daffiest',
  'daffy',
  'daft',
  'daily',
  'daimen',
  'daimonic',
  'daintier',
  'dainties',
  'daintiest',
  'dainty',
  'daisied',
  'dam',
  'damageable',
  'damaged',
  'damaging',
  'damascene',
  'damascened',
  'damask',
  'damfool',
  'damn',
  'damnable',
  'damnatory',
  'damned',
  'damning',
  'damp',
  'dampish',
  'danceable',
  'dancing',
  'dandiacal',
  'dandified',
  'dandy',
  'dandyish',
  'dang',
  'danged',
  'dangerous',
  'dangling',
  'dank',
  'dapper',
  'dapple',
  'dappled',
  'daring',
  'dark',
  'darkened',
  'darkening',
  'darkish',
  'darkling',
  'darksome',
  'darling',
  'darn',
  'darned',
  'darting',
  'dash',
  'dashed',
  'dashing',
  'dastard',
  'dastardly',
  'dasyphyllous',
  'datable',
  'dateable',
  'dated',
  'dateless',
  'datival',
  'dative',
  'daubed',
  'dauby',
  'daughterly',
  'daunted',
  'daunting',
  'dauntless',
  'dawdling',
  'day-old',
  'day-to-day',
  'daylong',
  'daytime',
  'dazed',
  'dazzled',
  'dazzling',
  'dead',
  'dead-and-alive',
  'dead-letter',
  'dead-on',
  'dead-set',
  'deadened',
  'deadening',
  'deadlier',
  'deadliest',
  'deadlocked',
  'deadly',
  'deaf',
  'deaf-and-dumb',
  'deaf-mute',
  'deafened',
  'deafening',
  'deal',
  'dear',
  'deathful',
  'deathless',
  'deathlike',
  'deathly',
  'deathy',
  'debased',
  'debasing',
  'debatable',
  'debauched',
  'debentured',
  'debilitated',
  'debilitating',
  'debilitative',
  'debonair',
  'deboned',
  'debonnaire',
  'decadal',
  'decadent',
  'decagonal',
  'decahedral',
  'decamerous',
  'decanal',
  'decani',
  'decapitated',
  'decapod',
  'decapodous',
  'decasyllabic',
  'decayed',
  'deceased',
  'deceitful',
  'decemviral',
  'decennary',
  'decennial',
  'decent',
  'decentralize',
  'decentralized',
  'decentralizing',
  'deceptive',
  'decidable',
  'decided',
  'deciding',
  'decidual',
  'deciduate',
  'deciduous',
  'decillionth',
  'decimal',
  'decinormal',
  'decipherable',
  'deciphered',
  'decisive',
  'decked',
  'deckle-edged',
  'deckled',
  'declamatory',
  'declarable',
  'declarative',
  'declaratory',
  'declared',
  'declassified',
  'declensional',
  'declinable',
  'declinate',
  'declinatory',
  'declining',
  'declivitous',
  'declivous',
  'decoctive',
  'decolorant',
  'decomposable',
  'decomposed',
  'decomposing',
  'decompound',
  'decompressive',
  'decongestant',
  'decongestive',
  'deconsecrated',
  'deconstructionist',
  'decontaminative',
  'decorated',
  'decorative',
  'decorous',
  'decreased',
  'decreasing',
  'decreed',
  'decrepit',
  'decrescendo',
  'decrescent',
  'decretal',
  'decretive',
  'decretory',
  'decuman',
  'decumbent',
  'decuple',
  'decurrent',
  'decurved',
  'decussate',
  'dedal',
  'dedicate',
  'dedicated',
  'dedicational',
  'dedicatory',
  'deducible',
  'deducted',
  'deductible',
  'deductive',
  'deedless',
  'deep',
  'deep-dyed',
  'deep-fried',
  'deep-laid',
  'deep-rooted',
  'deep-sea',
  'deep-seated',
  'deep-set',
  'deepened',
  'deepening',
  'defaced',
  'defamatory',
  'defeasible',
  'defeated',
  'defeatism',
  'defeatist',
  'defectible',
  'defective',
  'defenceless',
  'defendable',
  'defendant',
  'defending',
  'defenseless',
  'defensible',
  'defensive',
  'deferable',
  'deferent',
  'deferential',
  'deferrable',
  'deferred',
  'defiant',
  'deficient',
  'defiled',
  'definable',
  'defined',
  'definite',
  'definitive',
  'deflagrable',
  'deflated',
  'deflation',
  'deflationary',
  'deflationist',
  'deflected',
  'deflective',
  'deflexed',
  'defoliate',
  'defoliated',
  'deformable',
  'deformed',
  'defrayable',
  'deft',
  'defunct',
  'defunctive',
  'degenerate',
  'degenerative',
  'degradable',
  'degraded',
  'degrading',
  'degree',
  'degressive',
  'dehiscent',
  'dehortative',
  'dehortatory',
  'dehumanized',
  'dehydrated',
  'deicidal',
  'deictic',
  'deific',
  'deiform',
  'deism',
  'deistic',
  'deistical',
  'deject',
  'dejected',
  'delayed',
  'delectable',
  'delegable',
  'deleterious',
  'deliberate',
  'deliberative',
  'delible',
  'delicate',
  'delicious',
  'delighted',
  'delightful',
  'delightless',
  'delightsome',
  'delimitative',
  'delimited',
  'delineable',
  'delineate',
  'delineated',
  'delineative',
  'delinquent',
  'deliquescent',
  'delirious',
  'delitescent',
  'deliverable',
  'deltaic',
  'deltoid',
  'delusional',
  'delusive',
  'delusory',
  'demagogic',
  'demagogical',
  'demandable',
  'demanding',
  'demeaning',
  'demented',
  'demersal',
  'demisable',
  'demiurgeous',
  'demiurgic',
  'demiurgical',
  'democratic',
  'demoded',
  'demographic',
  'demographical',
  'demolished',
  'demolition',
  'demoniac',
  'demoniacal',
  'demonic',
  'demonologic',
  'demonological',
  'demonology',
  'demonstrable',
  'demonstrated',
  'demonstrative',
  'demoralising',
  'demoralized',
  'demoralizing',
  'demotic',
  'demountable',
  'demulcent',
  'demure',
  'demurer',
  'demurest',
  'demurrable',
  'demythologized',
  'denary',
  'denatured',
  'denaturized',
  'dendriform',
  'dendritic',
  'dendrochronological',
  'dendroid',
  'dendroidal',
  'dendrological',
  'deniable',
  'denigrating',
  'denominate',
  'denominational',
  'denominationalism',
  'denominative',
  'denotable',
  'denotative',
  'dense',
  'denser',
  'densest',
  'densimetric',
  'densitometric',
  'dental',
  'dentate',
  'dented',
  'denticulate',
  'dentiform',
  'dentilingual',
  'dentirostral',
  'dentoid',
  'denudate',
  'denuded',
  'denumerable',
  'denunciatory',
  'deontic',
  'deontological',
  'departed',
  'departing',
  'departmental',
  'depauperate',
  'dependable',
  'dependant',
  'dependent',
  'depicted',
  'depictive',
  'depilatory',
  'depletable',
  'depleted',
  'depletive',
  'depletory',
  'deplorable',
  'deponent',
  'depopulated',
  'deposable',
  'deposed',
  'depositional',
  'depraved',
  'deprecating',
  'deprecative',
  'deprecatory',
  'depreciating',
  'depreciative',
  'depreciatory',
  'depredatory',
  'depressant',
  'depressed',
  'depressible',
  'depressing',
  'depressive',
  'deprivable',
  'deprivative',
  'deprived',
  'depurative',
  'deputy',
  'deranged',
  'dere',
  'derelict',
  'derisible',
  'derisive',
  'derisory',
  'derivable',
  'derivational',
  'derivative',
  'derived',
  'dermal',
  'dermatic',
  'dermatographic',
  'dermatoid',
  'dermatological',
  'dermatoplastic',
  'dermic',
  'dermoid',
  'dern',
  'dernier',
  'derogate',
  'derogative',
  'derogatory',
  'descant',
  'descendant',
  'descendent',
  'descendible',
  'descending',
  'describable',
  'described',
  'descriptive',
  'desecrated',
  'desegregate',
  'desensitized',
  'desensitizing',
  'desert',
  'deserted',
  'deserved',
  'deserving',
  'desiccant',
  'desiccate',
  'desiccated',
  'desiccative',
  'desiderative',
  'designate',
  'designated',
  'designative',
  'designatory',
  'designed',
  'designer',
  'designing',
  'desinent',
  'desirable',
  'desired',
  'desireless',
  'desirous',
  'desktop',
  'desmoid',
  'desolate',
  'desolated',
  'despairful',
  'despairing',
  'desperate',
  'despicable',
  'despisable',
  'despised',
  'despiteful',
  'despiteous',
  'despoiled',
  'despondent',
  'despotic',
  'despotical',
  'destined',
  'destitute',
  'destroyable',
  'destroyed',
  'destructible',
  'destructive',
  'desultory',
  'detachable',
  'detached',
  'detailed',
  'detainable',
  'detectable',
  'detected',
  'detectible',
  'detective',
  'detergent',
  'deteriorating',
  'deteriorative',
  'determinable',
  'determinant',
  'determinate',
  'determinately',
  'determinative',
  'determined',
  'determining',
  'determinism',
  'determinist',
  'deterministic',
  'deterrent',
  'detersive',
  'detestable',
  'detested',
  'detonating',
  'detoxicant',
  'detractive',
  'detrimental',
  'detrital',
  'deuced',
  'deuteranopic',
  'deutoplasmic',
  'devalued',
  'devastated',
  'devastating',
  'devastative',
  'developable',
  'developed',
  'developing',
  'developmental',
  'deviant',
  'deviate',
  'deviationism',
  'deviatory',
  'deviceful',
  'devil-may-care',
  'deviled',
  'devilish',
  'devious',
  'devisable',
  'devoid',
  'devolution',
  'devoted',
  'devotional',
  'devoured',
  'devouring',
  'devout',
  'dewlapped',
  'dewy',
  'dewy-eyed',
  'dexter',
  'dexterous',
  'dextral',
  'dextrogyrate',
  'dextrorotatory',
  'dextrorse',
  'dextrous',
  'diabasic',
  'diabetic',
  'diabolic',
  'diabolical',
  'diacaustic',
  'diachronic',
  'diacid',
  'diaconal',
  'diacritic',
  'diacritical',
  'diactinic',
  'diadelphous',
  'diagenetic',
  'diageotropic',
  'diagnosable',
  'diagnostic',
  'diagonal',
  'diagrammatic',
  'dialectal',
  'dialectic',
  'dialectical',
  'dialogic',
  'dialogistic',
  'dialysable',
  'dialytic',
  'dialyzable',
  'diamagnetic',
  'diamantiferous',
  'diamantine',
  'diametral',
  'diametric',
  'diametrical',
  'diandrous',
  'dianoetic',
  'diapedetic',
  'diaphanous',
  'diaphoretic',
  'diaphragmatic',
  'diapophysial',
  'diarch',
  'diarchic',
  'diarrheal',
  'diarrheic',
  'diarrhoeal',
  'diarrhoeic',
  'diastatic',
  'diastolic',
  'diastrophic',
  'diastyle',
  'diathermic',
  'diathetic',
  'diatomaceous',
  'diatomic',
  'diatonic',
  'diatropic',
  'diazo',
  'dibasic',
  'dibranchiate',
  'dicastic',
  'dicephalous',
  'dicey',
  'dichasial',
  'dichlamydeous',
  'dichogamous',
  'dichotomic',
  'dichotomous',
  'dichroic',
  'dichromatic',
  'dichromic',
  'dichroscopic',
  'dickey',
  'dicky',
  'diclinous',
  'dicotyledonous',
  'dicrotic',
  'dictated',
  'dictatorial',
  'didactic',
  'didactical',
  'didymous',
  'didynamous',
  'die-cast',
  'die-casting',
  'die-hard',
  'dielectric',
  'diesel-electric',
  'diesel-hydraulic',
  'diet',
  'dietary',
  'dietetic',
  'dietetical',
  'different',
  'differential',
  'differentiated',
  'differing',
  'difficile',
  'difficult',
  'diffident',
  'diffluent',
  'diffractive',
  'diffuse',
  'diffused',
  'diffusible',
  'diffusing',
  'diffusive',
  'digamous',
  'digastric',
  'digested',
  'digestible',
  'digestive',
  'dighted',
  'digital',
  'digitate',
  'digitiform',
  'digitigrade',
  'diglot',
  'dignified',
  'dignifying',
  'digressional',
  'digressive',
  'dihedral',
  'dihydric',
  'dilapidated',
  'dilatable',
  'dilatant',
  'dilated',
  'dilative',
  'dilatory',
  'dilemmatic',
  'dilettante',
  'dilettantish',
  'diligent',
  'diluent',
  'dilute',
  'diluted',
  'diluvial',
  'diluvian',
  'dim',
  'dime',
  'dimensional',
  'dimensioning',
  'dimensionless',
  'dimerous',
  'dimetric',
  'dimidiate',
  'diminishable',
  'diminished',
  'diminishing',
  'diminuendo',
  'diminutive',
  'dimissory',
  'dimmed',
  'dimmest',
  'dimming',
  'dimorphous',
  'dimply',
  'ding-dong',
  'dingbats',
  'dinge',
  'dingier',
  'dingiest',
  'dingy',
  'dink',
  'dinkier',
  'dinkies',
  'dinkiest',
  'dinkum',
  'dinky',
  'dinky-di',
  'dinnerless',
  'dinoflagellate',
  'diocesan',
  'dioecious',
  'dioptric',
  'dioramic',
  'dioritic',
  'diorthotic',
  'dipetalous',
  'diphtheritic',
  'diphtheroid',
  'diphthongal',
  'diphthongic',
  'diphycercal',
  'diphyletic',
  'diphyodont',
  'diplex',
  'diploid',
  'diplomatic',
  'diplomatical',
  'diplostemonous',
  'dipnoan',
  'dipolar',
  'dipped',
  'dippier',
  'dippiest',
  'dippy',
  'dipsomaniac',
  'dipteral',
  'dipteran',
  'dipterocarpaceous',
  'dipterous',
  'dire',
  'direct',
  'directed',
  'directing',
  'directional',
  'directionless',
  'directive',
  'directorial',
  'directory',
  'direful',
  'direr',
  'direst',
  'dirigible',
  'diriment',
  'dirt',
  'dirt-cheap',
  'dirtier',
  'dirtiest',
  'dirty',
  'disabled',
  'disabling',
  'disabused',
  'disadvantaged',
  'disadvantageous',
  'disaffected',
  'disagreeable',
  'disallowable',
  'disappearing',
  'disappointed',
  'disappointing',
  'disapproved',
  'disapproving',
  'disarming',
  'disarranged',
  'disarrayed',
  'disastrous',
  'disbelieving',
  'discalceate',
  'discalced',
  'discarded',
  'discarnate',
  'discernible',
  'discerning',
  'discerptible',
  'discharged',
  'disciplinable',
  'disciplinal',
  'disciplinarian',
  'disciplinary',
  'disciplined',
  'disclosed',
  'disclosing',
  'discoid',
  'discoidal',
  'discolored',
  'discoloured',
  'discombobulated',
  'discomfited',
  'discomfortable',
  'discommodious',
  'discomposed',
  'discomycetous',
  'disconcerted',
  'disconcerting',
  'disconfirming',
  'disconnected',
  'disconsolate',
  'discontent',
  'discontented',
  'discontinued',
  'discontinuous',
  'discordant',
  'discorporate',
  'discountable',
  'discouraged',
  'discouraging',
  'discourteous',
  'discoverable',
  'discovered',
  'discovert',
  'discreditable',
  'discredited',
  'discreet',
  'discrepant',
  'discrete',
  'discretional',
  'discretionary',
  'discriminate',
  'discriminating',
  'discriminative',
  'discriminatory',
  'discursive',
  'discussable',
  'discussible',
  'disdainful',
  'diseased',
  'disembodied',
  'disenchanted',
  'disenchanting',
  'disenfranchised',
  'disentangled',
  'disepalous',
  'disfigured',
  'disfranchised',
  'disgraced',
  'disgraceful',
  'disgruntled',
  'disguisable',
  'disguised',
  'disgusted',
  'disgustful',
  'disgusting',
  'disgustingly',
  'disharmonious',
  'disheartened',
  'disheartening',
  'dished',
  'disheveled',
  'dishevelled',
  'dishonest',
  'dishonorable',
  'dishonored',
  'dishonourable',
  'dishy',
  'disillusioned',
  'disillusioning',
  'disillusive',
  'disimpassioned',
  'disincentive',
  'disinclined',
  'disinfectant',
  'disingenuous',
  'disinherited',
  'disintegrable',
  'disintegrative',
  'disinterested',
  'disjoined',
  'disjoint',
  'disjointed',
  'disjunct',
  'disjunctive',
  'dislikable',
  'disliked',
  'dislocated',
  'disloyal',
  'dismal',
  'dismantled',
  'dismayed',
  'dismaying',
  'dismissed',
  'dismissible',
  'dismissive',
  'dismounted',
  'disobedient',
  'disobliging',
  'disordered',
  'disorderly',
  'disorganized',
  'disoriented',
  'disorienting',
  'disowned',
  'disparaging',
  'disparate',
  'dispassionate',
  'dispatched',
  'dispensable',
  'dispensational',
  'dispensatory',
  'dispensed',
  'dispermous',
  'disperse',
  'dispersed',
  'dispersive',
  'dispirited',
  'dispiriting',
  'dispiteous',
  'displaceable',
  'displayed',
  'displeased',
  'displeasing',
  'disposable',
  'disposed',
  'dispositional',
  'dispossessed',
  'disproportionable',
  'disproportional',
  'disproportionate',
  'disprovable',
  'disputable',
  'disputant',
  'disputatious',
  'disputative',
  'disputed',
  'disqualifiable',
  'disqualified',
  'disqualifying',
  'disquiet',
  'disquieted',
  'disquieting',
  'disquisitional',
  'disregarded',
  'disregardful',
  'disreputable',
  'disrespectable',
  'disrespectful',
  'disrupted',
  'disruptive',
  'dissatisfactory',
  'dissatisfied',
  'dissected',
  'dissectible',
  'dissembling',
  'disseminating',
  'disseminative',
  'dissentient',
  'dissenting',
  'dissentious',
  'dissepimental',
  'dissertational',
  'dissident',
  'dissilient',
  'dissimilar',
  'dissimulating',
  'dissimulative',
  'dissipated',
  'dissipative',
  'dissociable',
  'dissocial',
  'dissociated',
  'dissociative',
  'dissoluble',
  'dissolute',
  'dissolvable',
  'dissolved',
  'dissolvent',
  'dissonant',
  'dissuasive',
  'dissymmetric',
  'dissymmetrical',
  'distaff',
  'distal',
  'distanceless',
  'distant',
  'distasteful',
  'distended',
  'distensible',
  'distensile',
  'distent',
  'distichal',
  'distichous',
  'distillable',
  'distillatory',
  'distilled',
  'distinct',
  'distinctive',
  'distinguishable',
  'distinguished',
  'distinguishing',
  'distorted',
  'distortive',
  'distracted',
  'distractible',
  'distractive',
  'distrainable',
  'distrait',
  'distraught',
  'distressed',
  'distressful',
  'distressing',
  'distributable',
  'distributed',
  'distributional',
  'distributive',
  'district',
  'distrustful',
  'disturbed',
  'disturbing',
  'distyle',
  'disunited',
  'disused',
  'disyllabic',
  'ditheistic',
  'ditheistical',
  'dithyrambic',
  'ditriglyphic',
  'diuretic',
  'diurnal',
  'divalent',
  'divaricate',
  'divergent',
  'diverging',
  'divers',
  'diverse',
  'diversifiable',
  'diversified',
  'diversionary',
  'diverted',
  'divertible',
  'diverticular',
  'diverting',
  'divertive',
  'divestible',
  'dividable',
  'divided',
  'dividing',
  'dividual',
  'divinatory',
  'divine',
  'divisible',
  'divisional',
  'divisionary',
  'divisionism',
  'divisive',
  'divorceable',
  'divorced',
  'divorcive',
  'divulsive',
  'dizygotic',
  'dizzied',
  'dizzier',
  'dizziest',
  'dizzy',
  'dizzying',
  'do-it-yourself',
  'do-nothing',
  'doable',
  'docile',
  'docked',
  'dockside',
  'doctoral',
  'doctorial',
  'doctrinaire',
  'doctrinal',
  'documental',
  'documentary',
  'documented',
  'doddered',
  'doddering',
  'doddery',
  'dodecahedral',
  'dodecaphonic',
  'dodecastyle',
  'dodecasyllabic',
  'dodgy',
  'dog-cheap',
  'dog-eared',
  'dog-eat-dog',
  'dog-legged',
  'dog-tired',
  'dogged',
  'doggier',
  'doggiest',
  'dogging',
  'doggish',
  'doggone',
  'doggoned',
  'doggy',
  'doglike',
  'dogmatic',
  'dogmatical',
  'doiled',
  'doited',
  'dolabriform',
  'dolce',
  'doleful',
  'doleritic',
  'dolesome',
  'dolichocephalic',
  'dollish',
  'dolomitic',
  'doloroso',
  'dolorous',
  'doltish',
  'domanial',
  'domed',
  'domestic',
  'domesticable',
  'domesticated',
  'domical',
  'domiciliary',
  'dominant',
  'dominated',
  'dominating',
  'dominative',
  'domineering',
  'dominical',
  'donated',
  'donative',
  'done',
  'donnard',
  'donnered',
  'donnish',
  'donsie',
  'doomed',
  'door-to-door',
  'doped',
  'dopey',
  'dopier',
  'dopiest',
  'dopy',
  'dormant',
  'dormie',
  'dormient',
  'dormy',
  'dorsal',
  'dorsiferous',
  'dorsigrade',
  'dorsiventral',
  'dorsolumbar',
  'dorty',
  'dosed',
  'dotal',
  'dotier',
  'dotiest',
  'doting',
  'dotted',
  'dottier',
  'dottiest',
  'dotty',
  'doty',
  'double',
  'double-acting',
  'double-barreled',
  'double-barrelled',
  'double-bass',
  'double-blind',
  'double-breasted',
  'double-chinned',
  'double-dealing',
  'double-dyed',
  'double-edged',
  'double-faced',
  'double-hung',
  'double-jointed',
  'double-minded',
  'double-quick',
  'double-reed',
  'double-spaced',
  'double-tongued',
  'doubled',
  'doubling',
  'doubtable',
  'doubtful',
  'doubting',
  'doubtless',
  'douce',
  'doughier',
  'doughiest',
  'doughtier',
  'doughtiest',
  'doughty',
  'doughy',
  'dour',
  'dovelike',
  'dovetailed',
  'dovish',
  'dowable',
  'dowdy',
  'dowdyish',
  'dowered',
  'dowerless',
  'dowf',
  'dowie',
  'down',
  'down-and-out',
  'down-at-heel',
  'down-market',
  'down-the-line',
  'down-to-earth',
  'downbeat',
  'downed',
  'downfallen',
  'downhearted',
  'downhill',
  'downier',
  'downiest',
  'downrange',
  'downright',
  'downstage',
  'downstair',
  'downstairs',
  'downstate',
  'downstream',
  'downtrodden',
  'downward',
  'downwind',
  'downy',
  'dozen',
  'dozenth',
  'dozier',
  'doziest',
  'dozing',
  'dozy',
  'drab',
  'draconian',
  'draconic',
  'draffy',
  'draftier',
  'draftiest',
  'drafty',
  'dragging',
  'draggled',
  'draggy',
  'dragonish',
  'dragonlike',
  'drainable',
  'drained',
  'draining',
  'dramatic',
  'dramatisable',
  'dramatizable',
  'dramaturgic',
  'dramaturgical',
  'draped',
  'draperied',
  'drastic',
  'dratted',
  'draughtier',
  'draughtiest',
  'draughty',
  'drawable',
  'drawing-room',
  'drawling',
  'drawn',
  'drawn-out',
  'dread',
  'dreaded',
  'dreadful',
  'dreamed',
  'dreamful',
  'dreamier',
  'dreamiest',
  'dreamless',
  'dreamlike',
  'dreamy',
  'drear',
  'drearier',
  'dreariest',
  'drearisome',
  'dreary',
  'dree',
  'dreggy',
  'dreich',
  'drenched',
  'dress',
  'dressed',
  'dressier',
  'dressiest',
  'dressy',
  'dried',
  'drier',
  'driest',
  'driftier',
  'driftiest',
  'drifting',
  'driftless',
  'drifty',
  'drilled',
  'drinkable',
  'drinking',
  'drip-dry',
  'dripping',
  'drippy',
  'drivable',
  'driveable',
  'driven',
  'driverless',
  'driving',
  'drizzling',
  'drizzly',
  'droll',
  'droning',
  'dronish',
  'droopier',
  'droopiest',
  'drooping',
  'droopy',
  'dropped',
  'dropping',
  'dropsical',
  'dropsied',
  'drossier',
  'drossiest',
  'drossy',
  'droughtier',
  'droughtiest',
  'droughty',
  'drouthier',
  'drouthiest',
  'drouthy',
  'drowned',
  'drowsier',
  'drowsiest',
  'drowsing',
  'drowsy',
  'drudging',
  'drugged',
  'druidic',
  'druidical',
  'drumhead',
  'drumlier',
  'drumliest',
  'drumly',
  'drunk',
  'drunken',
  'drupaceous',
  'dry',
  'dry-cleaned',
  'dry-eyed',
  'dry-shod',
  'dry-stone',
  'dryer',
  'drying',
  'dual',
  'dual-purpose',
  'dualistic',
  'dubious',
  'dubitable',
  'dubitative',
  'ducal',
  'duck-billed',
  'duck-legged',
  'duckbill',
  'duckie',
  'duckier',
  'duckiest',
  'ducky',
  'ductile',
  'ductless',
  'dud',
  'duddy',
  'dudish',
  'due',
  'duff',
  'dulcet',
  'dull',
  'dulled',
  'dullish',
  'dulotic',
  'dumb',
  'dumbfounded',
  'dumbfounding',
  'dumbstruck',
  'dumfounded',
  'dumfounding',
  'dummy',
  'dumped',
  'dumpier',
  'dumpiest',
  'dumpish',
  'dumpy',
  'dun',
  'dunderheaded',
  'dungy',
  'dunked',
  'duodecimal',
  'duodenal',
  'duodenary',
  'dupable',
  'duple',
  'duplex',
  'duplicate',
  'duplicative',
  'duplicitous',
  'durable',
  'dural',
  'durational',
  'durative',
  'dure',
  'dusk',
  'duskier',
  'duskiest',
  'duskish',
  'dusky',
  'dustier',
  'dustiest',
  'dustless',
  'dustproof',
  'dusty',
  'dutch',
  'duteous',
  'dutiable',
  'dutiful',
  'duty-bound',
  'duty-free',
  'dwarf',
  'dwarfish',
  'dwindling',
  'dyable',
  'dyadic',
  'dyeable',
  'dyed',
  'dyed-in-the-wool',
  'dyeline',
  'dying',
  'dynamic',
  'dynamical',
  'dynamistic',
  'dynamometric',
  'dynamometrical',
  'dynastic',
  'dynastical',
  'dysaesthetic',
  'dysenteric',
  'dysfunctional',
  'dysgenic',
  'dyslectic',
  'dyslexic',
  'dyslogistic',
  'dysmenorrheal',
  'dyspathetic',
  'dyspeptic',
  'dysphagic',
  'dysphemistic',
  'dysphonic',
  'dysphoric',
  'dysplastic',
  'dyspneal',
  'dyspneic',
  'dyspnoeal',
  'dyspnoeic',
  'dysteleological',
  'dysthymic',
  'dystonic',
  'dystopian',
  'dystrophic',
  'dysuric',
  'dytiscid',
  'each',
  'eager',
  'eagle-eyed',
  'ear-piercing',
  'ear-splitting',
  'eared',
  'earless',
  'earlier',
  'earliest',
  'early',
  'earned',
  'earnest',
  'earthborn',
  'earthbound',
  'earthen',
  'earthier',
  'earthiest',
  'earthlier',
  'earthliest',
  'earthly',
  'earthquaked',
  'earthquaking',
  'earthshaking',
  'earthward',
  'earthy',
  'earwiggy',
  'eased',
  'easeful',
  'easier',
  'easiest',
  'east',
  'east-by-north',
  'eastbound',
  'easterly',
  'eastern',
  'easternmost',
  'eastmost',
  'eastward',
  'eastwardly',
  'easy',
  'easy-going',
  'eatable',
  'eaten',
  'eath',
  'eating',
  'ebb',
  'ebon',
  'ebony',
  'ebracteate',
  'ebullient',
  'ecaudate',
  'ecbolic',
  'eccentric',
  'eccentrical',
  'ecchymotic',
  'ecclesiastic',
  'ecclesiastical',
  'ecclesiological',
  'eccrine',
  'echinate',
  'echinodermatous',
  'echinoid',
  'echoic',
  'echoing',
  'echoless',
  'echt',
  'eclamptic',
  'eclectic',
  'ecliptic',
  'ecologic',
  'ecological',
  'econometric',
  'econometrical',
  'economic',
  'economical',
  'ecru',
  'ecstatic',
  'ectoblastic',
  'ectodermal',
  'ectodermic',
  'ectogenetic',
  'ectogenous',
  'ectomorphic',
  'ectophytic',
  'ectopic',
  'ectoplasmic',
  'ectotrophic',
  'ectozoan',
  'ectozoic',
  'ectypal',
  'ecumenic',
  'ecumenical',
  'eczematous',
  'edacious',
  'edaphic',
  'edematous',
  'edental',
  'edentate',
  'edentulous',
  'edge',
  'edged',
  'edgeless',
  'edgier',
  'edgiest',
  'edging',
  'edgy',
  'edible',
  'edictal',
  'edificatory',
  'edificial',
  'edified',
  'edifying',
  'edited',
  'editorial',
  'educable',
  'educated',
  'educational',
  'educative',
  'educatory',
  'educible',
  'edulcorative',
  'eely',
  'eerie',
  'eerier',
  'eeriest',
  'eery',
  'effable',
  'effaceable',
  'effected',
  'effectible',
  'effective',
  'effectless',
  'effectual',
  'effeminate',
  'efferent',
  'effervescent',
  'effervescible',
  'effervescing',
  'effete',
  'efficacious',
  'efficient',
  'efflorescent',
  'effluent',
  'effluvial',
  'effortful',
  'effortless',
  'effulgent',
  'effuse',
  'effusive',
  'egal',
  'egalitarian',
  'egestive',
  'egg-shaped',
  'eggshell',
  'egocentric',
  'egoistic',
  'egoistical',
  'egomaniacal',
  'egotistic',
  'egotistical',
  'egregious',
  'eidetic',
  'eight',
  'eighteen',
  'eighteenth',
  'eightfold',
  'eighth',
  'eightieth',
  'eightpenny',
  'eighty',
  'eild',
  'eirenic',
  'eisteddfodic',
  'ejaculatory',
  'ejective',
  'elaborate',
  'elaborated',
  'elaborative',
  'elapsed',
  'elasmobranch',
  'elastic',
  'elasticized',
  'elastomeric',
  'elated',
  'elating',
  'elative',
  'elder',
  'elderly',
  'eldest',
  'eldritch',
  'elect',
  'elected',
  'electioneer',
  'elective',
  'electoral',
  'electric',
  'electrical',
  'electrifying',
  'electroacoustic',
  'electroanalytical',
  'electrochemical',
  'electroencephalographic',
  'electrolytic',
  'electromagnetic',
  'electromechanical',
  'electrometallurgical',
  'electrometric',
  'electrometrical',
  'electromotive',
  'electronegative',
  'electronic',
  'electrophilic',
  'electrophoresis',
  'electrophoretic',
  'electrophotographic',
  'electrophysiological',
  'electroplate',
  'electropositive',
  'electroscopic',
  'electrostatic',
  'electrothermal',
  'electrotonic',
  'electrotypic',
  'electrovalent',
  'eleemosynary',
  'elegant',
  'elegiac',
  'elemental',
  'elementary',
  'elenctic',
  'elephantine',
  'elephantoid',
  'elevated',
  'eleven',
  'eleventh',
  'elfin',
  'elfish',
  'elicited',
  'eligible',
  'eliminable',
  'eliminative',
  'eliminatory',
  'elite',
  'ellipsoid',
  'ellipsoidal',
  'elliptic',
  'elliptical',
  'elmier',
  'elmiest',
  'elmy',
  'elocutionary',
  'elongate',
  'elongated',
  'eloquent',
  'else',
  'elucidative',
  'elusive',
  'eluvial',
  'elvish',
  'emaciated',
  'emanant',
  'emanational',
  'emanative',
  'emanatory',
  'emancipated',
  'emancipating',
  'emancipatory',
  'emarginate',
  'emasculate',
  'emasculated',
  'emasculatory',
  'embarrassed',
  'embarrassing',
  'embattled',
  'embedded',
  'embellished',
  'embezzled',
  'emblematic',
  'emblematical',
  'embodied',
  'emboldened',
  'embolic',
  'embolismic',
  'embonpoint',
  'embossed',
  'embowed',
  'embowered',
  'embraceable',
  'embracive',
  'embroidered',
  'embroiled',
  'embryo',
  'embryoid',
  'embryologic',
  'embryological',
  'embryonal',
  'embryonic',
  'embryotic',
  'emendable',
  'emendatory',
  'emended',
  'emergency',
  'emergent',
  'emerging',
  'emeritus',
  'emersed',
  'emetic',
  'emigrational',
  'emigratory',
  'eminent',
  'emissive',
  'emitting',
  'emmenagogue',
  'emmetropic',
  'emollient',
  'emotionable',
  'emotional',
  'emotionless',
  'emotive',
  'empathetic',
  'empathic',
  'emphasized',
  'emphatic',
  'emphysematous',
  'empire-builder',
  'empiric',
  'empirical',
  'empiricism',
  'empiricist',
  'employable',
  'employed',
  'empowered',
  'emptied',
  'empties',
  'emptiest',
  'empty',
  'empty-handed',
  'empty-headed',
  'emptying',
  'empurpled',
  'empyemic',
  'empyreal',
  'empyrean',
  'emulative',
  'emulous',
  'emulsified',
  'emulsive',
  'emunctory',
  'enabling',
  'enactive',
  'enameled',
  'enamored',
  'enantiomorphic',
  'enantiomorphous',
  'enantiotropic',
  'enarched',
  'enarthrodial',
  'enate',
  'encased',
  'encaustic',
  'enceinte',
  'encephalic',
  'encephalitic',
  'enchained',
  'enchanted',
  'enchanting',
  'enchorial',
  'encircled',
  'encircling',
  'enclitic',
  'enclosed',
  'encomiastic',
  'encompassing',
  'encouraged',
  'encouraging',
  'encroaching',
  'encrusted',
  'encumbered',
  'encyclical',
  'encyclopaedic',
  'encyclopedic',
  'encysted',
  'end-stopped',
  'endangered',
  'endarch',
  'endearing',
  'ended',
  'endemic',
  'endermatic',
  'endermic',
  'endless',
  'endmost',
  'endocardial',
  'endocrinal',
  'endocrine',
  'endodermal',
  'endodermic',
  'endogamic',
  'endogamous',
  'endogenic',
  'endogenous',
  'endometrial',
  'endomorphic',
  'endophytic',
  'endoplasmic',
  'endorsable',
  'endorsed',
  'endoscopic',
  'endoskeletal',
  'endosmotic',
  'endothelial',
  'endothermic',
  'endotrophic',
  'endowed',
  'endozoic',
  'endurable',
  'enduring',
  'endways',
  'enemy',
  'energetic',
  'energising',
  'energizing',
  'enervate',
  'enervated',
  'enervating',
  'enervative',
  'enfeebled',
  'enfeebling',
  'enforceable',
  'enforced',
  'enfranchised',
  'engaged',
  'engaging',
  'engorged',
  'engrailed',
  'engrained',
  'engraved',
  'engrossed',
  'engrossing',
  'engulfed',
  'enhanced',
  'enhancive',
  'enharmonic',
  'enigmatic',
  'enigmatical',
  'enjambed',
  'enjoyable',
  'enkindled',
  'enlargeable',
  'enlarged',
  'enlightened',
  'enlightening',
  'enlisted',
  'enlivened',
  'enlivening',
  'enmeshed',
  'enneadic',
  'enneahedral',
  'enneastyle',
  'ennobling',
  'enorm',
  'enormous',
  'enough',
  'enow',
  'enraged',
  'enrapt',
  'enraptured',
  'enrolled',
  'ensiform',
  'enslaved',
  'ensorcelled',
  'ensuing',
  'entangled',
  'enterable',
  'enteral',
  'enteric',
  'entering',
  'enterprising',
  'entertained',
  'entertaining',
  'enthetic',
  'enthralled',
  'enthralling',
  'enthusiastic',
  'enticing',
  'entire',
  'entitative',
  'entitled',
  'entomic',
  'entomological',
  'entomophagous',
  'entomophilous',
  'entomostracan',
  'entomostracous',
  'entophytic',
  'entopic',
  'entozoic',
  'entranced',
  'entrancing',
  'entrenched',
  'entrepreneurial',
  'enucleate',
  'enumerable',
  'enumerative',
  'enunciable',
  'enunciative',
  'enunciatory',
  'enured',
  'enuretic',
  'enveloped',
  'enveloping',
  'enviable',
  'envious',
  'environmental',
  'envisioned',
  'enwrapped',
  'enzootic',
  'enzymatic',
  'eolithic',
  'eosinophilic',
  'epagogic',
  'epaxial',
  'epeirogenic',
  'epencephalic',
  'epenthetic',
  'epexegetic',
  'ephebic',
  'ephemeral',
  'epiblast',
  'epiblastic',
  'epic',
  'epical',
  'epicanthic',
  'epicedial',
  'epicedian',
  'epicene',
  'epicentral',
  'epicontinental',
  'epicritic',
  'epicyclic',
  'epicycloidal',
  'epideictic',
  'epidemic',
  'epidemiological',
  'epidermal',
  'epidermic',
  'epidermoid',
  'epidotic',
  'epidural',
  'epifocal',
  'epigamic',
  'epigastric',
  'epigeal',
  'epigene',
  'epigenetic',
  'epigeous',
  'epiglottic',
  'epigrammatic',
  'epigraphic',
  'epigynous',
  'epileptic',
  'epimeric',
  'epinastic',
  'epipetalous',
  'epiphanic',
  'epiphyllous',
  'epiphytic',
  'epiphytical',
  'epiploic',
  'episcopal',
  'episcopalian',
  'episcopally',
  'episematic',
  'episepalous',
  'episodic',
  'epispastic',
  'epistatic',
  'epistemic',
  'epistemological',
  'epistolary',
  'epistolatory',
  'epistolic',
  'epitaphic',
  'epitaxial',
  'epithalamic',
  'epithelial',
  'epitheliomatous',
  'epithetic',
  'epitomic',
  'epitomical',
  'epizoan',
  'epizoic',
  'epizootic',
  'epoch-making',
  'epochal',
  'eponymic',
  'eponymous',
  'epoxy',
  'equable',
  'equal',
  'equalised',
  'equalitarian',
  'equalized',
  'equanimous',
  'equatable',
  'equatorial',
  'equestrian',
  'equiangular',
  'equidistant',
  'equilateral',
  'equine',
  'equinoctial',
  'equipoised',
  'equipollent',
  'equiponderant',
  'equipotent',
  'equipotential',
  'equipped',
  'equiprobable',
  'equisetic',
  'equitable',
  'equitant',
  'equivalent',
  'equivalve',
  'equivocal',
  'eradicable',
  'eradicative',
  'erasable',
  'erased',
  'erect',
  'erectile',
  'erective',
  'eremitic',
  'erethismic',
  'erethistic',
  'ergative',
  'ergodic',
  'ergonomic',
  'ericaceous',
  'ericoid',
  'eristic',
  'ermined',
  'eroded',
  'erodent',
  'erodible',
  'erogenous',
  'erose',
  'erosive',
  'erotic',
  'erotically',
  'erotogenic',
  'errable',
  'errant',
  'erratic',
  'errhine',
  'erring',
  'erroneous',
  'ersatz',
  'erstwhile',
  'erubescent',
  'eruciform',
  'erudite',
  'erumpent',
  'erupting',
  'eruptional',
  'eruptive',
  'erysipelatous',
  'erythemal',
  'erythematic',
  'erythrocyte',
  'escapable',
  'escaped',
  'escapeless',
  'escapism',
  'escapist',
  'escharotic',
  'eschatological',
  'escheatable',
  'esculent',
  'escutcheoned',
  'esemplastic',
  'esophageal',
  'esoteric',
  'especial',
  'essayistic',
  'essential',
  'essive',
  'established',
  'establishmentarian',
  'esteemed',
  'esthetic',
  'estimable',
  'estimated',
  'estimative',
  'estipulate',
  'estival',
  'estranged',
  'estranging',
  'estrous',
  'estuarial',
  'estuarine',
  'esurient',
  'etched',
  'eternal',
  'eterne',
  'etesian',
  'ethereal',
  'ethereous',
  'ethic',
  'ethical',
  'ethmoid',
  'ethnic',
  'ethnical',
  'ethnocentric',
  'ethnographic',
  'ethnographical',
  'ethnolinguistic',
  'ethnological',
  'ethological',
  'ethylene',
  'etiolate',
  'etiolated',
  'etiological',
  'etymological',
  'eucaryotic',
  'eucharistic',
  'eudaemonic',
  'eudemonic',
  'eugenic',
  'euhemeristic',
  'eukaryotic',
  'eulogistic',
  'eunuchoid',
  'eupeptic',
  'euphemistic',
  'euphonic',
  'euphonical',
  'euphonious',
  'euphorbiaceous',
  'euphoriant',
  'euphoric',
  'euphuistic',
  'eurhythmic',
  'eurythermal',
  'eurythmic',
  'eurythmical',
  'eusporangiate',
  'eustatic',
  'eustyle',
  'eutectic',
  'eutectoid',
  'eutherian',
  'eutrophic',
  'evacuant',
  'evacuated',
  'evacuative',
  'evadable',
  'evaluative',
  'evanescent',
  'evangelical',
  'evangelistic',
  'evaporable',
  'evaporated',
  'evaporative',
  'evasive',
  'even',
  'even-handed',
  'even-minded',
  'even-tempered',
  'evens',
  'eventful',
  'eventual',
  'evergreen',
  'everlasting',
  'eversible',
  'every',
  'everyday',
  'evidenced',
  'evident',
  'evidential',
  'evidentiary',
  'evil',
  'evil-eyed',
  'evil-minded',
  'evincible',
  'evincive',
  'eviscerate',
  'evitable',
  'evocable',
  'evocative',
  'evoked',
  'evolutional',
  'evolutionary',
  'evolutionist',
  'evolutive',
  'evolvable',
  'ewe-necked',
  'ex-directory',
  'ex-service',
  'exacerbating',
  'exact',
  'exactable',
  'exacting',
  'exaggerated',
  'exaggerative',
  'exalted',
  'exalting',
  'examinable',
  'examinational',
  'exanimate',
  'exanthematic',
  'exarate',
  'exarch',
  'exarchal',
  'exasperate',
  'exasperated',
  'exasperating',
  'excaudate',
  'exceeding',
  'excellent',
  'excentric',
  'exceptionable',
  'exceptional',
  'exceptive',
  'excerptible',
  'excess',
  'excessive',
  'exchangeable',
  'exchanged',
  'excisable',
  'excitable',
  'excitant',
  'excitative',
  'excitatory',
  'excited',
  'exciting',
  'exclamational',
  'exclamatory',
  'excludable',
  'exclusionary',
  'exclusionist',
  'exclusive',
  'exclusory',
  'excogitative',
  'excommunicable',
  'excommunicate',
  'excommunicative',
  'excommunicatory',
  'excrementitious',
  'excrescent',
  'excretal',
  'excretive',
  'excretory',
  'excruciating',
  'exculpable',
  'exculpated',
  'exculpatory',
  'excurrent',
  'excursive',
  'excusable',
  'excusatory',
  'excused',
  'excusive',
  'execrable',
  'execrative',
  'execratory',
  'executable',
  'executed',
  'executive',
  'executorial',
  'executory',
  'exegetic',
  'exegetical',
  'exemplary',
  'exemplifiable',
  'exemplificative',
  'exemplifying',
  'exempt',
  'exenterate',
  'exequial',
  'exercisable',
  'exergonic',
  'exergual',
  'exertive',
  'exfoliative',
  'exhalant',
  'exhaled',
  'exhaling',
  'exhausted',
  'exhaustible',
  'exhausting',
  'exhaustive',
  'exhaustless',
  'exhibitionist',
  'exhibitionistic',
  'exhibitive',
  'exhibitory',
  'exhilarant',
  'exhilarated',
  'exhilarating',
  'exhilarative',
  'exhortative',
  'exhortatory',
  'exigeant',
  'exigent',
  'exigible',
  'exiguous',
  'exilic',
  'eximious',
  'existent',
  'existential',
  'existentialist',
  'existing',
  'exocrine',
  'exodermal',
  'exoergic',
  'exogamic',
  'exogamous',
  'exogenetic',
  'exogenous',
  'exonerated',
  'exonerative',
  'exophthalmic',
  'exopoditic',
  'exorable',
  'exorbitant',
  'exordial',
  'exoskeletal',
  'exosmotic',
  'exospherical',
  'exosporal',
  'exosporous',
  'exoteric',
  'exothermal',
  'exothermic',
  'exotic',
  'exotoxic',
  'expandable',
  'expanded',
  'expanding',
  'expansible',
  'expansile',
  'expansional',
  'expansionary',
  'expansionism',
  'expansionistic',
  'expansive',
  'expatriate',
  'expectable',
  'expectant',
  'expectative',
  'expected',
  'expecting',
  'expectorant',
  'expedient',
  'expediential',
  'expedite',
  'expedited',
  'expeditionary',
  'expeditious',
  'expellant',
  'expendable',
  'expended',
  'expensive',
  'experienced',
  'experienceless',
  'experiential',
  'experimental',
  'experimentative',
  'expert',
  'expiable',
  'expiatory',
  'expiratory',
  'expired',
  'expiring',
  'expiscatory',
  'explainable',
  'explanatory',
  'expletive',
  'explicable',
  'explicative',
  'explicit',
  'exploding',
  'exploitable',
  'exploitative',
  'exploited',
  'exploitive',
  'explorative',
  'exploratory',
  'explosible',
  'explosive',
  'exponent',
  'exponential',
  'exponible',
  'exportable',
  'exposable',
  'exposed',
  'expositional',
  'expositive',
  'expository',
  'expostulatory',
  'express',
  'expressed',
  'expressible',
  'expressional',
  'expressionism',
  'expressionist',
  'expressionistic',
  'expressionless',
  'expressive',
  'expropriable',
  'expropriated',
  'expugnable',
  'expulsive',
  'expurgated',
  'expurgatorial',
  'expurgatory',
  'exquisite',
  'exsanguine',
  'exsert',
  'exserted',
  'exsertile',
  'exsiccative',
  'exstipulate',
  'exsufflicate',
  'extant',
  'extemporal',
  'extemporaneous',
  'extemporary',
  'extempore',
  'extendable',
  'extended',
  'extended-play',
  'extendible',
  'extensible',
  'extensile',
  'extensional',
  'extensive',
  'extenuating',
  'extenuative',
  'extenuatory',
  'exterior',
  'exterminable',
  'exterminated',
  'exterminatory',
  'external',
  'exteroceptive',
  'exterritorial',
  'extinct',
  'extinctive',
  'extinguishable',
  'extinguished',
  'extirpable',
  'extirpative',
  'extorsive',
  'extortionary',
  'extortionate',
  'extortive',
  'extra',
  'extra-condensed',
  'extracanonical',
  'extracorporeal',
  'extractable',
  'extractible',
  'extractive',
  'extraditable',
  'extraneous',
  'extranuclear',
  'extraordinary',
  'extrapolated',
  'extrapolative',
  'extrapolatory',
  'extravagant',
  'extravehicular',
  'extraversive',
  'extravert',
  'extraverted',
  'extreme',
  'extremer',
  'extremest',
  'extremist',
  'extricable',
  'extricated',
  'extrinsic',
  'extrorse',
  'extroversive',
  'extrovert',
  'extroverted',
  'extrusible',
  'extrusive',
  'exuberant',
  'exudative',
  'exultant',
  'exulting',
  'exuvial',
  'eye-catching',
  'eyed',
  'eyeless',
  'fab',
  'fabaceous',
  'fabled',
  'fabricated',
  'fabricative',
  'fabulous',
  'face-saving',
  'faced',
  'faceless',
  'facete',
  'faceted',
  'facetious',
  'facial',
  'facile',
  'facilitated',
  'facilitative',
  'facinorous',
  'fact-finding',
  'factional',
  'factious',
  'factitious',
  'factitive',
  'factorable',
  'factorial',
  'factual',
  'facular',
  'facultative',
  'fadable',
  'faddier',
  'faddiest',
  'faddish',
  'faddy',
  'faded',
  'fadeless',
  'fading',
  'faecal',
  'faerie',
  'faery',
  'fagaceous',
  'fagged',
  'fail-safe',
  'failed',
  'failing',
  'fain',
  'faint',
  'faintish',
  'fair',
  'fair-haired',
  'fair-minded',
  'fair-spoken',
  'fair-weather',
  'fairish',
  'fairy',
  'fairylike',
  'faithful',
  'faithless',
  'fake',
  'falcate',
  'falciform',
  'falconine',
  'fallacious',
  'fallen',
  'fallibilist',
  'fallible',
  'falling',
  'fallow',
  'false',
  'false-hearted',
  'falser',
  'falsest',
  'falsetto',
  'falsifiable',
  'faltering',
  'famed',
  'fameless',
  'familial',
  'familiar',
  'familiarized',
  'familiarizing',
  'familistic',
  'famished',
  'famous',
  'fanatic',
  'fanatical',
  'fancied',
  'fanciful',
  'fanciless',
  'fancy',
  'fancy-free',
  'fanfold',
  'fanged',
  'fangled',
  'fangless',
  'fanned',
  'fantastic',
  'fantastical',
  'far',
  'far-flung',
  'far-gone',
  'far-off',
  'far-out',
  'far-reaching',
  'far-seeing',
  'far-sighted',
  'faradic',
  'farand',
  'faraway',
  'farci',
  'farcical',
  'fardel-bound',
  'farewell',
  'farfetched',
  'farinaceous',
  'farinose',
  'farm',
  'farming',
  'farouche',
  'farraginous',
  'farrow',
  'farther',
  'farthermost',
  'farthest',
  'fascial',
  'fasciate',
  'fascicular',
  'fasciculate',
  'fascinated',
  'fascinating',
  'fascist',
  'fascistic',
  'fashionable',
  'fashioned',
  'fast',
  'fastened',
  'fastidious',
  'fastigiate',
  'fastuous',
  'fat',
  'fat-faced',
  'fat-free',
  'fat-witted',
  'fatal',
  'fatalist',
  'fatalistic',
  'fated',
  'fateful',
  'fatherless',
  'fatherlike',
  'fatherly',
  'fathomable',
  'fathomless',
  'fatigable',
  'fatigate',
  'fatigue',
  'fatigued',
  'fatless',
  'fattened',
  'fattening',
  'fatter',
  'fattest',
  'fattier',
  'fattiest',
  'fattish',
  'fatty',
  'fatuitous',
  'fatuous',
  'faucal',
  'faucial',
  'fault-finding',
  'faultier',
  'faultiest',
  'faultless',
  'faulty',
  'faunal',
  'faustian',
  'faux',
  'faveolate',
  'favorable',
  'favored',
  'favoring',
  'favorite',
  'favorless',
  'favourable',
  'favoured',
  'favourite',
  'favourless',
  'fawning',
  'fay',
  'fazed',
  'feal',
  'fearful',
  'fearless',
  'fearsome',
  'feasible',
  'feastful',
  'feat',
  'featherbrained',
  'feathered',
  'featherless',
  'feathery',
  'feature-length',
  'featured',
  'featureless',
  'febrifacient',
  'febrific',
  'febrifugal',
  'febrifuge',
  'febrile',
  'fecal',
  'feckless',
  'feculent',
  'fecund',
  'federal',
  'federalism',
  'federalist',
  'federate',
  'federated',
  'federative',
  'feeble',
  'feeble-minded',
  'feebler',
  'feeblest',
  'feeblish',
  'feeling',
  'feelingless',
  'feetless',
  'feigned',
  'feisty',
  'feldspathic',
  'feldspathoid',
  'felicific',
  'felicitous',
  'feline',
  'fell',
  'fellable',
  'felled',
  'fellow',
  'felon',
  'felonious',
  'felsic',
  'felsitic',
  'felspathic',
  'felt',
  'felted',
  'female',
  'feminine',
  'feminism',
  'feminist',
  'feministic',
  'femoral',
  'fencible',
  'fenestral',
  'fenestrated',
  'fenny',
  'feodal',
  'feracious',
  'feral',
  'ferial',
  'ferine',
  'fermentable',
  'fermentation',
  'fermentative',
  'fermented',
  'fernier',
  'ferniest',
  'ferny',
  'ferocious',
  'ferreous',
  'ferrety',
  'ferric',
  'ferriferous',
  'ferroelectric',
  'ferromagnesian',
  'ferromagnetic',
  'ferrous',
  'ferruginous',
  'fertile',
  'fertilised',
  'fertilized',
  'ferulaceous',
  'fervent',
  'fervid',
  'fesswise',
  'festal',
  'festering',
  'festinate',
  'festive',
  'fetal',
  'fetching',
  'fetial',
  'fetichistic',
  'feticidal',
  'fetid',
  'fetishistic',
  'fettered',
  'fetterless',
  'feudal',
  'feudalist',
  'feudalistic',
  'feudatory',
  'fevered',
  'feverish',
  'feverous',
  'few',
  'fewer',
  'fewest',
  'fey',
  'fezzed',
  'fibered',
  'fiberless',
  'fibreless',
  'fibriform',
  'fibrillar',
  'fibrillose',
  'fibrinous',
  'fibroblastic',
  'fibroid',
  'fibrotic',
  'fibrous',
  'fibrovascular',
  'fibular',
  'fickle',
  'fictile',
  'fictional',
  'fictitious',
  'fictive',
  'fiddling',
  'fiddly',
  'fidgety',
  'fiducial',
  'fiduciary',
  'fiendish',
  'fierce',
  'fiercer',
  'fiercest',
  'fierier',
  'fieriest',
  'fiery',
  'fifteen',
  'fifteenth',
  'fifth',
  'fiftieth',
  'fifty',
  'fifty-fifty',
  'fightable',
  'fighting',
  'figuline',
  'figurable',
  'figural',
  'figurate',
  'figurative',
  'figured',
  'filaceous',
  'filagree',
  'filamentary',
  'filamentous',
  'filar',
  'filarial',
  'filial',
  'filibusterous',
  'filiform',
  'filigree',
  'filigreed',
  'filled',
  'filmable',
  'filmed',
  'filmier',
  'filmiest',
  'filmy',
  'filose',
  'filter-tipped',
  'filterable',
  'filthier',
  'filthiest',
  'filthy',
  'filtrable',
  'fimbriate',
  'fin-footed',
  'finable',
  'final',
  'financed',
  'financial',
  'fine',
  'fine-drawn',
  'fineable',
  'fined',
  'finer',
  'finest',
  'fingered',
  'fingerless',
  'finical',
  'finicky',
  'finished',
  'finite',
  'finless',
  'finned',
  'finnier',
  'finniest',
  'finny',
  'fire-and-brimstone',
  'fire-eater',
  'fire-new',
  'fire-resistant',
  'fire-resisting',
  'fired',
  'fireless',
  'fireproof',
  'firm',
  'firmamental',
  'firry',
  'first',
  'first-aid',
  'first-born',
  'first-chop',
  'first-class',
  'first-generation',
  'first-hand',
  'first-rate',
  'first-string',
  'fiscal',
  'fish-bellied',
  'fishable',
  'fishier',
  'fishiest',
  'fishy',
  'fishyback',
  'fissile',
  'fissionable',
  'fissiparous',
  'fissiped',
  'fissirostral',
  'fistic',
  'fistular',
  'fistulous',
  'fit',
  'fitchy',
  'fitful',
  'fitted',
  'fitter',
  'fitting',
  'five',
  'fivefold',
  'fivepenny',
  'fixable',
  'fixative',
  'fixed',
  'fizzier',
  'fizziest',
  'fizzing',
  'fizzy',
  'flabbergasted',
  'flabbier',
  'flabbiest',
  'flabby',
  'flabellate',
  'flaccid',
  'flag-waving',
  'flagellate',
  'flagellated',
  'flagellatory',
  'flagelliform',
  'flaggier',
  'flaggiest',
  'flagging',
  'flaggy',
  'flagitious',
  'flagrant',
  'flailing',
  'flakier',
  'flakiest',
  'flaky',
  'flamboyant',
  'flameproof',
  'flamier',
  'flamiest',
  'flaming',
  'flammable',
  'flamy',
  'flannelly',
  'flapperish',
  'flappy',
  'flared',
  'flaring',
  'flash',
  'flashier',
  'flashiest',
  'flashing',
  'flashy',
  'flat',
  'flat-footed',
  'flatling',
  'flattened',
  'flattering',
  'flattest',
  'flattish',
  'flatulent',
  'flauntier',
  'flauntiest',
  'flaunty',
  'flavescent',
  'flavored',
  'flavorful',
  'flavorless',
  'flavorous',
  'flavorsome',
  'flavoured',
  'flavourful',
  'flavourless',
  'flavourous',
  'flavoursome',
  'flawed',
  'flawier',
  'flawiest',
  'flawless',
  'flawy',
  'flaxen',
  'flea-bitten',
  'flecked',
  'fleckless',
  'fledged',
  'fledgeling',
  'fledgier',
  'fledgiest',
  'fledgling',
  'fledgy',
  'fleeceless',
  'fleecier',
  'fleeciest',
  'fleecy',
  'fleeing',
  'fleet',
  'fleeting',
  'fleshier',
  'fleshiest',
  'fleshless',
  'fleshly',
  'fleshy',
  'fleury',
  'flexed',
  'flexible',
  'flexile',
  'flexuous',
  'flexural',
  'flickering',
  'flighted',
  'flightier',
  'flightiest',
  'flightless',
  'flighty',
  'flimsier',
  'flimsies',
  'flimsy',
  'flintier',
  'flintiest',
  'flinty',
  'flip',
  'flippant',
  'flipping',
  'flirtatious',
  'flittering',
  'flitting',
  'floatable',
  'floatier',
  'floatiest',
  'floating',
  'floaty',
  'floccose',
  'flocculent',
  'floccus',
  'flood',
  'flooded',
  'flooding',
  'floodlighted',
  'floodlit',
  'floored',
  'floppier',
  'floppiest',
  'floppy',
  'floral',
  'floreated',
  'florentine',
  'florescent',
  'floriated',
  'floricultural',
  'florid',
  'floriferous',
  'floristic',
  'flory',
  'flossy',
  'flourishing',
  'floury',
  'flowered',
  'flowering',
  'flowerless',
  'flowery',
  'flowing',
  'flown',
  'fluctuant',
  'fluctuating',
  'fluent',
  'fluffier',
  'fluffiest',
  'fluffy',
  'fluid',
  'fluidal',
  'fluidic',
  'flukey',
  'flukier',
  'flukiest',
  'fluky',
  'fluorescent',
  'fluoric',
  'fluorometric',
  'fluoroscopic',
  'flurried',
  'flush',
  'flushed',
  'flustered',
  'fluted',
  'flutier',
  'flutiest',
  'fluttering',
  'fluttery',
  'fluty',
  'fluvial',
  'fluviatile',
  'fluxional',
  'fluxionary',
  'fly',
  'fly-by-night',
  'flyable',
  'flyaway',
  'flying',
  'flyweight',
  'foaled',
  'foamier',
  'foamiest',
  'foaming',
  'foamless',
  'foamy',
  'focal',
  'focused',
  'focussed',
  'foetal',
  'foetid',
  'fogbound',
  'fogged',
  'foggier',
  'foggiest',
  'foggy',
  'fogless',
  'fogyish',
  'foiled',
  'fold',
  'foldable',
  'foldaway',
  'folded',
  'folding',
  'foliaceous',
  'foliaged',
  'foliar',
  'foliate',
  'foliated',
  'folio',
  'foliolate',
  'foliose',
  'folk',
  'folkish',
  'folklore',
  'folkloric',
  'folksier',
  'folksiest',
  'folksy',
  'follicular',
  'follow-up',
  'following',
  'fond',
  'fontal',
  'foodless',
  'fool',
  'foolhardier',
  'foolhardiest',
  'foolhardy',
  'fooling',
  'foolish',
  'foolproof',
  'foot-loose',
  'footed',
  'footier',
  'footiest',
  'footless',
  'footling',
  'footsore',
  'footworn',
  'foppish',
  'foraminal',
  'foraminiferal',
  'foraminiferous',
  'forbearing',
  'forbidden',
  'forbidding',
  'forced',
  'forceful',
  'forceless',
  'forcible',
  'forcipate',
  'fordable',
  'fore',
  'foreboding',
  'foreclosable',
  'foregoing',
  'foregone',
  'forehand',
  'forehanded',
  'foreign',
  'foreknowable',
  'foremost',
  'forenamed',
  'forensic',
  'foreordained',
  'foresaid',
  'foreseeable',
  'foreseen',
  'foreshadowing',
  'foresighted',
  'foresightful',
  'forespent',
  'forestal',
  'forested',
  'forethoughtful',
  'foretold',
  'forfeit',
  'forfeitable',
  'forfeited',
  'forficate',
  'forgeable',
  'forged',
  'forgetful',
  'forgetive',
  'forgettable',
  'forgivable',
  'forgiving',
  'forgotten',
  'forked',
  'forkier',
  'forkiest',
  'forky',
  'forlorn',
  'formable',
  'formal',
  'formalistic',
  'formalized',
  'formational',
  'formative',
  'formed',
  'former',
  'formic',
  'formidable',
  'formless',
  'formulaic',
  'formulary',
  'formulated',
  'formulism',
  'fornent',
  'fornical',
  'fornicate',
  'forsaken',
  'forspent',
  'forte',
  'forthcoming',
  'forthright',
  'fortieth',
  'fortifiable',
  'fortified',
  'fortifying',
  'fortis',
  'fortissimo',
  'fortitudinous',
  'fortnightly',
  'fortuitism',
  'fortuitist',
  'fortuitous',
  'fortunate',
  'fortuneless',
  'forty',
  'fortyish',
  'forward',
  'forward-looking',
  'forworn',
  'forzando',
  'fossiliferous',
  'fossilized',
  'fossorial',
  'foster',
  'fostered',
  'fou',
  'foudroyant',
  'foughten',
  'foul',
  'foul-mouthed',
  'foul-spoken',
  'fouled',
  'found',
  'foundational',
  'founded',
  'founderous',
  'fountainless',
  'four',
  'four-dimensional',
  'four-footed',
  'four-handed',
  'four-legged',
  'four-part',
  'four-stroke',
  'four-wheel',
  'fourfold',
  'fourpenny',
  'fourscore',
  'foursquare',
  'fourteen',
  'fourteenth',
  'fourth',
  'fourth-class',
  'fourth-dimensional',
  'foveal',
  'foveate',
  'foxier',
  'foxiest',
  'foxy',
  'fozier',
  'foziest',
  'fozy',
  'fractional',
  'fractious',
  'fractured',
  'fragile',
  'fragmental',
  'fragmentary',
  'fragmented',
  'fragrant',
  'frail',
  'framed',
  'francophone',
  'frangible',
  'frank',
  'frantic',
  'fratchy',
  'fraternal',
  'fratricidal',
  'fraudful',
  'fraudulent',
  'fraught',
  'frayed',
  'frazzled',
  'freakier',
  'freakiest',
  'freakish',
  'freaky',
  'frecklier',
  'freckliest',
  'freckly',
  'free',
  'free-and-easy',
  'free-floating',
  'free-form',
  'free-hand',
  'free-handed',
  'free-hearted',
  'free-living',
  'free-range',
  'free-soil',
  'free-spoken',
  'free-swimming',
  'free-thinking',
  'free-trade',
  'freebie',
  'freeborn',
  'freed',
  'freehold',
  'freeing',
  'freemasonic',
  'freer',
  'freest',
  'freewheeling',
  'freezable',
  'freeze-dried',
  'freezing',
  'fremd',
  'french',
  'frenetic',
  'frenzied',
  'frequent',
  'frequentative',
  'fresh',
  'fresh-run',
  'freshman',
  'freshwater',
  'fretful',
  'fretted',
  'fretty',
  'friable',
  'fribble',
  'fricative',
  'frictional',
  'frictionless',
  'fried',
  'friended',
  'friendless',
  'friendlier',
  'friendliest',
  'friendly',
  'frightened',
  'frightening',
  'frightful',
  'frigid',
  'frigorific',
  'frilled',
  'frilly',
  'fringe',
  'fringed',
  'fringeless',
  'fringillid',
  'fringilline',
  'fringy',
  'friskier',
  'friskiest',
  'frisky',
  'frivolous',
  'frizzier',
  'frizziest',
  'frizzlier',
  'frizzliest',
  'frizzly',
  'frizzy',
  'frockless',
  'frogged',
  'froggier',
  'froggiest',
  'froggy',
  'frolic',
  'frolicsome',
  'fronded',
  'frondescent',
  'front',
  'front-rank',
  'frontal',
  'frontier',
  'frontless',
  'frore',
  'frostbitten',
  'frosted',
  'frostier',
  'frostiest',
  'frostless',
  'frostlike',
  'frosty',
  'frothier',
  'frothiest',
  'frothing',
  'frothy',
  'froward',
  'frowning',
  'frowsier',
  'frowsiest',
  'frowsty',
  'frowsy',
  'frowzier',
  'frowziest',
  'frowzy',
  'frozen',
  'fructed',
  'fructiferous',
  'fructuous',
  'frugal',
  'frugivorous',
  'fruitarian',
  'fruited',
  'fruitful',
  'fruitier',
  'fruitiest',
  'fruiting',
  'fruitive',
  'fruitless',
  'fruity',
  'frumentaceous',
  'frumpier',
  'frumpiest',
  'frumpish',
  'frumpy',
  'frustrate',
  'frustrated',
  'frustrating',
  'frutescent',
  'fruticose',
  'fubsier',
  'fubsiest',
  'fubsy',
  'fucoid',
  'fuddled',
  'fugacious',
  'fugal',
  'fugato',
  'fuggy',
  'fugitive',
  'fulfilled',
  'fulgent',
  'fulgid',
  'fulgorous',
  'fulgurant',
  'fulgurating',
  'fulgurous',
  'fuliginous',
  'full',
  'full-blooded',
  'full-blown',
  'full-bodied',
  'full-bottomed',
  'full-bound',
  'full-cream',
  'full-dress',
  'full-face',
  'full-faced',
  'full-fashioned',
  'full-fledged',
  'full-frontal',
  'full-grown',
  'full-length',
  'full-mouthed',
  'full-page',
  'full-rigged',
  'full-sailed',
  'full-scale',
  'full-size',
  'full-time',
  'fully-fashioned',
  'fully-fledged',
  'fully-grown',
  'fulminant',
  'fulminatory',
  'fulminous',
  'fulsome',
  'fulvous',
  'fumarolic',
  'fumatory',
  'fumbling',
  'fumed',
  'fumier',
  'fumiest',
  'fumigatory',
  'fumy',
  'fun',
  'functional',
  'functionalism',
  'functionalist',
  'functionary',
  'functioning',
  'functionless',
  'fundamental',
  'fundamentalism',
  'fundamentalist',
  'funded',
  'funerary',
  'funereal',
  'funest',
  'fungal',
  'fungible',
  'fungicidal',
  'fungiform',
  'fungistatic',
  'fungoid',
  'fungous',
  'funicular',
  'funiculate',
  'funked',
  'funkier',
  'funkiest',
  'funky',
  'funnier',
  'funniest',
  'funny',
  'furcate',
  'furcular',
  'furfuraceous',
  'furibund',
  'furioso',
  'furious',
  'furled',
  'furnished',
  'furred',
  'furriest',
  'furrowed',
  'furrowy',
  'furry',
  'further',
  'furthermost',
  'furthest',
  'furtive',
  'furuncular',
  'furunculous',
  'furzy',
  'fuscous',
  'fused',
  'fusible',
  'fusiform',
  'fusil',
  'fusile',
  'fusionism',
  'fusionist',
  'fussier',
  'fussiest',
  'fussy',
  'fustian',
  'fustier',
  'fustiest',
  'fusty',
  'futile',
  'futilitarian',
  'future',
  'future-perfect',
  'futureless',
  'futurism',
  'futurist',
  'futuristic',
  'fuzzed',
  'fuzzier',
  'fuzziest',
  'fuzzy',
  'gabbling',
  'gabbroic',
  'gabbroid',
  'gabbroitic',
  'gabby',
  'gabled',
  'gadoid',
  'gadrooned',
  'gaff-rigged',
  'gaga',
  'gaillard',
  'gainable',
  'gainful',
  'gainless',
  'gainly',
  'gaited',
  'gala',
  'galactagogue',
  'galactic',
  'galactophorous',
  'galactopoietic',
  'galeate',
  'gallant',
  'galled',
  'galleried',
  'galliambic',
  'galliard',
  'gallinaceous',
  'galling',
  'gallooned',
  'galloping',
  'galore',
  'galvanic',
  'galvanizing',
  'galvanometric',
  'galvanoplastic',
  'gambling',
  'gambogian',
  'gambrel',
  'game',
  'gamer',
  'gamesome',
  'gamest',
  'gametic',
  'gamey',
  'gamic',
  'gamier',
  'gamiest',
  'gammy',
  'gamopetalous',
  'gamophyllous',
  'gamosepalous',
  'gamy',
  'gangliar',
  'gangliate',
  'ganglier',
  'gangliest',
  'gangliform',
  'gangling',
  'ganglionic',
  'gangly',
  'gangrenous',
  'ganoid',
  'gap-toothed',
  'gaping',
  'garbed',
  'garbled',
  'gardant',
  'gargantuan',
  'gargety',
  'garish',
  'garlandless',
  'garlicky',
  'garmented',
  'garmentless',
  'garni',
  'garnished',
  'garreted',
  'garrulous',
  'gas-fired',
  'gaseous',
  'gashed',
  'gasified',
  'gasiform',
  'gasometric',
  'gasometrical',
  'gasping',
  'gassier',
  'gassiest',
  'gassy',
  'gasteropod',
  'gastralgic',
  'gastric',
  'gastroenteric',
  'gastrointestinal',
  'gastronomic',
  'gastronomical',
  'gastropod',
  'gated',
  'gateless',
  'gathered',
  'gathering',
  'gauche',
  'gaudy',
  'gauge',
  'gaugeable',
  'gaumless',
  'gaunt',
  'gauntleted',
  'gaussian',
  'gauzier',
  'gauziest',
  'gauzy',
  'gawkier',
  'gawkiest',
  'gawky',
  'gawsy',
  'gay',
  'geanticlinal',
  'geared',
  'gearless',
  'geegaw',
  'geitonogamous',
  'gelatinoid',
  'gelatinous',
  'gelded',
  'gelid',
  'gemel',
  'geminate',
  'gemmaceous',
  'gemmate',
  'gemmed',
  'gemmier',
  'gemmiest',
  'gemmiferous',
  'gemmiparous',
  'gemmological',
  'gemmy',
  'gemological',
  'genal',
  'genealogic',
  'genealogical',
  'generable',
  'general',
  'general-purpose',
  'generalisable',
  'generalizable',
  'generalized',
  'generative',
  'generic',
  'generous',
  'genethliac',
  'genethliacally',
  'genethlialogic',
  'genethlialogical',
  'genetic',
  'genetical',
  'genial',
  'genic',
  'geniculate',
  'genital',
  'genitalic',
  'genitival',
  'genitive',
  'genocidal',
  'genotypic',
  'genteel',
  'gentianaceous',
  'gentile',
  'gentle',
  'gentled',
  'gentlemanlike',
  'gentlemanly',
  'gentler',
  'gentlest',
  'gentlewomanly',
  'gentling',
  'genty',
  'genuine',
  'geocentric',
  'geochemical',
  'geochronological',
  'geodesic',
  'geodesical',
  'geodetic',
  'geodic',
  'geodynamic',
  'geodynamical',
  'geognostic',
  'geognostical',
  'geographic',
  'geographical',
  'geoidal',
  'geologic',
  'geological',
  'geomagnetic',
  'geomantic',
  'geomedical',
  'geometric',
  'geometrical',
  'geometrid',
  'geomorphologic',
  'geomorphological',
  'geophagous',
  'geophilous',
  'geophysical',
  'geophytic',
  'geopolitical',
  'geoponic',
  'georgic',
  'geostatic',
  'geostationary',
  'geostrophic',
  'geosynchronous',
  'geosynclinal',
  'geotactic',
  'geotectonic',
  'geothermal',
  'geotropic',
  'geriatric',
  'german',
  'germane',
  'germanous',
  'germicidal',
  'germinable',
  'germinal',
  'germinant',
  'germinative',
  'gerontological',
  'gerundial',
  'gerundival',
  'gerundive',
  'gestational',
  'gestative',
  'gestic',
  'gesticulating',
  'gesticulative',
  'gesticulatory',
  'gestural',
  'gettable',
  'gewgaw',
  'ghast',
  'ghastful',
  'ghastlier',
  'ghastliest',
  'ghastly',
  'ghostlier',
  'ghostliest',
  'ghostly',
  'ghoulish',
  'giant',
  'gibbed',
  'gibbose',
  'gibbous',
  'giddied',
  'giddier',
  'giddiest',
  'giddy',
  'giddying',
  'gifted',
  'gigantean',
  'gigantesque',
  'gigantic',
  'gigglier',
  'giggliest',
  'giggly',
  'gilded',
  'gilled',
  'gilt',
  'gilt-edged',
  'gimcrack',
  'gimlet',
  'gimlet-eyed',
  'gimmicky',
  'gimpy',
  'ginger',
  'gingerly',
  'gingery',
  'gingival',
  'ginned',
  'giocoso',
  'girlish',
  'girly',
  'girt',
  'given',
  'giving',
  'glabellar',
  'glabrate',
  'glabrous',
  'glacial',
  'glaciated',
  'glaciological',
  'glad',
  'gladdened',
  'gladiate',
  'gladiatorial',
  'gladsome',
  'glaikit',
  'glairier',
  'glairiest',
  'glairy',
  'glaived',
  'glamorous',
  'glamourous',
  'glandered',
  'glanderous',
  'glandular',
  'glandulous',
  'glare',
  'glarier',
  'glariest',
  'glaring',
  'glary',
  'glass-faced',
  'glassed',
  'glassier',
  'glassiest',
  'glasslike',
  'glassy',
  'glaucescent',
  'glaucomatous',
  'glauconitic',
  'glaucous',
  'glazed',
  'gleaming',
  'gleeful',
  'gleesome',
  'gleetier',
  'gleetiest',
  'gleety',
  'gleg',
  'glenoid',
  'glial',
  'glib',
  'glibber',
  'glibbest',
  'gliddery',
  'glimmering',
  'glimmery',
  'glinting',
  'gliomatous',
  'glistening',
  'glistering',
  'glittering',
  'glittery',
  'global',
  'globate',
  'globoid',
  'globose',
  'globular',
  'globuliferous',
  'glomerate',
  'glomerular',
  'gloomful',
  'gloomier',
  'gloomiest',
  'glooming',
  'gloomy',
  'glorified',
  'glorious',
  'glossarial',
  'glossier',
  'glossies',
  'glossiest',
  'glossographical',
  'glossological',
  'glossy',
  'glottal',
  'glottic',
  'glottidean',
  'glottogonic',
  'gloved',
  'glowering',
  'glowing',
  'gluconeogenic',
  'glucosic',
  'glucosuric',
  'glued',
  'gluey',
  'glum',
  'glumaceous',
  'glummer',
  'glummest',
  'glumpier',
  'glumpiest',
  'glumpy',
  'gluteal',
  'glutenous',
  'glutinous',
  'glutted',
  'gluttonous',
  'glyceric',
  'glycogen',
  'glycogenetic',
  'glycogenic',
  'glycolic',
  'glycolytic',
  'glycosidic',
  'glycosuric',
  'glyphic',
  'glyphographic',
  'glyptic',
  'glyptographic',
  'gnarled',
  'gnarlier',
  'gnarliest',
  'gnarly',
  'gnathic',
  'gnathonic',
  'gneissic',
  'gneissoid',
  'gnomic',
  'gnomish',
  'gnomonic',
  'gnostic',
  'gnotobiotic',
  'go-ahead',
  'go-as-you-please',
  'go-to-meeting',
  'goaded',
  'goalless',
  'goateed',
  'goatish',
  'gobioid',
  'gobony',
  'god-fearing',
  'goddam',
  'goddamn',
  'goddamned',
  'godless',
  'godlier',
  'godliest',
  'godlike',
  'godly',
  'goggle-eyed',
  'going',
  'goitrous',
  'gold',
  'gold-foil',
  'gold-leaf',
  'golden',
  'goliardic',
  'gonadal',
  'gonadial',
  'gonadotropic',
  'gone',
  'gonidial',
  'gonidic',
  'goniometric',
  'goniometrical',
  'gonococcal',
  'gonococcic',
  'gonococcoid',
  'gonorrheal',
  'gonorrheic',
  'good',
  'good-for-nothing',
  'good-humoured',
  'good-looking',
  'good-natured',
  'good-sized',
  'good-tempered',
  'goodish',
  'goodlier',
  'goodliest',
  'goodly',
  'goody-goody',
  'gooey',
  'goofier',
  'goofiest',
  'goofy',
  'gooier',
  'gooiest',
  'goosey',
  'goosy',
  'gorged',
  'gorgeous',
  'gorgonian',
  'gorier',
  'goriest',
  'gorillian',
  'gorilline',
  'gorilloid',
  'gormless',
  'gorsy',
  'gory',
  'gossamer',
  'gossipy',
  'goutier',
  'goutiest',
  'gouty',
  'governable',
  'governessy',
  'governing',
  'governmental',
  'gowaned',
  'gowany',
  'gowned',
  'goyish',
  'grab',
  'graceful',
  'graceless',
  'gracile',
  'gracious',
  'gradable',
  'gradational',
  'gradatory',
  'grade',
  'graded',
  'gradely',
  'gradient',
  'gradual',
  'gradualism',
  'gradualist',
  'gradualistic',
  'graduate',
  'graduated',
  'grained',
  'grainier',
  'grainiest',
  'grainy',
  'grallatorial',
  'gram-negative',
  'gram-positive',
  'gramineous',
  'graminivorous',
  'grammatic',
  'grammatical',
  'gramophonic',
  'grand',
  'grand-ducal',
  'grandfatherly',
  'grandiloquent',
  'grandiose',
  'grandioso',
  'grandmotherly',
  'granitic',
  'granitoid',
  'granivorous',
  'granolithic',
  'granophyric',
  'grantable',
  'granted',
  'granular',
  'granulated',
  'granulative',
  'granuliferous',
  'granulitic',
  'granulocytic',
  'granulomatous',
  'granulose',
  'grapey',
  'graphic',
  'graphical',
  'graphitic',
  'graphologic',
  'graphological',
  'grapier',
  'grapiest',
  'graptolitic',
  'grapy',
  'graspable',
  'grasping',
  'graspless',
  'grass-green',
  'grass-roots',
  'grassier',
  'grassiest',
  'grassy',
  'grateful',
  'gratified',
  'gratifying',
  'grating',
  'gratis',
  'gratuitous',
  'gratulant',
  'gratulatory',
  'grave',
  'gravel',
  'gravel-blind',
  'graveless',
  'gravelly',
  'graven',
  'gravest',
  'gravid',
  'gravimetric',
  'gravitational',
  'gravitative',
  'gray',
  'grayed',
  'grayish',
  'grazed',
  'greased',
  'greasier',
  'greasiest',
  'greasy',
  'great',
  'great-bellied',
  'great-hearted',
  'greater',
  'greatest',
  'greaved',
  'greedier',
  'greediest',
  'greedy',
  'green',
  'green-eyed',
  'greening',
  'greenish',
  'greensick',
  'gregarine',
  'gregarious',
  'greige',
  'gressorial',
  'grey',
  'grey-haired',
  'grey-headed',
  'greyish',
  'grief-stricken',
  'griefless',
  'grieving',
  'grievous',
  'griffinish',
  'grilled',
  'grim',
  'grimier',
  'grimiest',
  'grimmer',
  'grimmest',
  'grimy',
  'grippier',
  'grippiest',
  'gripping',
  'gripple',
  'grippy',
  'griseous',
  'grislier',
  'grisliest',
  'grisly',
  'gristlier',
  'gristliest',
  'gristly',
  'grittier',
  'grittiest',
  'gritty',
  'grizzled',
  'grizzlier',
  'grizzlies',
  'grizzliest',
  'grizzly',
  'groaning',
  'groggier',
  'groggiest',
  'groggy',
  'groomed',
  'grooved',
  'groovier',
  'grooviest',
  'groovy',
  'groping',
  'gross',
  'grotesque',
  'grotty',
  'grouchier',
  'grouchiest',
  'grouchy',
  'ground',
  'groundless',
  'group',
  'grouped',
  'grouse',
  'groutier',
  'groutiest',
  'grouty',
  'groveling',
  'grovelling',
  'growable',
  'growing',
  'grown',
  'grown-up',
  'grubbier',
  'grubbiest',
  'grubby',
  'grudging',
  'grueling',
  'gruelling',
  'gruesome',
  'gruff',
  'gruffish',
  'grum',
  'grumbling',
  'grumbly',
  'grummer',
  'grummest',
  'grumose',
  'grumous',
  'grumpier',
  'grumpiest',
  'grumpy',
  'grungy',
  'grunting',
  'gruntled',
  'guaranteed',
  'guardable',
  'guardant',
  'guarded',
  'guardian',
  'guardless',
  'gubernacular',
  'gubernatorial',
  'gude',
  'guerilla',
  'guerrilla',
  'guessable',
  'guest',
  'guidable',
  'guided',
  'guideless',
  'guiding',
  'guileful',
  'guileless',
  'guiltier',
  'guiltiest',
  'guiltless',
  'guilty',
  'gular',
  'gules',
  'gulfy',
  'gull-wing',
  'gullable',
  'gullible',
  'gummatous',
  'gummed',
  'gummier',
  'gummiest',
  'gummous',
  'gummy',
  'gumptious',
  'gun-shy',
  'gunless',
  'gunned',
  'guns',
  'gushier',
  'gushiest',
  'gushing',
  'gushy',
  'gusseted',
  'gustable',
  'gustative',
  'gustatory',
  'gustier',
  'gustiest',
  'gusty',
  'gutless',
  'gutsier',
  'gutsiest',
  'gutsy',
  'gutta',
  'guttate',
  'guttering',
  'guttural',
  'gutturalized',
  'gymnasial',
  'gymnastic',
  'gymnorhinal',
  'gymnospermous',
  'gynaecocracy',
  'gynaecocratic',
  'gynaecoid',
  'gynaecologic',
  'gynaecological',
  'gynandromorphic',
  'gynandromorphous',
  'gynandrous',
  'gynecoid',
  'gynecologic',
  'gynecological',
  'gynodioecious',
  'gynomonoecious',
  'gypseous',
  'gypsiferous',
  'gypsy',
  'gyral',
  'gyrate',
  'gyrational',
  'gyratory',
  'gyroidal',
  'gyromagnetic',
  'gyronny',
  'gyroscopic',
  'gyrose',
  'gyrostatic',
  'habile',
  'habit-forming',
  'habitable',
  'habitational',
  'habited',
  'habitual',
  'habitudinal',
  'hack',
  'hacking',
  'hackly',
  'hackneyed',
  'hadal',
  'haemal',
  'haematic',
  'haematinic',
  'haematogenous',
  'haematoid',
  'haematopoiesis',
  'haematopoietic',
  'haemic',
  'haemolysis',
  'haemolytic',
  'haemorrhagic',
  'haemorrhoidal',
  'haemostatic',
  'hag-ridden',
  'haggard',
  'haggish',
  'hagiographic',
  'hagiographical',
  'hagiologic',
  'hagiological',
  'hagioscopic',
  'hail-fellow',
  'hail-fellow-well-met',
  'hair-raising',
  'hair-trigger',
  'haired',
  'hairier',
  'hairiest',
  'hairless',
  'hairlike',
  'hairy',
  'halcyon',
  'hale',
  'halest',
  'half',
  'half-a-dozen',
  'half-and-half',
  'half-asleep',
  'half-assed',
  'half-baked',
  'half-blooded',
  'half-bound',
  'half-bred',
  'half-breed',
  'half-calf',
  'half-caste',
  'half-cocked',
  'half-cut',
  'half-dead',
  'half-done',
  'half-door',
  'half-dozen',
  'half-hardy',
  'half-hearted',
  'half-hour',
  'half-hourly',
  'half-length',
  'half-pound',
  'half-price',
  'half-round',
  'half-seas-over',
  'half-size',
  'half-starved',
  'half-timbered',
  'half-time',
  'half-track',
  'half-tracked',
  'half-witted',
  'halftone',
  'halfway',
  'hallowed',
  'hallucinating',
  'hallucinative',
  'hallucinatory',
  'hallucinogenic',
  'halogenous',
  'haloid',
  'halophilous',
  'halt',
  'halted',
  'halting',
  'ham-fisted',
  'ham-handed',
  'hamate',
  'hammered',
  'hammerless',
  'hammier',
  'hammiest',
  'hammy',
  'hamular',
  'hamulate',
  'hand-held',
  'hand-knit',
  'hand-me-down',
  'hand-picked',
  'hand-to-hand',
  'hand-to-mouth',
  'handcrafted',
  'handed',
  'handicapped',
  'handier',
  'handiest',
  'handled',
  'handless',
  'handmade',
  'hands-off',
  'handsome',
  'handsomer',
  'handsomest',
  'handworked',
  'handwritten',
  'handwrought',
  'handy',
  'hangable',
  'hangdog',
  'hanging',
  'haphazard',
  'hapless',
  'haploid',
  'happening',
  'happier',
  'happiest',
  'happy',
  'happy-go-lucky',
  'haptic',
  'harassed',
  'harborless',
  'harbourless',
  'hard',
  'hard-and-fast',
  'hard-bitten',
  'hard-boiled',
  'hard-featured',
  'hard-fisted',
  'hard-fought',
  'hard-handed',
  'hard-headed',
  'hard-hit',
  'hard-hitting',
  'hard-mouthed',
  'hard-nosed',
  'hard-pressed',
  'hard-set',
  'hard-up',
  'hard-wearing',
  'hard-working',
  'hardback',
  'hardbacked',
  'hardcover',
  'hardened',
  'hardier',
  'hardiest',
  'hardscrabble',
  'hardwood',
  'hardy',
  'harlequin',
  'harlot',
  'harmed',
  'harmful',
  'harmless',
  'harmonic',
  'harmonical',
  'harmonious',
  'harmonistic',
  'harmonized',
  'harnessed',
  'harried',
  'harrowing',
  'harsh',
  'harum-scarum',
  'haruspical',
  'hask',
  'hastate',
  'hastening',
  'hastier',
  'hastiest',
  'hasty',
  'hatable',
  'hatched',
  'hatching',
  'hateable',
  'hated',
  'hateful',
  'hatless',
  'hatted',
  'haughtier',
  'haughtiest',
  'haughty',
  'haunched',
  'haunted',
  'haunting',
  'hauriant',
  'haustellate',
  'hawk-eyed',
  'hawkish',
  'hawklike',
  'hawser-laid',
  'haywire',
  'hazardable',
  'hazardous',
  'hazel',
  'hazelly',
  'hazier',
  'haziest',
  'hazy',
  'head',
  'head-on',
  'headachy',
  'headed',
  'headfirst',
  'headhunting',
  'headier',
  'headiest',
  'headless',
  'headlong',
  'headmost',
  'headstrong',
  'heady',
  'healable',
  'healed',
  'healing',
  'healthful',
  'healthier',
  'healthiest',
  'healthy',
  'heaped',
  'heaping',
  'heapy',
  'heard',
  'hearing',
  'hearing-impaired',
  'hearsay',
  'heart-free',
  'heart-rending',
  'heart-shaped',
  'heart-stricken',
  'heart-to-heart',
  'heart-warming',
  'heart-whole',
  'heartbreaking',
  'heartbroken',
  'hearted',
  'heartening',
  'heartfelt',
  'heartier',
  'hearties',
  'heartiest',
  'heartless',
  'heartsome',
  'hearty',
  'heated',
  'heathen',
  'heathenish',
  'heather',
  'heathery',
  'heathier',
  'heathiest',
  'heathy',
  'heating',
  'heaven-born',
  'heaven-sent',
  'heavenly',
  'heavenward',
  'heavier',
  'heavier-than-air',
  'heavies',
  'heaviest',
  'heaving',
  'heavy',
  'heavy-armed',
  'heavy-duty',
  'heavy-handed',
  'heavy-hearted',
  'heavy-laden',
  'heavyweight',
  'hebdomadal',
  'hebdomadary',
  'hebephrenic',
  'hebetate',
  'hebetudinous',
  'hectic',
  'hectographic',
  'hedged',
  'hedgier',
  'hedgiest',
  'hedgy',
  'hedonic',
  'hedonist',
  'hedonistic',
  'heedful',
  'heedless',
  'heel-and-toe',
  'heeled',
  'heelless',
  'heftier',
  'heftiest',
  'hefty',
  'hegemonic',
  'hegemonical',
  'heigh',
  'heightening',
  'heinous',
  'heirless',
  'held',
  'heliac',
  'heliacal',
  'helical',
  'helicoid',
  'helicoidal',
  'heliocentric',
  'heliochromic',
  'heliographic',
  'heliographical',
  'heliolatrous',
  'heliolithic',
  'heliometric',
  'heliometrical',
  'helioscopic',
  'heliotropic',
  'heliotypic',
  'hell-bent',
  'hellish',
  'helluva',
  'helmed',
  'helmeted',
  'helminthic',
  'helminthoid',
  'helminthologic',
  'helminthological',
  'helmless',
  'helpable',
  'helpful',
  'helpless',
  'helter-skelter',
  'hemal',
  'hematologic',
  'hemicyclic',
  'hemihedral',
  'hemimorphic',
  'hemiopic',
  'hemiparasitic',
  'hemiplegic',
  'hemipterous',
  'hemispheric',
  'hemispherical',
  'hemispheroidal',
  'hemistichal',
  'hemitropic',
  'hemizygous',
  'hemorrhagic',
  'hempen',
  'hempy',
  'hendecagonal',
  'hendecasyllabic',
  'hennaed',
  'henotheistic',
  'henpecked',
  'heortological',
  'hep',
  'hepatic',
  'heptagonal',
  'heptamerous',
  'heptarchic',
  'heptasyllabic',
  'heptavalent',
  'heralded',
  'heraldic',
  'herbaceous',
  'herbaged',
  'herbal',
  'herbicidal',
  'herbier',
  'herbiest',
  'herbivorous',
  'herbless',
  'herby',
  'herding',
  'here',
  'hereditable',
  'hereditary',
  'heretical',
  'heretofore',
  'heritable',
  'hermaphrodite',
  'hermaphroditic',
  'hermeneutic',
  'hermetic',
  'hermitical',
  'hernial',
  'herniated',
  'heroic',
  'heroical',
  'herpetic',
  'herpetologic',
  'herpetological',
  'hesitant',
  'hesitating',
  'hesitative',
  'het',
  'hetero',
  'heterocercal',
  'heterochromatic',
  'heterochromous',
  'heteroclite',
  'heterocyclic',
  'heterodactyl',
  'heterodactylous',
  'heterodont',
  'heterodox',
  'heterodyne',
  'heteroecious',
  'heterogamous',
  'heterogeneous',
  'heterogenetic',
  'heterogonous',
  'heterologous',
  'heteromerous',
  'heteromorphic',
  'heteronomous',
  'heterophyllous',
  'heteroplastic',
  'heteropolar',
  'heteropterous',
  'heterosexual',
  'heterosporous',
  'heterostyled',
  'heterotactic',
  'heterothallic',
  'heterotopic',
  'heterotrophic',
  'heterotypic',
  'heterozygous',
  'heuristic',
  'hewn',
  'hex',
  'hexadecimal',
  'hexadic',
  'hexagonal',
  'hexahedral',
  'hexamerous',
  'hexametric',
  'hexametrical',
  'hexaplar',
  'hexaplaric',
  'hexastyle',
  'hexavalent',
  'hexed',
  'hi-fi',
  'hibernal',
  'hibernating',
  'hick',
  'hidden',
  'hideous',
  'hidrotic',
  'hiemal',
  'hierarchal',
  'hierarchic',
  'hierarchical',
  'hieratic',
  'hierocratic',
  'hieroglyphic',
  'hieroglyphical',
  'hierogrammatic',
  'hierogrammatical',
  'hierologic',
  'hierological',
  'hierophantic',
  'hierurgical',
  'higgledy-piggledy',
  'high',
  'high-class',
  'high-fidelity',
  'high-flown',
  'high-flying',
  'high-grade',
  'high-handed',
  'high-hat',
  'high-key',
  'high-keyed',
  'high-level',
  'high-minded',
  'high-necked',
  'high-octane',
  'high-pitched',
  'high-powered',
  'high-pressure',
  'high-priced',
  'high-principled',
  'high-proof',
  'high-ranking',
  'high-rise',
  'high-risk',
  'high-sounding',
  'high-speed',
  'high-spirited',
  'high-stepping',
  'high-strung',
  'high-tension',
  'high-test',
  'high-toned',
  'high-top',
  'high-voltage',
  'highbrow',
  'higher',
  'highest',
  'highland',
  'highty-tighty',
  'hijacking',
  'hilar',
  'hilarious',
  'hilding',
  'hillier',
  'hilliest',
  'hillocky',
  'hilly',
  'hind',
  'hinder',
  'hindering',
  'hindermost',
  'hindmost',
  'hindward',
  'hip',
  'hipped',
  'hipper',
  'hippest',
  'hippiatric',
  'hippier',
  'hippiest',
  'hippocampal',
  'hippodromic',
  'hippophagous',
  'hippopotamic',
  'hippy',
  'hirable',
  'hircine',
  'hireable',
  'hired',
  'hirsute',
  'hirudinean',
  'hirudinoid',
  'hirundine',
  'hispid',
  'hissing',
  'histie',
  'histiocytic',
  'histioid',
  'histogenetic',
  'histoid',
  'histologic',
  'histological',
  'histolytic',
  'histopathological',
  'historiated',
  'historic',
  'historical',
  'historicism',
  'historicist',
  'historiographic',
  'historiographical',
  'histrionic',
  'hit',
  'hit-and-run',
  'hitchy',
  'hither',
  'hithermost',
  'hiveless',
  'hivelike',
  'ho-hum',
  'hoar',
  'hoarier',
  'hoariest',
  'hoarse',
  'hoary',
  'hobbyless',
  'hobnail',
  'hobnailed',
  'hoc',
  'hogged',
  'hoggish',
  'hoiden',
  'hoity-toity',
  'hokey',
  'holding',
  'hole-and-corner',
  'holey',
  'holier',
  'holier-than-thou',
  'holies',
  'holiest',
  'holistic',
  'hollow',
  'hollow-eyed',
  'hollowed',
  'hollowhearted',
  'holmic',
  'holoblastic',
  'holocaustal',
  'holocaustic',
  'holocrine',
  'holograph',
  'holographic',
  'holohedral',
  'holometabolic',
  'holometabolous',
  'holophrastic',
  'holophytic',
  'holothurian',
  'holotypic',
  'holozoic',
  'holstered',
  'holy',
  'home',
  'home-baked',
  'home-brewed',
  'home-grown',
  'home-made',
  'homebound',
  'homeless',
  'homelier',
  'homeliest',
  'homelike',
  'homely',
  'homemaker',
  'homemaking',
  'homeomorphic',
  'homeomorphous',
  'homeopathic',
  'homeostatic',
  'homeothermal',
  'homeothermic',
  'homesick',
  'homespun',
  'homeward',
  'homeward-bound',
  'homey',
  'homicidal',
  'homier',
  'homiest',
  'homiletic',
  'homiletical',
  'homing',
  'hominid',
  'hominoid',
  'homocentric',
  'homocercal',
  'homochromatic',
  'homochromous',
  'homocyclic',
  'homodont',
  'homodyne',
  'homoeomorphic',
  'homoeomorphous',
  'homoeopathic',
  'homoerotic',
  'homogamous',
  'homogeneous',
  'homogenetic',
  'homogenized',
  'homogenous',
  'homoiothermal',
  'homoiothermic',
  'homological',
  'homologous',
  'homomorphic',
  'homomorphous',
  'homonymic',
  'homonymous',
  'homophile',
  'homophonic',
  'homophonous',
  'homoplastic',
  'homopolar',
  'homopterous',
  'homosexual',
  'homosporous',
  'homotaxial',
  'homotaxic',
  'homothallic',
  'homothermal',
  'homothermic',
  'homotypic',
  'homozygous',
  'homuncular',
  'homy',
  'honest',
  'honey',
  'honey-sweet',
  'honeycombed',
  'honeyed',
  'honeyless',
  'honied',
  'honorable',
  'honorary',
  'honorific',
  'honour',
  'honourable',
  'honoured',
  'honourless',
  'hooded',
  'hoodless',
  'hoofed',
  'hoofless',
  'hook-nosed',
  'hooked',
  'hookier',
  'hookiest',
  'hooly',
  'hopeful',
  'hopeless',
  'hopping',
  'horal',
  'horary',
  'horizontal',
  'hormonal',
  'hormonic',
  'horn-mad',
  'horn-rimmed',
  'hornblendic',
  'horned',
  'hornier',
  'horniest',
  'hornish',
  'hornless',
  'hornlike',
  'horny',
  'horologic',
  'horoscopic',
  'horrendous',
  'horrent',
  'horrible',
  'horrid',
  'horrific',
  'horrified',
  'horrifying',
  'horror-stricken',
  'horror-struck',
  'horse-and-buggy',
  'horse-faced',
  'horsey',
  'horsier',
  'horsiest',
  'horsy',
  'hortative',
  'hortatory',
  'horticultural',
  'hospitable',
  'hostile',
  'hot',
  'hot-blooded',
  'hot-short',
  'hot-tempered',
  'hotfoot',
  'hotheaded',
  'hotshot',
  'hotter',
  'hottest',
  'hotting',
  'hottish',
  'hourlong',
  'hourly',
  'house-broken',
  'house-proud',
  'house-to-house',
  'housebound',
  'household',
  'houseless',
  'housewifely',
  'hoven',
  'how-to',
  'howe',
  'howling',
  'hoyden',
  'hoydenish',
  'huddled',
  'hued',
  'hueless',
  'huffier',
  'huffiest',
  'huffing',
  'huffish',
  'huffy',
  'huge',
  'huger',
  'hugest',
  'huggable',
  'hugger-mugger',
  'hulkier',
  'hulkiest',
  'hulking',
  'hulky',
  'hull-down',
  'human',
  'humane',
  'humanist',
  'humanistic',
  'humanitarian',
  'humanlike',
  'humanoid',
  'humble',
  'humbled',
  'humbler',
  'humblest',
  'humbling',
  'humdrum',
  'humectant',
  'humeral',
  'humic',
  'humid',
  'humiliated',
  'humiliating',
  'humiliatory',
  'hummel',
  'humming',
  'hummocky',
  'humongous',
  'humoral',
  'humoristic',
  'humorless',
  'humorous',
  'humourless',
  'humoursome',
  'humpbacked',
  'humped',
  'humpier',
  'humpiest',
  'humpy',
  'hunchback',
  'hunchbacked',
  'hunched',
  'hundred',
  'hundredfold',
  'hundredth',
  'hung',
  'hungerly',
  'hungry',
  'hunkered',
  'hunky',
  'hunky-dory',
  'huntaway',
  'hunted',
  'hurling',
  'hurly-burly',
  'hurried',
  'hurry-scurry',
  'hurry-skurry',
  'hurrying',
  'hurt',
  'hurtful',
  'hurtless',
  'hurtling',
  'husbandless',
  'husbandly',
  'hush-hush',
  'hushed',
  'huskier',
  'huskiest',
  'husky',
  'hyacinthine',
  'hyaline',
  'hyaloid',
  'hybrid',
  'hybridisable',
  'hybridizable',
  'hydra-headed',
  'hydragogue',
  'hydrated',
  'hydraulic',
  'hydric',
  'hydriodic',
  'hydro',
  'hydrobromic',
  'hydrocephalic',
  'hydrochloric',
  'hydrocyanic',
  'hydrodynamic',
  'hydroelectric',
  'hydrofluoric',
  'hydrogenous',
  'hydrographic',
  'hydrographical',
  'hydroid',
  'hydrokinetic',
  'hydrologic',
  'hydrological',
  'hydrolytic',
  'hydromantic',
  'hydromedusan',
  'hydrometric',
  'hydrometrical',
  'hydropathic',
  'hydropathical',
  'hydrophanous',
  'hydrophilic',
  'hydrophilous',
  'hydrophobic',
  'hydrophytic',
  'hydropic',
  'hydroponic',
  'hydroptic',
  'hydrostatic',
  'hydrotactic',
  'hydrotherapeutic',
  'hydrothermal',
  'hydrotropic',
  'hydrous',
  'hydroxy',
  'hydrozoan',
  'hyetal',
  'hyetographic',
  'hyetographical',
  'hygienic',
  'hygrometric',
  'hygrophilous',
  'hygrophytic',
  'hygroscopic',
  'hylomorphic',
  'hylophagous',
  'hylotheist',
  'hylozoistic',
  'hymenal',
  'hymeneal',
  'hymenial',
  'hymenopterous',
  'hymnal',
  'hyoid',
  'hypabyssal',
  'hypaethral',
  'hypalgesic',
  'hyperactive',
  'hyperacute',
  'hyperaemic',
  'hyperaesthetic',
  'hyperalgesic',
  'hyperbaric',
  'hyperbatic',
  'hyperbolic',
  'hypercatalectic',
  'hyperconscious',
  'hypercorrect',
  'hypercritical',
  'hyperemetic',
  'hyperemic',
  'hyperesthetic',
  'hypereutectic',
  'hypergamous',
  'hypergolic',
  'hypermetrical',
  'hypermetropic',
  'hyperphysical',
  'hyperplastic',
  'hyperpyretic',
  'hypersensitive',
  'hypersensitized',
  'hypersensual',
  'hypersonic',
  'hypersthenic',
  'hypertensive',
  'hyperthermal',
  'hypertonic',
  'hypertrophic',
  'hypertrophied',
  'hyphal',
  'hyphenated',
  'hyphenic',
  'hypnagogic',
  'hypnogenetic',
  'hypnoid',
  'hypnoidal',
  'hypnopompic',
  'hypnotic',
  'hypnotisable',
  'hypnotised',
  'hypnotistic',
  'hypnotizable',
  'hypnotized',
  'hypoblastic',
  'hypochondriac',
  'hypochondriacal',
  'hypocoristic',
  'hypocritical',
  'hypocycloidal',
  'hypodermal',
  'hypodermic',
  'hypoeutectic',
  'hypogastric',
  'hypogeal',
  'hypogene',
  'hypogeous',
  'hypoglossal',
  'hypoglycemic',
  'hypognathous',
  'hypogynous',
  'hypomanic',
  'hypophosphorous',
  'hypophyseal',
  'hypophysial',
  'hypoplastic',
  'hypostatic',
  'hypostyle',
  'hypotactic',
  'hypotensive',
  'hypothalamic',
  'hypothecary',
  'hypothermal',
  'hypothetic',
  'hypothetical',
  'hypothyroid',
  'hypothyroidism',
  'hypotonic',
  'hypoxic',
  'hypsometric',
  'hyracoid',
  'hysteretic',
  'hysteric',
  'hysterical',
  'hysterogenic',
  'hysteroid',
  'iambic',
  'iatric',
  'iatrochemical',
  'iatrogenic',
  'ice-cold',
  'ice-cube',
  'ice-free',
  'iced',
  'ichnographic',
  'ichnographical',
  'ichorous',
  'ichthyic',
  'ichthyoid',
  'ichthyolitic',
  'ichthyological',
  'ichthyophagous',
  'ichthyosaurian',
  'ichthyotic',
  'icier',
  'iciest',
  'ickier',
  'ickiest',
  'icky',
  'iconic',
  'iconoclastic',
  'iconomatic',
  'icosahedral',
  'icteric',
  'ictic',
  'icy',
  'ideal',
  'idealess',
  'idealist',
  'idealistic',
  'idealized',
  'ideational',
  'ideative',
  'idem',
  'idempotent',
  'identic',
  'identical',
  'identifiable',
  'identified',
  'identifying',
  'ideographic',
  'ideographical',
  'ideologic',
  'ideological',
  'ideomotor',
  'idioblastic',
  'idiographic',
  'idiomatic',
  'idiomatical',
  'idiomorphic',
  'idiopathic',
  'idiorrhythmic',
  'idiosyncratic',
  'idiotic',
  'idle',
  'idled',
  'idlest',
  'idling',
  'idolatrous',
  'idolized',
  'idyllic',
  'iffy',
  'igneous',
  'ignescent',
  'ignitable',
  'ignited',
  'ignitible',
  'ignoble',
  'ignominious',
  'ignorable',
  'ignorant',
  'ignored',
  'iguana',
  'iguanid',
  'ileac',
  'iliac',
  'ilka',
  'ill',
  'ill-advised',
  'ill-affected',
  'ill-assorted',
  'ill-behaved',
  'ill-boding',
  'ill-bred',
  'ill-conceived',
  'ill-conditioned',
  'ill-considered',
  'ill-defined',
  'ill-disposed',
  'ill-equipped',
  'ill-fated',
  'ill-favored',
  'ill-favoured',
  'ill-founded',
  'ill-gotten',
  'ill-humoured',
  'ill-judged',
  'ill-looking',
  'ill-mannered',
  'ill-natured',
  'ill-omened',
  'ill-spent',
  'ill-starred',
  'ill-tempered',
  'ill-timed',
  'ill-treated',
  'ill-used',
  'illative',
  'illaudable',
  'illegal',
  'illegible',
  'illegitimate',
  'illiberal',
  'illicit',
  'illimitable',
  'illiquid',
  'illiterate',
  'illogical',
  'illuminable',
  'illuminant',
  'illuminate',
  'illuminated',
  'illuminating',
  'illuminative',
  'illusive',
  'illusory',
  'illustrated',
  'illustrational',
  'illustrative',
  'illustrious',
  'illuvial',
  'imageable',
  'imaginable',
  'imaginal',
  'imaginary',
  'imaginative',
  'imagined',
  'imagism',
  'imagist',
  'imagistic',
  'imbecile',
  'imbecilic',
  'imbibitional',
  'imbricate',
  'imbricated',
  'imbued',
  'imidic',
  'imitable',
  'imitation',
  'imitative',
  'immaculate',
  'immane',
  'immanent',
  'immaterial',
  'immature',
  'immeasurable',
  'immediate',
  'immedicable',
  'immemorial',
  'immense',
  'immensurable',
  'immersed',
  'immersible',
  'immethodical',
  'imminent',
  'immiscible',
  'immitigable',
  'immobile',
  'immoderate',
  'immodest',
  'immoral',
  'immortal',
  'immotile',
  'immovable',
  'immoveable',
  'immune',
  'immunized',
  'immunogenic',
  'immunological',
  'immunosuppressive',
  'immutable',
  'impacted',
  'impactive',
  'impaired',
  'impalpable',
  'imparipinnate',
  'imparisyllabic',
  'impartable',
  'impartial',
  'impartible',
  'impassable',
  'impassible',
  'impassionate',
  'impassioned',
  'impassive',
  'impatient',
  'impavid',
  'impeachable',
  'impeccable',
  'impeccant',
  'impecunious',
  'impeded',
  'impedimental',
  'impeding',
  'impeditive',
  'impel',
  'impelled',
  'impellent',
  'impelling',
  'impendent',
  'impending',
  'impenetrable',
  'impenitent',
  'impennate',
  'imperative',
  'imperatorial',
  'imperceptible',
  'imperceptive',
  'impercipient',
  'imperfect',
  'imperfectible',
  'imperfective',
  'imperforate',
  'imperial',
  'imperialist',
  'imperialistic',
  'imperious',
  'imperishable',
  'impermanent',
  'impermeable',
  'impermissible',
  'impersonal',
  'impertinent',
  'imperturbable',
  'imperviable',
  'impervious',
  'impetiginous',
  'impetrative',
  'impetratory',
  'impetuous',
  'impingent',
  'impious',
  'impish',
  'implacable',
  'implacental',
  'implanted',
  'implausible',
  'implemental',
  'implemented',
  'implicated',
  'implicative',
  'implicit',
  'implied',
  'imploratory',
  'imploring',
  'implosive',
  'impolite',
  'impolitic',
  'imponderable',
  'importable',
  'important',
  'imported',
  'importunate',
  'imposable',
  'imposed',
  'imposing',
  'impossible',
  'impotent',
  'impoundable',
  'impoverished',
  'impracticable',
  'impractical',
  'imprecatory',
  'imprecise',
  'impregnable',
  'impregnate',
  'impregnated',
  'imprescriptible',
  'impressed',
  'impressible',
  'impressionable',
  'impressionist',
  'impressionistic',
  'impressive',
  'imprisonable',
  'imprisoned',
  'improbable',
  'impromptu',
  'improper',
  'impropriate',
  'improvable',
  'improved',
  'improvident',
  'improving',
  'improvisational',
  'improvisatory',
  'improvised',
  'imprudent',
  'impudent',
  'impugnable',
  'impuissant',
  'impulsive',
  'impure',
  'imputable',
  'imputative',
  'in-and-in',
  'in-between',
  'in-built',
  'in-car',
  'in-depth',
  'in-flight',
  'in-house',
  'in-service',
  'inaccessible',
  'inaccurate',
  'inactive',
  'inadaptable',
  'inadequate',
  'inadmissible',
  'inadvertent',
  'inadvisable',
  'inalienable',
  'inalterable',
  'inane',
  'inanimate',
  'inappeasable',
  'inappellable',
  'inappetent',
  'inapplicable',
  'inapposite',
  'inappreciable',
  'inappreciative',
  'inapprehensible',
  'inapprehensive',
  'inapproachable',
  'inappropriate',
  'inapt',
  'inarticulate',
  'inartificial',
  'inartistic',
  'inattentive',
  'inaudible',
  'inaugural',
  'inauspicious',
  'inauthentic',
  'inboard',
  'inborn',
  'inbound',
  'inbred',
  'inbreed',
  'inby',
  'incalculable',
  'incalescent',
  'incandescent',
  'incantational',
  'incantatory',
  'incapable',
  'incapacious',
  'incapacitated',
  'incapacitating',
  'incarcerate',
  'incarnadine',
  'incarnate',
  'incased',
  'incautious',
  'incendiary',
  'incensed',
  'incentive',
  'inceptive',
  'incertain',
  'incessant',
  'incestuous',
  'inchoate',
  'inchoative',
  'incident',
  'incidental',
  'incipient',
  'incised',
  'incisive',
  'incisory',
  'incitant',
  'incivil',
  'inclement',
  'inclinable',
  'inclinational',
  'inclinatory',
  'inclined',
  'includable',
  'included',
  'includible',
  'inclusive',
  'incoercible',
  'incog',
  'incogitable',
  'incogitant',
  'incognita',
  'incognito',
  'incognizable',
  'incognizant',
  'incognoscible',
  'incoherent',
  'incombustible',
  'incoming',
  'incommensurable',
  'incommensurate',
  'incommodious',
  'incommunicable',
  'incommunicado',
  'incommunicative',
  'incommutable',
  'incomparable',
  'incompatible',
  'incompetent',
  'incomplete',
  'incompliant',
  'incomprehensible',
  'incomprehensive',
  'incompressible',
  'incomputable',
  'inconceivable',
  'inconclusive',
  'incondensable',
  'incondite',
  'incongruent',
  'incongruous',
  'inconsecutive',
  'inconsequent',
  'inconsequential',
  'inconsiderable',
  'inconsiderate',
  'inconsistent',
  'inconsolable',
  'inconsonant',
  'inconspicuous',
  'inconstant',
  'inconsumable',
  'incontestable',
  'incontinent',
  'incontrollable',
  'incontrovertible',
  'inconvenient',
  'inconvertible',
  'inconvincible',
  'incoordinate',
  'incorporate',
  'incorporated',
  'incorporating',
  'incorporative',
  'incorporeal',
  'incorrect',
  'incorrigible',
  'incorrupt',
  'incorruptible',
  'incrassate',
  'incrassative',
  'increasable',
  'increased',
  'increasing',
  'increate',
  'incredible',
  'incredulous',
  'incremental',
  'increscent',
  'incriminating',
  'incriminatory',
  'incrust',
  'incubative',
  'incubatory',
  'incubous',
  'inculcative',
  'inculpable',
  'inculpatory',
  'incult',
  'incumbent',
  'incunabular',
  'incurable',
  'incurious',
  'incurrable',
  'incurrent',
  'incursive',
  'incurvate',
  'incurved',
  'incuse',
  'indagative',
  'indebted',
  'indecent',
  'indeciduate',
  'indeciduous',
  'indecipherable',
  'indecisive',
  'indeclinable',
  'indecomposable',
  'indecorous',
  'indefatigable',
  'indefeasible',
  'indefectible',
  'indefensible',
  'indefinable',
  'indefinite',
  'indehiscent',
  'indelible',
  'indelicate',
  'indemonstrable',
  'indented',
  'indentured',
  'independent',
  'indescribable',
  'indestructible',
  'indeterminable',
  'indeterminate',
  'indeterminism',
  'indeterminist',
  'index-linked',
  'indexical',
  'indexless',
  'indicative',
  'indicatory',
  'indicial',
  'indictable',
  'indifferent',
  'indigenous',
  'indigent',
  'indigested',
  'indigestible',
  'indigestive',
  'indign',
  'indignant',
  'indigo-blue',
  'indirect',
  'indiscernible',
  'indiscerptible',
  'indiscoverable',
  'indiscreet',
  'indiscrete',
  'indiscriminate',
  'indiscriminating',
  'indiscriminative',
  'indispensable',
  'indisposed',
  'indisputable',
  'indissoluble',
  'indistinct',
  'indistinctive',
  'indistinguishable',
  'indivertible',
  'individual',
  'individualist',
  'individualistic',
  'individualized',
  'indivisible',
  'indocile',
  'indolent',
  'indomitable',
  'indoor',
  'indrawn',
  'indubitable',
  'induced',
  'inducible',
  'inductile',
  'inductive',
  'indulgent',
  'induplicate',
  'indurate',
  'indurative',
  'indusial',
  'indusiate',
  'industrial',
  'industrialized',
  'industrious',
  'indwelling',
  'inebriant',
  'inebriate',
  'inebriated',
  'inedible',
  'inedited',
  'ineducable',
  'ineffable',
  'ineffaceable',
  'ineffective',
  'ineffectual',
  'inefficacious',
  'inefficient',
  'inelaborate',
  'inelastic',
  'inelegant',
  'ineligible',
  'ineloquent',
  'ineluctable',
  'inenarrable',
  'inept',
  'inequable',
  'inequitable',
  'ineradicable',
  'inerasable',
  'inerrable',
  'inerrant',
  'inert',
  'inertial',
  'inescapable',
  'inessential',
  'inessive',
  'inestimable',
  'inevitable',
  'inexact',
  'inexcusable',
  'inexhaustible',
  'inexistent',
  'inexorable',
  'inexpedient',
  'inexpensive',
  'inexperienced',
  'inexpert',
  'inexpiable',
  'inexplainable',
  'inexplicable',
  'inexplicit',
  'inexpressible',
  'inexpressive',
  'inexpugnable',
  'inexpungible',
  'inextensible',
  'inextinguishable',
  'inextirpable',
  'inextricable',
  'infallible',
  'infamous',
  'infant',
  'infanticidal',
  'infantile',
  'infantine',
  'infatuate',
  'infatuated',
  'infeasible',
  'infect',
  'infected',
  'infectious',
  'infective',
  'infecund',
  'infelicitous',
  'infelt',
  'inferable',
  'inferential',
  'inferior',
  'infernal',
  'inferrible',
  'infertile',
  'infested',
  'infidel',
  'infiltrative',
  'infinite',
  'infinitesimal',
  'infinitival',
  'infinitive',
  'infirm',
  'inflamed',
  'inflammable',
  'inflammatory',
  'inflatable',
  'inflated',
  'inflationary',
  'inflationism',
  'inflected',
  'inflectional',
  'inflectionless',
  'inflective',
  'inflexed',
  'inflexible',
  'inflexional',
  'inflexionless',
  'inflictive',
  'inflorescent',
  'inflowing',
  'influent',
  'influential',
  'influenzal',
  'inform',
  'informal',
  'informational',
  'informative',
  'informatory',
  'informed',
  'infracostal',
  'infrahuman',
  'inframaxillary',
  'infrangible',
  'infrasonic',
  'infrequent',
  'infundibular',
  'infundibulate',
  'infundibuliform',
  'infuriate',
  'infuriated',
  'infuriating',
  'infuscate',
  'infusible',
  'infusive',
  'infusorial',
  'infusorian',
  'ingenerate',
  'ingenious',
  'ingenuous',
  'ingested',
  'ingestible',
  'ingestive',
  'inglorious',
  'ingoing',
  'ingrain',
  'ingrained',
  'ingrate',
  'ingratiating',
  'ingravescent',
  'ingressive',
  'ingrowing',
  'ingrown',
  'inguinal',
  'inhabitable',
  'inhabited',
  'inhalant',
  'inhaled',
  'inhaling',
  'inharmonic',
  'inharmonious',
  'inherent',
  'inheritable',
  'inherited',
  'inheriting',
  'inhibited',
  'inhibiting',
  'inhibitory',
  'inhomogeneous',
  'inhospitable',
  'inhuman',
  'inhumane',
  'inhumed',
  'inimical',
  'inimitable',
  'iniquitous',
  'initial',
  'initiate',
  'initiated',
  'initiative',
  'initiatory',
  'injectable',
  'injudicious',
  'injunctive',
  'injured',
  'injurious',
  'inkier',
  'inkiest',
  'inky',
  'inlaid',
  'inland',
  'inlying',
  'inmost',
  'innate',
  'inner',
  'inner-directed',
  'innermost',
  'innocent',
  'innocuous',
  'innominate',
  'innovative',
  'innovatory',
  'innoxious',
  'innumerable',
  'innumerate',
  'innumerous',
  'innutritious',
  'inobservant',
  'inoculable',
  'inoculative',
  'inodorous',
  'inoffensive',
  'inofficious',
  'inoperable',
  'inoperative',
  'inopportune',
  'inordinate',
  'inorganic',
  'inotropic',
  'inphase',
  'inpouring',
  'inquiline',
  'inquilinous',
  'inquiring',
  'inquisitional',
  'inquisitive',
  'inquisitorial',
  'inrush',
  'inrushing',
  'insalubrious',
  'insane',
  'insanitary',
  'insatiable',
  'insatiate',
  'inscribable',
  'inscribed',
  'inscriptional',
  'inscriptive',
  'inscrutable',
  'insecticidal',
  'insectile',
  'insectivorous',
  'insecure',
  'inseminated',
  'insensate',
  'insensible',
  'insensitive',
  'insentient',
  'inseparable',
  'insertable',
  'inserted',
  'insertional',
  'insessorial',
  'inseverable',
  'inshore',
  'inside',
  'inside-out',
  'insides',
  'insidious',
  'insightful',
  'insignificant',
  'insincere',
  'insinuating',
  'insinuative',
  'insipid',
  'insipient',
  'insistent',
  'insociable',
  'insolent',
  'insoluble',
  'insolvable',
  'insolvent',
  'insomniac',
  'insomnious',
  'insouciant',
  'inspectional',
  'inspective',
  'inspectorial',
  'inspirable',
  'inspirational',
  'inspirative',
  'inspiratory',
  'inspired',
  'inspiring',
  'inspiriting',
  'instable',
  'instant',
  'instantaneous',
  'instigative',
  'instinct',
  'instinctive',
  'instinctual',
  'institutional',
  'institutionalized',
  'institutionary',
  'institutive',
  'instructed',
  'instructible',
  'instructional',
  'instructive',
  'instrumental',
  'instrumentalist',
  'insubordinate',
  'insubstantial',
  'insufferable',
  'insufficient',
  'insular',
  'insultable',
  'insulted',
  'insulting',
  'insuperable',
  'insupportable',
  'insuppressible',
  'insurable',
  'insured',
  'insurgent',
  'insurmountable',
  'insurrection',
  'insurrectional',
  'insurrectionary',
  'insusceptible',
  'intact',
  'intangible',
  'integrable',
  'integral',
  'integrant',
  'integrate',
  'integrated',
  'integrative',
  'integumentary',
  'intellective',
  'intellectual',
  'intellectualism',
  'intelligent',
  'intelligential',
  'intelligible',
  'intemerate',
  'intemperate',
  'intended',
  'intense',
  'intensified',
  'intensifying',
  'intensional',
  'intensive',
  'intent',
  'intentional',
  'intentioned',
  'inter',
  'interactionist',
  'interactive',
  'interatomic',
  'interbank',
  'interbedded',
  'interbred',
  'intercalary',
  'intercalative',
  'intercellular',
  'interceptive',
  'intercessional',
  'intercessorial',
  'intercessory',
  'interchangeable',
  'intercity',
  'interclavicular',
  'intercollegiate',
  'intercolonial',
  'intercolumnar',
  'intercommunal',
  'intercommunicable',
  'intercommunity',
  'interconnected',
  'intercontinental',
  'interconvertible',
  'intercostal',
  'intercrossed',
  'intercrural',
  'intercurrent',
  'interdenominational',
  'interdental',
  'interdepartmental',
  'interdependent',
  'interdictory',
  'interdigital',
  'interdisciplinary',
  'interested',
  'interesting',
  'interfacial',
  'interfaith',
  'interfascicular',
  'interfemoral',
  'interferential',
  'interfering',
  'interferometric',
  'interfertile',
  'interfluent',
  'interfrontal',
  'intergalactic',
  'interglacial',
  'intergovernmental',
  'interim',
  'interior',
  'interior-sprung',
  'interjacent',
  'interjaculatory',
  'interjectional',
  'interjectural',
  'interlaced',
  'interlacing',
  'interlaminar',
  'interlinear',
  'interlinking',
  'interlobular',
  'interlocking',
  'interlocutory',
  'interludial',
  'interlunar',
  'intermaxillary',
  'intermediary',
  'intermediate',
  'intermediatory',
  'intermetallic',
  'interminable',
  'intermingled',
  'intermissive',
  'intermittent',
  'intermolecular',
  'intermontane',
  'intermundane',
  'intern',
  'internal',
  'international',
  'internationalist',
  'internationalistic',
  'internecine',
  'interneural',
  'internodal',
  'internuncial',
  'interoceanic',
  'interoceptive',
  'interocular',
  'interorbital',
  'interosseous',
  'interparietal',
  'interpellant',
  'interpenetrable',
  'interpenetrant',
  'interpenetrative',
  'interpersonal',
  'interpetiolar',
  'interplanetary',
  'interpleural',
  'interpolable',
  'interpolar',
  'interpolative',
  'interpretable',
  'interpretative',
  'interpreted',
  'interpretive',
  'interprovincial',
  'interproximal',
  'interracial',
  'interradial',
  'interramal',
  'interred',
  'interrelated',
  'interrogable',
  'interrogative',
  'interrogatory',
  'interrupted',
  'interruptive',
  'interscapular',
  'interscholastic',
  'intersecting',
  'intersectional',
  'interseptal',
  'intersexual',
  'intersidereal',
  'interspatial',
  'interspecific',
  'interspinal',
  'interspinous',
  'interstadial',
  'interstate',
  'interstellar',
  'interstitial',
  'intersubjective',
  'intertarsal',
  'intertentacular',
  'interterritorial',
  'intertidal',
  'intertissued',
  'intertribal',
  'intertropical',
  'intertwined',
  'interunion',
  'interurban',
  'intervenient',
  'intervening',
  'interventionist',
  'intervocalic',
  'interwoven',
  'interwrought',
  'interzonal',
  'intestate',
  'intestinal',
  'intimate',
  'intime',
  'intimidated',
  'intimidating',
  'intimidatory',
  'intimist',
  'intimiste',
  'intoed',
  'intolerable',
  'intolerant',
  'intoned',
  'intown',
  'intoxicant',
  'intoxicated',
  'intoxicating',
  'intracardiac',
  'intracellular',
  'intracranial',
  'intractable',
  'intradermal',
  'intramolecular',
  'intramundane',
  'intramural',
  'intramuscular',
  'intranational',
  'intransigent',
  'intransitive',
  'intrastate',
  'intravascular',
  'intravenous',
  'intrepid',
  'intricate',
  'intriguing',
  'intrinsic',
  'intrinsical',
  'introducible',
  'introductory',
  'introjected',
  'intromissive',
  'intromittent',
  'introrse',
  'introspectionist',
  'introspective',
  'introversive',
  'introvert',
  'introverted',
  'introvertive',
  'intruding',
  'intrusive',
  'intuitional',
  'intuitionist',
  'intuitive',
  'intumescent',
  'intussusceptive',
  'inundant',
  'inundated',
  'inurbane',
  'inured',
  'invading',
  'invaginate',
  'invalid',
  'invalidated',
  'invalidating',
  'invaluable',
  'invariable',
  'invariant',
  'invasive',
  'invected',
  'invective',
  'inventable',
  'invented',
  'inventible',
  'inventive',
  'inventorial',
  'inverse',
  'inversive',
  'invertebrate',
  'inverted',
  'invested',
  'investigable',
  'investigative',
  'investigatory',
  'investitive',
  'inveterate',
  'inviable',
  'invidious',
  'invigorated',
  'invigorating',
  'invincible',
  'inviolable',
  'inviolate',
  'invisible',
  'invitation',
  'invitatory',
  'invited',
  'inviting',
  'invocatory',
  'involucral',
  'involucrate',
  'involuntary',
  'involute',
  'involutional',
  'involved',
  'invulnerable',
  'inward',
  'inwrought',
  'iodic',
  'iodized',
  'iodometric',
  'iodous',
  'ionic',
  'ionized',
  'ionospheric',
  'ipsilateral',
  'iracund',
  'irascible',
  'irate',
  'ireful',
  'irenic',
  'iridaceous',
  'iridescent',
  'iridic',
  'iritic',
  'irksome',
  'iron',
  'iron-gray',
  'iron-grey',
  'iron-hearted',
  'iron-sick',
  'ironclad',
  'ironed',
  'ironfisted',
  'ironic',
  'ironical',
  'irony',
  'irradiant',
  'irradiative',
  'irrational',
  'irrationalist',
  'irrationalistic',
  'irrebuttable',
  'irreclaimable',
  'irreconcilable',
  'irrecoverable',
  'irrecusable',
  'irredeemable',
  'irredentist',
  'irreducible',
  'irreformable',
  'irrefragable',
  'irrefrangible',
  'irrefutable',
  'irregular',
  'irrelative',
  'irrelevant',
  'irrelievable',
  'irreligious',
  'irremeable',
  'irremediable',
  'irremissible',
  'irremovable',
  'irreparable',
  'irrepealable',
  'irreplaceable',
  'irrepleviable',
  'irreplevisable',
  'irrepressible',
  'irreproachable',
  'irreproducible',
  'irresistible',
  'irresoluble',
  'irresolute',
  'irresolvable',
  'irrespective',
  'irrespirable',
  'irresponsible',
  'irresponsive',
  'irretentive',
  'irretrievable',
  'irreverent',
  'irreversible',
  'irrevocable',
  'irrigable',
  'irrigational',
  'irrigative',
  'irriguous',
  'irritable',
  'irritant',
  'irritated',
  'irritating',
  'irritative',
  'irruptive',
  'isagogic',
  'ischaemic',
  'ischemic',
  'ischiadic',
  'isentropic',
  'ish',
  'isobaric',
  'isobathic',
  'isobilateral',
  'isochasmic',
  'isocheimal',
  'isocheimenal',
  'isocheimic',
  'isochimal',
  'isochoric',
  'isochromatic',
  'isochronal',
  'isochronous',
  'isoclinal',
  'isocratic',
  'isocyclic',
  'isodiametric',
  'isodimorphic',
  'isodimorphous',
  'isodynamic',
  'isoelectric',
  'isoelectronic',
  'isogamous',
  'isogamy',
  'isogenous',
  'isogeothermal',
  'isogeothermic',
  'isoglossal',
  'isogonal',
  'isogonic',
  'isohyetal',
  'isolable',
  'isolate',
  'isolated',
  'isolating',
  'isolationism',
  'isolationist',
  'isolative',
  'isolecithal',
  'isologous',
  'isomagnetic',
  'isomeric',
  'isomerous',
  'isometric',
  'isometrical',
  'isomorphic',
  'isomorphous',
  'isonomic',
  'isonomous',
  'isoperimetrical',
  'isopod',
  'isopodan',
  'isopodous',
  'isorhythmic',
  'isosceles',
  'isoseismal',
  'isoseismic',
  'isostatic',
  'isostemonous',
  'isosteric',
  'isotactic',
  'isotheral',
  'isothermal',
  'isotonic',
  'isotopic',
  'isotropic',
  'isotropous',
  'issuable',
  'issuant',
  'issueless',
  'isthmian',
  'italic',
  'itchier',
  'itchiest',
  'itching',
  'itchy',
  'iterant',
  'iterative',
  'ithyphallic',
  'itinerant',
  'itinerary',
  'itty-bitty',
  'ivied',
  'ivory-towered',
  'jabbering',
  'jack',
  'jacketed',
  'jaculatory',
  'jade',
  'jaded',
  'jadish',
  'jagged',
  'jaggier',
  'jaggiest',
  'jaggy',
  'jailed',
  'jake',
  'jalapic',
  'jalousied',
  'jam-packed',
  'jammed',
  'jammy',
  'jangling',
  'jangly',
  'janiform',
  'janitorial',
  'jannock',
  'japan',
  'jarring',
  'jasp',
  'jaspery',
  'jaundiced',
  'jauntier',
  'jauntiest',
  'jaunty',
  'jawbreaking',
  'jazzier',
  'jazziest',
  'jazzy',
  'jealous',
  'jeering',
  'jejune',
  'jelled',
  'jellied',
  'jeopardous',
  'jerkier',
  'jerkiest',
  'jerking',
  'jerkwater',
  'jerky',
  'jerry-built',
  'jessant',
  'jestful',
  'jesting',
  'jet',
  'jet-black',
  'jet-propelled',
  'jet-propulsion',
  'jetting',
  'jetty',
  'jeweled',
  'jewelled',
  'jiggered',
  'jiggish',
  'jiggly',
  'jilted',
  'jim-crow',
  'jim-dandy',
  'jimp',
  'jingling',
  'jingly',
  'jingoish',
  'jingoism',
  'jingoist',
  'jingoistic',
  'jinxed',
  'jittery',
  'jobless',
  'jocose',
  'jocular',
  'jocund',
  'joined',
  'joint',
  'jointed',
  'jointless',
  'jointured',
  'joking',
  'jollier',
  'jolliest',
  'jolly',
  'jolted',
  'joltier',
  'joltiest',
  'jolting',
  'jolty',
  'jouncing',
  'journalistic',
  'jovial',
  'jowled',
  'jowlier',
  'jowliest',
  'jowly',
  'joyful',
  'joyless',
  'joyous',
  'jubate',
  'jubilant',
  'judge-made',
  'judgemental',
  'judgmental',
  'judicable',
  'judicative',
  'judicatory',
  'judicial',
  'judiciary',
  'judicious',
  'jugal',
  'jugate',
  'juglandaceous',
  'jugular',
  'juiceless',
  'juicier',
  'juiciest',
  'juicy',
  'julienne',
  'jumbled',
  'jumbo',
  'jumpable',
  'jumpier',
  'jumpiest',
  'jumping',
  'jumpy',
  'juncaceous',
  'jungly',
  'junior',
  'junked',
  'jural',
  'jurant',
  'juratory',
  'juridic',
  'juridical',
  'jurisdictional',
  'jurisdictive',
  'jurisprudent',
  'jurisprudential',
  'juristic',
  'jury',
  'jury-rigged',
  'jussive',
  'just',
  'justiciable',
  'justiciary',
  'justifiable',
  'justificative',
  'justificatory',
  'justified',
  'jutting',
  'juvenal',
  'juvenescent',
  'juvenile',
  'juxtaposed',
  'juxtapositional',
  'kacha',
  'kaleidoscopic',
  'kaput',
  'karmic',
  'karstic',
  'karyotypic',
  'kashmiri',
  'katabatic',
  'katabolic',
  'kayoed',
  'keeled',
  'keen',
  'keeperless',
  'keloidal',
  'kempt',
  'kenotic',
  'kenspeckle',
  'kept',
  'keramic',
  'keratinous',
  'keratogenous',
  'keratoid',
  'keratose',
  'kerchiefed',
  'kernelly',
  'kerygmatic',
  'key',
  'keyed',
  'keyless',
  'khaki',
  'khedival',
  'khedivial',
  'kickable',
  'kid-glove',
  'kidnapped',
  'kidney-shaped',
  'killing',
  'kilted',
  'kin',
  'kinaesthetic',
  'kind',
  'kind-hearted',
  'kindled',
  'kindless',
  'kindlier',
  'kindliest',
  'kindly',
  'kindred',
  'kinematic',
  'kinematical',
  'kinesthetic',
  'kinetic',
  'king-size',
  'king-sized',
  'kingdomless',
  'kingless',
  'kinglier',
  'kingliest',
  'kinglike',
  'kingly',
  'kinkier',
  'kinkiest',
  'kinky',
  'kinless',
  'kirtled',
  'kissable',
  'kitsch',
  'kittenish',
  'kittle',
  'kitty-cornered',
  'klephtic',
  'klutzy',
  'knaggy',
  'knarred',
  'knavish',
  'knee-deep',
  'knee-high',
  'knee-length',
  'kneeling',
  'knickered',
  'knifeless',
  'knightless',
  'knightly',
  'knitted',
  'knobbed',
  'knobbier',
  'knobbiest',
  'knobbly',
  'knobby',
  'knock-down',
  'knock-kneed',
  'knockabout',
  'knocked-down',
  'knockout',
  'knotless',
  'knotted',
  'knottier',
  'knottiest',
  'knotty',
  'know-it-all',
  'know-nothing',
  'knowable',
  'knowing',
  'knowledgeable',
  'known',
  'knuckleheaded',
  'knuckly',
  'knurled',
  'knurlier',
  'knurliest',
  'knurly',
  'kookie',
  'kookier',
  'kookiest',
  'kooky',
  'kosher',
  'kraal',
  'kutcha',
  'kymographic',
  'kyphotic',
  'la-di-da',
  'labelled',
  'labelloid',
  'labial',
  'labialized',
  'labiate',
  'labile',
  'labiodental',
  'labiovelar',
  'labored',
  'laboring',
  'laborious',
  'labour-saving',
  'laboured',
  'labouring',
  'labrid',
  'labroid',
  'labyrinthian',
  'labyrinthine',
  'laccolithic',
  'lace-up',
  'laced',
  'lacerable',
  'lacerant',
  'lacerate',
  'lacerated',
  'lacerative',
  'lacertilian',
  'lachrymal',
  'lachrymatory',
  'lachrymose',
  'lacier',
  'laciest',
  'laciniate',
  'lackadaisical',
  'lacking',
  'lackluster',
  'lacklustre',
  'laconic',
  'laconical',
  'lacrimal',
  'lacrimatory',
  'lactating',
  'lacteal',
  'lacteous',
  'lactescent',
  'lactic',
  'lactiferous',
  'lactogenic',
  'lacunal',
  'lacunar',
  'lacunose',
  'lacustrine',
  'lacy',
  'laddery',
  'laddish',
  'laden',
  'lady-killer',
  'ladyish',
  'ladylike',
  'laevorotatory',
  'lageniform',
  'laggard',
  'laggardly',
  'lagomorphic',
  'lagomorphous',
  'lagoonal',
  'lah-di-dah',
  'laic',
  'laid',
  'laid-back',
  'laigh',
  'lairy',
  'laissez-faire',
  'laith',
  'lakier',
  'lakiest',
  'laky',
  'lambdoid',
  'lambent',
  'lamblike',
  'lame',
  'lamellar',
  'lamellate',
  'lamellibranch',
  'lamellicorn',
  'lamelliform',
  'lamellirostral',
  'lamellose',
  'lamentable',
  'lamented',
  'lamenting',
  'lamer',
  'lamest',
  'laminable',
  'laminar',
  'laminate',
  'laming',
  'laminose',
  'lamplit',
  'lamprophyric',
  'lanate',
  'lanceolate',
  'lanceted',
  'lanciform',
  'lancinate',
  'land',
  'land-poor',
  'landed',
  'landholding',
  'landless',
  'landowner',
  'landscaped',
  'landward',
  'lane',
  'lang',
  'langued',
  'languid',
  'languishing',
  'languorous',
  'laniary',
  'laniferous',
  'lank',
  'lanky',
  'lanose',
  'lantern-jawed',
  'lanuginose',
  'lap-jointed',
  'lapelled',
  'lapidarian',
  'lapidary',
  'lapidific',
  'lappeted',
  'lapsable',
  'lapsed',
  'lapstrake',
  'larboard',
  'larcenous',
  'lardaceous',
  'lardier',
  'lardiest',
  'lardy',
  'large',
  'large-handed',
  'large-hearted',
  'large-minded',
  'large-scale',
  'larger',
  'largest',
  'larghetto',
  'largish',
  'largo',
  'larine',
  'larkish',
  'larky',
  'larval',
  'larvicidal',
  'larviparous',
  'laryngeal',
  'laryngitic',
  'laryngological',
  'laryngoscopic',
  'lascivious',
  'lashed',
  'lashing',
  'last',
  'last-minute',
  'lasting',
  'latched',
  'late',
  'lated',
  'lateen',
  'latent',
  'later',
  'lateral',
  'laterigrade',
  'lateritic',
  'lateritious',
  'latest',
  'lathery',
  'lathier',
  'lathiest',
  'lathlike',
  'lathy',
  'laticiferous',
  'latino',
  'latish',
  'latitudinal',
  'latitudinarian',
  'latitudinous',
  'latter',
  'latter-day',
  'lattermost',
  'latticed',
  'laudable',
  'laudatory',
  'laughable',
  'laughing',
  'lauraceous',
  'laureate',
  'laurelled',
  'lavender',
  'lavish',
  'law-abiding',
  'lawful',
  'lawgiver',
  'lawless',
  'lawny',
  'lawyerly',
  'lax',
  'laxative',
  'lay',
  'layered',
  'lazier',
  'laziest',
  'lazy',
  'leachier',
  'leachiest',
  'leachy',
  'lead-free',
  'leaded',
  'leaden',
  'leaderless',
  'leadier',
  'leadiest',
  'leading',
  'leadless',
  'leady',
  'leafed',
  'leafier',
  'leafiest',
  'leafless',
  'leafy',
  'leaking',
  'leaky',
  'leal',
  'lean',
  'lean-faced',
  'leaning',
  'leaping',
  'learnable',
  'learned',
  'leary',
  'leasable',
  'leased',
  'least',
  'leathered',
  'leathern',
  'leathery',
  'leaved',
  'leavened',
  'lecherous',
  'led',
  'ledgier',
  'ledgiest',
  'ledgy',
  'lee',
  'leerier',
  'leeriest',
  'leering',
  'leery',
  'leeward',
  'left',
  'left-hand',
  'left-handed',
  'left-wing',
  'leftish',
  'leftist',
  'leftover',
  'leftward',
  'legal',
  'legalism',
  'legalistic',
  'legatine',
  'legato',
  'legendary',
  'legged',
  'leggier',
  'leggiest',
  'leggy',
  'legible',
  'legion',
  'legionary',
  'legislative',
  'legislatorial',
  'legit',
  'legitimate',
  'legitimist',
  'legless',
  'leguminous',
  'leisurable',
  'leisure',
  'leisured',
  'leisurely',
  'lemony',
  'lemuroid',
  'lengthened',
  'lengthening',
  'lengthier',
  'lengthiest',
  'lengthways',
  'lengthwise',
  'lengthy',
  'lenient',
  'lenis',
  'lenitive',
  'lentando',
  'lenten',
  'lentic',
  'lenticellate',
  'lenticular',
  'lentiform',
  'lentiginous',
  'lentissimo',
  'lento',
  'lentoid',
  'leonine',
  'lepidopterous',
  'lepidote',
  'leporine',
  'leprose',
  'leprous',
  'leptodactylous',
  'leptophyllous',
  'leptorrhine',
  'leptosomatic',
  'leptosomic',
  'leptosporangiate',
  'lesbian',
  'less',
  'lessened',
  'lessening',
  'lesser',
  'let-out',
  'lethal',
  'lethargic',
  'lethiferous',
  'letter-perfect',
  'lettered',
  'letterless',
  'leucitic',
  'leucocratic',
  'leucocytic',
  'leucoderma',
  'leucopoiesis',
  'leukemic',
  'level',
  'level-headed',
  'leviable',
  'levigate',
  'leviratical',
  'levitical',
  'levorotatory',
  'lewd',
  'lexical',
  'lexicographic',
  'lexicographical',
  'liable',
  'lianoid',
  'libellous',
  'libelous',
  'liberal',
  'liberalism',
  'liberalist',
  'liberalistic',
  'liberated',
  'liberating',
  'liberatory',
  'libertarian',
  'liberticidal',
  'libertine',
  'libidinal',
  'libidinous',
  'librational',
  'libratory',
  'licenced',
  'licensed',
  'licentious',
  'lichenoid',
  'lichenous',
  'licht',
  'licit',
  'licked',
  'lickerish',
  'lidded',
  'lidless',
  'lief',
  'liege',
  'lienal',
  'lienteric',
  'life-and-death',
  'life-giving',
  'life-size',
  'life-sized',
  'lifeful',
  'lifeless',
  'lifelike',
  'lifelong',
  'liftable',
  'lifted',
  'ligamentous',
  'light',
  'light-armed',
  'light-fingered',
  'light-footed',
  'light-handed',
  'light-headed',
  'light-hearted',
  'light-minded',
  'light-sensitive',
  'lighted',
  'lighter-than-air',
  'lightfast',
  'lightful',
  'lightish',
  'lightless',
  'lightsome',
  'lightweight',
  'ligneous',
  'lignified',
  'ligniform',
  'lignite',
  'lignitic',
  'lignivorous',
  'ligular',
  'ligulate',
  'liguloid',
  'likable',
  'like',
  'like-minded',
  'likeable',
  'liked',
  'likelier',
  'likeliest',
  'likely',
  'lilac',
  'liliaceous',
  'lilied',
  'lilliputian',
  'lilting',
  'lily-livered',
  'lily-white',
  'limacine',
  'limbate',
  'limbed',
  'limber',
  'limbic',
  'limbless',
  'lime',
  'limey',
  'limicolous',
  'limier',
  'limiest',
  'liminal',
  'limitable',
  'limitary',
  'limitative',
  'limited',
  'limiting',
  'limitless',
  'limitrophe',
  'limnetic',
  'limnological',
  'limonitic',
  'limp',
  'limpid',
  'limy',
  'lineal',
  'linear',
  'lineate',
  'lined',
  'lineolate',
  'liney',
  'lingering',
  'lingual',
  'linguiform',
  'linguistic',
  'linguistical',
  'lingulate',
  'linked',
  'lintier',
  'lintiest',
  'linty',
  'liny',
  'lion-hearted',
  'lionly',
  'lipless',
  'lipogrammatic',
  'lipoid',
  'lipomatous',
  'lipped',
  'lippy',
  'liquefacient',
  'liquefiable',
  'liquefied',
  'liquescent',
  'liquid',
  'liquified',
  'liquorish',
  'lissom',
  'lissome',
  'lissotrichous',
  'listed',
  'listening',
  'listless',
  'lit',
  'lite',
  'literal',
  'literalistic',
  'literary',
  'literate',
  'lithe',
  'lither',
  'lithesome',
  'lithest',
  'lithic',
  'litho',
  'lithographic',
  'lithographical',
  'lithoid',
  'lithologic',
  'lithological',
  'lithophytic',
  'lithotomic',
  'lithotomical',
  'litigable',
  'litigant',
  'litigious',
  'litten',
  'littered',
  'littery',
  'little',
  'littler',
  'littlest',
  'littoral',
  'liturgical',
  'livable',
  'live',
  'liveable',
  'lived',
  'livelier',
  'liveliest',
  'livelong',
  'lively',
  'liveried',
  'liverish',
  'livery',
  'livid',
  'living',
  'load-bearing',
  'loaded',
  'loading',
  'loaferish',
  'loamy',
  'loanable',
  'loath',
  'loathful',
  'loathly',
  'loathsome',
  'lobar',
  'lobate',
  'lobed',
  'lobose',
  'lobular',
  'lobulate',
  'local',
  'localized',
  'locatable',
  'located',
  'locative',
  'lochial',
  'lockable',
  'locked',
  'loco',
  'locomobile',
  'locomotive',
  'locomotor',
  'locular',
  'loculate',
  'loculicidal',
  'lodged',
  'loftier',
  'loftiest',
  'lofty',
  'log',
  'logaoedic',
  'logarithmic',
  'loggerheaded',
  'logical',
  'logistic',
  'logistical',
  'logographic',
  'logopedic',
  'logy',
  'lolling',
  'lomentaceous',
  'lone',
  'lonelier',
  'loneliest',
  'lonely',
  'lonesome',
  'long',
  'long-ago',
  'long-dated',
  'long-distance',
  'long-drawn',
  'long-drawn-out',
  'long-faced',
  'long-haired',
  'long-headed',
  'long-lasting',
  'long-legged',
  'long-lived',
  'long-playing',
  'long-range',
  'long-sighted',
  'long-standing',
  'long-suffering',
  'long-term',
  'long-tongued',
  'long-waisted',
  'long-winded',
  'longanimous',
  'longer',
  'longest',
  'longevous',
  'longhand',
  'longicorn',
  'longing',
  'longish',
  'longitudinal',
  'longshore',
  'longsome',
  'longwall',
  'longwise',
  'looking',
  'loonier',
  'loonies',
  'looniest',
  'loony',
  'looped',
  'loopy',
  'loose',
  'loose-jointed',
  'loose-leaf',
  'loose-limbed',
  'loosened',
  'looser',
  'loosest',
  'looted',
  'lop-eared',
  'lophobranch',
  'lophobranchiate',
  'lophodont',
  'lopped',
  'lopsided',
  'loquacious',
  'loral',
  'lordless',
  'lordlier',
  'lordliest',
  'lordly',
  'lordotic',
  'loricate',
  'lorn',
  'losable',
  'losel',
  'losing',
  'lossy',
  'lost',
  'loth',
  'lotic',
  'louche',
  'loud',
  'loud-mouthed',
  'loudish',
  'lounging',
  'louring',
  'loury',
  'lousier',
  'lousiest',
  'lousy',
  'loutish',
  'louvered',
  'louvred',
  'lovable',
  'loveable',
  'loved',
  'loveless',
  'lovelier',
  'loveliest',
  'lovelorn',
  'lovely',
  'loverless',
  'loverly',
  'lovesick',
  'lovesome',
  'lovey-dovey',
  'loving',
  'low',
  'low-cal',
  'low-cut',
  'low-down',
  'low-frequency',
  'low-key',
  'low-keyed',
  'low-lying',
  'low-minded',
  'low-necked',
  'low-pitched',
  'low-pressure',
  'low-rise',
  'low-spirited',
  'low-tension',
  'lower',
  'lower-case',
  'lowered',
  'lowering',
  'lowermost',
  'lowery',
  'lowest',
  'lowland',
  'lowlier',
  'lowliest',
  'lowly',
  'lown',
  'lowse',
  'loxodromic',
  'loyal',
  'lozenged',
  'lozengy',
  'lubberly',
  'lubric',
  'lubricant',
  'lubricated',
  'lubricative',
  'lubricious',
  'lubricous',
  'lucent',
  'lucid',
  'luciferous',
  'luckier',
  'luckiest',
  'luckless',
  'lucky',
  'lucrative',
  'luculent',
  'ludicrous',
  'luetic',
  'lugubrious',
  'lukewarm',
  'lulling',
  'lumbar',
  'lumbering',
  'lumbricoid',
  'luminary',
  'luminescent',
  'luminiferous',
  'luminous',
  'lumpen',
  'lumpier',
  'lumpiest',
  'lumpish',
  'lumpy',
  'lunar',
  'lunate',
  'lunatic',
  'lunisolar',
  'lunitidal',
  'lunular',
  'lunulate',
  'lupine',
  'lurching',
  'lurdan',
  'lurid',
  'lurking',
  'luscious',
  'lush',
  'lushy',
  'lusterless',
  'lustful',
  'lustier',
  'lustiest',
  'lustral',
  'lustred',
  'lustreless',
  'lustrous',
  'lusty',
  'luteal',
  'luteous',
  'luxe',
  'luxuriant',
  'luxurious',
  'luxury',
  'lycanthropic',
  'lying',
  'lying-in',
  'lymphangial',
  'lymphatic',
  'lymphoid',
  'lyncean',
  'lynx-eyed',
  'lyophilic',
  'lyophilised',
  'lyophilized',
  'lyophobic',
  'lyrate',
  'lyric',
  'lyrical',
  'lyriform',
  'lythraceous',
  'macabre',
  'macadam',
  'macadamized',
  'macaronic',
  'machinable',
  'machine-made',
  'macho',
  'macled',
  'macro',
  'macrobiotic',
  'macrocephalic',
  'macrocephalous',
  'macrocosmic',
  'macromolecular',
  'macropterous',
  'macroscopic',
  'macrurous',
  'macular',
  'maculate',
  'mad',
  'madcap',
  'maddened',
  'maddening',
  'maddest',
  'madding',
  'made',
  'made-to-order',
  'made-up',
  'madrigalian',
  'maenadic',
  'maestoso',
  'magenta',
  'maggoty',
  'magic',
  'magical',
  'magisterial',
  'magistral',
  'magmatic',
  'magnanimous',
  'magnesian',
  'magnetic',
  'magnetized',
  'magnetomotive',
  'magnific',
  'magnificent',
  'magnified',
  'magniloquent',
  'magnoliaceous',
  'maiden',
  'maidenish',
  'maidenlike',
  'maidenly',
  'maieutic',
  'maigre',
  'mail-clad',
  'mailable',
  'mailed',
  'maimed',
  'main',
  'mainstream',
  'mainstreamed',
  'maintainable',
  'maintained',
  'mair',
  'majestic',
  'major',
  'majuscular',
  'majuscule',
  'makable',
  'make-believe',
  'make-or-break',
  'makeless',
  'makeshift',
  'malacological',
  'malacophilous',
  'malacopterygian',
  'malacostracan',
  'maladapted',
  'maladaptive',
  'maladjusted',
  'maladroit',
  'malapert',
  'malapropos',
  'malar',
  'malarial',
  'malarian',
  'malarious',
  'malcontent',
  'male',
  'maledict',
  'maledictive',
  'maledictory',
  'malefic',
  'maleficent',
  'malevolent',
  'malfeasance',
  'malformed',
  'malfunctioning',
  'malicious',
  'malign',
  'malignant',
  'maligned',
  'malleable',
  'malnourished',
  'malodorous',
  'maltreated',
  'malty',
  'malvaceous',
  'mammalian',
  'mammalogical',
  'mammary',
  'mammiferous',
  'mammonistic',
  'mammoth',
  'man',
  'man-eating',
  'man-made',
  'man-sized',
  'man-to-man',
  'manageable',
  'managerial',
  'managing',
  'mandatory',
  'mandible',
  'mandibular',
  'mandibulate',
  'manducable',
  'manducatory',
  'maned',
  'maneless',
  'maneuverable',
  'manful',
  'manganic',
  'manganous',
  'mangey',
  'mangier',
  'mangiest',
  'mangled',
  'mangy',
  'maniac',
  'maniacal',
  'manic',
  'manic-depressive',
  'manifest',
  'manifestative',
  'manifold',
  'maniform',
  'manipulable',
  'manipular',
  'manipulatable',
  'manipulative',
  'manky',
  'manlier',
  'manliest',
  'manlike',
  'manly',
  'manned',
  'mannered',
  'manneristic',
  'mannerless',
  'mannerly',
  'mannish',
  'manoeuvrable',
  'manometric',
  'manometrical',
  'manorial',
  'mansard',
  'mantic',
  'mantled',
  'manual',
  'manubrial',
  'manufactural',
  'manufactured',
  'many',
  'many-sided',
  'marauding',
  'marble',
  'marbled',
  'marcescent',
  'marching',
  'mardy',
  'margaric',
  'marginal',
  'marginate',
  'marine',
  'marish',
  'marital',
  'maritime',
  'marked',
  'marketable',
  'marled',
  'marly',
  'marmalade',
  'marmoreal',
  'maroon',
  'marooned',
  'marred',
  'marriageable',
  'married',
  'marrowish',
  'marshier',
  'marshiest',
  'marshy',
  'marsipobranch',
  'marsupial',
  'martensitic',
  'martial',
  'martyrological',
  'marvellous',
  'marvelous',
  'masculine',
  'masked',
  'masking',
  'masochistic',
  'masonic',
  'mass',
  'mass-produced',
  'massed',
  'massier',
  'massiest',
  'massive',
  'massy',
  'masted',
  'master',
  'mastered',
  'masterful',
  'masterless',
  'masterly',
  'masticable',
  'masticatory',
  'mastigophoran',
  'mastless',
  'mastoid',
  'mastoidal',
  'masturbatory',
  'mat',
  'matchable',
  'matched',
  'matching',
  'matchless',
  'matchmaker',
  'matchmaking',
  'mated',
  'mateless',
  'material',
  'materialistic',
  'maternal',
  'matey',
  'mathematical',
  'matin',
  'matriarchal',
  'matrilineal',
  'matrilinear',
  'matrilocal',
  'matrimonial',
  'matroclinous',
  'matronal',
  'matronly',
  'matronymic',
  'matt',
  'matte',
  'matted',
  'matterful',
  'matterless',
  'mattery',
  'maturational',
  'maturative',
  'mature',
  'matured',
  'matutinal',
  'maudlin',
  'maungy',
  'mausolean',
  'mauve',
  'maverick',
  'mawkish',
  'maxi',
  'maxillary',
  'maxillofacial',
  'maximal',
  'maximizing',
  'maximum',
  'mayoral',
  'mazed',
  'mazier',
  'maziest',
  'mazy',
  'meager',
  'meagerly',
  'meagre',
  'mealier',
  'mealiest',
  'mealy',
  'mealy-mouthed',
  'mean',
  'meandering',
  'meandrous',
  'meaning',
  'meaningful',
  'meaningless',
  'measled',
  'measlier',
  'measliest',
  'measly',
  'measurable',
  'measured',
  'measureless',
  'meatal',
  'meatier',
  'meatiest',
  'meatless',
  'meaty',
  'mechanic',
  'mechanical',
  'mechanistic',
  'mechanized',
  'medal',
  'medallic',
  'meddlesome',
  'meddling',
  'mediaeval',
  'medial',
  'median',
  'mediastinal',
  'mediate',
  'mediated',
  'mediative',
  'mediatorial',
  'medicable',
  'medical',
  'medicamental',
  'medicative',
  'medicinable',
  'medicinal',
  'medieval',
  'mediocre',
  'meditative',
  'mediterranean',
  'medium',
  'medium-dated',
  'medium-sized',
  'medley',
  'medullary',
  'medullated',
  'medusoid',
  'meek',
  'meet',
  'megalithic',
  'megalomaniacal',
  'megalopolitan',
  'megaphonic',
  'megascopic',
  'meiotic',
  'melancholic',
  'melancholy',
  'melanic',
  'melanistic',
  'melanous',
  'meliaceous',
  'melic',
  'meliorative',
  'meliorist',
  'melioristic',
  'melismatic',
  'melliferous',
  'mellifluent',
  'mellifluous',
  'mellow',
  'mellowed',
  'mellowing',
  'melodic',
  'melodious',
  'melodramatic',
  'melted',
  'melting',
  'membranous',
  'memorable',
  'memorial',
  'memoriter',
  'menacing',
  'mendacious',
  'mendicant',
  'menial',
  'meningeal',
  'meningococcal',
  'meningococcic',
  'meniscoid',
  'menispermaceous',
  'menopausal',
  'mensal',
  'menseful',
  'menseless',
  'menstrual',
  'menstruating',
  'mensurable',
  'mensural',
  'mental',
  'mentholated',
  'mentionable',
  'mephitic',
  'mercantile',
  'mercantilism',
  'mercenary',
  'merchantable',
  'merchantlike',
  'merciful',
  'merciless',
  'mercurial',
  'mercuric',
  'merdivorous',
  'mere',
  'merest',
  'meretricious',
  'merged',
  'merging',
  'meridian',
  'meridional',
  'merino',
  'meristematic',
  'meristic',
  'merited',
  'meriting',
  'meritorious',
  'meroblastic',
  'merrier',
  'merriest',
  'merry',
  'mesarch',
  'mesencephalic',
  'mesenteric',
  'meshed',
  'meshuga',
  'mesial',
  'mesic',
  'mesmeric',
  'mesmerised',
  'mesmerized',
  'mesmerizing',
  'mesne',
  'mesoblastic',
  'mesocephalic',
  'mesomorphic',
  'mesonic',
  'mesophytic',
  'mesothelial',
  'mesothoracic',
  'messier',
  'messiest',
  'messy',
  'metabolic',
  'metacarpal',
  'metacentric',
  'metagalactic',
  'metagnathous',
  'metagrabolized',
  'metagrobolized',
  'metal',
  'metaleptic',
  'metaleptical',
  'metalinguistic',
  'metalled',
  'metallic',
  'metalliferous',
  'metalline',
  'metallographic',
  'metalloid',
  'metallurgic',
  'metallurgical',
  'metamere',
  'metameric',
  'metamorphic',
  'metaphoric',
  'metaphorical',
  'metaphrastic',
  'metaphysic',
  'metaphysical',
  'metapsychological',
  'metastable',
  'metastatic',
  'metatarsal',
  'metathetic',
  'metathetical',
  'metathoracic',
  'metazoan',
  'metazoic',
  'metempirical',
  'meteoric',
  'meteoritic',
  'meteoritical',
  'meteorologic',
  'meteorological',
  'methodical',
  'methodist',
  'methodological',
  'methylated',
  'methylic',
  'meticulous',
  'metonymic',
  'metonymical',
  'metopic',
  'metric',
  'metrical',
  'metrological',
  'metronymic',
  'metropolitan',
  'mettled',
  'mettlesome',
  'mezzo-rilievo',
  'miasmal',
  'miasmatic',
  'miasmic',
  'micellar',
  'mickle',
  'micro',
  'microanalytical',
  'microbial',
  'microbian',
  'microbic',
  'microbiological',
  'microcephalic',
  'microcephalous',
  'micrococcal',
  'microcosmic',
  'microcosmical',
  'microcrystalline',
  'microelectronic',
  'micrographic',
  'micrologic',
  'micrological',
  'micrometrical',
  'microminiature',
  'microphotographic',
  'microphytic',
  'microporous',
  'microscopic',
  'microscopical',
  'microseismic',
  'microseismical',
  'microsomal',
  'microtonal',
  'mid',
  'mid-Victorian',
  'middle',
  'middle-aged',
  'middle-distance',
  'middle-of-the-road',
  'middlebrow',
  'middlemost',
  'middleweight',
  'middling',
  'midget',
  'midi',
  'midland',
  'midmost',
  'midnightly',
  'midship',
  'midships',
  'midway',
  'miffed',
  'miffier',
  'miffiest',
  'miffy',
  'mightier',
  'mightiest',
  'mighty',
  'mignon',
  'mignonette',
  'migrainous',
  'migrant',
  'migratory',
  'milch',
  'mild',
  'mild-mannered',
  'mildewy',
  'miliary',
  'militant',
  'militaristic',
  'militarized',
  'military',
  'milk',
  'milk-and-water',
  'milk-livered',
  'milk-white',
  'milkier',
  'milkiest',
  'milkless',
  'milklike',
  'milky',
  'milled',
  'millenarian',
  'millenary',
  'millennial',
  'millesimal',
  'milliary',
  'milling',
  'million',
  'millionth',
  'millrun',
  'mim',
  'mimetic',
  'mimic',
  'mimical',
  'mimosaceous',
  'minacious',
  'minatory',
  'mincing',
  'mind-altering',
  'mind-bending',
  'mind-blowing',
  'mind-boggling',
  'mind-expanding',
  'minded',
  'mindful',
  'mindless',
  'mined',
  'mineral',
  'mineralized',
  'mineralogical',
  'mingling',
  'mingy',
  'mini',
  'miniature',
  'minikin',
  'minim',
  'minimal',
  'minimized',
  'minimum',
  'minimus',
  'minion',
  'miniscule',
  'ministerial',
  'ministering',
  'ministrant',
  'ministrative',
  'minor',
  'mint',
  'minus',
  'minuscular',
  'minuscule',
  'minute',
  'minutely',
  'miotic',
  'miraculous',
  'mired',
  'mirier',
  'miriest',
  'mirkier',
  'mirkiest',
  'mirky',
  'mirrored',
  'mirthful',
  'mirthless',
  'miry',
  'mis',
  'misanthropic',
  'misanthropical',
  'misapplied',
  'misapprehensive',
  'misappropriated',
  'misbegot',
  'misbegotten',
  'miscellaneous',
  'mischief-making',
  'mischievous',
  'miscible',
  'miscreant',
  'miscreate',
  'miscreated',
  'miscreative',
  'miserable',
  'miserly',
  'misfeatured',
  'misformed',
  'misguided',
  'mislaid',
  'misleading',
  'mismatched',
  'mismated',
  'misogynistic',
  'misogynous',
  'misplaced',
  'misproud',
  'misrelated',
  'misrepresented',
  'missed',
  'misshapen',
  'missing',
  'missive',
  'mistakable',
  'mistaken',
  'mistier',
  'mistiest',
  'mistreated',
  'mistrustful',
  'misty',
  'mistyped',
  'misunderstood',
  'misused',
  'mitered',
  'mitigable',
  'mitigated',
  'mitigative',
  'mitigatory',
  'mitochondrial',
  'mitotic',
  'mitrailleur',
  'mitral',
  'mixable',
  'mixed',
  'mixed-up',
  'mixolydian',
  'mizzen',
  'mnemic',
  'mnemonic',
  'mnemotechnic',
  'moanful',
  'moaning',
  'moated',
  'mob',
  'mobbish',
  'mobile',
  'mobocratic',
  'mock',
  'mock-heroic',
  'mocking',
  'mod',
  'modal',
  'model',
  'modeled',
  'moderate',
  'moderated',
  'moderating',
  'moderato',
  'modern',
  'modernism',
  'modernist',
  'modernistic',
  'modernized',
  'modest',
  'modifiable',
  'modified',
  'modiolar',
  'modish',
  'modular',
  'modulated',
  'moire',
  'moist',
  'molal',
  'molar',
  'molded',
  'moldered',
  'moldering',
  'moldy',
  'molecular',
  'molested',
  'moline',
  'molluscoid',
  'molluscous',
  'molten',
  'molybdic',
  'molybdous',
  'momentaneous',
  'momentary',
  'momentous',
  'monachal',
  'monachist',
  'monacid',
  'monadelphous',
  'monandrous',
  'monarch',
  'monarchal',
  'monarchial',
  'monarchic',
  'monarchical',
  'monarchist',
  'monarchistic',
  'monasterial',
  'monastic',
  'monastical',
  'monatomic',
  'monaural',
  'monaxial',
  'mondial',
  'monecious',
  'monetary',
  'money-grubbing',
  'moneyed',
  'moneyless',
  'monger',
  'mongol',
  'mongoloid',
  'mongrel',
  'monied',
  'moniliform',
  'monism',
  'monistic',
  'monistical',
  'monitorial',
  'monitory',
  'monkeyish',
  'monkish',
  'mono',
  'monoacid',
  'monobasic',
  'monocarpellary',
  'monocarpic',
  'monocarpous',
  'monochasial',
  'monochromatic',
  'monochrome',
  'monochromic',
  'monocled',
  'monoclinal',
  'monoclinic',
  'monoclinous',
  'monoclonal',
  'monocoque',
  'monocotyledonous',
  'monocular',
  'monocultural',
  'monocyclic',
  'monodic',
  'monodical',
  'monodramatic',
  'monoecious',
  'monogamic',
  'monogamous',
  'monogenetic',
  'monogenic',
  'monogenistic',
  'monogenous',
  'monogrammatic',
  'monographic',
  'monographical',
  'monogynous',
  'monohydric',
  'monolatrous',
  'monolingual',
  'monolithic',
  'monologic',
  'monological',
  'monomaniacal',
  'monomeric',
  'monometallic',
  'monomial',
  'monomolecular',
  'monomorphic',
  'mononuclear',
  'monopetalous',
  'monophagous',
  'monophonic',
  'monophthongal',
  'monophyletic',
  'monopodial',
  'monopolistic',
  'monopteral',
  'monosepalous',
  'monostichous',
  'monostrophic',
  'monosyllabic',
  'monosymmetric',
  'monotheism',
  'monotheistic',
  'monotheistical',
  'monotone',
  'monotonic',
  'monotonous',
  'monotypic',
  'monovalent',
  'monozygotic',
  'monsoonal',
  'monstrous',
  'montane',
  'monthly',
  'monticulate',
  'monticulous',
  'monumental',
  'mony',
  'monzonitic',
  'moodier',
  'moodiest',
  'moody',
  'moon-eyed',
  'moon-faced',
  'mooned',
  'moonish',
  'moonless',
  'moonlit',
  'moonshiny',
  'moonstruck',
  'moony',
  'moorish',
  'moory',
  'moot',
  'mop-headed',
  'mopey',
  'mopier',
  'mopiest',
  'mopy',
  'moraceous',
  'morainal',
  'morainic',
  'moral',
  'moralistic',
  'morbid',
  'morbific',
  'mordacious',
  'mordant',
  'more',
  'moreish',
  'morganatic',
  'moribund',
  'morish',
  'mornay',
  'morning',
  'moronic',
  'morose',
  'morphemic',
  'morphogenetic',
  'morphologic',
  'morphological',
  'morphophonemic',
  'morphotic',
  'mortal',
  'mortgaged',
  'mortiferous',
  'mortified',
  'mortifying',
  'mortuary',
  'mosaic',
  'moss-grown',
  'mossier',
  'mossiest',
  'mossy',
  'most',
  'motey',
  'moth-eaten',
  'mother-naked',
  'motherless',
  'motherlike',
  'motherly',
  'mothier',
  'mothiest',
  'mothy',
  'motile',
  'motional',
  'motionless',
  'motivated',
  'motivating',
  'motivational',
  'motive',
  'motiveless',
  'motley',
  'motor',
  'motor-driven',
  'motorable',
  'motored',
  'motorized',
  'mottled',
  'mouldered',
  'mouldering',
  'mouldier',
  'mouldiest',
  'mouldy',
  'mountain',
  'mountainous',
  'mounted',
  'mournful',
  'mourning',
  'mousey',
  'mousier',
  'mousiest',
  'mousy',
  'mouth-to-mouth',
  'mouthier',
  'mouthiest',
  'mouthless',
  'mouthwatering',
  'mouthy',
  'movable',
  'moveable',
  'moved',
  'moveless',
  'moving',
  'mowburnt',
  'mown',
  'mozambican',
  'much',
  'mucic',
  'mucid',
  'muciferous',
  'mucilaginous',
  'muckier',
  'muckiest',
  'mucking',
  'muckle',
  'mucky',
  'mucoid',
  'mucopurulent',
  'mucous',
  'mucronate',
  'muddied',
  'muddier',
  'muddiest',
  'muddled',
  'muddleheaded',
  'muddy',
  'muffled',
  'muggier',
  'muggiest',
  'muggy',
  'mulatto',
  'muley',
  'mulish',
  'mulley',
  'mullioned',
  'multangular',
  'multiarticulate',
  'multicapitate',
  'multicellular',
  'multicentral',
  'multicentric',
  'multicoloured',
  'multicostate',
  'multicultural',
  'multicuspidate',
  'multidentate',
  'multidenticulate',
  'multidigitate',
  'multidimensional',
  'multidirectional',
  'multidisciplinary',
  'multifaced',
  'multifaceted',
  'multifactorial',
  'multifarious',
  'multifid',
  'multiflorous',
  'multifoliate',
  'multiform',
  'multijugate',
  'multilateral',
  'multilineal',
  'multilinear',
  'multilingual',
  'multilobate',
  'multilobed',
  'multilobular',
  'multinational',
  'multinominal',
  'multinuclear',
  'multinucleate',
  'multinucleolate',
  'multiparous',
  'multipartite',
  'multiped',
  'multiphase',
  'multiple',
  'multiple-choice',
  'multiplex',
  'multiplicate',
  'multiplicative',
  'multiplied',
  'multipolar',
  'multipurpose',
  'multiracial',
  'multiramified',
  'multiscreen',
  'multiseptate',
  'multiseriate',
  'multispiral',
  'multistorey',
  'multistory',
  'multisulcate',
  'multituberculate',
  'multitudinous',
  'multivalent',
  'multivariate',
  'multivocal',
  'multivoltine',
  'mum',
  'mumbling',
  'mumchance',
  'mundane',
  'municipal',
  'munificent',
  'mural',
  'murdered',
  'murderous',
  'muriatic',
  'muricate',
  'murine',
  'murk',
  'murky',
  'murmuring',
  'murmurous',
  'murrey',
  'murrhine',
  'musaceous',
  'muscid',
  'muscle-bound',
  'muscly',
  'muscular',
  'museful',
  'mushier',
  'mushiest',
  'mushy',
  'musical',
  'musicianly',
  'musicological',
  'musing',
  'muskier',
  'muskiest',
  'musky',
  'mussier',
  'mussiest',
  'mussy',
  'must',
  'mustached',
  'mustachioed',
  'musteline',
  'mustier',
  'mustiest',
  'musty',
  'mutable',
  'mutagenic',
  'mutant',
  'mutational',
  'mutative',
  'mute',
  'muted',
  'muticous',
  'mutilated',
  'mutinous',
  'muttering',
  'muttony',
  'mutual',
  'mutualism',
  'muzzy',
  'myalgic',
  'myasthenic',
  'mycelial',
  'mycologic',
  'mycological',
  'mydriatic',
  'myeloid',
  'mylohyoid',
  'myocardial',
  'myogenic',
  'myographic',
  'myoid',
  'myological',
  'myopic',
  'myotic',
  'myotonia',
  'myriad',
  'myriapod',
  'myriopod',
  'myrmecological',
  'myrmecophagous',
  'myrmecophilous',
  'myrtaceous',
  'mystagogic',
  'mystagogical',
  'mysterious',
  'mystic',
  'mystical',
  'mystified',
  'mystifying',
  'mythic',
  'mythical',
  'mythologic',
  'mythological',
  'mythomania',
  'mythomaniac',
  'mythopoeic',
  'myxomycete',
  'mzee',
  'n-type',
  'nacred',
  'nacreous',
  'nae',
  'naevoid',
  'nagging',
  'naggy',
  'naiant',
  'naif',
  'nailless',
  'naissant',
  'naive',
  'naked',
  'namby-pamby',
  'namby-pambyish',
  'named',
  'nameless',
  'naming',
  'naphthalic',
  'napiform',
  'napless',
  'napped',
  'nappier',
  'nappiest',
  'napping',
  'nappy',
  'narcissistic',
  'narcoleptic',
  'narcotic',
  'narcotized',
  'narcotizing',
  'narial',
  'narratable',
  'narrative',
  'narrow',
  'narrow-gauge',
  'narrow-minded',
  'narrowed',
  'narrowing',
  'nary',
  'nasal',
  'nascent',
  'nasofrontal',
  'nastier',
  'nastiest',
  'nasty',
  'natal',
  'natant',
  'natatorial',
  'natatory',
  'national',
  'nationalism',
  'nationalist',
  'nationalistic',
  'nationwide',
  'native',
  'native-born',
  'nativism',
  'nativist',
  'nativistic',
  'nattier',
  'nattiest',
  'natty',
  'natural',
  'natural-born',
  'naturalistic',
  'naturalized',
  'naturism',
  'naturistic',
  'naturopathic',
  'naught',
  'naughtier',
  'naughtiest',
  'naughty',
  'nauplioid',
  'nauseated',
  'nauseating',
  'nauseous',
  'nautical',
  'naval',
  'navicular',
  'navigable',
  'navigational',
  "ne'er-do-well",
  'neap',
  'neaped',
  'near',
  'near-hand',
  'near-sighted',
  'nearer',
  'nearest',
  'neat',
  'nebular',
  'nebule',
  'nebulous',
  'nebuly',
  'necessarian',
  'necessary',
  'necessitarianism',
  'necessitous',
  'necked',
  'necrologic',
  'necrological',
  'necromantic',
  'necromantical',
  'necrophiliac',
  'necrophilic',
  'necrophobic',
  'necrotic',
  'nectareous',
  'nectariferous',
  'nectarous',
  'needed',
  'needful',
  'needier',
  'neediest',
  'needless',
  'needy',
  'nefarious',
  'negative',
  'negativism',
  'neglected',
  'neglectful',
  'negligent',
  'negligible',
  'negotiable',
  'negroid',
  'neighbor',
  'neighboring',
  'neighborless',
  'neighborly',
  'neighbour',
  'neighbouring',
  'neighbourless',
  'neighbourly',
  'nematic',
  'nematocystic',
  'nemertean',
  'neoclassic',
  'neoclassical',
  'neoclassicist',
  'neologic',
  'neological',
  'neologistic',
  'neologistical',
  'neonatal',
  'neophytic',
  'neoplastic',
  'neotenous',
  'neoteric',
  'nepenthean',
  'nephological',
  'nephric',
  'nephritic',
  'nephrotic',
  'nepotic',
  'nepotistic',
  'neritic',
  'nerval',
  'nervate',
  'nerve-racking',
  'nerve-wracking',
  'nerveless',
  'nervine',
  'nervous',
  'nervy',
  'nescient',
  'nesh',
  'nested',
  'nestled',
  'nestlike',
  'net',
  'nether',
  'nethermost',
  'netherward',
  'nett',
  'netted',
  'nettled',
  'nettlelike',
  'nettlesome',
  'nettly',
  'neural',
  'neuralgic',
  'neurasthenic',
  'neuritic',
  'neuroanatomical',
  'neurobiological',
  'neurogenic',
  'neurological',
  'neuromuscular',
  'neuron',
  'neuronal',
  'neuronic',
  'neuropathic',
  'neurophysiological',
  'neuropsychiatric',
  'neuropterous',
  'neurosurgical',
  'neurotic',
  'neurotropic',
  'neurovascular',
  'neuter',
  'neutered',
  'neutral',
  'neutralism',
  'neutralized',
  'neutrophil',
  'never-ending',
  'never-never',
  'never-say-die',
  'new',
  'new-fashioned',
  'new-made',
  'new-model',
  'new-mown',
  'newborn',
  'newest',
  'newfangled',
  'newish',
  'newsier',
  'newsiest',
  'newsless',
  'newsworthy',
  'newsy',
  'next',
  'next-door',
  'nibbed',
  'nice',
  'nicer',
  'nicest',
  'nickel',
  'nickel-and-dime',
  'nickelic',
  'nickeliferous',
  'nickelous',
  'nicotined',
  'nicotinic',
  'niddering',
  'niddle-noddle',
  'nidicolous',
  'nidifugous',
  'niffy',
  'niftier',
  'niftiest',
  'nifty',
  'niggard',
  'niggardly',
  'niggling',
  'nigh',
  'nighted',
  'nightless',
  'nightlong',
  'nightly',
  'nightmarish',
  'nigrescent',
  'nihilism',
  'nihilist',
  'nihilistic',
  'nimble',
  'nimble-fingered',
  'nimbused',
  'niminy-piminy',
  'nine',
  'ninefold',
  'ninepenny',
  'nineteen',
  'nineteenth',
  'ninetieth',
  'ninety',
  'ninth',
  'niobic',
  'niobous',
  'nipping',
  'nippy',
  'nisi',
  'nitid',
  'nitpicking',
  'nitric',
  'nitrogenous',
  'nitrous',
  'nittier',
  'nittiest',
  'nitty',
  'nitwitted',
  'nival',
  'niveous',
  'no-account',
  'no-fault',
  'no-nonsense',
  'nobbier',
  'nobbiest',
  'nobby',
  'nobiliary',
  'noble',
  'noble-minded',
  'nobler',
  'noblest',
  'nocent',
  'nociceptive',
  'noctilucent',
  'noctuid',
  'nocturnal',
  'nocuous',
  'nodal',
  'nodding',
  'nodical',
  'nodose',
  'nodous',
  'nodular',
  'nodulated',
  'noduled',
  'nodulose',
  'noetic',
  'noiseless',
  'noisemaker',
  'noisette',
  'noisier',
  'noisiest',
  'noisome',
  'noisy',
  'nomadic',
  'nomenclatorial',
  'nomenclatural',
  'nominal',
  'nominalistic',
  'nominate',
  'nominated',
  'nominative',
  'nomistic',
  'nomographic',
  'nomographical',
  'nomological',
  'nomothetic',
  'non',
  'non-Christian',
  'non-Euclidean',
  'non-U',
  'non-iron',
  'non-profit-making',
  'nonabrasive',
  'nonabsorbent',
  'nonacademic',
  'nonaddictive',
  'nonadministrative',
  'nonaged',
  'nonagenarian',
  'nonagon',
  'nonary',
  'nonautomatic',
  'nonbelligerent',
  'nonbiological',
  'nonbreakable',
  'nonchalant',
  'nonchromosomal',
  'nonclassified',
  'nonclinical',
  'noncognizable',
  'noncommercial',
  'nonconclusive',
  'nonconcurrent',
  'nonconforming',
  'nonconformist',
  'noncontagious',
  'noncontroversial',
  'nondescript',
  'nondestructive',
  'nonexecutive',
  'nonflowering',
  'nonfunctional',
  'nonharmonic',
  'nonillionth',
  'nonionic',
  'nonjudgmental',
  'nonlethal',
  'nonnegotiable',
  'nonoperational',
  'nonpareil',
  'nonparous',
  'nonpathogenic',
  'nonpersistent',
  'nonplused',
  'nonplussed',
  'nonpoisonous',
  'nonpolar',
  'nonprofit',
  'nonracial',
  'nonscientific',
  'nonsense',
  'nonsensical',
  'nonstandard',
  'nonstick',
  'nontechnical',
  'nontoxic',
  'nonuple',
  'nonverbal',
  'nonvintage',
  'nonvolatile',
  'normal',
  'norman',
  'normative',
  'north',
  'northerly',
  'northern',
  'northernmost',
  'northmost',
  'northward',
  'nosed',
  'noseless',
  'nosey',
  'nosier',
  'nosiest',
  'nosographic',
  'nosological',
  'nostalgic',
  'nostologic',
  'nosy',
  'notable',
  'notal',
  'notarial',
  'notational',
  'notched',
  'notchy',
  'noted',
  'noteless',
  'noteworthy',
  'noticeable',
  'noticed',
  'notifiable',
  'notional',
  'notochordal',
  'notorious',
  'notoungulate',
  'notour',
  'noumenal',
  'noumenon',
  'nourishable',
  'nourished',
  'nourishing',
  'novel',
  'novelettish',
  'novelistic',
  'novercal',
  'novice',
  'nowed',
  'noxious',
  'nth',
  'nubblier',
  'nubbliest',
  'nubbly',
  'nubby',
  'nubile',
  'nubilous',
  'nuclear',
  'nucleate',
  'nucleolar',
  'nucleolated',
  'nucleophilic',
  'nucleoplasm',
  'nude',
  'nudicaul',
  'nudist',
  'nugatory',
  'nuggety',
  'null',
  'nullifidian',
  'nullified',
  'nulliparous',
  'numb',
  'numberless',
  'numbing',
  'numerable',
  'numeral',
  'numerary',
  'numerate',
  'numeric',
  'numerical',
  'numerous',
  'numinous',
  'nummary',
  'nummular',
  'nummulitic',
  'nuncupative',
  'nuptial',
  'nursed',
  'nurturable',
  'nurtural',
  'nutant',
  'nutational',
  'nutlike',
  'nutmegged',
  'nutrient',
  'nutrimental',
  'nutritional',
  'nutritious',
  'nutritive',
  'nuts',
  'nutty',
  'nyctaginaceous',
  'nyctitropic',
  'nymphaeaceous',
  'nymphal',
  'nymphalid',
  'nymphean',
  'nymphomania',
  'nymphomaniac',
  'nymphomaniacal',
  'nystagmic',
  'oafish',
  'oak',
  'oaken',
  'oared',
  'oarless',
  'oaten',
  'obbligato',
  'obconic',
  'obconical',
  'obcordate',
  'obdurate',
  'obedient',
  'obeisant',
  'obeliscal',
  'obese',
  'objectionable',
  'objective',
  'objectivist',
  'objectivistic',
  'objurgative',
  'objurgatory',
  'oblanceolate',
  'oblate',
  'oblatory',
  'obligate',
  'obligated',
  'obligational',
  'obligato',
  'obligatory',
  'obliged',
  'obliging',
  'oblique',
  'obliterate',
  'obliterated',
  'obliterating',
  'obliterative',
  'oblivious',
  'oblong',
  'obnoxious',
  'obovate',
  'obovoid',
  'obreptitious',
  'obscene',
  'obscurant',
  'obscurantist',
  'obscure',
  'obsequent',
  'obsequious',
  'observable',
  'observant',
  'observational',
  'observed',
  'observing',
  'obsessed',
  'obsessional',
  'obsessive',
  'obsessive-compulsive',
  'obsolescent',
  'obsolete',
  'obstetric',
  'obstetrical',
  'obstinate',
  'obstreperous',
  'obstructed',
  'obstructive',
  'obstruent',
  'obtainable',
  'obtect',
  'obtrusive',
  'obtundent',
  'obtuse',
  'obtuse-angled',
  'obtuse-angular',
  'obumbrate',
  'obverse',
  'obviating',
  'obvious',
  'obvolute',
  'occasional',
  'occidental',
  'occipital',
  'occluded',
  'occludent',
  'occlusal',
  'occlusive',
  'occult',
  'occultism',
  'occultist',
  'occupational',
  'occupative',
  'occupied',
  'occurrent',
  'ocean-going',
  'oceanic',
  'oceanographic',
  'oceanographical',
  'ocellar',
  'ocellated',
  'oceloid',
  'ocher',
  'ocherous',
  'ochery',
  'ochlocratic',
  'ochlocratical',
  'ochre',
  'ochreous',
  'ocker',
  'ocreate',
  'octachordal',
  'octadic',
  'octagonal',
  'octahedral',
  'octal',
  'octamerous',
  'octangular',
  'octantal',
  'octastyle',
  'octaval',
  'octave',
  'octennial',
  'octennially',
  'octillionth',
  'octogenarian',
  'octonary',
  'octopod',
  'octosyllabic',
  'octuple',
  'octupled',
  'octuplet',
  'octupling',
  'ocular',
  'oculomotor',
  'odd',
  'odd-job',
  'oddball',
  'odds-on',
  'odious',
  'odontalgic',
  'odontoid',
  'odontological',
  'odontophorous',
  'odoriferous',
  'odorless',
  'odorous',
  'odourless',
  'oecumenic',
  'oecumenical',
  'oesophageal',
  'oestrous',
  'off',
  'off-Broadway',
  'off-centre',
  'off-key',
  'off-line',
  'off-off-Broadway',
  'off-putting',
  'off-road',
  'off-site',
  'off-street',
  'off-the-cuff',
  'off-the-peg',
  'off-the-shelf',
  'off-white',
  'offbeat',
  'offenceless',
  'offended',
  'offending',
  'offensive',
  'offerable',
  'offhand',
  'offhanded',
  'official',
  'officinal',
  'officious',
  'offish',
  'offscreen',
  'offsetting',
  'offshore',
  'offside',
  'often',
  'ogreish',
  'ohmic',
  'oil-fired',
  'oiled',
  'oilier',
  'oiliest',
  'oily',
  'okay',
  'oke',
  'okey-doke',
  'old',
  'old-established',
  'old-fashioned',
  'old-fogeyish',
  'old-fogyish',
  'old-maidish',
  'old-rose',
  'old-time',
  'old-womanish',
  'old-world',
  'olde-worlde',
  'olden',
  'older',
  'oldest',
  'oldfangled',
  'oldish',
  'oleaceous',
  'oleaginous',
  'oleic',
  'oleophilic',
  'olfactive',
  'olfactory',
  'olid',
  'oligarchic',
  'oligarchical',
  'oligochaete',
  'oligopolistic',
  'oligopsonistic',
  'oligotrophic',
  'olivaceous',
  'olivary',
  'olive',
  'omental',
  'ominous',
  'omissible',
  'omissive',
  'omnibus',
  'omnicompetent',
  'omnidirectional',
  'omnifarious',
  'omnific',
  'omnipotent',
  'omnipresent',
  'omniscient',
  'omnivorous',
  'omophagic',
  'on-line',
  'on-site',
  'on-stream',
  'on-the-spot',
  'onagraceous',
  'onanistic',
  'once',
  'oncogenic',
  'oncoming',
  'one',
  'one-armed',
  'one-dimensional',
  'one-eyed',
  'one-handed',
  'one-horse',
  'one-man',
  'one-on-one',
  'one-piece',
  'one-sided',
  'one-time',
  'one-to-one',
  'one-track',
  'one-up',
  'one-way',
  'oneiric',
  'oneirocritical',
  'onerous',
  'ongoing',
  'oniony',
  'only',
  'only-begotten',
  'onomastic',
  'onomatopoeic',
  'onomatopoetic',
  'onshore',
  'onside',
  'onstage',
  'ontogenetic',
  'ontogenic',
  'ontological',
  'onward',
  'onymous',
  'oogamous',
  'oogenetic',
  'oolitic',
  'oozier',
  'ooziest',
  'oozing',
  'oozy',
  'opalescent',
  'opaline',
  'opaque',
  'ope',
  'open',
  'open-air',
  'open-and-shut',
  'open-chain',
  'open-door',
  'open-end',
  'open-ended',
  'open-eyed',
  'open-faced',
  'open-field',
  'open-handed',
  'open-hearted',
  'open-hearth',
  'open-letter',
  'open-minded',
  'open-mouthed',
  'open-plan',
  'open-shop',
  'opencast',
  'opened',
  'opening',
  'operable',
  'operant',
  'operatic',
  'operating',
  'operational',
  'operative',
  'operculate',
  'operculated',
  'operose',
  'ophidian',
  'ophiolatrous',
  'ophiologic',
  'ophiological',
  'ophitic',
  'ophthalmic',
  'ophthalmological',
  'ophthalmoscopic',
  'ophthalmoscopical',
  'opiate',
  'opinionated',
  'opinionative',
  'opisthognathous',
  'oppidan',
  'opponent',
  'opportune',
  'opportunist',
  'opportunistic',
  'opposable',
  'opposed',
  'opposing',
  'opposite',
  'oppositional',
  'oppositive',
  'oppressed',
  'oppressive',
  'opprobrious',
  'oppugnant',
  'opsonic',
  'optative',
  'optic',
  'optical',
  'optimal',
  'optimistic',
  'optimum',
  'optional',
  'optometrical',
  'opulent',
  'opuscule',
  'oracular',
  'oral',
  'orange',
  'oratorical',
  'orbicular',
  'orbiculate',
  'orbital',
  'orchestral',
  'orchestrated',
  'orchidaceous',
  'orchitic',
  'ordainable',
  'ordained',
  'ordered',
  'orderly',
  'ordinaire',
  'ordinal',
  'ordinary',
  'ordinate',
  'ordurous',
  'orectic',
  'organic',
  'organicism',
  'organisable',
  'organisational',
  'organismal',
  'organismic',
  'organizable',
  'organizational',
  'organized',
  'organoleptic',
  'organometallic',
  'orgasmic',
  'orgastic',
  'orgiastic',
  'orgulous',
  'orient',
  'oriental',
  'orientated',
  'orientating',
  'oriented',
  'orienting',
  'original',
  'originative',
  'orinasal',
  'ornamental',
  'ornamented',
  'ornate',
  'ornery',
  'ornithic',
  'ornithischian',
  'ornithoid',
  'ornithological',
  'orobanchaceous',
  'orogenetic',
  'orogenic',
  'orological',
  'orotund',
  'orphan',
  'orphaned',
  'ortho',
  'orthochromatic',
  'orthodontic',
  'orthodontics',
  'orthodox',
  'orthogenetic',
  'orthogenic',
  'orthognathous',
  'orthogonal',
  'orthographic',
  'orthopaedic',
  'orthopedic',
  'orthopedical',
  'orthophosphoric',
  'orthophyric',
  'orthopterous',
  'orthoptic',
  'orthorhombic',
  'orthoscopic',
  'orthostichous',
  'orthotone',
  'orthotropic',
  'orthotropous',
  'oscillating',
  'oscillatory',
  'oscine',
  'oscitant',
  'osculant',
  'oscular',
  'osculatory',
  'osiered',
  'osmic',
  'osmious',
  'osmotic',
  'osmous',
  'osseous',
  'ossicular',
  'ossiferous',
  'ossified',
  'osteal',
  'ostensible',
  'ostensive',
  'ostentatious',
  'osteoarthritis',
  'osteogenetic',
  'osteoid',
  'osteological',
  'osteopathic',
  'osteophytic',
  'osteoplastic',
  'ostracodan',
  'ostracodous',
  'other',
  'othergates',
  'otherguess',
  'otherwise',
  'otherworldly',
  'otic',
  'otiose',
  'out',
  'out-and-out',
  'out-of-bounds',
  'out-of-date',
  'out-of-door',
  'out-of-fashion',
  'out-of-place',
  'out-of-pocket',
  'out-of-print',
  'out-of-stock',
  'out-of-the-way',
  'out-of-town',
  'out-of-work',
  'outback',
  'outboard',
  'outbound',
  'outbred',
  'outcast',
  'outcaste',
  'outclassed',
  'outdated',
  'outdone',
  'outdoor',
  'outdoorsy',
  'outer',
  'outermost',
  'outfitted',
  'outflowing',
  'outgoing',
  'outland',
  'outlandish',
  'outlaw',
  'outlawed',
  'outlined',
  'outlying',
  'outmoded',
  'outmost',
  'outraged',
  'outrageous',
  'outremer',
  'outright',
  'outside',
  'outsize',
  'outsized',
  'outspoken',
  'outspread',
  'outstanding',
  'outstretched',
  'outward',
  'outward-bound',
  'outworn',
  'oval',
  'ovarian',
  'ovate',
  'oven-ready',
  'over',
  'over-the-counter',
  'overabundant',
  'overactive',
  'overall',
  'overambitious',
  'overarm',
  'overawed',
  'overbearing',
  'overblown',
  'overbold',
  'overburdened',
  'overburdensome',
  'overbusy',
  'overcareful',
  'overcast',
  'overcautious',
  'overcome',
  'overcorrect',
  'overcredulous',
  'overcritical',
  'overcurious',
  'overdelicate',
  'overdone',
  'overdressed',
  'overdue',
  'overeager',
  'overearnest',
  'overemotional',
  'overenthusiastic',
  'overexcitable',
  'overexcited',
  'overfar',
  'overfed',
  'overflowing',
  'overfond',
  'overforward',
  'overfraught',
  'overfree',
  'overfull',
  'overgenerous',
  'overglaze',
  'overgreat',
  'overgreedy',
  'overground',
  'overgrown',
  'overhand',
  'overhanded',
  'overhanging',
  'overhappy',
  'overhasty',
  'overhead',
  'overheated',
  'overindulgent',
  'overjoyed',
  'overkind',
  'overladen',
  'overland',
  'overlapping',
  'overlarge',
  'overloaded',
  'overlong',
  'overlooked',
  'overlooking',
  'overloud',
  'overlying',
  'overmerry',
  'overmodest',
  'overmuch',
  'overneat',
  'overnice',
  'overnight',
  'overoptimistic',
  'overpowered',
  'overpowering',
  'overpriced',
  'overproof',
  'overprotective',
  'overproud',
  'overrank',
  'overrash',
  'overreaching',
  'overriding',
  'overripe',
  'overrun',
  'overscrupulous',
  'oversea',
  'overseas',
  'oversensitive',
  'oversewn',
  'oversexed',
  'overshot',
  'oversimplified',
  'oversize',
  'oversized',
  'overspreading',
  'overstated',
  'overstayed',
  'overstrong',
  'overstrung',
  'overstuffed',
  'oversubscribed',
  'oversubtle',
  'oversuspicious',
  'overt',
  'overtedious',
  'overthrown',
  'overturned',
  'overviolent',
  'overweary',
  'overweening',
  'overweight',
  'overwhelmed',
  'overwhelming',
  'overwrought',
  'overzealous',
  'oviferous',
  'oviform',
  'ovine',
  'oviparous',
  'ovoid',
  'ovoviviparous',
  'ovular',
  'owed',
  'owing',
  'owlish',
  'owlishly',
  'owllike',
  'own',
  'owned',
  'owner-occupied',
  'ownerless',
  'ox-eyed',
  'oxalic',
  'oxblood',
  'oxidised',
  'oxidizable',
  'oxidized',
  'oxygenated',
  'oxytocic',
  'ozoniferous',
  'p-type',
  'paced',
  'pachydermal',
  'pachydermatous',
  'pachydermic',
  'pachydermous',
  'pacific',
  'pacifical',
  'pacifist',
  'pacifying',
  'packaged',
  'packed',
  'pactional',
  'padded',
  'paddle-wheel',
  'paederastic',
  'paediatric',
  'paedophilia',
  'pagan',
  'paginal',
  'pagurian',
  'paid',
  'paid-up',
  'pained',
  'painful',
  'painless',
  'painstaking',
  'paintable',
  'painted',
  'painterly',
  'paired',
  'palaeanthropic',
  'palaeobotanic',
  'palaeobotanical',
  'palaeoecological',
  'palaeolithic',
  'palaeontological',
  'palaeozoological',
  'palaeozoology',
  'palaestral',
  'palaestric',
  'palatable',
  'palatal',
  'palatalized',
  'palatial',
  'palatine',
  'pale',
  'paleaceous',
  'paled',
  'paleolithic',
  'paler',
  'palest',
  'palimpsest',
  'palindromic',
  'palindromical',
  'paling',
  'palish',
  'palladic',
  'palladous',
  'pallial',
  'palliative',
  'pallid',
  'pally',
  'palmaceous',
  'palmar',
  'palmary',
  'palmate',
  'palmatifid',
  'palmier',
  'palmiest',
  'palmitic',
  'palmy',
  'palpable',
  'palpate',
  'palpebral',
  'palpitant',
  'palpitating',
  'palsied',
  'palsy-walsy',
  'paltrier',
  'paltriest',
  'paltry',
  'paludal',
  'paly',
  'palynological',
  'pampean',
  'pampered',
  'pampering',
  'pan',
  'panchromatic',
  'pancratic',
  'pancreatic',
  'pandanaceous',
  'pandemic',
  'pandemoniacal',
  'pandurate',
  'panduriform',
  'paned',
  'panegyric',
  'panegyrical',
  'paneled',
  'pangenetic',
  'panhellenic',
  'panic-stricken',
  'panic-struck',
  'panicked',
  'panicky',
  'panicled',
  'paniculate',
  'panniered',
  'panoplied',
  'panoptic',
  'panoptical',
  'panoramic',
  'panpsychistic',
  'pansophic',
  'pansophical',
  'pantaletted',
  'pantalooned',
  'pantheist',
  'pantheistic',
  'pantheistical',
  'panting',
  'pantographic',
  'pantographical',
  'pantomimic',
  'pantomimical',
  'panzer',
  'papal',
  'papaveraceous',
  'paper',
  'paperback',
  'paperbacked',
  'paperbound',
  'papery',
  'papilionaceous',
  'papillar',
  'papillary',
  'papillate',
  'papillomatous',
  'papillose',
  'papist',
  'papistic',
  'papistical',
  'pappose',
  'pappy',
  'papular',
  'papulose',
  'papyraceous',
  'par',
  'parabolic',
  'parabolical',
  'paraboloid',
  'paraboloidal',
  'paradigmatic',
  'paradigmatical',
  'paradisaic',
  'paradisaical',
  'paradisal',
  'paradisiac',
  'paradisiacal',
  'paradisial',
  'paradoxal',
  'paradoxical',
  'paraffinic',
  'paraffinoid',
  'paragenetic',
  'paraglossate',
  'paragogic',
  'paragogical',
  'paragraphic',
  'parallactic',
  'parallel',
  'parallelism',
  'paralytic',
  'paralyzed',
  'paramagnetic',
  'paramedic',
  'paramedical',
  'parametric',
  'paramilitary',
  'paramorphic',
  'paramount',
  'paranoiac',
  'paranoid',
  'paranormal',
  'parapeted',
  'paraphrastic',
  'paraplegic',
  'parapodial',
  'parapsychological',
  'parasitic',
  'parasitical',
  'parasiticide',
  'parasympathetic',
  'parasynthetic',
  'paratactic',
  'paratactical',
  'parathyroid',
  'paratyphoid',
  'paravail',
  'parcel-gilt',
  'parched',
  'pardine',
  'pardonable',
  'pardonless',
  'parecious',
  'parenchymatous',
  'parental',
  'parented',
  'parenteral',
  'parenthetic',
  'parenthetical',
  'parentless',
  'paretic',
  'parheliacal',
  'parietal',
  'paripinnate',
  'parisyllabic',
  'parked',
  'parklike',
  'parky',
  'parlando',
  'parliamentarian',
  'parliamentary',
  'parlous',
  'parochial',
  'parodic',
  'parodistic',
  'paroicous',
  'parol',
  'paronomastic',
  'paronymous',
  'parotic',
  'parotid',
  'paroxysmal',
  'paroxytone',
  'parricidal',
  'parsimonious',
  'parsonic',
  'parsonical',
  'parsonish',
  'part',
  'part-time',
  'parted',
  'parthenocarpic',
  'parthenogenetic',
  'partial',
  'partible',
  'participant',
  'participating',
  'participatory',
  'participial',
  'particular',
  'particularised',
  'particularism',
  'particularistic',
  'particularized',
  'particulate',
  'parting',
  'partisan',
  'partite',
  'partitioned',
  'partitive',
  'partizan',
  'parturient',
  'party',
  'party-spirited',
  'parvenu',
  'parvenue',
  'paschal',
  'pass',
  'passable',
  'passant',
  'passed',
  'passerine',
  'passible',
  'passing',
  'passional',
  'passionate',
  'passionless',
  'passive',
  'passless',
  'past',
  'pasteboard',
  'pasted',
  'pastel',
  'pasteurized',
  'pastier',
  'pasties',
  'pastiest',
  'pastoral',
  'pastural',
  'pastureless',
  'pasty',
  'pasty-faced',
  'pat',
  'patchable',
  'patched',
  'patchier',
  'patchiest',
  'patchy',
  'patellar',
  'patellate',
  'patelliform',
  'patent',
  'patentable',
  'patented',
  'paternal',
  'paternalism',
  'paternalistic',
  'pathetic',
  'pathic',
  'pathless',
  'pathogenetic',
  'pathogenic',
  'pathognomonic',
  'pathologic',
  'pathological',
  'patient',
  'patriarchal',
  'patrician',
  'patricidal',
  'patrilineage',
  'patrilineal',
  'patrilinear',
  'patrilocal',
  'patrimonial',
  'patriotic',
  'patristic',
  'patristical',
  'patronal',
  'patronising',
  'patronized',
  'patronizing',
  'patronless',
  'patronymic',
  'patterned',
  'patulous',
  'paunchy',
  'pausal',
  'pauseful',
  'pauseless',
  'paved',
  'pavid',
  'pavonine',
  'pawky',
  'payable',
  'paying',
  'payoff',
  'pea-green',
  'peaceable',
  'peaceful',
  'peaceless',
  'peacemaking',
  'peach-blow',
  'peachier',
  'peachiest',
  'peachy',
  'peacock-blue',
  'peacockish',
  'peacocky',
  'peak',
  'peaked',
  'peaky',
  'peanut',
  'pear-shaped',
  'pearl',
  'pearl-grey',
  'pearlier',
  'pearliest',
  'pearlized',
  'pearly',
  'peart',
  'peatier',
  'peatiest',
  'peaty',
  'pebble-dashed',
  'pebbly',
  'peccable',
  'peccant',
  'peckish',
  'pectic',
  'pectinaceous',
  'pectinate',
  'pectoral',
  'peculiar',
  'pecuniary',
  'pedagogic',
  'pedagogical',
  'pedagoguish',
  'pedal',
  'pedantic',
  'pedantical',
  'pedate',
  'pedatifid',
  'peddling',
  'pederastic',
  'pedestrian',
  'pediatric',
  'pedicellate',
  'pedicular',
  'pediculate',
  'pediculous',
  'pedigree',
  'pedigreed',
  'pedimental',
  'pedimented',
  'pedological',
  'pedunculate',
  'peekaboo',
  'peeled',
  'peelie-wally',
  'peeling',
  'peerless',
  'peeved',
  'peevish',
  'peewee',
  'peg-top',
  'pegmatitic',
  'pejorative',
  'pelagic',
  'pelitic',
  'pell-mell',
  'pellicular',
  'pellucid',
  'peloric',
  'peltate',
  'pelting',
  'pelvic',
  'pemphigous',
  'penal',
  'penalized',
  'penannular',
  'pencilled',
  'pendant',
  'pendent',
  'pending',
  'pendulous',
  'penetrable',
  'penetralian',
  'penetrant',
  'penetrating',
  'penetrative',
  'penial',
  'penicillate',
  'penile',
  'peninsular',
  'penitent',
  'penitential',
  'penitentiary',
  'pennate',
  'penned',
  'pennied',
  'penniless',
  'pennoned',
  'penny',
  'penny-a-line',
  'penny-pincher',
  'penny-pinching',
  'penny-plain',
  'penny-wise',
  'penological',
  'pensile',
  'pensionable',
  'pensionary',
  'pensive',
  'pent',
  'pent-up',
  'pentadactyl',
  'pentagonal',
  'pentamerous',
  'pentameter',
  'pentangular',
  'pentastyle',
  'pentasyllabic',
  'pentatomic',
  'pentatonic',
  'pentavalent',
  'pentomic',
  'penultimate',
  'penurious',
  'peopled',
  'pepper-and-salt',
  'peppercorny',
  'peppery',
  'peppier',
  'peppiest',
  'peppy',
  'peptic',
  'peptizing',
  'perambulating',
  'perambulatory',
  'perceivable',
  'perceived',
  'percent',
  'percental',
  'perceptible',
  'perceptional',
  'perceptive',
  'perceptual',
  'perched',
  'perchloric',
  'percipient',
  'percoid',
  'percurrent',
  'percussional',
  'percussive',
  'percutaneous',
  'perdu',
  'perdurable',
  'peregrinate',
  'peregrine',
  'peremptory',
  'perennial',
  'perfect',
  'perfected',
  'perfectible',
  'perfectionist',
  'perfectionistic',
  'perfective',
  'perfervid',
  'perfidious',
  'perfoliate',
  'perforable',
  'perforate',
  'perforated',
  'perforative',
  'performable',
  'performative',
  'performing',
  'perfumed',
  'perfumeless',
  'perfumy',
  'perfunctory',
  'perfusive',
  'pericardiac',
  'pericardial',
  'pericarpial',
  'pericentral',
  'pericentric',
  'perichaetial',
  'periclinal',
  'pericranial',
  'peridermal',
  'peridial',
  'peridotic',
  'perigeal',
  'perigean',
  'periglacial',
  'perigonial',
  'perigynous',
  'perilous',
  'perimorphic',
  'perimorphous',
  'perinatal',
  'perineal',
  'perinephric',
  'periodic',
  'periodical',
  'periodontal',
  'periosteal',
  'periostitic',
  'periotic',
  'peripatetic',
  'peripheral',
  'periphrastic',
  'peripteral',
  'periscopic',
  'perishable',
  'perished',
  'perishing',
  'perispomenon',
  'perissodactyl',
  'perissodactylous',
  'peristaltic',
  'peristomal',
  'peristomatic',
  'peristomial',
  'peristylar',
  'peritectic',
  'perithecial',
  'peritoneal',
  'peritonitic',
  'periwigged',
  'perjured',
  'perk',
  'perked',
  'perkier',
  'perkiest',
  'perky',
  'perlitic',
  'permanent',
  'permanganic',
  'permeable',
  'permeated',
  'permeating',
  'permeative',
  'permed',
  'permissible',
  'permissive',
  'permitted',
  'permutable',
  'pernicious',
  'pernickety',
  'peroneal',
  'perpendicular',
  'perpetuable',
  'perpetual',
  'perplexed',
  'perplexing',
  'perse',
  'persecuted',
  'perseverant',
  'persevering',
  'persistent',
  'persisting',
  'persnickety',
  'person-to-person',
  'personable',
  'personal',
  'personalism',
  'personalistic',
  'personalized',
  'personate',
  'personative',
  'persons',
  'perspectival',
  'perspectivist',
  'perspicacious',
  'perspicuous',
  'perspirable',
  'perspiratory',
  'perspiring',
  'persuadable',
  'persuasible',
  'persuasive',
  'pert',
  'perthitic',
  'pertinacious',
  'pertinent',
  'perturbable',
  'perturbational',
  'perturbed',
  'perturbing',
  'pertussal',
  'peruked',
  'pervading',
  'pervasive',
  'perverse',
  'perversive',
  'perverted',
  'pervertible',
  'pervious',
  'peskier',
  'peskiest',
  'pesky',
  'pessimal',
  'pessimistic',
  'pessimum',
  'pestered',
  'pestering',
  'pesticidal',
  'pestiferous',
  'pestilent',
  'pestilential',
  'pet',
  'petaliferous',
  'petaline',
  'petalled',
  'petaloid',
  'petalous',
  'petechial',
  'petiolar',
  'petiolate',
  'petit',
  'petite',
  'petitionary',
  'petitory',
  'petrified',
  'petrifying',
  'petrochemical',
  'petrogenetic',
  'petrographic',
  'petrographical',
  'petroleous',
  'petrolic',
  'petrological',
  'petrosal',
  'petrous',
  'petticoated',
  'pettier',
  'pettiest',
  'pettifogging',
  'pettish',
  'petty',
  'petulant',
  'pewter',
  'phagedaenic',
  'phagedenic',
  'phagocytic',
  'phalangeal',
  'phalansterian',
  'phallic',
  'phanerogamic',
  'phanerogamous',
  'phantasmagorial',
  'phantasmagoric',
  'phantasmagorical',
  'phantasmal',
  'phantom',
  'pharaonic',
  'pharisaic',
  'pharisaical',
  'pharmaceutic',
  'pharmaceutical',
  'pharmacognostic',
  'pharmacological',
  'pharmacopoeial',
  'pharyngeal',
  'phaseless',
  'phasic',
  'phasmid',
  'phatic',
  'phellogenetic',
  'phenetic',
  'phenolic',
  'phenological',
  'phenomenal',
  'phenomenalism',
  'phenomenalistic',
  'phenomenize',
  'phenotypic',
  'phenotypical',
  'philanthropic',
  'philatelic',
  'philharmonic',
  'philhellene',
  'philhellenic',
  'philippine',
  'philistine',
  'philologic',
  'philological',
  'philoprogenitive',
  'philosophic',
  'philosophical',
  'phlegmatic',
  'phlegmatical',
  'phlegmier',
  'phlegmiest',
  'phlegmy',
  'phlogistic',
  'phobic',
  'phocine',
  'phonal',
  'phonatory',
  'phonematic',
  'phonemic',
  'phonetic',
  'phoney',
  'phonic',
  'phonier',
  'phonies',
  'phoniest',
  'phonographic',
  'phonolitic',
  'phonological',
  'phonotypic',
  'phonotypical',
  'phony',
  'phosphorescent',
  'phosphoric',
  'phosphorous',
  'photic',
  'photoactive',
  'photochemical',
  'photochemistry',
  'photoconductive',
  'photoelastic',
  'photoelectric',
  'photogenic',
  'photographic',
  'photolithographic',
  'photoluminescent',
  'photolytic',
  'photomechanical',
  'photometric',
  'photoperiodic',
  'photophilous',
  'photopic',
  'photosensitive',
  'photospheric',
  'photostatic',
  'photosynthetic',
  'phototactic',
  'phototropic',
  'phototypic',
  'photovoltaic',
  'phrasal',
  'phraseologic',
  'phraseological',
  'phreatic',
  'phreatophytic',
  'phrenetic',
  'phrenic',
  'phrenitic',
  'phrenologic',
  'phrenological',
  'phthalic',
  'phthisic',
  'phthisical',
  'phycological',
  'phylacteric',
  'phylacterical',
  'phyletic',
  'phylloid',
  'phyllopod',
  'phyllotactic',
  'phyllotactical',
  'phylogenetic',
  'physic',
  'physical',
  'physicalism',
  'physicochemical',
  'physiocratic',
  'physiognomic',
  'physiologic',
  'physiological',
  'physiotherapeutic',
  'phytogenic',
  'phytogeographic',
  'phytographic',
  'phytological',
  'phytophagic',
  'phytophagous',
  'phytotoxic',
  'piacular',
  'pianissimo',
  'pianistic',
  'piano',
  'piazzian',
  'picaresque',
  'picayune',
  'piceous',
  'picked',
  'pickier',
  'pickiest',
  'pickled',
  'picky',
  'picric',
  'pictographic',
  'pictorial',
  'pictural',
  'pictured',
  'picturesque',
  'piddling',
  'pie-eyed',
  'piebald',
  'piecemeal',
  'pied',
  'pierceable',
  'pierced',
  'piercing',
  'pieridine',
  'pietistic',
  'pietistical',
  'piezoelectric',
  'piffling',
  'pigeon-breasted',
  'pigeon-hearted',
  'pigeon-toed',
  'piggie',
  'piggish',
  'piggy',
  'pigheaded',
  'pileate',
  'pileated',
  'piled',
  'pileous',
  'pilgarlicky',
  'piliferous',
  'piliform',
  'pillaged',
  'pillar-box',
  'pillared',
  'pillowy',
  'pilose',
  'pilot',
  'pilotless',
  'pilous',
  'pimpled',
  'pimply',
  'pinacoidal',
  'pinchbeck',
  'pinched',
  'pinchpenny',
  'pineal',
  'piney',
  'pinguid',
  'pinier',
  'piniest',
  'pinioned',
  'pink',
  'pinkish',
  'pinnate',
  'pinnated',
  'pinnatifid',
  'pinnatipartite',
  'pinnatiped',
  'pinnatisect',
  'pinned',
  'pinniped',
  'pinnulate',
  'pinpoint',
  'pint-sized',
  'pinto',
  'piny',
  'pious',
  'pipeless',
  'pipelike',
  'piperaceous',
  'pipier',
  'pipiest',
  'piping',
  'pipy',
  'piquant',
  'pique',
  'piratic',
  'piratical',
  'piscatorial',
  'piscatory',
  'piscicultural',
  'pisciculture',
  'pisciform',
  'piscine',
  'piscivorous',
  'pisiform',
  'pisolitic',
  'pissed',
  'pistachio',
  'pistillate',
  'pitch-black',
  'pitch-dark',
  'pitched',
  'pitchier',
  'pitchiest',
  'pitchy',
  'piteous',
  'pithecoid',
  'pithy',
  'pitiable',
  'pitiful',
  'pitiless',
  'pitted',
  'pituitary',
  'pitying',
  'pivotal',
  'pixilated',
  'pizzicato',
  'placable',
  'placating',
  'placatory',
  'placed',
  'placeless',
  'placental',
  'placid',
  'placoid',
  'plagal',
  'plagiarized',
  'plagued',
  'plaguey',
  'plaguy',
  'plaided',
  'plain',
  'plain-spoken',
  'plaintive',
  'planar',
  'plane',
  'planet-struck',
  'planetary',
  'planetoidal',
  'plangent',
  'planimetric',
  'planimetrical',
  'planktonic',
  'planless',
  'planned',
  'plano-concave',
  'plano-convex',
  'plantable',
  'plantar',
  'planted',
  'plantigrade',
  'plantless',
  'planular',
  'plashier',
  'plashiest',
  'plashy',
  'plasmodial',
  'plastered',
  'plastery',
  'plastic',
  'plastics',
  'plated',
  'platelike',
  'platier',
  'platiest',
  'platinic',
  'platiniferous',
  'platinoid',
  'platinous',
  'platitudinous',
  'platonic',
  'platy',
  'platycephalic',
  'platyrrhine',
  'platyrrhinian',
  'plausible',
  'plausive',
  'play',
  'playable',
  'played',
  'played-out',
  'playful',
  'playing',
  'pleadable',
  'pleading',
  'pleasant',
  'pleased',
  'pleasing',
  'pleasurable',
  'pleasureful',
  'pleasureless',
  'pleated',
  'plebby',
  'plebeian',
  'plectognathic',
  'plectognathous',
  'pledgeable',
  'pledged',
  'plein-air',
  'pleiomerous',
  'plenary',
  'plenipotent',
  'plenipotentiary',
  'plenteous',
  'plentiful',
  'pleochroic',
  'pleomorphic',
  'pleonastic',
  'plethoric',
  'pleural',
  'pleuritic',
  'pleurodont',
  'plexiform',
  'pleximetric',
  'pliable',
  'pliant',
  'plical',
  'plicate',
  'plodding',
  'plosive',
  'plotful',
  'plotless',
  'plotted',
  'ploughed',
  'plucked',
  'pluckier',
  'pluckiest',
  'plucky',
  'plug-ugly',
  'plum',
  'plumaged',
  'plumate',
  'plumb',
  'plumbaginaceous',
  'plumbaginous',
  'plumbed',
  'plumbeous',
  'plumbic',
  'plumbiferous',
  'plumbless',
  'plumbous',
  'plumed',
  'plumier',
  'plumiest',
  'plummier',
  'plummiest',
  'plummy',
  'plumose',
  'plump',
  'plumping',
  'plumular',
  'plumulose',
  'plumy',
  'plundered',
  'plundering',
  'pluperfect',
  'plural',
  'pluralism',
  'pluralistic',
  'pluriliteral',
  'plus',
  'plush',
  'plusher',
  'plushest',
  'plushy',
  'pluteal',
  'plutocratic',
  'plutonic',
  'pluvial',
  'pluviometric',
  'pluviometrical',
  'pluvious',
  'pneumatic',
  'pneumatological',
  'pneumogastric',
  'pneumonic',
  'po-faced',
  'poaceous',
  'poached',
  'poachier',
  'poachiest',
  'poachy',
  'pocked',
  'pockier',
  'pockiest',
  'pockmarked',
  'pocky',
  'poco',
  'pococurante',
  'poculiform',
  'podgier',
  'podgiest',
  'podgy',
  'podsolic',
  'poetic',
  'poetical',
  'poignant',
  'poikilitic',
  'poikilothermic',
  'point-blank',
  'point-device',
  'point-of-sale',
  'pointed',
  'pointillism',
  'pointing',
  'pointless',
  'poised',
  'poison-pen',
  'poisonous',
  'poker-faced',
  'pokey',
  'pokier',
  'pokies',
  'pokiest',
  'poky',
  'polar',
  'polemic',
  'polemical',
  'polemoniaceous',
  'polish',
  'polished',
  'polite',
  'politic',
  'political',
  'polled',
  'pollened',
  'pollinic',
  'polliniferous',
  'polluted',
  'polo-neck',
  'poltroon',
  'polyacid',
  'polyadelphous',
  'polyandrous',
  'polyatomic',
  'polybasic',
  'polycarpic',
  'polycarpous',
  'polychaete',
  'polychromatic',
  'polychrome',
  'polychromic',
  'polyconic',
  'polycrystalline',
  'polycyclic',
  'polydactyl',
  'polygalaceous',
  'polygamous',
  'polygenist',
  'polyglot',
  'polygonaceous',
  'polygonal',
  'polygraphic',
  'polygynous',
  'polyhedral',
  'polyhistoric',
  'polyhydric',
  'polyhydroxy',
  'polymeric',
  'polymerous',
  'polymorphic',
  'polymorphous',
  'polynomial',
  'polyonymous',
  'polypetalous',
  'polyphase',
  'polyphonic',
  'polyphyletic',
  'polyphyodont',
  'polyploid',
  'polypod',
  'polypoid',
  'polypous',
  'polysepalous',
  'polysyllabic',
  'polysynthetic',
  'polytechnic',
  'polytheistic',
  'polytheistical',
  'polytonal',
  'polytypic',
  'polyunsaturated',
  'polyvalent',
  'polyzoan',
  'polyzoarial',
  'polyzoic',
  'pomaceous',
  'pomaded',
  'pomiferous',
  'pomological',
  'pompous',
  'ponceau',
  'ponderable',
  'pondering',
  'ponderous',
  'pongid',
  'pontific',
  'pontifical',
  'poor',
  'poor-spirited',
  'poorest',
  'poorly',
  'pop',
  'pop-up',
  'popish',
  'popliteal',
  'poppied',
  'pops',
  'popular',
  'populated',
  'populist',
  'populous',
  'porcine',
  'poriferous',
  'porkier',
  'porkiest',
  'porky',
  'pornographic',
  'poromeric',
  'porose',
  'porous',
  'porphyritic',
  'porrect',
  'port',
  'portable',
  'portative',
  'portentous',
  'porticoed',
  'portionless',
  'portlier',
  'portliest',
  'portly',
  'portrayed',
  'posh',
  'positional',
  'positioning',
  'positive',
  'positivism',
  'positivist',
  'positivistic',
  'posological',
  'possessed',
  'possessive',
  'possessory',
  'possible',
  'post',
  'post-Tertiary',
  'post-bellum',
  'post-free',
  'post-mortem',
  'post-obit',
  'postal',
  'postconsonantal',
  'posterior',
  'postern',
  'posthumous',
  'postiche',
  'posticous',
  'postmenopausal',
  'postmenstrual',
  'postoral',
  'postponed',
  'postpositional',
  'postpositive',
  'posttraumatic',
  'postulational',
  'postural',
  'postvocalic',
  'pot-bound',
  'pot-valiant',
  'potable',
  'potamic',
  'potassic',
  'potatory',
  'potent',
  'potential',
  'potentiometric',
  'potted',
  'pottier',
  'pottiest',
  'potty',
  'pouched',
  'pound-foolish',
  'pourable',
  'pouring',
  'poverty-stricken',
  'powder-puff',
  'powdered',
  'powdery',
  'power',
  'power-assisted',
  'powered',
  'powerful',
  'powerless',
  'pozzolanic',
  'practic',
  'practicable',
  'practical',
  'practiced',
  'practicing',
  'practised',
  'praedial',
  'praetorial',
  'praetorian',
  'pragmatic',
  'pragmatical',
  'pragmatism',
  'pragmatist',
  'praiseful',
  'praiseworthy',
  'praising',
  'prandial',
  'prankish',
  'prattling',
  'prayerful',
  'prayerless',
  'pre',
  'pre-Columbian',
  'pre-eminent',
  'pre-emptive',
  'pre-exilian',
  'pre-existent',
  'preachier',
  'preachiest',
  'preachy',
  'prearranged',
  'prebendal',
  'preborn',
  'precancerous',
  'precarious',
  'precast',
  'precative',
  'precatory',
  'precautional',
  'precautionary',
  'precautious',
  'precedent',
  'precedented',
  'precedential',
  'preceding',
  'preceptive',
  'precessional',
  'precious',
  'precipiced',
  'precipitant',
  'precipitate',
  'precipitating',
  'precipitative',
  'precipitous',
  'precise',
  'precisive',
  'preclassical',
  'preclinical',
  'preclusive',
  'precocial',
  'precocious',
  'precognitive',
  'precognizant',
  'preconceived',
  'preconcerted',
  'preconditioned',
  'preconscious',
  'preconsonantal',
  'precooked',
  'precooled',
  'precordial',
  'precritical',
  'precursory',
  'predaceous',
  'predacious',
  'predatory',
  'predestinarian',
  'predestinate',
  'predestined',
  'predeterminate',
  'predetermined',
  'predial',
  'predicable',
  'predicant',
  'predicate',
  'predicative',
  'predicatory',
  'predictable',
  'predicted',
  'predictive',
  'predigested',
  'predisposed',
  'predispositional',
  'predominant',
  'predominate',
  'prefab',
  'prefabricated',
  'prefatorial',
  'prefatory',
  'prefectorial',
  'prefectural',
  'preferable',
  'preferential',
  'preferred',
  'prefigurative',
  'prefrontal',
  'preggers',
  'pregnable',
  'pregnant',
  'prehensible',
  'prehensile',
  'prehistoric',
  'prehistorical',
  'prehuman',
  'prejudiced',
  'prejudicial',
  'prelatic',
  'preliminary',
  'prelingual',
  'preliterate',
  'preludial',
  'preludious',
  'premandibular',
  'premarital',
  'premature',
  'premaxillary',
  'premed',
  'premedical',
  'premeditated',
  'premeditative',
  'premenstrual',
  'premier',
  'premiere',
  'premillennial',
  'premium',
  'premolar',
  'premonitory',
  'premorse',
  'premosaic',
  'prenasal',
  'prenatal',
  'prenominate',
  'prenuptial',
  'preoccupied',
  'preocular',
  'preoral',
  'prepacked',
  'prepaid',
  'preparative',
  'preparatory',
  'prepared',
  'prepense',
  'preponderant',
  'preponderating',
  'prepositional',
  'prepositive',
  'prepossessing',
  'preposterous',
  'prepotent',
  'prepubertal',
  'prepubescent',
  'prepunctual',
  'preputial',
  'prerecorded',
  'prerequisite',
  'prerogative',
  'presageful',
  'presbyopic',
  'presbyteral',
  'presbyterial',
  'preschool',
  'prescient',
  'prescientific',
  'prescribed',
  'prescript',
  'prescriptible',
  'prescription',
  'prescriptive',
  'present',
  'present-day',
  'presentable',
  'presentational',
  'presentationism',
  'presentationist',
  'presentative',
  'presented',
  'presentient',
  'presentimental',
  'presentive',
  'preservable',
  'preservative',
  'preserved',
  'preserving',
  'preset',
  'presidential',
  'presidial',
  'presidiary',
  'pressed',
  'pressing',
  'pressor',
  'pressurized',
  'prest',
  'prestigious',
  'prestissimo',
  'presto',
  'presumable',
  'presumed',
  'presumptive',
  'presumptuous',
  'pretenceless',
  'pretend',
  'pretended',
  'pretentious',
  'preterhuman',
  'preterist',
  'preterit',
  'preterite',
  'preteritive',
  'preternatural',
  'prettier',
  'prettiest',
  'pretty',
  'pretty-pretty',
  'prettyish',
  'prevailing',
  'prevalent',
  'prevenient',
  'preventable',
  'preventative',
  'preventive',
  'preverbal',
  'previous',
  'previsional',
  'prewar',
  'priapic',
  'priced',
  'priceless',
  'pricey',
  'pricklier',
  'prickliest',
  'prickling',
  'prickly',
  'pricy',
  'prideful',
  'prideless',
  'priest-ridden',
  'priestlier',
  'priestliest',
  'priestly',
  'priggish',
  'prim',
  'prima',
  'primaeval',
  'primal',
  'primary',
  'primatal',
  'primate',
  'primatial',
  'prime',
  'primed',
  'primeval',
  'primigenial',
  'primitive',
  'primitivism',
  'primogenial',
  'primogenital',
  'primogenitary',
  'primordial',
  'primrose',
  'primsie',
  'primulaceous',
  'princelier',
  'princeliest',
  'princely',
  'principal',
  'principled',
  'print',
  'printable',
  'printed',
  'printless',
  'prior',
  'prismatic',
  'prissy',
  'pristine',
  'private',
  'privative',
  'privies',
  'privileged',
  'privy',
  'prize',
  'pro',
  'pro-am',
  'probabilism',
  'probabilistic',
  'probable',
  'probationary',
  'probative',
  'probeable',
  'probing',
  'problematic',
  'problematical',
  'proboscidean',
  'procaryotic',
  'procedural',
  'proceleusmatic',
  'procephalic',
  'processed',
  'processional',
  'proclaimed',
  'proclitic',
  'proconsular',
  'procrastinative',
  'procrastinatory',
  'procreative',
  'procryptic',
  'proctodaeal',
  'procumbent',
  'procurable',
  'procuratorial',
  'prodigal',
  'prodigious',
  'prodromal',
  'produced',
  'productile',
  'productional',
  'productive',
  'proemial',
  'profanatory',
  'profane',
  'profaned',
  'professed',
  'professional',
  'professionalism',
  'professorial',
  'proficient',
  'profitable',
  'profitless',
  'profligate',
  'profluent',
  'profound',
  'profuse',
  'progenitive',
  'progenitorial',
  'prognathic',
  'prognathous',
  'prognostic',
  'prognosticative',
  'programmable',
  'programmatic',
  'progressional',
  'progressive',
  'prohibited',
  'prohibitionary',
  'prohibitive',
  'prohibitory',
  'projected',
  'projectile',
  'projecting',
  'projectional',
  'projective',
  'prokaryotic',
  'prolate',
  'proleptic',
  'proleptical',
  'proletarian',
  'proletary',
  'proliferative',
  'proliferous',
  'prolific',
  'prolificacy',
  'prolix',
  'prolonged',
  'prolusory',
  'prominent',
  'promiscuous',
  'promised',
  'promiseful',
  'promising',
  'promissory',
  'promotional',
  'promotive',
  'prompt',
  'promulgated',
  'prone',
  'pronephric',
  'pronged',
  'pronominal',
  'pronounceable',
  'pronounced',
  'proof',
  'proofed',
  'propaedeutic',
  'propagable',
  'propaganda',
  'propagandist',
  'propagandistic',
  'propagative',
  'proparoxytone',
  'propellant',
  'propellent',
  'propelling',
  'proper',
  'propertied',
  'prophetic',
  'prophetical',
  'prophylactic',
  'propitiable',
  'propitiative',
  'propitiatory',
  'propitious',
  'proportionable',
  'proportional',
  'proportionate',
  'proportioned',
  'proportionless',
  'proposable',
  'proposed',
  'propositional',
  'propraetorial',
  'propraetorian',
  'proprietary',
  'proprietorial',
  'proprioceptive',
  'propulsive',
  'propylic',
  'prosaic',
  'proscribed',
  'prosecutable',
  'prosenchymatous',
  'prosimian',
  'prosodic',
  'prosodical',
  'prospective',
  'prospering',
  'prosperous',
  'prostate',
  'prostatic',
  'prostomial',
  'prostrate',
  'prostyle',
  'prosy',
  'protandrous',
  'protanomalous',
  'protanopic',
  'protean',
  'protected',
  'protecting',
  'protective',
  'protectoral',
  'protectorless',
  'proteiform',
  'proteinaceous',
  'proteinic',
  'proteinous',
  'proteolytic',
  'proterandrous',
  'proterogynous',
  'proterozoic',
  'protestant',
  'protesting',
  'prothallium',
  'prothalloid',
  'prothetic',
  'prothoracic',
  'protistic',
  'proto',
  'protochordate',
  'protogynous',
  'protohuman',
  'protolithic',
  'protomorphic',
  'protonemal',
  'protonematal',
  'protonic',
  'protopathic',
  'protoplasmal',
  'protoplasmatic',
  'protoplasmic',
  'prototherian',
  'prototrophic',
  'prototypal',
  'prototypical',
  'protozoal',
  'protozoan',
  'protozoic',
  'protozoological',
  'protracted',
  'protractible',
  'protractile',
  'protractive',
  'protrudable',
  'protrudent',
  'protruding',
  'protrusible',
  'protrusile',
  'protrusive',
  'protuberant',
  'proud',
  'provable',
  'proved',
  'proven',
  'proverbial',
  'providable',
  'provident',
  'providential',
  'provincial',
  'provisional',
  'provisionary',
  'provisory',
  'provocative',
  'provoked',
  'provoking',
  'prowessed',
  'proximal',
  'proximate',
  'proximo',
  'proxy',
  'prudent',
  'prudential',
  'prudish',
  'pruinose',
  'pruned',
  'prunted',
  'prurient',
  'pruriginous',
  'pruritic',
  'prying',
  'psammophytic',
  'psephological',
  'pseud',
  'pseudo',
  'pseudo-Gothic',
  'pseudocarp',
  'pseudocubic',
  'pseudohexagonal',
  'pseudonymous',
  'psilanthropic',
  'psilotic',
  'psittacine',
  'psoriatic',
  'psychedelic',
  'psychiatric',
  'psychiatrical',
  'psychic',
  'psychical',
  'psycho',
  'psychoactive',
  'psychoanalytic',
  'psychoanalytical',
  'psychobiological',
  'psychochemical',
  'psychodelic',
  'psychogenetic',
  'psychogenic',
  'psychographic',
  'psychokinetic',
  'psycholinguistic',
  'psychological',
  'psychologist',
  'psychometric',
  'psychometrical',
  'psychomotor',
  'psychoneurotic',
  'psychopathic',
  'psychosexual',
  'psychosocial',
  'psychosomatic',
  'psychotic',
  'psychotomimetic',
  'psychotropic',
  'psychrometric',
  'psychrometrical',
  'psychrophilic',
  'pterygial',
  'pterygoid',
  'pterylographic',
  'pterylographical',
  'puberulent',
  'pubescent',
  'pubic',
  'public',
  'public-spirited',
  'publicized',
  'publishable',
  'published',
  'pucka',
  'puckered',
  'puckery',
  'puckish',
  'puddly',
  'pudendal',
  'pudgy',
  'puerile',
  'puerperal',
  'puff',
  'puffed',
  'puffier',
  'puffiest',
  'puffing',
  'puffy',
  'pug-nose',
  'pug-nosed',
  'puggish',
  'puggy',
  'pugilistic',
  'pugilistical',
  'pugnacious',
  'puir',
  'puisne',
  'puissant',
  'pukka',
  'pulchritudinous',
  'puling',
  'pull-in',
  'pulled',
  'pulmonary',
  'pulmonate',
  'pulmonic',
  'pulpier',
  'pulpiest',
  'pulpy',
  'pulsatile',
  'pulsating',
  'pulsatory',
  'pulsed',
  'pulseless',
  'pulsing',
  'pulverable',
  'pulverisable',
  'pulverizable',
  'pulverized',
  'pulverulent',
  'pulvinate',
  'pump-action',
  'punch-drunk',
  'punchy',
  'punctate',
  'punctilious',
  'punctual',
  'punctuative',
  'punctured',
  'pungent',
  'punier',
  'puniest',
  'punishable',
  'punished',
  'punishing',
  'punitive',
  'punitory',
  'punk',
  'punkah',
  'puny',
  'pupal',
  'pupillary',
  'pupiparous',
  'puppyish',
  'purblind',
  'purchasable',
  'pure',
  'purer',
  'purest',
  'purgative',
  'purgatorial',
  'purging',
  'purified',
  'purifying',
  'puristic',
  'puristical',
  'puritan',
  'puritanic',
  'puritanical',
  'purloined',
  'purple',
  'purplish',
  'purported',
  'purpose-built',
  'purposeful',
  'purposeless',
  'purposive',
  'purpure',
  'purpuric',
  'purse-proud',
  'pursier',
  'pursiest',
  'pursuable',
  'pursuant',
  'pursued',
  'pursuing',
  'pursy',
  'purulent',
  'push-button',
  'pushed',
  'pushful',
  'pushier',
  'pushiest',
  'pushing',
  'pushy',
  'pusillanimous',
  'pussy',
  'pustulant',
  'pustular',
  'pustulate',
  'pustulous',
  'put-on',
  'put-up',
  'putative',
  'putrefacient',
  'putrefactive',
  'putrefiable',
  'putrefied',
  'putrescent',
  'putrescible',
  'putrid',
  'puzzled',
  'puzzling',
  'pyaemic',
  'pycnostyle',
  'pyelitic',
  'pyelonephritic',
  'pygmoid',
  'pyknic',
  'pyloric',
  'pyogenic',
  'pyoid',
  'pyorrhoeal',
  'pyorrhoeic',
  'pyralid',
  'pyramidal',
  'pyramidical',
  'pyretic',
  'pyrheliometric',
  'pyriform',
  'pyritic',
  'pyrochemical',
  'pyroclastic',
  'pyrogallic',
  'pyrogenic',
  'pyrogenous',
  'pyroligneous',
  'pyrolytic',
  'pyromaniacal',
  'pyromantic',
  'pyrophoric',
  'pyrotechnic',
  'pyrotechnical',
  'pyrotechnics',
  'pyroxenic',
  'pyrrhic',
  'pythogenic',
  'quack',
  'quadragenarian',
  'quadrangular',
  'quadrantal',
  'quadraphonic',
  'quadrate',
  'quadratic',
  'quadrennial',
  'quadric',
  'quadricentennial',
  'quadricipital',
  'quadrifid',
  'quadrilateral',
  'quadrilingual',
  'quadripartite',
  'quadrivalent',
  'quadrivial',
  'quadrophonics',
  'quadrumanous',
  'quadruped',
  'quadrupedal',
  'quadruple',
  'quadruplex',
  'quadruplicate',
  'quadruplication',
  'quaggier',
  'quaggiest',
  'quaggy',
  'quaint',
  'quakier',
  'quakiest',
  'quaking',
  'quaky',
  'qualifiable',
  'qualificatory',
  'qualified',
  'qualifying',
  'qualitative',
  'quality',
  'qualmish',
  'quantal',
  'quantifiable',
  'quantitative',
  'quaquaversal',
  'quarantined',
  'quare',
  'quarrelsome',
  'quarriable',
  'quartan',
  'quarter',
  'quarter-bound',
  'quarter-hour',
  'quartered',
  'quarterly',
  'quartic',
  'quartile',
  'quartziferous',
  'quartzitic',
  'quasi',
  'quaternary',
  'quaternate',
  'quavering',
  'quavery',
  'queasier',
  'queasiest',
  'queasy',
  'queen-size',
  'queenless',
  'queenlier',
  'queenliest',
  'queenly',
  'queer',
  'quelled',
  'quenchable',
  'quenched',
  'quenchless',
  'querulous',
  'questionable',
  'questioning',
  'questionless',
  'quibbling',
  'quick',
  'quick-change',
  'quick-fire',
  'quick-frozen',
  'quick-sighted',
  'quick-tempered',
  'quick-witted',
  'quickset',
  'quicksilver',
  'quicksilvery',
  'quiescent',
  'quiet',
  'quietening',
  'quietism',
  'quietist',
  'quietistic',
  'quilted',
  'quinary',
  'quinate',
  'quincentenary',
  'quincentennial',
  'quincuncial',
  'quinoid',
  'quinoidal',
  'quinonoid',
  'quinquagenarian',
  'quinquefoliate',
  'quinquennial',
  'quinquevalent',
  'quinsied',
  'quintan',
  'quintessential',
  'quintic',
  'quintillionth',
  'quintuple',
  'quintuplicate',
  'quippish',
  'quirky',
  'quit',
  'quits',
  'quivering',
  'quivery',
  'quixotic',
  'quizzical',
  'quodlibetic',
  'quodlibetical',
  'quondam',
  'quotable',
  'quotidian',
  'rabbinic',
  'rabbinical',
  'rabbinism',
  'rabble-rousing',
  'rabic',
  'rabid',
  'racemed',
  'racemic',
  'racemose',
  'rachidial',
  'rachidian',
  'rachitic',
  'racial',
  'racialism',
  'racialistic',
  'racier',
  'raciest',
  'racing',
  'racist',
  'rack-and-pinion',
  'rackety',
  'racking',
  'racy',
  'raddled',
  'radial',
  'radial-ply',
  'radiant',
  'radiate',
  'radiating',
  'radiative',
  'radical',
  'radicant',
  'radiculose',
  'radio',
  'radio-controlled',
  'radioactive',
  'radiogenic',
  'radiographic',
  'radiological',
  'radiometric',
  'radiopaque',
  'radiophonic',
  'radiosensitive',
  'radiotelegraphy',
  'radiotoxic',
  'radular',
  'raffish',
  'raftered',
  'ragged',
  'raggedy',
  'raggle-taggle',
  'raging',
  'raglan',
  'raiding',
  'rainbowy',
  'rainier',
  'rainiest',
  'raining',
  'rainless',
  'rainproof',
  'rainy',
  'raisable',
  'raiseable',
  'raised',
  'raising',
  'raked',
  'rakehell',
  'rakish',
  'rallentando',
  'ralline',
  'rallying',
  'ramal',
  'rambling',
  'rambunctious',
  'ramiform',
  'rammish',
  'ramose',
  'rampageous',
  'rampant',
  'ramshackle',
  'ramstam',
  'ramulose',
  'rancid',
  'rancorous',
  'randie',
  'random',
  'randomized',
  'randy',
  'ranged',
  'rangier',
  'rangiest',
  'ranging',
  'rangy',
  'rank',
  'ranked',
  'ranking',
  'ransacked',
  'ransomed',
  'ranunculaceous',
  'rapacious',
  'raped',
  'rapid',
  'rapid-fire',
  'raploch',
  'rapt',
  'raptorial',
  'raptureless',
  'rapturous',
  'rare',
  'rarefactive',
  'rarefiable',
  'rarefied',
  'rarer',
  'rarest',
  'raring',
  'rascal',
  'rascally',
  'rash',
  'rasorial',
  'raspier',
  'raspiest',
  'rasping',
  'raspy',
  'ratable',
  'rateable',
  'rath',
  'rathe',
  'ratified',
  'ratiocinative',
  'rational',
  'rationalist',
  'rationalistic',
  'rationed',
  'ratite',
  'ratlike',
  'rattier',
  'rattiest',
  'rattish',
  'rattled',
  'rattling',
  'rattly',
  'ratty',
  'raucous',
  'raunchy',
  'ravaged',
  'ravaging',
  'ravening',
  'ravenous',
  'raving',
  'ravishing',
  'raw',
  'rawboned',
  'rawish',
  'rayless',
  'razed',
  'razor-sharp',
  're-entrant',
  'reachable',
  'reactionary',
  'reactionist',
  'reactive',
  'read',
  'readable',
  'readier',
  'readiest',
  'ready',
  'ready-made',
  'ready-to-wear',
  'ready-witted',
  'readying',
  'real',
  'real-time',
  'realisable',
  'realistic',
  'realizable',
  'realized',
  'realizing',
  'reanimated',
  'rear',
  'rearing',
  'rearmost',
  'rearward',
  'reasonable',
  'reasoned',
  'reasoning',
  'reasonless',
  'reassured',
  'reassuring',
  'rebarbative',
  'rebel',
  'rebelling',
  'rebellious',
  'reboant',
  'reborn',
  'rebuilt',
  'rebuked',
  'rebuttable',
  'recalcitrant',
  'recallable',
  'recapitulative',
  'recapitulatory',
  'receding',
  'receivable',
  'received',
  'recent',
  'receptive',
  'recessed',
  'recessional',
  'recessive',
  'rechargeable',
  'recidivism',
  'recipient',
  'reciprocal',
  'reciprocating',
  'reciprocative',
  'recitative',
  'reckless',
  'reclaimable',
  'reclaimed',
  'reclinable',
  'reclinate',
  'reclining',
  'recluse',
  'reclusive',
  'recognisable',
  'recognizable',
  'recognized',
  'recoilless',
  'recollected',
  'recollective',
  'recommendable',
  'recommendatory',
  'recommended',
  'reconcilable',
  'reconciled',
  'reconciliatory',
  'reconciling',
  'recondite',
  'reconditioned',
  'reconstituted',
  'reconstructed',
  'reconstructional',
  'reconstructionary',
  'reconstructive',
  'record',
  'record-breaking',
  'recordable',
  'recorded',
  'recoverable',
  'recovered',
  'recovering',
  'recreant',
  'recreational',
  'recreative',
  'recriminative',
  'recriminatory',
  'recrudescent',
  'rectal',
  'rectangular',
  'rectifiable',
  'rectified',
  'rectilineal',
  'rectilinear',
  'rectricial',
  'recumbent',
  'recuperative',
  'recurrent',
  'recursive',
  'recurved',
  'recusant',
  'recyclable',
  'red',
  'red-blooded',
  'red-faced',
  'red-figure',
  'red-headed',
  'red-hot',
  'red-letter',
  'red-light',
  'reddened',
  'redder',
  'reddest',
  'reddish',
  'redeemable',
  'redeemed',
  'redeeming',
  'redemptive',
  'redemptory',
  'redirect',
  'redistributed',
  'redivivus',
  'redolent',
  'redoubled',
  'redoubtable',
  'redoubted',
  'reduced',
  'reducible',
  'reductionist',
  'reductive',
  'redundant',
  'reduplicate',
  'reduplicative',
  'reduviid',
  'reedier',
  'reediest',
  'reedy',
  'reeking',
  'reeky',
  'reel-to-reel',
  'refer',
  'referable',
  'referenced',
  'referential',
  'refillable',
  'refined',
  'reflected',
  'reflecting',
  'reflective',
  'reflex',
  'reflexive',
  'refluent',
  'reformable',
  'reformative',
  'reformatory',
  'reformed',
  'reformism',
  'reformist',
  'refractable',
  'refractive',
  'refractory',
  'refrangible',
  'refreshed',
  'refreshful',
  'refreshing',
  'refrigerant',
  'refrigerated',
  'refrigerating',
  'refrigerative',
  'refrigeratory',
  'refringent',
  'refulgent',
  'refundable',
  'refusable',
  'refutable',
  'regainable',
  'regal',
  'regardable',
  'regardant',
  'regardful',
  'regarding',
  'regardless',
  'regenerable',
  'regenerate',
  'regenerating',
  'regenerative',
  'regent',
  'regicidal',
  'regimental',
  'regimented',
  'regional',
  'regionalism',
  'regionalist',
  'registered',
  'registrable',
  'regnal',
  'regnant',
  'regressing',
  'regressive',
  'regretful',
  'regrettable',
  'regular',
  'regularized',
  'regulated',
  'regulating',
  'regulation',
  'regulative',
  'regulatory',
  'reguline',
  'regurgitate',
  'rehabilitated',
  'rehabilitative',
  'reheated',
  'reigning',
  'reincarnate',
  'reincarnation',
  'reincorporate',
  'reinforced',
  'reinvigorated',
  'reissuable',
  'reiterant',
  'reiterative',
  'rejectable',
  'rejected',
  'rejective',
  'rejoiceful',
  'rejoicing',
  'related',
  'relational',
  'relationless',
  'relative',
  'relativism',
  'relativistic',
  'relaxant',
  'relaxative',
  'relaxed',
  'relaxer',
  'relaxing',
  'releasable',
  'released',
  'releasing',
  'relegable',
  'relentless',
  'relevant',
  'reliable',
  'reliant',
  'reliefless',
  'relievable',
  'relieved',
  'religionism',
  'religiose',
  'religious',
  'relinquished',
  'relishable',
  'relivable',
  'relocated',
  'relucent',
  'reluctant',
  'remaining',
  'remanent',
  'remarkable',
  'remediable',
  'remedial',
  'remediless',
  'remembered',
  'remigial',
  'remindful',
  'reminiscent',
  'remiss',
  'remissible',
  'remittent',
  'remnant',
  'remonstrant',
  'remonstrative',
  'remontant',
  'remorseful',
  'remorseless',
  'remote',
  'remote-controlled',
  'remoter',
  'remotest',
  'removable',
  'removed',
  'remunerable',
  'remunerated',
  'remunerative',
  'renal',
  'renascent',
  'renderable',
  'rending',
  'renegade',
  'renegotiable',
  'renewable',
  'renewed',
  'renewing',
  'reniform',
  'renitent',
  'renounceable',
  'renovated',
  'renowned',
  'rent',
  'rent-free',
  'rentable',
  'rental',
  'renunciative',
  'renunciatory',
  'reorganized',
  'reorient',
  'repairable',
  'repaired',
  'repand',
  'reparable',
  'reparative',
  'repayable',
  'repealable',
  'repeatable',
  'repeated',
  'repellant',
  'repellent',
  'repent',
  'repentant',
  'repetitious',
  'repetitive',
  'repining',
  'replaceable',
  'replete',
  'replicate',
  'reply-paid',
  'reportable',
  'reported',
  'reportorial',
  'reposeful',
  'reprehensible',
  'representable',
  'representational',
  'representationalism',
  'representative',
  'represented',
  'repressed',
  'repressible',
  'repressing',
  'repressive',
  'reprimanded',
  'reproachable',
  'reproachful',
  'reproachless',
  'reprobate',
  'reprobative',
  'reprocessed',
  'reproducible',
  'reproductive',
  'reproved',
  'reproving',
  'reptant',
  'reptile',
  'reptilian',
  'reptiloid',
  'republican',
  'repudiated',
  'repudiative',
  'repugnant',
  'repulsive',
  'repurchase',
  'reputable',
  'reputed',
  'requested',
  'required',
  'requisite',
  'requisitionary',
  'requitable',
  'requited',
  'resalable',
  'rescissory',
  'rescued',
  'resealable',
  'resemblant',
  'resentful',
  'reservable',
  'reserve',
  'reserved',
  'resettled',
  'resident',
  'residential',
  'residentiary',
  'residual',
  'residuary',
  'resigned',
  'resilient',
  'resinated',
  'resiniferous',
  'resinoid',
  'resinous',
  'resistant',
  'resistible',
  'resistive',
  'resistless',
  'resoluble',
  'resolute',
  'resolutive',
  'resolvable',
  'resolved',
  'resolvent',
  'resonant',
  'resonating',
  'resorbent',
  'resorptive',
  'resounding',
  'resourceful',
  'resourceless',
  'respectable',
  'respected',
  'respectful',
  'respective',
  'respirable',
  'respiratory',
  'resplendent',
  'respondent',
  'responseless',
  'responsible',
  'responsive',
  'rested',
  'restful',
  'restiform',
  'resting',
  'restitutive',
  'restitutory',
  'restive',
  'restless',
  'restorable',
  'restorationism',
  'restorative',
  'restored',
  'restrainable',
  'restrained',
  'restricted',
  'restricting',
  'restriction',
  'restrictive',
  'resultant',
  'resulting',
  'resumable',
  'resumptive',
  'resupinate',
  'resupine',
  'resurgent',
  'resurrectional',
  'resurrectionary',
  'resurrective',
  'resuscitable',
  'resuscitated',
  'resuscitative',
  'retail',
  'retained',
  'retaining',
  'retaliative',
  'retaliatory',
  'retardant',
  'retardative',
  'retarded',
  'retentive',
  'retial',
  'retiary',
  'reticent',
  'reticular',
  'reticulate',
  'reticulated',
  'retiform',
  'retinal',
  'retinoscopy',
  'retired',
  'retirement',
  'retiring',
  'retractable',
  'retracted',
  'retractile',
  'retral',
  'retreating',
  'retributive',
  'retributory',
  'retrievable',
  'retro-operative',
  'retroactive',
  'retrobulbar',
  'retrocessive',
  'retroflex',
  'retroflexed',
  'retrograde',
  'retrogressive',
  'retrolental',
  'retrorse',
  'retrospective',
  'returnable',
  'returning',
  'retuse',
  'reunionistic',
  'reusable',
  'revanchism',
  'revealable',
  'revealed',
  'revealing',
  'revelational',
  'revelative',
  'revelatory',
  'revengeful',
  'revengeless',
  'revenued',
  'reverable',
  'reverberant',
  'reverberating',
  'reverberative',
  'reverberatory',
  'revered',
  'reverenced',
  'reverend',
  'reverent',
  'reverential',
  'reverse',
  'reversed',
  'reversible',
  'reversionary',
  'revertible',
  'reverting',
  'revertive',
  'review',
  'reviewable',
  'reviled',
  'revised',
  'revisional',
  'revisionary',
  'revisionism',
  'revisionist',
  'revisory',
  'revitalized',
  'revitalizing',
  'revivable',
  'revivalist',
  'revivalistic',
  'revived',
  'reviving',
  'reviviscent',
  'revocable',
  'revokable',
  'revolting',
  'revolute',
  'revolutionary',
  'revolutionist',
  'revolved',
  'revolving',
  'revulsionary',
  'revulsive',
  'rewardable',
  'rewardful',
  'rewarding',
  'rhamnaceous',
  'rhapsodic',
  'rhematic',
  'rheologic',
  'rheological',
  'rheotropic',
  'rhetorical',
  'rheumatic',
  'rheumatoid',
  'rheumy',
  'rhinal',
  'rhinencephalic',
  'rhinocerotic',
  'rhinological',
  'rhinoplastic',
  'rhizocarpous',
  'rhizogenic',
  'rhizomatous',
  'rhizomorphous',
  'rhizophagous',
  'rhizopod',
  'rhodic',
  'rhombic',
  'rhombohedral',
  'rhomboid',
  'rhomboidal',
  'rhonchial',
  'rhotic',
  'rhymed',
  'rhymeless',
  'rhyming',
  'rhythmic',
  'rhythmical',
  'riant',
  'ribald',
  'ribbed',
  'ribbony',
  'ribless',
  'riblike',
  'rich',
  'rickettsial',
  'rickety',
  'rid',
  'ridable',
  'ridden',
  'riddled',
  'rident',
  'riderless',
  'ridged',
  'ridgier',
  'ridgiest',
  'ridgy',
  'ridiculous',
  'riding',
  'rife',
  'rifled',
  'riftless',
  'rigged',
  'right',
  'right-about',
  'right-angled',
  'right-down',
  'right-hand',
  'right-handed',
  'right-minded',
  'right-wing',
  'rightable',
  'righteous',
  'rightful',
  'rightish',
  'rightist',
  'rights',
  'rightward',
  'rigid',
  'rigorous',
  'riled',
  'rimed',
  'riming',
  'rimless',
  'rimmed',
  'rimose',
  'rimy',
  'rindless',
  'rindy',
  'ring-necked',
  'ring-tailed',
  'ringed',
  'ringent',
  'ringing',
  'ringless',
  'ringleted',
  'riotous',
  'rip-roaring',
  'riparian',
  'ripe',
  'ripened',
  'ripped',
  'ripping',
  'ripple',
  'rippled',
  'ripply',
  'risen',
  'risible',
  'rising',
  'riskier',
  'riskiest',
  'risky',
  'ritardando',
  'riteless',
  'ritenuto',
  'ritual',
  'ritualistic',
  'ritzier',
  'ritziest',
  'ritzy',
  'rival',
  'riven',
  'riverine',
  'riverless',
  'riverlike',
  'riveting',
  'road',
  'road-hoggish',
  'roadless',
  'roadworthy',
  'roan',
  'roaring',
  'roast',
  'roasted',
  'roasting',
  'robed',
  'roborant',
  'robust',
  'robustious',
  'rock-bottom',
  'rock-bound',
  'rock-ribbed',
  'rock-steady',
  'rockier',
  'rockiest',
  'rocky',
  'rococo',
  'rodded',
  'rodless',
  'rodlike',
  'rogatory',
  'roguish',
  'roiled',
  'roilier',
  'roiliest',
  'roiling',
  'roily',
  'roll-on',
  'roll-top',
  'rollable',
  'rolled',
  'rollicking',
  'rolling',
  'rollneck',
  'roly-poly',
  'roman',
  'romance',
  'romantic',
  'romanticist',
  'rompish',
  'roofed',
  'roofless',
  'rooky',
  'roomier',
  'roomiest',
  'roomy',
  'root',
  'rooted',
  'rootless',
  'rootlike',
  'ropable',
  'ropeable',
  'ropey',
  'ropier',
  'ropiest',
  'ropy',
  'rosaceous',
  'rose',
  'rose-cheeked',
  'rose-cut',
  'rose-red',
  'roseate',
  'roseless',
  'roselike',
  'rosiny',
  'rostral',
  'rostrate',
  'rosy',
  'rosy-cheeked',
  'rotary',
  'rotatable',
  'rotate',
  'rotated',
  'rotating',
  'rotational',
  'rotative',
  'rotatory',
  'rotiferal',
  'rotiferous',
  'rotted',
  'rotten',
  'rotting',
  'rotund',
  'rouged',
  'rough',
  'rough-and-ready',
  'rough-and-tumble',
  'rough-dry',
  'rough-spoken',
  'roughcast',
  'roughened',
  'roughish',
  'round',
  'round-arm',
  'round-backed',
  'round-eyed',
  'round-faced',
  'round-shouldered',
  'round-table',
  'round-the-clock',
  'round-trip',
  'roundabout',
  'rounded',
  'rounding',
  'roundish',
  'roupy',
  'rousing',
  'routed',
  'routine',
  'rove-over',
  'roving',
  'rowable',
  'rowdy',
  'rowdyish',
  'royal',
  'royalist',
  'rubber',
  'rubberised',
  'rubberized',
  'rubbery',
  'rubbishy',
  'rubblier',
  'rubbliest',
  'rubbly',
  'rubescent',
  'rubiaceous',
  'rubicund',
  'rubied',
  'rubify',
  'rubiginous',
  'rubious',
  'rubric',
  'rubricated',
  'ruby',
  'ruby-red',
  'rudderless',
  'ruddier',
  'ruddiest',
  'ruddy',
  'rude',
  'ruderal',
  'rudimentary',
  'rudish',
  'rueful',
  'rufescent',
  'ruffed',
  'ruffianly',
  'ruffled',
  'rufous',
  'rugged',
  'rugose',
  'rugulose',
  'ruinable',
  'ruined',
  'ruinous',
  'ruled',
  'ruling',
  'ruly',
  'rum',
  'rumbling',
  'rumbly',
  'rumbustious',
  'ruminant',
  'ruminative',
  'rummy',
  'rumpled',
  'rumpless',
  'run-down',
  'run-in',
  'run-of-the-mill',
  'run-on',
  'runaway',
  'runcinate',
  'runed',
  'runic',
  'runnier',
  'runniest',
  'running',
  'runny',
  'runtier',
  'runtiest',
  'runtish',
  'runty',
  'rupicolous',
  'ruptured',
  'rural',
  'rush',
  'rushed',
  'rushier',
  'rushiest',
  'rushing',
  'rushy',
  'russet',
  'russety',
  'rust',
  'rusted',
  'rustic',
  'rustier',
  'rustiest',
  'rustless',
  'rustling',
  'rustred',
  'rusty',
  'rutaceous',
  'ruthenic',
  'ruthenious',
  'ruthful',
  'ruthless',
  'rutilant',
  'rutilated',
  'rutted',
  'ruttier',
  'ruttiest',
  'ruttish',
  'rutty',
  'sabbatical',
  'sable',
  'sabre-toothed',
  'sabulous',
  'saccharic',
  'sacchariferous',
  'saccharine',
  'saccharoid',
  'saccular',
  'sacculate',
  'sacerdotal',
  'sachemic',
  'sacked',
  'saclike',
  'sacral',
  'sacramental',
  'sacred',
  'sacrificial',
  'sacrilegious',
  'sacroiliac',
  'sacrosanct',
  'sad',
  'saddening',
  'saddle-backed',
  'saddle-sore',
  'saddled',
  'sadist',
  'sadistic',
  'safe',
  'safe-deposit',
  'safety-deposit',
  'sagacious',
  'sage',
  'sage-green',
  'sagging',
  'sagittal',
  'sagittate',
  'sagittiform',
  'said',
  'sailing',
  'sailorly',
  'sainted',
  'saintlier',
  'saintliest',
  'saintlike',
  'saintly',
  'salable',
  'salacious',
  'salamandrine',
  'salaried',
  'saleable',
  'sales',
  'salic',
  'salicaceous',
  'salicylic',
  'salient',
  'salientian',
  'saliferous',
  'saline',
  'salivary',
  'sallow',
  'sallowish',
  'sallowy',
  'salmonoid',
  'salpiform',
  'salpingian',
  'salpingitic',
  'salt',
  'saltando',
  'saltant',
  'saltato',
  'saltatorial',
  'saltatory',
  'salted',
  'saltier',
  'saltigrade',
  'saltish',
  'saltless',
  'salty',
  'salubrious',
  'salutary',
  'salutational',
  'salutatory',
  'salvable',
  'salvageable',
  'salvationist',
  'salverform',
  'salving',
  'samariform',
  'same',
  'sanative',
  'sanatory',
  'sanctified',
  'sanctimonious',
  'sanctioned',
  'sanctioning',
  'sand-blind',
  'sandalled',
  'sanded',
  'sandier',
  'sandiest',
  'sandy',
  'sane',
  'saner',
  'sanest',
  'sanguiferous',
  'sanguinary',
  'sanguine',
  'sanguineous',
  'sanguinolent',
  'sanguivorous',
  'sanious',
  'sanitarian',
  'sanitary',
  'sanitized',
  'santalaceous',
  'sap',
  'sapheaded',
  'saphenous',
  'sapid',
  'sapient',
  'sapiential',
  'sapindaceous',
  'sapless',
  'saponaceous',
  'saponified',
  'saporous',
  'sapotaceous',
  'sapphire',
  'sapphirine',
  'sappier',
  'sappiest',
  'sappy',
  'saprogenic',
  'sapropelic',
  'saprophagous',
  'saprophytic',
  'saprozoic',
  'sarcastic',
  'sarcoid',
  'sarcophagous',
  'sarcous',
  'sardonic',
  'sarky',
  'sarmentose',
  'saronic',
  'sarraceniaceous',
  'sartorial',
  'sassier',
  'sassy',
  'satanic',
  'sated',
  'satellite',
  'satem',
  'satiable',
  'satiate',
  'satiated',
  'satin',
  'satiny',
  'satiric',
  'satirical',
  'satisfactory',
  'satisfiable',
  'satisfied',
  'satisfying',
  'saturable',
  'saturant',
  'saturate',
  'saturated',
  'saturniid',
  'saturnine',
  'satyric',
  'satyrical',
  'saucer-eyed',
  'saucier',
  'sauciest',
  'saucy',
  'saurian',
  'saurischian',
  'saussuritic',
  'saut',
  'savable',
  'savage',
  'saved',
  'saving',
  'savorous',
  'savory',
  'savourless',
  'savoury',
  'savvy',
  'saw-set',
  'saw-toothed',
  'sawdusty',
  'sawed-off',
  'sawn-off',
  'saxatile',
  'saxicoline',
  'saxicolous',
  'saxifragaceous',
  'sayable',
  'scabbardless',
  'scabbier',
  'scabbiest',
  'scabby',
  'scabious',
  'scabrous',
  'scalable',
  'scalar',
  'scalariform',
  'scald',
  'scaldic',
  'scalding',
  'scaled',
  'scaleless',
  'scalelike',
  'scalene',
  'scalier',
  'scaliest',
  'scalled',
  'scalloped',
  'scalpless',
  'scaly',
  'scampering',
  'scampish',
  'scandalmongering',
  'scandalous',
  'scandent',
  'scannable',
  'scansorial',
  'scant',
  'scantier',
  'scantiest',
  'scanty',
  'scaphocephalic',
  'scaphocephalous',
  'scaphoid',
  'scapular',
  'scapulary',
  'scarabaeid',
  'scarabaeoid',
  'scarce',
  'scarcer',
  'scarcest',
  'scared',
  'scarey',
  'scarious',
  'scarless',
  'scarlet',
  'scarred',
  'scary',
  'scatheless',
  'scathing',
  'scatological',
  'scatophagous',
  'scatterable',
  'scattered',
  'scattering',
  'scattershot',
  'scatty',
  'scavenging',
  'scenic',
  'scenographic',
  'scenographical',
  'scented',
  'scentless',
  'sceptered',
  'scepterless',
  'sceptic',
  'sceptical',
  'sceptral',
  'sceptred',
  'scheduled',
  'schematic',
  'scheming',
  'scherzando',
  'schismatic',
  'schismatical',
  'schistose',
  'schizo',
  'schizocarpic',
  'schizocarpous',
  'schizogenetic',
  'schizogenous',
  'schizogonous',
  'schizoid',
  'schizomycetic',
  'schizomycetous',
  'schizophrenic',
  'schizophyceous',
  'schizophytic',
  'schizothymic',
  'schlock',
  'schmaltzy',
  'schmalzier',
  'schmalziest',
  'schmalzy',
  'scholarly',
  'scholastic',
  'scholiastic',
  'school-age',
  'schoolboyish',
  'schooled',
  'schoolgirlish',
  'schorlaceous',
  'sciaenid',
  'sciaenoid',
  'sciatic',
  'sciential',
  'scientific',
  'scientistic',
  'scincoid',
  'scintillant',
  'scintillating',
  'scirrhoid',
  'scirrhous',
  'scissile',
  'sciurine',
  'sciuroid',
  'sclerenchymatous',
  'sclerodermatous',
  'scleroid',
  'sclerometric',
  'sclerophyllous',
  'sclerosal',
  'sclerosed',
  'sclerotial',
  'sclerotic',
  'sclerotized',
  'sclerous',
  'scolding',
  'scolopendrine',
  'scombrid',
  'scombroid',
  'scopate',
  'scorbutic',
  'scorched',
  'scorching',
  'score',
  'scoriaceous',
  'scorned',
  'scornful',
  'scorpaenid',
  'scorpaenoid',
  'scorpioid',
  'scorpionic',
  'scot-free',
  'scotch',
  'scotomatous',
  'scotopic',
  'scoundrelly',
  'scoured',
  'scowling',
  'scraggly',
  'scraggy',
  'scrambled',
  'scrannel',
  'scrap',
  'scraped',
  'scrappier',
  'scrappiest',
  'scrappy',
  'scratch',
  'scratched',
  'scratchier',
  'scratchiest',
  'scratching',
  'scratchless',
  'scratchy',
  'scrawled',
  'scrawlier',
  'scrawliest',
  'scrawly',
  'scrawnier',
  'scrawniest',
  'scrawny',
  'screaky',
  'screaming',
  'screeching',
  'screechy',
  'screw-pine',
  'screw-topped',
  'screwed',
  'screwy',
  'scribal',
  'scrimp',
  'scrimpier',
  'scrimpiest',
  'scrimpy',
  'scripted',
  'scriptural',
  'scrobiculate',
  'scroddled',
  'scrofulous',
  'scroggy',
  'scrophulariaceous',
  'scrotal',
  'scrub',
  'scrubbed',
  'scrubbier',
  'scrubbiest',
  'scrubby',
  'scruffier',
  'scruffiest',
  'scruffy',
  'scrumptious',
  'scrupulous',
  'scrutable',
  'sculpted',
  'sculptural',
  'sculptured',
  'sculpturesque',
  'scummier',
  'scummiest',
  'scummy',
  'scungy',
  'scurfy',
  'scurrile',
  'scurrilous',
  'scurry',
  'scurrying',
  'scurvy',
  'scutate',
  'scutellate',
  'scutiform',
  'scyphiform',
  'scyphozoan',
  'sea',
  'sea-foam',
  'sea-green',
  'sea-heath',
  'sea-island',
  'sea-level',
  'seaboard',
  'seaborne',
  'seafaring',
  'sealed',
  'sealed-beam',
  'seamanlike',
  'seamanly',
  'seamed',
  'seamier',
  'seamiest',
  'seamless',
  'seamy',
  'sear',
  'searchable',
  'searching',
  'seared',
  'seasick',
  'seaside',
  'seasonable',
  'seasonal',
  'seasoned',
  'seasonless',
  'seated',
  'seatless',
  'seaward',
  'seaworthy',
  'sebaceous',
  'sebacic',
  'sebiferous',
  'seborrheic',
  'sec',
  'secernent',
  'secessional',
  'secessionist',
  'secluded',
  'seclusive',
  'second',
  'second-best',
  'second-class',
  'second-rate',
  'second-sighted',
  'second-string',
  'secondary',
  'secret',
  'secretarial',
  'secretive',
  'secretory',
  'sectarian',
  'sectile',
  'sectional',
  'sectioned',
  'sectoral',
  'sectorial',
  'secular',
  'secularistic',
  'secularized',
  'secund',
  'securable',
  'secure',
  'secured',
  'sedate',
  'sedated',
  'sedative',
  'sedentary',
  'sedged',
  'sedgy',
  'sedimentary',
  'sedimentological',
  'sedition',
  'seditious',
  'seduced',
  'seductive',
  'sedulous',
  'see-through',
  'seeable',
  'seeded',
  'seedier',
  'seediest',
  'seedless',
  'seedy',
  'seeing',
  'seeking',
  'seely',
  'seeming',
  'seemlier',
  'seemliest',
  'seemly',
  'seen',
  'seeping',
  'seething',
  'segmental',
  'segmentary',
  'segmentate',
  'segmented',
  'segreant',
  'segregable',
  'segregated',
  'seigneurial',
  'seismal',
  'seismic',
  'seismograph',
  'seismographic',
  'seismographical',
  'seismologic',
  'seismological',
  'seismoscopic',
  'seizable',
  'seized',
  'sejant',
  'selachian',
  'select',
  'selected',
  'selective',
  'selenic',
  'selenious',
  'selenitic',
  'selenodont',
  'selenographic',
  'selenographical',
  'selenous',
  'self',
  'self-absorbed',
  'self-accusatory',
  'self-acting',
  'self-addressed',
  'self-adjusting',
  'self-affrighted',
  'self-aggrandizing',
  'self-annealing',
  'self-appointed',
  'self-approving',
  'self-asserting',
  'self-assertive',
  'self-assumed',
  'self-assured',
  'self-aware',
  'self-balanced',
  'self-begotten',
  'self-blinded',
  'self-born',
  'self-catering',
  'self-centred',
  'self-cleaning',
  'self-closing',
  'self-cocking',
  'self-collected',
  'self-coloured',
  'self-conceited',
  'self-condemned',
  'self-condemning',
  'self-confessed',
  'self-confident',
  'self-conscious',
  'self-consistent',
  'self-constituted',
  'self-consuming',
  'self-contained',
  'self-content',
  'self-contradiction',
  'self-contradictory',
  'self-convicted',
  'self-correcting',
  'self-created',
  'self-critical',
  'self-deceived',
  'self-defeating',
  'self-denying',
  'self-dependent',
  'self-depraved',
  'self-deprecating',
  'self-destroying',
  'self-destructive',
  'self-determined',
  'self-determining',
  'self-developing',
  'self-devoted',
  'self-directed',
  'self-directing',
  'self-disciplined',
  'self-disliked',
  'self-displeased',
  'self-distrust',
  'self-drawing',
  'self-drive',
  'self-driven',
  'self-educated',
  'self-effacing',
  'self-elected',
  'self-employed',
  'self-evident',
  'self-evolved',
  'self-excited',
  'self-executing',
  'self-exiled',
  'self-existent',
  'self-explanatory',
  'self-figured',
  'self-flattering',
  'self-focusing',
  'self-forgetful',
  'self-fulfilling',
  'self-giving',
  'self-glazed',
  'self-governing',
  'self-harming',
  'self-healing',
  'self-important',
  'self-imposed',
  'self-induced',
  'self-indulgent',
  'self-inflicted',
  'self-interested',
  'self-invited',
  'self-involved',
  'self-justifying',
  'self-killed',
  'self-lighting',
  'self-limited',
  'self-liquidating',
  'self-loading',
  'self-locking',
  'self-loving',
  'self-luminous',
  'self-made',
  'self-moving',
  'self-neglect',
  'self-neglecting',
  'self-occupied',
  'self-opened',
  'self-opening',
  'self-operating',
  'self-opinionated',
  'self-ordained',
  'self-perpetuating',
  'self-pitying',
  'self-planted',
  'self-pleasing',
  'self-possessed',
  'self-proclaimed',
  'self-produced',
  'self-professed',
  'self-propagating',
  'self-propelled',
  'self-propelling',
  'self-raised',
  'self-raising',
  'self-recording',
  'self-regarding',
  'self-registering',
  'self-regulating',
  'self-reliant',
  'self-repeating',
  'self-respectful',
  'self-respecting',
  'self-revealing',
  'self-reverent',
  'self-righteous',
  'self-rigorous',
  'self-rising',
  'self-sacrificing',
  'self-satisfied',
  'self-satisfying',
  'self-schooled',
  'self-sealing',
  'self-seeded',
  'self-seeking',
  'self-service',
  'self-serving',
  'self-slain',
  'self-sown',
  'self-styled',
  'self-subdued',
  'self-sufficient',
  'self-sufficing',
  'self-supporting',
  'self-surviving',
  'self-sustained',
  'self-sustaining',
  'self-tapping',
  'self-taught',
  'self-tempted',
  'self-tormenting',
  'self-trained',
  'self-willed',
  'self-winding',
  'selfish',
  'selfishness',
  'selfless',
  'selfsame',
  'sellable',
  'semantic',
  'sematic',
  'semblable',
  'semeiotic',
  'semestral',
  'semestrial',
  'semi',
  'semi-independent',
  'semiaquatic',
  'semiarid',
  'semibold',
  'semicircular',
  'semicomatose',
  'semiconducting',
  'semiconscious',
  'semicrystalline',
  'semifinished',
  'semifluid',
  'semiglobular',
  'semiliterate',
  'semilucent',
  'seminal',
  'seminarial',
  'seminary',
  'seminiferous',
  'semiotic',
  'semioviparous',
  'semipalmate',
  'semiparasitic',
  'semipermeable',
  'semipostal',
  'semiprofessional',
  'semisolid',
  'semitonic',
  'semitransparent',
  'semitropical',
  'sempiternal',
  'semplice',
  'senary',
  'senatorial',
  'senescent',
  'senile',
  'senior',
  'sensate',
  'sensational',
  'sensationalistic',
  'sensationist',
  'sensed',
  'senseless',
  'sensible',
  'sensitive',
  'sensitized',
  'sensitizing',
  'sensorial',
  'sensory',
  'sensual',
  'sensualistic',
  'sensuous',
  'sent',
  'sentential',
  'sententious',
  'sentient',
  'sentimental',
  'sepaloid',
  'separable',
  'separate',
  'separated',
  'separatist',
  'separative',
  'separatory',
  'sepia',
  'septal',
  'septarian',
  'septate',
  'septenary',
  'septennial',
  'septentrional',
  'septic',
  'septicemic',
  'septicidal',
  'septifragal',
  'septilateral',
  'septimal',
  'septuagenarian',
  'septuagenary',
  'septuple',
  'sepulchral',
  'sequacious',
  'sequent',
  'sequential',
  'sequestered',
  'sequined',
  'seral',
  'seraphic',
  'seraphical',
  'sere',
  'serene',
  'serfish',
  'serflike',
  'serial',
  'seriate',
  'sericeous',
  'sericitic',
  'sericultural',
  'seriocomic',
  'seriocomical',
  'serious',
  'serological',
  'serotinal',
  'serotine',
  'serous',
  'serpentiform',
  'serpentine',
  'serpiginous',
  'serranid',
  'serrate',
  'serrated',
  'serried',
  'serrulate',
  'servantless',
  'serviceable',
  'serviced',
  'servile',
  'servo',
  'servomechanical',
  'sesamoid',
  'sesquicentennial',
  'sesquipedalian',
  'sessile',
  'sessional',
  'set',
  'set-aside',
  'set-in',
  'set-up',
  'setaceous',
  'setiform',
  'setigerous',
  'setose',
  'setting',
  'settleable',
  'settled',
  'setulose',
  'seven',
  'sevenfold',
  'seventeen',
  'seventeenth',
  'seventh',
  'seventieth',
  'seventy',
  'seventy-eight',
  'severable',
  'several',
  'severe',
  'severed',
  'severer',
  'severest',
  'sewed',
  'sewn',
  'sex-limited',
  'sex-linked',
  'sex-starved',
  'sexagenarian',
  'sexagenary',
  'sexagesimal',
  'sexcentenary',
  'sexed',
  'sexennial',
  'sexier',
  'sexiest',
  'sexism',
  'sexist',
  'sexivalent',
  'sexless',
  'sexological',
  'sexpartite',
  'sextan',
  'sextuple',
  'sexual',
  'sexy',
  'sforzando',
  'shabbier',
  'shabbiest',
  'shabby',
  'shabby-genteel',
  'shackled',
  'shaded',
  'shadeless',
  'shadowed',
  'shadowing',
  'shadowless',
  'shadowy',
  'shady',
  'shaftless',
  'shagged',
  'shaggier',
  'shaggiest',
  'shaggy',
  'shakable',
  'shakeable',
  'shaken',
  'shakier',
  'shakiest',
  'shaking',
  'shaky',
  'shallow',
  'shalwar',
  'sham',
  'shamanic',
  'shamanist',
  'shamanistic',
  'shamed',
  'shamefaced',
  'shameful',
  'shameless',
  'shapable',
  'shapeable',
  'shaped',
  'shapeless',
  'shapelier',
  'shapeliest',
  'shapely',
  'shaping',
  'shared',
  'sharing',
  'sharp',
  'sharp-cut',
  'sharp-edged',
  'sharp-eyed',
  'sharp-nosed',
  'sharp-set',
  'sharp-sighted',
  'sharp-tongued',
  'sharp-witted',
  'sharpened',
  'shattered',
  'shattering',
  'shaved',
  'shaven',
  'shawlless',
  'sheared',
  'sheathed',
  'sheathy',
  'shed',
  'sheen',
  'sheenier',
  'sheeniest',
  'sheepish',
  'sheer',
  'shelfy',
  'shell',
  'shell-less',
  'shell-like',
  'shelled',
  'shellier',
  'shelliest',
  'shellproof',
  'shellshocked',
  'shelly',
  'sheltered',
  'shelterless',
  'shelvy',
  'shickered',
  'shield-shaped',
  'shielded',
  'shielding',
  'shieldless',
  'shieldlike',
  'shier',
  'shiest',
  'shiftier',
  'shiftiest',
  'shifting',
  'shiftless',
  'shifty',
  'shill',
  'shillyshally',
  'shimmering',
  'shimmery',
  'shingly',
  'shinier',
  'shiniest',
  'shining',
  'shiny',
  'ship-rigged',
  'shipboard',
  'shipless',
  'shipshape',
  'shipwrecked',
  'shirtless',
  'shirty',
  'shivering',
  'shivery',
  'shoal',
  'shoaly',
  'shock',
  'shock-headed',
  'shockable',
  'shocked',
  'shocking',
  'shod',
  'shoddy',
  'shoed',
  'shoeless',
  'shogunal',
  'shopworn',
  'shore',
  'shoreless',
  'shoreward',
  'shorn',
  'short',
  'short-dated',
  'short-handed',
  'short-lived',
  'short-range',
  'short-sighted',
  'short-spoken',
  'short-staffed',
  'short-tempered',
  'short-term',
  'short-winded',
  'shortcut',
  'shortened',
  'shortest',
  'shorthand',
  'shortish',
  'shorty',
  'shot',
  'shotgun',
  'shotten',
  'shouldered',
  'shouted',
  'shouting',
  'showerless',
  'showery',
  'showier',
  'showiest',
  'showy',
  'shredded',
  'shredless',
  'shrewd',
  'shrewish',
  'shrieked',
  'shrieking',
  'shrieval',
  'shrill',
  'shrilling',
  'shrimpy',
  'shrinelike',
  'shrinkable',
  'shriveled',
  'shrivelled',
  'shroud-laid',
  'shrouding',
  'shroudless',
  'shrubbier',
  'shrubbiest',
  'shrubby',
  'shrunk',
  'shrunken',
  'shuddering',
  'shuddery',
  'shuffling',
  'shunnable',
  'shunt-wound',
  'shut',
  'shut-in',
  'shuttered',
  'shy',
  'shyer',
  'shyest',
  'sialagogic',
  'sialoid',
  'siamese',
  'sibilant',
  'sibilation',
  'sibylic',
  'sibyllic',
  'sic',
  'sick',
  'sickening',
  'sicker',
  'sickish',
  'sickle-shaped',
  'sicklied',
  'sicklier',
  'sickliest',
  'sickly',
  'side',
  'side-by-side',
  'side-splitting',
  'side-wheel',
  'sidearm',
  'sideling',
  'sidelong',
  'sidereal',
  'sideward',
  'sideways',
  'sighful',
  'sightable',
  'sighted',
  'sightless',
  'sightlier',
  'sightliest',
  'sightly',
  'sigillary',
  'sigillate',
  'sigmate',
  'sigmoid',
  'sigmoidal',
  'sign',
  'signal',
  'signatory',
  'signed',
  'significant',
  'significative',
  'silenced',
  'silent',
  'siliceous',
  'silicic',
  'silicious',
  'silicotic',
  'siliculose',
  'silken',
  'silkier',
  'silkiest',
  'silky',
  'silly',
  'siltier',
  'siltiest',
  'silty',
  'silurid',
  'silvan',
  'silver',
  'silver-tongued',
  'silvern',
  'silvery',
  'simaroubaceous',
  'simian',
  'similar',
  'simious',
  'simon-pure',
  'simoniacal',
  'simpatico',
  'simple',
  'simple-hearted',
  'simple-minded',
  'simplex',
  'simplified',
  'simplistic',
  'simulant',
  'simular',
  'simulate',
  'simulated',
  'simulative',
  'simulatory',
  'simultaneous',
  'sincere',
  'sincipital',
  'sinewless',
  'sinewy',
  'sinful',
  'singable',
  'singing',
  'single',
  'single-acting',
  'single-breasted',
  'single-entry',
  'single-handed',
  'single-hearted',
  'single-minded',
  'single-phase',
  'single-spaced',
  'single-tax',
  'singled',
  'singling',
  'singsong',
  'singular',
  'sinister',
  'sinistral',
  'sinistrodextral',
  'sinistrorsal',
  'sinistrorse',
  'sinistrous',
  'sinkable',
  'sinless',
  'sinning',
  'sintered',
  'sinuate',
  'sinuous',
  'sinusoidal',
  'siphonal',
  'siphonic',
  'sipunculid',
  'sirenian',
  'sirenic',
  'sissified',
  'sissy',
  'sister',
  'sisterless',
  'sisterly',
  'sitting',
  'situate',
  'situated',
  'situational',
  'situla',
  'siwash',
  'six',
  'sixfold',
  'sixpenny',
  'sixteen',
  'sixteenth',
  'sixth',
  'sixtieth',
  'sixty',
  'sizable',
  'size',
  'sizeable',
  'sized',
  'sizy',
  'sizzling',
  'skaldic',
  'skeigh',
  'skeletal',
  'skeptic',
  'skeptical',
  'sketchable',
  'sketchy',
  'skew',
  'skewbald',
  'skewed',
  'ski',
  'skiable',
  'skidproof',
  'skilful',
  'skilled',
  'skillful',
  'skim',
  'skimmed',
  'skimpy',
  'skin',
  'skin-deep',
  'skinking',
  'skinless',
  'skinned',
  'skinnier',
  'skinniest',
  'skinny',
  'skint',
  'skirting',
  'skirtless',
  'skittish',
  'skulking',
  'sky-blue',
  'sky-high',
  'skyward',
  'slab-sided',
  'slabbery',
  'slack',
  'slaggier',
  'slaggiest',
  'slaggy',
  'slain',
  'slaked',
  'slakeless',
  'slanderous',
  'slangier',
  'slangiest',
  'slangy',
  'slant',
  'slant-eyed',
  'slanted',
  'slanting',
  'slantwise',
  'slap-up',
  'slapped',
  'slapstick',
  'slashed',
  'slashing',
  'slate',
  'slate-gray',
  'slatier',
  'slatiest',
  'slatternly',
  'slaty',
  'slaughterous',
  'slave',
  'slavish',
  'sleazier',
  'sleaziest',
  'sleazy',
  'sledge-hammer',
  'sleek',
  'sleekier',
  'sleekiest',
  'sleekit',
  'sleeky',
  'sleepier',
  'sleepiest',
  'sleeping',
  'sleepless',
  'sleepwalk',
  'sleepy',
  'sleetier',
  'sleetiest',
  'sleety',
  'sleeved',
  'sleeveless',
  'slender',
  'sliced',
  'slick',
  'slicked',
  'slickered',
  'sliding',
  'slier',
  'sliest',
  'slight',
  'slightest',
  'slighting',
  'slim',
  'slimed',
  'slimier',
  'slimiest',
  'slimline',
  'slimmer',
  'slimmest',
  'slimming',
  'slimsy',
  'slimy',
  'slinkier',
  'slinkiest',
  'slinky',
  'slip-on',
  'slipover',
  'slippered',
  'slipperier',
  'slipperiest',
  'slippery',
  'slippier',
  'slippiest',
  'slipping',
  'slippy',
  'slipshod',
  'slit',
  'slithering',
  'slithery',
  'slobbery',
  'sloe-eyed',
  'sloped',
  'sloping',
  'slopped',
  'sloppier',
  'sloppiest',
  'sloppy',
  'sloshed',
  'sloshier',
  'sloshiest',
  'sloshy',
  'slothful',
  'slouched',
  'slouchier',
  'slouchiest',
  'slouching',
  'slouchy',
  'sloughy',
  'slovenlier',
  'slovenliest',
  'slovenly',
  'slow',
  'slow-motion',
  'slow-moving',
  'slow-witted',
  'slub',
  'slubbed',
  'sludgier',
  'sludgiest',
  'sludgy',
  'sluggard',
  'sluggish',
  'sluicing',
  'slum',
  'slumbering',
  'slumberless',
  'slumberous',
  'slumbery',
  'slumbrous',
  'slummier',
  'slummiest',
  'slummy',
  'slumped',
  'slung',
  'slurred',
  'slushier',
  'slushiest',
  'slushy',
  'sluttish',
  'sly',
  'smacking',
  'small',
  'small-bore',
  'small-minded',
  'small-scale',
  'small-time',
  'small-town',
  'smaller',
  'smallest',
  'smallish',
  'smaragdine',
  'smarmy',
  'smart',
  'smart-aleck',
  'smart-alecky',
  'smarting',
  'smartish',
  'smarty',
  'smash-and-grab',
  'smashed',
  'smashing',
  'smeared',
  'smearier',
  'smeariest',
  'smeary',
  'smectic',
  'smell-less',
  'smellier',
  'smelliest',
  'smelling',
  'smelly',
  'smileless',
  'smiling',
  'smirched',
  'smitten',
  'smoggy',
  'smoke-dried',
  'smoked',
  'smokeless',
  'smokeproof',
  'smokier',
  'smokiest',
  'smoking',
  'smoky',
  'smooth',
  'smooth-faced',
  'smooth-spoken',
  'smooth-tongued',
  'smoothed',
  'smoothened',
  'smorzando',
  'smothered',
  'smothering',
  'smothery',
  'smouldering',
  'smudged',
  'smudgy',
  'smug',
  'smugger',
  'smuggest',
  'smuggled',
  'smuttier',
  'smuttiest',
  'smutty',
  'snafu',
  'snaggy',
  'snail-paced',
  'snake-hipped',
  'snakelike',
  'snakier',
  'snakiest',
  'snaky',
  'snap-brim',
  'snappier',
  'snappiest',
  'snapping',
  'snappish',
  'snappy',
  'snarled',
  'snarly',
  'snatchier',
  'snatchiest',
  'snatchy',
  'snazzier',
  'snazziest',
  'snazzy',
  'sneak',
  'sneakier',
  'sneakiest',
  'sneaking',
  'sneaky',
  'snecked',
  'sneering',
  'sneezy',
  'snide',
  'snider',
  'snidest',
  'sniffier',
  'sniffiest',
  'sniffling',
  'sniffy',
  'snippier',
  'snippiest',
  'snippy',
  'snobbish',
  'snobby',
  'snod',
  'snoopy',
  'snootier',
  'snootiest',
  'snooty',
  'snoozy',
  'snoring',
  'snorting',
  'snorty',
  'snotty',
  'snotty-nosed',
  'snouted',
  'snow-blind',
  'snow-white',
  'snowier',
  'snowiest',
  'snowless',
  'snowlike',
  'snowy',
  'snub',
  'snub-nosed',
  'snubbier',
  'snubbiest',
  'snubby',
  'snuff',
  'snuff-brown',
  'snuffier',
  'snuffiest',
  'snuffling',
  'snuffly',
  'snuffy',
  'snug',
  'snugger',
  'snuggest',
  'snugging',
  'snuggled',
  'so-called',
  'so-so',
  'soaked',
  'soaking',
  'soapier',
  'soapiest',
  'soapless',
  'soapy',
  'soaring',
  'sober',
  'sober-minded',
  'sobering',
  'sociable',
  'social',
  'socialist',
  'socialistic',
  'socialized',
  'societal',
  'sociobiological',
  'socioeconomic',
  'sociolinguistic',
  'sociological',
  'sociologistic',
  'sociopathic',
  'socko',
  'soda-lime',
  'sodden',
  'sodding',
  'soft',
  'soft-boiled',
  'soft-centred',
  'soft-cover',
  'soft-finned',
  'soft-footed',
  'soft-headed',
  'soft-hearted',
  'soft-shell',
  'soft-spoken',
  'softened',
  'softening',
  'softish',
  'softwood',
  'soggy',
  'soi-disant',
  'soiled',
  'sola',
  'solanaceous',
  'solar',
  'sold',
  'soldierlike',
  'soldierly',
  'sole',
  'soled',
  'solemn',
  'solenoidal',
  'solfataric',
  'soli',
  'solicited',
  'solicitous',
  'solid',
  'solid-state',
  'solidary',
  'solidifiable',
  'solidified',
  'solidungulate',
  'solipsism',
  'solitary',
  'solitudinous',
  'sollar',
  'solo',
  'solstitial',
  'soluble',
  'solus',
  'solute',
  'solutional',
  'solvable',
  'solved',
  'solvent',
  'somatic',
  'somatogenic',
  'somatologic',
  'somatological',
  'somatotonic',
  'somber',
  'sombre',
  'sombrous',
  'some',
  'sometime',
  'somnambulant',
  'somnambulism',
  'somnambulistic',
  'somnifacient',
  'somniferous',
  'somnific',
  'somnolent',
  'sonant',
  'songful',
  'songless',
  'sonic',
  'sonless',
  'sonorous',
  'sonsie',
  'sonsy',
  'sooth',
  'soothfast',
  'soothing',
  'sooty',
  'sophistic',
  'sophistical',
  'sophisticated',
  'sophomore',
  'sophomoric',
  'soporiferous',
  'soporific',
  'soppier',
  'soppiest',
  'sopping',
  'soppy',
  'sopranino',
  'soprano',
  'sorbed',
  'sorbefacient',
  'sorcerous',
  'sordid',
  'sore',
  'sorediate',
  'sorer',
  'sorest',
  'soricine',
  'soritic',
  'soritical',
  'sororal',
  'sorrel',
  'sorrier',
  'sorriest',
  'sorrowful',
  'sorrowing',
  'sorrowless',
  'sorry',
  'sortable',
  'sorted',
  'sostenuto',
  'sotted',
  'sottish',
  'sottishness',
  'soughing',
  'sought',
  'sought-after',
  'soul-destroying',
  'soul-searching',
  'soul-stirring',
  'soulful',
  'soulless',
  'sound',
  'sounded',
  'sounding',
  'soundless',
  'soundproof',
  'soupier',
  'soupiest',
  'soupy',
  'sour',
  'soured',
  'sourish',
  'soused',
  'south',
  'southerly',
  'southern',
  'southernly',
  'southernmost',
  'southmost',
  'southpaw',
  'southward',
  'sovereign',
  'soviet',
  'sovietism',
  'sovran',
  'sown',
  'sozzled',
  'spaced',
  'spaceless',
  'spacial',
  'spacious',
  'spadelike',
  'spadiceous',
  'spagyric',
  'span-new',
  'spanaemic',
  'spangled',
  'spangly',
  'spanking',
  'spare',
  'sparid',
  'sparing',
  'sparkish',
  'sparkless',
  'sparkling',
  'sparkly',
  'sparoid',
  'sparry',
  'sparse',
  'sparser',
  'sparsest',
  'spasmodic',
  'spastic',
  'spathaceous',
  'spathic',
  'spathose',
  'spathulate',
  'spatial',
  'spatiotemporal',
  'spattered',
  'spatulate',
  'spavined',
  'spayed',
  'speakable',
  'speaking',
  'spec',
  'special',
  'specialist',
  'specialistic',
  'specialized',
  'specifiable',
  'specific',
  'specified',
  'specious',
  'specked',
  'speckled',
  'speckless',
  'spectacled',
  'spectacular',
  'spectatorial',
  'spectral',
  'spectrographic',
  'spectrological',
  'spectrometric',
  'spectroscopic',
  'spectroscopical',
  'specular',
  'speculative',
  'speechless',
  'speedful',
  'speedier',
  'speediest',
  'speeding',
  'speedless',
  'speedy',
  'spelaean',
  'spellable',
  'spellbinding',
  'spellbound',
  'spendable',
  'spendthrift',
  'spent',
  'spermatic',
  'spermatozoal',
  'spermatozoan',
  'spermatozoic',
  'spermatozoon',
  'spermic',
  'spermicidal',
  'spermophytic',
  'spermous',
  'sphagnous',
  'sphenic',
  'sphenoid',
  'spheral',
  'sphereless',
  'spherelike',
  'spheric',
  'spherical',
  'spheroidal',
  'spherular',
  'spherulitic',
  'sphery',
  'sphincteral',
  'sphincterial',
  'sphinxlike',
  'sphygmic',
  'sphygmographic',
  'sphygmoid',
  'spicate',
  'spiccato',
  'spicier',
  'spiciest',
  'spick',
  'spiculate',
  'spicy',
  'spidery',
  'spiffier',
  'spiffiest',
  'spiffing',
  'spiffy',
  'spiflicated',
  'spiked',
  'spikier',
  'spikiest',
  'spiky',
  'spinaceous',
  'spinal',
  'spindle-legged',
  'spindle-shanked',
  'spindle-shaped',
  'spindlier',
  'spindliest',
  'spindling',
  'spindly',
  'spined',
  'spineless',
  'spinescent',
  'spinier',
  'spiniest',
  'spiniferous',
  'spinning',
  'spinose',
  'spinous',
  'spinulose',
  'spiny',
  'spiracular',
  'spiral',
  'spirant',
  'spired',
  'spireless',
  'spirillar',
  'spirited',
  'spiritistic',
  'spiritless',
  'spiritous',
  'spiritual',
  'spiritualist',
  'spiritualistic',
  'spirituel',
  'spirituous',
  'spiroid',
  'spirometric',
  'spiry',
  'spiteful',
  'splanchnic',
  'splashed',
  'splashier',
  'splashiest',
  'splashy',
  'splattered',
  'splay',
  'spleenful',
  'spleenish',
  'spleeny',
  'splendent',
  'splendid',
  'splendiferous',
  'splendorous',
  'splendrous',
  'splenetic',
  'splenial',
  'splenic',
  'splintered',
  'splintery',
  'split',
  'split-level',
  'split-second',
  'splitting',
  'splotched',
  'splurgy',
  'spluttering',
  'spoiled',
  'spoilt',
  'spoken',
  'spokewise',
  'spondaic',
  'spondylitic',
  'spongier',
  'spongiest',
  'spongy',
  'spontaneous',
  'spookier',
  'spookiest',
  'spooky',
  'spoon-fed',
  'spoonier',
  'spooniest',
  'spoony',
  'sporadic',
  'sporangial',
  'sporocystic',
  'sporogenous',
  'sporophoric',
  'sporophytic',
  'sporozoan',
  'sport',
  'sportful',
  'sportier',
  'sportiest',
  'sporting',
  'sportive',
  'sportless',
  'sportsmanlike',
  'sporty',
  'sporular',
  'spot-on',
  'spotless',
  'spotted',
  'spotty',
  'spousal',
  'spouseless',
  'spouted',
  'spouting',
  'spoutless',
  'sprawled',
  'sprawling',
  'sprawly',
  'spread',
  'spread-eagle',
  'spreading',
  'spreathed',
  'sprigged',
  'spriggier',
  'spriggiest',
  'spriggy',
  'sprightful',
  'sprightlier',
  'sprightliest',
  'sprightly',
  'spring',
  'spring-loaded',
  'springier',
  'springiest',
  'springing',
  'springless',
  'springlike',
  'springtime',
  'springy',
  'sprinkled',
  'sprouted',
  'spruce',
  'sprucer',
  'sprucest',
  'sprucing',
  'spry',
  'spryer',
  'spryest',
  'spumescent',
  'spumous',
  'spumy',
  'spun',
  'spunkier',
  'spunkiest',
  'spunky',
  'spurious',
  'spurless',
  'spurned',
  'spurred',
  'spurting',
  'sputtering',
  'squab',
  'squabbier',
  'squabbiest',
  'squabby',
  'squalid',
  'squallier',
  'squalliest',
  'squalling',
  'squally',
  'squamate',
  'squamosal',
  'squamous',
  'squamulose',
  'squandered',
  'square',
  'square-built',
  'square-rigged',
  'square-shouldered',
  'square-toed',
  'squared',
  'squarish',
  'squarrose',
  'squashed',
  'squashier',
  'squashiest',
  'squashy',
  'squat',
  'squatty',
  'squawky',
  'squeakier',
  'squeakiest',
  'squeaking',
  'squeaky',
  'squealing',
  'squeamish',
  'squeezable',
  'squelched',
  'squiffy',
  'squiggly',
  'squint',
  'squint-eyed',
  'squinting',
  'squirarchal',
  'squirarchical',
  'squirearchal',
  'squirearchical',
  'squirming',
  'squirmy',
  'squirrelly',
  'squirting',
  'squishier',
  'squishiest',
  'squishy',
  'stabbed',
  'stabbing',
  'stabile',
  'stabilized',
  'stabilizing',
  'stable',
  'staccato',
  'stacked',
  'stage-struck',
  'staged',
  'stagey',
  'staggering',
  'stagier',
  'stagiest',
  'stagnant',
  'stagy',
  'staid',
  'stained',
  'stainless',
  'stalactiform',
  'stalagmitic',
  'stalagmitical',
  'stalagmometer',
  'stale',
  'stalemated',
  'stalked',
  'stalking',
  'stalkless',
  'stalky',
  'stall-fed',
  'stalwart',
  'stalworth',
  'staminal',
  'staminate',
  'staminiferous',
  'stammering',
  'stamped',
  'stanchable',
  'stand-alone',
  'stand-by',
  'stand-off',
  'stand-offish',
  'stand-up',
  'standard',
  'standardized',
  'standing',
  'stannic',
  'stanniferous',
  'stannous',
  'stapedial',
  'staphylococcal',
  'staple',
  'stapled',
  'star',
  'star-crossed',
  'star-shaped',
  'star-spangled',
  'star-studded',
  'starboard',
  'starch-reduced',
  'starchy',
  'staring',
  'stark',
  'stark-naked',
  'starkers',
  'starless',
  'starlight',
  'starlike',
  'starlit',
  'starred',
  'starrier',
  'starriest',
  'starring',
  'starry',
  'starry-eyed',
  'starting',
  'startled',
  'startling',
  'starved',
  'starveling',
  'starving',
  'statable',
  'statant',
  'state',
  'stated',
  'stateless',
  'statelier',
  'stateliest',
  'stately',
  'stateside',
  'statesmanlike',
  'statesmanly',
  'statewide',
  'static',
  'stational',
  'stationary',
  'statist',
  'statistical',
  'stative',
  'statuary',
  'statued',
  'statuesque',
  'statutable',
  'statute',
  'statutory',
  'staunch',
  'staurolitic',
  'stay-at-home',
  'steadfast',
  'steadied',
  'steadier',
  'steadiest',
  'steady',
  'steady-going',
  'steadying',
  'stealthier',
  'stealthiest',
  'stealthy',
  'steamed',
  'steamier',
  'steamiest',
  'steaming',
  'steamtight',
  'steamy',
  'stearic',
  'steatitic',
  'stedfast',
  'steel-blue',
  'steel-grey',
  'steel-plated',
  'steely',
  'steep',
  'steepish',
  'steepled',
  'steerable',
  'stelar',
  'stellar',
  'stellate',
  'stelliferous',
  'stelliform',
  'stellular',
  'stemless',
  'stemmed',
  'stenographic',
  'stenographical',
  'stenophyllous',
  'stenosed',
  'stenotopic',
  'stenotropic',
  'stentorian',
  'step-down',
  'step-in',
  'step-up',
  'stepwise',
  'stercoraceous',
  'stereo',
  'stereobatic',
  'stereographic',
  'stereographical',
  'stereoisomeric',
  'stereophonic',
  'stereoscopic',
  'stereospecific',
  'stereotactic',
  'stereotyped',
  'stereotypic',
  'stereotypical',
  'steric',
  'sterile',
  'sterilized',
  'sterling',
  'stern',
  'sternal',
  'sternitic',
  'sternmost',
  'sternutative',
  'sternutatory',
  'steroidal',
  'stertorous',
  'stethoscopic',
  'stewed',
  'stey',
  'sthenic',
  'stibial',
  'stichometric',
  'stichometrical',
  'stichomythic',
  'stick-in-the-mud',
  'stickier',
  'stickiest',
  'sticking',
  'stickit',
  'sticky',
  'stiff',
  'stiff-necked',
  'stiffened',
  'stiffish',
  'stifled',
  'stifling',
  'stigmatic',
  'still',
  'still-life',
  'stilly',
  'stilted',
  'stimulable',
  'stimulant',
  'stimulated',
  'stimulating',
  'stimulative',
  'stinging',
  'stingless',
  'stingy',
  'stinking',
  'stinko',
  'stinky',
  'stinting',
  'stintless',
  'stipellate',
  'stipendiary',
  'stipitate',
  'stippled',
  'stipular',
  'stipulate',
  'stipulatory',
  'stirless',
  'stirred',
  'stirring',
  'stitched',
  'stochastic',
  'stock',
  'stocked',
  'stockier',
  'stockiest',
  'stocking',
  'stockinged',
  'stockingless',
  'stockish',
  'stockless',
  'stocky',
  'stodgier',
  'stodgiest',
  'stodgy',
  'stoic',
  'stoical',
  'stoichiometric',
  'stolen',
  'stolid',
  'stolidity',
  'stoloniferous',
  'stomachal',
  'stomachic',
  'stomachy',
  'stomatal',
  'stomatic',
  'stone',
  'stone-blind',
  'stone-broke',
  'stone-cold',
  'stone-dead',
  'stone-deaf',
  'stoned',
  'stoneground',
  'stoneless',
  'stoneware',
  'stonier',
  'stoniest',
  'stonkered',
  'stony',
  'stony-broke',
  'stony-hearted',
  'stooped',
  'stooping',
  'stop-go',
  'stop-loss',
  'stopless',
  'stopped',
  'stoppered',
  'stopping',
  'storable',
  'store',
  'storeyed',
  'storiated',
  'storied',
  'storm-beaten',
  'storm-tossed',
  'stormbound',
  'stormier',
  'stormiest',
  'stormless',
  'stormproof',
  'stormy',
  'stoss',
  'stotious',
  'stout',
  'stoutish',
  'straggling',
  'straggly',
  'straight',
  'straight-arm',
  'straight-out',
  'straightaway',
  'straightforward',
  'strained',
  'straining',
  'strait',
  'strait-laced',
  'stranded',
  'strange',
  'strangest',
  'strangled',
  'strapless',
  'strapped',
  'strapping',
  'strategic',
  'strategical',
  'straticulate',
  'stratified',
  'stratiform',
  'stratocratic',
  'stratospheric',
  'stratous',
  'straucht',
  'straw',
  'strawless',
  'strawlike',
  'strawy',
  'stray',
  'straying',
  'streaked',
  'streakier',
  'streakiest',
  'streaky',
  'streamier',
  'streamiest',
  'streaming',
  'streamless',
  'streamlined',
  'streamy',
  'strengthened',
  'strengthening',
  'strenuous',
  'strepitous',
  'streptococcal',
  'stressed',
  'stressful',
  'stretch',
  'stretchable',
  'stretched',
  'stretchier',
  'stretchiest',
  'stretching',
  'stretchy',
  'strewn',
  'striate',
  'stricken',
  'strict',
  'strident',
  'stridulatory',
  'stridulous',
  'strifeful',
  'strifeless',
  'strigiform',
  'strigose',
  'striking',
  'stringed',
  'stringendo',
  'stringent',
  'stringless',
  'stringy',
  'strip',
  'strip-mined',
  'striped',
  'stripeless',
  'stripier',
  'stripiest',
  'stripped',
  'stripped-down',
  'stripy',
  'strobic',
  'strobilaceous',
  'strobiloid',
  'stroboscopic',
  'stromatic',
  'stromatous',
  'strong',
  'strong-minded',
  'strong-willed',
  'strongish',
  'strophic',
  'stroppy',
  'struck',
  'structural',
  'structuralism',
  'structuralist',
  'structured',
  'structureless',
  'struggling',
  'strung',
  'struthious',
  'strutting',
  'strychnic',
  'stubbled',
  'stubbly',
  'stubborn',
  'stubby',
  'stuck',
  'stuck-up',
  'studded',
  'studied',
  'studious',
  'stuffed',
  'stuffy',
  'stumbling',
  'stumpier',
  'stumpiest',
  'stumpy',
  'stung',
  'stunned',
  'stunning',
  'stunted',
  'stupefacient',
  'stupefactive',
  'stupefied',
  'stupefying',
  'stupendous',
  'stupid',
  'stuporous',
  'sturdied',
  'sturdier',
  'sturdiest',
  'sturdy',
  'stutter',
  'stuttering',
  'stylar',
  'styled',
  'styleless',
  'styliform',
  'stylised',
  'stylish',
  'stylistic',
  'stylized',
  'stylographic',
  'styloid',
  'stylolitic',
  'styptic',
  'styracaceous',
  'suable',
  'suasible',
  'suasory',
  'suave',
  'subacid',
  'subacidulous',
  'subacrid',
  'subacute',
  'subadult',
  'subaerial',
  'subaffluent',
  'subaggregate',
  'subalpine',
  'subaltern',
  'subalternate',
  'subangular',
  'subantarctic',
  'subapostolic',
  'subaqua',
  'subaquatic',
  'subaqueous',
  'subarachnoid',
  'subarboreal',
  'subarborescent',
  'subarctic',
  'subarcuate',
  'subarid',
  'subastral',
  'subatomic',
  'subaudible',
  'subaural',
  'subauricular',
  'subaverage',
  'subaxillary',
  'subbasal',
  'subcaliber',
  'subcalibre',
  'subcapsular',
  'subcardinal',
  'subcartilaginous',
  'subcelestial',
  'subclavian',
  'subclavicular',
  'subclinical',
  'subconscious',
  'subcontiguous',
  'subcontinental',
  'subcontrary',
  'subcordate',
  'subcortical',
  'subcostal',
  'subcranial',
  'subcritical',
  'subcultural',
  'subcutaneous',
  'subdermal',
  'subdiaconal',
  'subdivided',
  'subdominant',
  'subdorsal',
  'subduable',
  'subdued',
  'subdural',
  'subentire',
  'subequal',
  'subequatorial',
  'suberect',
  'subereous',
  'suberic',
  'subfreezing',
  'subfusc',
  'subgeneric',
  'subglacial',
  'subglobose',
  'subglobular',
  'subgrade',
  'subhedral',
  'subhuman',
  'subhumid',
  'subinfeudatory',
  'subjacent',
  'subject',
  'subjective',
  'subjugated',
  'subjunctive',
  'sublanceolate',
  'sublapsarianism',
  'sublethal',
  'sublimable',
  'sublimate',
  'sublimated',
  'sublime',
  'sublimed',
  'subliminal',
  'sublinear',
  'sublingual',
  'sublittoral',
  'sublunar',
  'sublunary',
  'sublunate',
  'submarginal',
  'submarine',
  'submaxillary',
  'submediant',
  'submental',
  'submerged',
  'submergible',
  'submersed',
  'submersible',
  'submicroscopic',
  'subminiature',
  'submiss',
  'submissive',
  'submontane',
  'submucous',
  'submultiple',
  'subnatural',
  'subneural',
  'subnormal',
  'suboceanic',
  'subocular',
  'suborbital',
  'subordinal',
  'subordinate',
  'subordinating',
  'subordinative',
  'subovate',
  'subparallel',
  'subphrenic',
  'subpolar',
  'subreptitious',
  'subsacral',
  'subscapular',
  'subscribable',
  'subscribed',
  'subscript',
  'subsequent',
  'subservient',
  'subsessile',
  'subsidiary',
  'subsiding',
  'subsidized',
  'subsistent',
  'subsolar',
  'subsonic',
  'subspinous',
  'substandard',
  'substantial',
  'substantiated',
  'substantiating',
  'substantival',
  'substantive',
  'substernal',
  'substituent',
  'substitutable',
  'substitute',
  'substitutional',
  'substitutionary',
  'substitutive',
  'substructural',
  'subsumable',
  'subsurface',
  'subtemperate',
  'subterminal',
  'subternatural',
  'subterranean',
  'subterrestrial',
  'subtile',
  'subtle',
  'subtorrid',
  'subtracted',
  'subtractive',
  'subtriangular',
  'subtriplicate',
  'subtropic',
  'subtropical',
  'subulate',
  'subungual',
  'suburban',
  'suburbanized',
  'suburbicarian',
  'subursine',
  'subventionary',
  'subversive',
  'subvertebral',
  'subvertical',
  'subvitreous',
  'subvocal',
  'subzero',
  'subzonal',
  'succedaneous',
  'succeeding',
  'successful',
  'successive',
  'successless',
  'succinct',
  'succinic',
  'succubous',
  'succulent',
  'succursal',
  'such',
  'suchlike',
  'sucking',
  'suckled',
  'suctorial',
  'sudatory',
  'sudden',
  'sudoriferous',
  'sudorific',
  'sudoriparous',
  'sudsy',
  'suety',
  'sufferable',
  'suffering',
  'sufficient',
  'suffixal',
  'suffocating',
  'suffocative',
  'suffragan',
  'suffruticose',
  'suffused',
  'suffusive',
  'sugar-candy',
  'sugar-cane',
  'sugar-coated',
  'sugar-loaf',
  'sugared',
  'sugarless',
  'sugary',
  'suggested',
  'suggestible',
  'suggestive',
  'suicidal',
  'suitable',
  'suited',
  'sulcate',
  'sulfa',
  'sulfinyl',
  'sulfuric',
  'sulkier',
  'sulkies',
  'sulkiest',
  'sulky',
  'sullen',
  'sullied',
  'sulpha',
  'sulphonic',
  'sulphureous',
  'sulphuretted',
  'sulphuric',
  'sulphurous',
  'sultanic',
  'sultrier',
  'sultriest',
  'sultry',
  'summary',
  'summational',
  'summative',
  'summer',
  'summerly',
  'summery',
  'summital',
  'summitless',
  'summonable',
  'sumptuary',
  'sumptuous',
  'sun-cured',
  'sun-drenched',
  'sun-dried',
  'sunbaked',
  'sunbeamed',
  'sunbeamy',
  'sunburned',
  'sunburnt',
  'sunburst',
  'sundry',
  'sunfast',
  'sung',
  'sunk',
  'sunken',
  'sunless',
  'sunlike',
  'sunlit',
  'sunnier',
  'sunniest',
  'sunny',
  'sunproof',
  'sunray',
  'sunrise',
  'sunset',
  'sunshiny',
  'sunstruck',
  'suntanned',
  'sunward',
  'super',
  'super-duper',
  'superable',
  'superabundant',
  'superactive',
  'superacute',
  'superadditional',
  'superambitious',
  'superannuated',
  'superb',
  'superbold',
  'supercelestial',
  'supercharged',
  'superciliary',
  'supercilious',
  'supercolumnar',
  'superconducting',
  'superconductive',
  'superconfident',
  'supercriminal',
  'supercritical',
  'superdainty',
  'supereminent',
  'supererogatory',
  'superevident',
  'superexcellent',
  'superfatted',
  'superfetate',
  'superficial',
  'superfine',
  'superfluid',
  'superfluous',
  'supergene',
  'superglacial',
  'superheterodyne',
  'superhuman',
  'superimportant',
  'superimposed',
  'superincumbent',
  'superintendent',
  'superior',
  'superjacent',
  'superlative',
  'superlunar',
  'superlunary',
  'supermundane',
  'supernal',
  'supernatant',
  'supernational',
  'supernatural',
  'supernaturalism',
  'supernaturalist',
  'supernaturalistic',
  'supernormal',
  'supernumerary',
  'superordinary',
  'superordinate',
  'superorganic',
  'superphysical',
  'superposable',
  'superrefined',
  'supersafe',
  'supersaturated',
  'superscript',
  'supersensible',
  'supersensitive',
  'supersensual',
  'superserviceable',
  'supersonic',
  'superstitious',
  'superstructural',
  'supersubstantial',
  'supersubtle',
  'supersweet',
  'superterrestrial',
  'supervenient',
  'supervirulent',
  'supervised',
  'supervisory',
  'supine',
  'supperless',
  'supple',
  'suppled',
  'supplemental',
  'supplementary',
  'suppler',
  'supplest',
  'suppletion',
  'suppletive',
  'suppletory',
  'suppliant',
  'supplicant',
  'supplicatory',
  'suppling',
  'supportable',
  'supported',
  'supporting',
  'supportive',
  'supportless',
  'supposable',
  'supposed',
  'suppositional',
  'suppositious',
  'supposititious',
  'suppositive',
  'suppressed',
  'suppressive',
  'suppurative',
  'supranational',
  'suprarenal',
  'suprasegmental',
  'supratemporal',
  'supremacist',
  'supreme',
  'sural',
  'surbased',
  'surculose',
  'surd',
  'sure',
  'sure-enough',
  'sure-fire',
  'surefooted',
  'surer',
  'surest',
  'surface',
  'surface-active',
  'surface-to-air',
  'surface-to-surface',
  'surfeited',
  'surficial',
  'surfy',
  'surgeless',
  'surgical',
  'surging',
  'surgy',
  'surly',
  'surmisable',
  'surmountable',
  'surmounted',
  'surpassable',
  'surpassing',
  'surpliced',
  'surplus',
  'surprised',
  'surprising',
  'surreal',
  'surrealism',
  'surrealistic',
  'surrendered',
  'surreptitious',
  'surrogate',
  'surrounded',
  'surrounding',
  'surveillant',
  'survivable',
  'surviving',
  'susceptible',
  'susceptive',
  'suspect',
  'suspected',
  'suspectless',
  'suspended',
  'suspenseful',
  'suspensible',
  'suspensive',
  'suspensory',
  'suspicionless',
  'suspicious',
  'sustainable',
  'sustained',
  'sustentacular',
  'sustentative',
  'susurrant',
  'sutural',
  'svelte',
  'svelter',
  'sveltest',
  'swagger',
  'swaggering',
  'swainish',
  'swallow-tailed',
  'swallowed',
  'swamped',
  'swampier',
  'swampiest',
  'swampy',
  'swank',
  'swankier',
  'swankiest',
  'swanky',
  'swaraj',
  'swarajist',
  'swarming',
  'swart',
  'swarth',
  'swarthy',
  'swashbuckling',
  'sway-backed',
  'swaying',
  'sweated',
  'sweatier',
  'sweatiest',
  'sweating',
  'sweaty',
  'sweeping',
  'sweer',
  'sweet',
  'sweet-and-sour',
  'sweet-scented',
  'sweet-tempered',
  'sweetened',
  'sweetish',
  'sweetmeal',
  'swell',
  'swelled',
  'swelled-headed',
  'swelling',
  'sweltering',
  'sweltry',
  'swept',
  'sweptwing',
  'swift',
  'swift-footed',
  'swimmable',
  'swimming',
  'swindled',
  'swing-wing',
  'swingeing',
  'swinging',
  'swingy',
  'swinish',
  'swirlier',
  'swirliest',
  'swirling',
  'swirly',
  'swish',
  'swishier',
  'swishiest',
  'swishing',
  'swishy',
  'switch',
  'swollen',
  'swollen-headed',
  'swooning',
  'sword-shaped',
  'swordless',
  'swordlike',
  'sworn',
  'sybarite',
  'sybaritic',
  'sycophantic',
  'sycophantical',
  'sycophantish',
  'syenitic',
  'syllabic',
  'syllabled',
  'syllogistic',
  'sylphic',
  'sylphid',
  'sylphish',
  'sylphy',
  'sylvan',
  'sylvatic',
  'symbiotic',
  'symbolic',
  'symbolical',
  'symbolist',
  'symbolistic',
  'symbolistical',
  'symmetric',
  'symmetrical',
  'sympathetic',
  'sympatholytic',
  'sympathomimetic',
  'sympatric',
  'sympetalous',
  'symphonic',
  'symphonious',
  'symphysial',
  'sympodial',
  'symposiac',
  'symptomatic',
  'symptomless',
  'synaesthetic',
  'synagogical',
  'synaptic',
  'syncarpous',
  'synchromesh',
  'synchronal',
  'synchronic',
  'synchronistic',
  'synchronistical',
  'synchronized',
  'synchronous',
  'synclastic',
  'synclinal',
  'syncopated',
  'syncretic',
  'syncytial',
  'syndactyl',
  'syndesmotic',
  'syndetic',
  'syndicalist',
  'syndicalistic',
  'syndromic',
  'synecdochic',
  'synecdochical',
  'synecologic',
  'synecological',
  'synergetic',
  'synergist',
  'synergistic',
  'syngamic',
  'synodal',
  'synodic',
  'synoecious',
  'synoicous',
  'synonymic',
  'synonymical',
  'synonymous',
  'synoptic',
  'synoptical',
  'synoptistic',
  'synovial',
  'syntactic',
  'syntactical',
  'synthetic',
  'synthetical',
  'syntonic',
  'syntonous',
  'syphilitic',
  'syphiloid',
  'syringeal',
  'syrupy',
  'systaltic',
  'systematic',
  'systematized',
  'systemic',
  'systemless',
  'syzygial',
  'tabby',
  'tabernacular',
  'tabescent',
  'taboo',
  'tabu',
  'tabular',
  'tabulate',
  'tachistoscopic',
  'tachygraphic',
  'tachygraphical',
  'tacit',
  'taciturn',
  'tackier',
  'tackiest',
  'tacky',
  'tactful',
  'tactical',
  'tactile',
  'tactless',
  'tactual',
  'tagged',
  'tagmemic',
  'tai',
  'tail',
  'tailed',
  'tailing',
  'tailless',
  'taillike',
  'tailor-made',
  'tailored',
  'tainted',
  'taintless',
  'take-down',
  'take-out',
  'takeaway',
  'taken',
  'taking',
  'talc',
  'talcose',
  'talented',
  'taligrade',
  'taliped',
  'talismanic',
  'talismanical',
  'talkable',
  'talkative',
  'talking',
  'talky',
  'tall',
  'tallish',
  'tallowy',
  'taloned',
  'tamable',
  'tame',
  'tameable',
  'tamed',
  'tameless',
  'tamer',
  'tamest',
  'taming',
  'tan',
  'tanagrine',
  'tandem',
  'tangent',
  'tangential',
  'tangerine',
  'tangible',
  'tangier',
  'tangiest',
  'tangled',
  'tangy',
  'tanked',
  'tannable',
  'tanned',
  'tannic',
  'tantalic',
  'tantalizing',
  'tantalous',
  'taped',
  'tapeless',
  'tapelike',
  'tapered',
  'tapering',
  'tapestried',
  'tapetal',
  'tappable',
  'tapped',
  'tardier',
  'tardiest',
  'tardigrade',
  'tardy',
  'tariffless',
  'tarmac',
  'tarmacadam',
  'tarnal',
  'tarnishable',
  'tarnished',
  'tarot',
  'tarry',
  'tarsal',
  'tart',
  'tartaric',
  'tartish',
  'tasimetric',
  'tasseled',
  'tasselled',
  'tasteful',
  'tasteless',
  'tasty',
  'tattered',
  'tattling',
  'tatty',
  'taught',
  'taunt',
  'taunting',
  'tauriform',
  'taurine',
  'tauromachian',
  'taut',
  'tautologic',
  'tautological',
  'tautomeric',
  'tawdrier',
  'tawdriest',
  'tawdry',
  'tawie',
  'tawney',
  'tawnier',
  'tawniest',
  'tawny',
  'tax-deductible',
  'tax-exempt',
  'tax-free',
  'taxable',
  'taxaceous',
  'taxidermal',
  'taxidermic',
  'taxing',
  'taxonomic',
  'taxonomical',
  'taxpaying',
  'tea-table',
  'teachable',
  'teacherless',
  'tearable',
  'tearaway',
  'tearful',
  'tearier',
  'teariest',
  'tearing',
  'tearless',
  'teary',
  'teased',
  'teasing',
  'techier',
  'techiest',
  'technical',
  'technocrat',
  'technological',
  'techy',
  'tectonic',
  'tectricial',
  'tedious',
  'teeming',
  'teen',
  'teenage',
  'teenier',
  'teeniest',
  'teensy',
  'teensy-weensy',
  'teentsy',
  'teeny',
  'teeny-weeny',
  'teetotal',
  'tegular',
  'teind',
  'teknonymous',
  'telaesthetic',
  'telangiectatic',
  'telautographic',
  'telegenic',
  'telegnostic',
  'telegonic',
  'telegrammatic',
  'telegrammic',
  'telegraphic',
  'telekinetic',
  'telemetered',
  'telencephalic',
  'teleological',
  'teleost',
  'teleostean',
  'telepathic',
  'telephonic',
  'telephotographic',
  'telescoped',
  'telescopic',
  'telesthetic',
  'televisional',
  'televisionary',
  'telial',
  'telic',
  'telling',
  'telltale',
  'tellurian',
  'telluric',
  'tellurous',
  'telocentric',
  'telophasic',
  'temerarious',
  'temperamental',
  'temperate',
  'tempered',
  'tempering',
  'tempest-tossed',
  'tempestuous',
  'templed',
  'temporal',
  'temporary',
  'temptable',
  'tempting',
  'ten',
  'tenable',
  'tenacious',
  'tenantable',
  'tenanted',
  'tenantless',
  'tendencious',
  'tendentious',
  'tender',
  'tender-hearted',
  'tenderized',
  'tending',
  'tendinous',
  'tendrillar',
  'tenebrific',
  'tenebrious',
  'tenebrism',
  'tenebrous',
  'tenfold',
  'tenor',
  'tenpenny',
  'tense',
  'tensed',
  'tenseless',
  'tenser',
  'tensest',
  'tensible',
  'tensile',
  'tensing',
  'tensional',
  'tensionless',
  'tensive',
  'tentacled',
  'tentacular',
  'tentaculoid',
  'tentative',
  'tented',
  'tenth',
  'tentie',
  'tentier',
  'tentiest',
  'tentless',
  'tentorial',
  'tenty',
  'tenuous',
  'tenured',
  'tenurial',
  'tenuto',
  'tephritic',
  'tepid',
  'tepidity',
  'tepidness',
  'teratogenic',
  'teratoid',
  'terbic',
  'tercentenary',
  'terebinthine',
  'terefah',
  'terete',
  'tergal',
  'tergiversatory',
  'teriyaki',
  'terminable',
  'terminal',
  'terminated',
  'terminational',
  'terminative',
  'terminatory',
  'terminist',
  'terminological',
  'termless',
  'ternary',
  'ternate',
  'terpsichorean',
  'terraqueous',
  'terrene',
  'terrestrial',
  'terrible',
  'terrific',
  'terrified',
  'terrifying',
  'terrigenous',
  'territorial',
  'terror-stricken',
  'terror-struck',
  'terrorful',
  'terrorist',
  'terroristic',
  'terrorless',
  'terse',
  'tertial',
  'tertian',
  'tertiary',
  'tertius',
  'tervalent',
  'tessellated',
  'test',
  'test-tube',
  'testable',
  'testaceous',
  'testamentary',
  'testate',
  'tested',
  'testicular',
  'testiculate',
  'testimonial',
  'testudinal',
  'testy',
  'tetanic',
  'tetartohedral',
  'tetchy',
  'tethered',
  'tetrabasic',
  'tetrabranchiate',
  'tetracid',
  'tetracyclic',
  'tetradynamous',
  'tetraethyl',
  'tetragonal',
  'tetrahedral',
  'tetramerous',
  'tetrandrous',
  'tetraploid',
  'tetrapodic',
  'tetrapterous',
  'tetrarchic',
  'tetrarchical',
  'tetrasporic',
  'tetrastichic',
  'tetrastichous',
  'tetrasyllabic',
  'tetrasyllabical',
  'tetratomic',
  'tetravalent',
  'textbook',
  'textbookish',
  'textile',
  'textless',
  'textual',
  'textualism',
  'textuary',
  'textured',
  'thae',
  'thalamencephalic',
  'thalassic',
  'thalassographic',
  'thallic',
  'thalloid',
  'thallophytic',
  'thallous',
  'thank-you',
  'thankful',
  'thankless',
  'thankworthy',
  'thatchless',
  'thawed',
  'theaceous',
  'thearchic',
  'theatrical',
  'thecal',
  'thecate',
  'thecodont',
  'theism',
  'theist',
  'theistic',
  'theistical',
  'thelytokous',
  'thematic',
  'themeless',
  'then',
  'thenar',
  'theocentric',
  'theocratic',
  'theocratical',
  'theodicean',
  'theodolitic',
  'theogonic',
  'theological',
  'theomorphic',
  'theophanic',
  'theoretic',
  'theoretical',
  'theosophic',
  'theosophical',
  'theralite',
  'therapeutic',
  'theriacal',
  'therian',
  'therianthropic',
  'theriomorphic',
  'thermal',
  'thermic',
  'thermionic',
  'thermochemical',
  'thermoduric',
  'thermodynamic',
  'thermodynamical',
  'thermoelectrical',
  'thermogenetic',
  'thermogenic',
  'thermolabile',
  'thermoluminescent',
  'thermolytic',
  'thermometric',
  'thermonuclear',
  'thermophile',
  'thermoplastic',
  'thermoscopic',
  'thermosetting',
  'thermostable',
  'thermostatic',
  'thermotactic',
  'thermotaxic',
  'thermotropic',
  'theroid',
  'theropod',
  'thersitical',
  'thespian',
  'thetic',
  'theurgic',
  'theurgical',
  'thick',
  'thick-skinned',
  'thick-skulled',
  'thick-witted',
  'thickened',
  'thickening',
  'thicketed',
  'thickety',
  'thickset',
  'thieving',
  'thievish',
  'thigmotactic',
  'thigmotropic',
  'thin',
  'thin-skinned',
  'thinkable',
  'thinking',
  'thinned',
  'thinnish',
  'thiocyanic',
  'third',
  'third-class',
  'third-rate',
  'thirdstream',
  'thirstier',
  'thirstiest',
  'thirstless',
  'thirsty',
  'thirteen',
  'thirteenth',
  'thirtieth',
  'thirty',
  'thistly',
  'thixotropic',
  'thoracic',
  'thornier',
  'thorniest',
  'thornless',
  'thorny',
  'thorough',
  'thoroughbred',
  'thought-out',
  'thoughtful',
  'thoughtless',
  'thousand',
  'thousandfold',
  'thousandth',
  'thowless',
  'thrashing',
  'thrasonical',
  'thraw',
  'thrawn',
  'threadbare',
  'threadlike',
  'thready',
  'threatened',
  'threatening',
  'threatful',
  'three',
  'three-cornered',
  'three-dimensional',
  'three-legged',
  'three-masted',
  'three-phase',
  'three-piece',
  'three-ply',
  'three-quarter',
  'three-sided',
  'three-square',
  'three-way',
  'threefold',
  'threepenny',
  'threescore',
  'threnodial',
  'thriftier',
  'thriftiest',
  'thriftless',
  'thrifty',
  'thrilled',
  'thrilling',
  'thriving',
  'throated',
  'throatier',
  'throatiest',
  'throaty',
  'throbbing',
  'throbless',
  'thrombolytic',
  'thrombosed',
  'thrombotic',
  'throneless',
  'thronged',
  'through',
  'through-composed',
  'through-other',
  'throwback',
  'thrown',
  'thru',
  'thudding',
  'thuggish',
  'thumbed',
  'thumbless',
  'thumblike',
  'thumping',
  'thundering',
  'thunderous',
  'thundery',
  'thwart',
  'thwarted',
  'thwarting',
  'thwartwise',
  'thymelaeaceous',
  'thymic',
  'thymier',
  'thymiest',
  'thymy',
  'thyroid',
  'thyrsoid',
  'thysanuran',
  'thysanurous',
  'tiaraed',
  'tibial',
  'tickety-boo',
  'tickling',
  'ticklish',
  'tidal',
  'tiddley',
  'tiddly',
  'tideless',
  'tidied',
  'tidy',
  'tie-in',
  'tied',
  'tierced',
  'tigerish',
  'tight',
  'tight-fisted',
  'tight-laced',
  'tight-lipped',
  'tightened',
  'tightknit',
  'tightly-knit',
  'tiled',
  'tiliaceous',
  'tillable',
  'tilled',
  'tilted',
  'timber-framed',
  'timber-line',
  'timbered',
  'time-consuming',
  'time-honoured',
  'time-sharing',
  'timed',
  'timeless',
  'timely',
  'timeous',
  'timid',
  'timocratic',
  'timocratical',
  'timorous',
  'tin',
  'tinct',
  'tinctorial',
  'tindery',
  'tineal',
  'tined',
  'tineid',
  'tinged',
  'tinglier',
  'tingliest',
  'tingling',
  'tingly',
  'tinhorn',
  'tinier',
  'tiniest',
  'tinklier',
  'tinkliest',
  'tinkling',
  'tinkly',
  'tinned',
  'tinnier',
  'tinniest',
  'tinny',
  'tinpot',
  'tinsel',
  'tinselly',
  'tinted',
  'tintless',
  'tiny',
  'tip-and-run',
  'tip-tilted',
  'tip-up',
  'tippable',
  'tipped',
  'tippier',
  'tippiest',
  'tippy',
  'tipsier',
  'tipsy',
  'tiptoe',
  'tiptop',
  'tired',
  'tireless',
  'tiresome',
  'tiring',
  'titanic',
  'titaniferous',
  'titanous',
  'tithable',
  'titillated',
  'titillating',
  'titillative',
  'titled',
  'tittering',
  'tittuppy',
  'titubant',
  'titular',
  'titulary',
  'to-and-fro',
  'to-be',
  'toadyish',
  'toasted',
  'toe',
  'toed',
  'toeless',
  'toey',
  'toffee-nosed',
  'togaed',
  'togate',
  'together',
  'togged',
  'toilful',
  'toiling',
  'toilsome',
  'token',
  'told',
  'tolerable',
  'tolerant',
  'toluic',
  'tombless',
  'tomboyish',
  'tomentose',
  'tomentous',
  'tomfoolish',
  'tomial',
  'ton-up',
  'tonal',
  'tonalitive',
  'tone-deaf',
  'toned',
  'toneless',
  'tonetic',
  'tongue-in-cheek',
  'tongue-lash',
  'tongue-tied',
  'tongued',
  'tongueless',
  'tonguelike',
  'tonic',
  'tonier',
  'toniest',
  'tonish',
  'tonnish',
  'tonsillar',
  'tonsillary',
  'tonsillitic',
  'tonsorial',
  'tonsured',
  'tony',
  'too-too',
  'toom',
  'toothed',
  'toothier',
  'toothiest',
  'toothless',
  'toothlike',
  'toothsome',
  'toothy',
  'top',
  'top-down',
  'top-drawer',
  'top-flight',
  'top-hat',
  'top-heavy',
  'top-hole',
  'top-level',
  'top-secret',
  'topazine',
  'topfull',
  'tophaceous',
  'topiary',
  'topical',
  'topless',
  'toplofty',
  'topmost',
  'topographic',
  'topographical',
  'topologic',
  'topological',
  'toponymic',
  'toponymical',
  'topped',
  'topping',
  'toppling',
  'tops',
  'toreutic',
  'toric',
  'tormented',
  'torn',
  'tornadic',
  'toroidal',
  'torose',
  'torpid',
  'torporific',
  'torquate',
  'torrent',
  'torrential',
  'torrid',
  'tortile',
  'tortious',
  'tortoise-shell',
  'tortricid',
  'tortuous',
  'tortured',
  'torturesome',
  'torturing',
  'torturous',
  'torulose',
  'tossing',
  'total',
  'totalitarian',
  'totemic',
  'tother',
  'totipalmate',
  'totipotent',
  'tottering',
  'tottery',
  'touch-and-go',
  'touchable',
  'touched',
  'touchier',
  'touchiest',
  'touching',
  'touchy',
  'tough',
  'tough-minded',
  'toughened',
  'toughish',
  'touring',
  'tourist',
  'touristic',
  'touristy',
  'tousled',
  'tow-headed',
  'towable',
  'toward',
  'towardly',
  'towered',
  'towering',
  'towerless',
  'towery',
  'townish',
  'townless',
  'towy',
  'toxemic',
  'toxic',
  'toxicant',
  'toxicogenic',
  'toxicologic',
  'toxicological',
  'toxophilite',
  'toxophilitic',
  'toyless',
  'toylike',
  'trabeate',
  'trabeated',
  'trabecular',
  'trabeculate',
  'traceable',
  'traced',
  'traceless',
  'traceried',
  'tracheal',
  'trachytic',
  'trachytoid',
  'trackable',
  'tracked',
  'trackless',
  'tractable',
  'tractile',
  'tractive',
  'tractrix',
  'trad',
  'trade',
  'trade-in',
  'trade-union',
  'traded',
  'traditional',
  'traditionalism',
  'traditionalist',
  'traditionalistic',
  'traditive',
  'traducianistic',
  'trafficless',
  'tragic',
  'tragical',
  'trailing',
  'trainable',
  'trained',
  'trainless',
  'traitorous',
  'tralatitious',
  'tramontane',
  'tramping',
  'trampled',
  'trampling',
  'tranquil',
  'tranquilizing',
  'transactional',
  'transalpine',
  'transatlantic',
  'transcalent',
  'transcendent',
  'transcendental',
  'transcendentalism',
  'transcendentalist',
  'transcontinental',
  'transcribed',
  'transcriptional',
  'transcriptive',
  'transeunt',
  'transferable',
  'transferential',
  'transferrable',
  'transfinite',
  'transfixed',
  'transformable',
  'transformational',
  'transformative',
  'transformed',
  'transformistic',
  'transfusable',
  'transfusible',
  'transfusive',
  'transgressive',
  'transhuman',
  'transhumant',
  'transient',
  'transilient',
  'transisthmian',
  'transistorized',
  'transitional',
  'transitionary',
  'transitive',
  'transitory',
  'translatable',
  'translational',
  'translative',
  'translucent',
  'translucid',
  'translunar',
  'translunary',
  'transmarine',
  'transmigrant',
  'transmissible',
  'transmissive',
  'transmittable',
  'transmitted',
  'transmittible',
  'transmontane',
  'transmundane',
  'transmutable',
  'transmutation',
  'transnational',
  'transoceanic',
  'transonic',
  'transpacific',
  'transpadane',
  'transparent',
  'transpersonal',
  'transpicuous',
  'transpirable',
  'transpiratory',
  'transpiring',
  'transplantable',
  'transpolar',
  'transpontine',
  'transportable',
  'transported',
  'transportive',
  'transposable',
  'transposed',
  'transpositional',
  'transpositive',
  'transsexual',
  'transubstantial',
  'transudatory',
  'transuranic',
  'transversal',
  'transverse',
  'transvestic',
  'transvestite',
  'trap-door',
  'trapezial',
  'trapeziform',
  'trapezohedral',
  'trapezoidal',
  'traplike',
  'trappean',
  'trapped',
  'trappy',
  'trashy',
  'traumatic',
  'travel-sick',
  'travel-soiled',
  'travel-stained',
  'traveled',
  'traveling',
  'travelled',
  'traversable',
  'traverse',
  'treacherous',
  'treacly',
  'treasonable',
  'treasonous',
  'treasured',
  'treated',
  'treble',
  'treed',
  'treeless',
  'treen',
  'tref',
  'trembling',
  'trembly',
  'tremendous',
  'tremolant',
  'tremolitic',
  'tremulous',
  'trenchant',
  'trendy',
  'trepid',
  'trespassing',
  'tressed',
  'tressier',
  'tressiest',
  'tressured',
  'tressy',
  'triable',
  'triacid',
  'triadelphous',
  'triadic',
  'trial',
  'trial-and-error',
  'triangled',
  'triangular',
  'triangulate',
  'triapsidal',
  'triatomic',
  'triaxial',
  'tribadic',
  'tribal',
  'tribalism',
  'tribasic',
  'tribeless',
  'triboelectric',
  'triboluminescent',
  'tribrachic',
  'tribunicial',
  'tribunitial',
  'tributary',
  'tricarpellary',
  'tricentenary',
  'tricentennial',
  'trichinous',
  'trichitic',
  'trichoid',
  'trichotomous',
  'trichromatic',
  'trichrome',
  'trickier',
  'trickiest',
  'trickish',
  'trickless',
  'tricksier',
  'tricksiest',
  'tricksome',
  'tricksy',
  'tricky',
  'triclinic',
  'tricolor',
  'tricorn',
  'tricostate',
  'tricrotic',
  'tricuspid',
  'tricuspidate',
  'tricyclic',
  'tridactyl',
  'trident',
  'tridentate',
  'tridimensional',
  'triecious',
  'tried',
  'triennial',
  'triethyl',
  'trifacial',
  'trifid',
  'trifling',
  'trifocal',
  'trifoliate',
  'triform',
  'trifurcate',
  'trig',
  'trigamous',
  'trigeminal',
  'trigger-happy',
  'trigonal',
  'trigonometric',
  'trigonometrical',
  'trigonous',
  'trihedral',
  'trihydric',
  'trilateral',
  'trilinear',
  'trilingual',
  'triliteral',
  'trilled',
  'trillion',
  'trillionth',
  'trilobate',
  'trilobated',
  'trilobed',
  'trilocular',
  'trim',
  'trimeric',
  'trimerous',
  'trimestrial',
  'trimeter',
  'trimetric',
  'trimmed',
  'trimonthly',
  'trimorphic',
  'trimorphous',
  'trinal',
  'trinary',
  'trine',
  'trinomial',
  'trioecious',
  'tripartite',
  'tripedal',
  'tripersonal',
  'tripetalous',
  'triphibious',
  'triphthongal',
  'triphyllous',
  'tripinnate',
  'triple',
  'triplex',
  'triplicate',
  'triploid',
  'tripodal',
  'trippant',
  'tripping',
  'tripterous',
  'triquetrous',
  'triradiate',
  'trisomic',
  'triste',
  'tristful',
  'tristichic',
  'tristichous',
  'trisyllabic',
  'trisyllabical',
  'tritanopic',
  'trite',
  'triter',
  'tritest',
  'tritheism',
  'tritheist',
  'tritheistic',
  'tritheistical',
  'triumphal',
  'triumphant',
  'triumviral',
  'triune',
  'trivalent',
  'trivalve',
  'trivial',
  'trochaic',
  'trochal',
  'trochanteric',
  'trochlear',
  'trochoid',
  'trochoidal',
  'trodden',
  'troglodytic',
  'troglodytical',
  'trollopy',
  'tromometric',
  "trompe-l'oeil",
  'trophallactic',
  'trophic',
  'trophied',
  'trophotropic',
  'tropic',
  'tropical',
  'tropistic',
  'tropologic',
  'tropological',
  'tropophilous',
  'tropospheric',
  'troppo',
  'trothless',
  'trouble-free',
  'troubled',
  'troubleshooter',
  'troublesome',
  'troubling',
  'troublous',
  'trousered',
  'troy',
  'truant',
  'truceless',
  'truculent',
  'true',
  'true-blue',
  'true-born',
  'true-life',
  'truer',
  'truffled',
  'truistic',
  'trumped-up',
  'trumpery',
  'truncate',
  'truncated',
  'trunnioned',
  'trussed',
  'trusted',
  'trustful',
  'trusting',
  'trustless',
  'trustworthy',
  'trusty',
  'truthful',
  'truthless',
  'trying',
  'tryptic',
  'tsarism',
  'tsarist',
  'tubal',
  'tubate',
  'tubbier',
  'tubbiest',
  'tubby',
  'tubed',
  'tubeless',
  'tubelike',
  'tubercular',
  'tuberculate',
  'tuberculose',
  'tuberculous',
  'tuberous',
  'tubular',
  'tubulate',
  'tubuliflorous',
  'tubulous',
  'tucked',
  'tufaceous',
  'tuffaceous',
  'tufted',
  'tuitional',
  'tuitionary',
  'tularaemic',
  'tularemic',
  'tum',
  'tumbling',
  'tumefacient',
  'tumescent',
  'tumid',
  'tumular',
  'tumultuous',
  'tunable',
  'tuneable',
  'tuned',
  'tuneful',
  'tuneless',
  'tunicate',
  'tuppenny',
  'turbaned',
  'turbellarian',
  'turbid',
  'turbinal',
  'turbinate',
  'turbo-electric',
  'turbulent',
  'turdine',
  'turfier',
  'turfiest',
  'turfy',
  'turgent',
  'turgescent',
  'turgid',
  'turned',
  'turning',
  'turnover',
  'turreted',
  'turtleneck',
  'tushed',
  'tuskless',
  'tussal',
  'tussive',
  'tutelar',
  'tutelary',
  'tutored',
  'tutorial',
  'tutti',
  'tutti-frutti',
  'twaddly',
  'twee',
  'tweedier',
  'tweediest',
  'tweedy',
  'twelfth',
  'twelve',
  'twelve-tone',
  'twelvefold',
  'twentieth',
  'twenty',
  'twenty-first',
  'twenty-five',
  'twenty-four',
  'twenty-one',
  'twenty-twenty',
  'twenty-two',
  'twentyfold',
  'twice-laid',
  'twice-told',
  'twiggier',
  'twiggiest',
  'twiggy',
  'twilight',
  'twilit',
  'twill',
  'twilled',
  'twin',
  'twin-screw',
  'twined',
  'twinkling',
  'twinkly',
  'twinned',
  'twinning',
  'twistable',
  'twisted',
  'twisting',
  'twisty',
  'twittery',
  'two',
  'two-a-penny',
  'two-bit',
  'two-dimensional',
  'two-edged',
  'two-faced',
  'two-fisted',
  'two-footed',
  'two-handed',
  'two-masted',
  'two-piece',
  'two-ply',
  'two-sided',
  'two-timing',
  'two-tone',
  'two-way',
  'twofold',
  'twopenny',
  'twopenny-halfpenny',
  'twp',
  'tympanic',
  'tympanitic',
  'typal',
  'type-high',
  'typed',
  'typewritten',
  'typhoid',
  'typhonic',
  'typhous',
  'typic',
  'typical',
  'typographic',
  'typographical',
  'typological',
  'tyrannic',
  'tyrannical',
  'tyrannicidal',
  'tyrannous',
  'ubiquitarian',
  'ubiquitous',
  'ugly',
  'ugsome',
  'ulcerated',
  'ulcerative',
  'ulcerous',
  'uliginous',
  'ullaged',
  'ulmaceous',
  'ulnar',
  'ulotrichous',
  'ult',
  'ulterior',
  'ultimate',
  'ultimo',
  'ultra',
  'ultrabasic',
  'ultracentrifugal',
  'ultrahigh-frequency',
  'ultraism',
  'ultraist',
  'ultramarine',
  'ultramicroscopic',
  'ultramontane',
  'ultramundane',
  'ultrared',
  'ultrashort',
  'ultrasonic',
  'ultraviolet',
  'ululant',
  'umbellar',
  'umbellate',
  'umbellated',
  'umbellately',
  'umbelliferous',
  'umber',
  'umbilical',
  'umbilicate',
  'umbonal',
  'umbonate',
  'umbrageous',
  'umbral',
  'umbrella',
  'umbrose',
  'umpteen',
  'umpteenth',
  'umptieth',
  'un-American',
  'un-English',
  'unabashed',
  'unabated',
  'unabbreviated',
  'unable',
  'unabolished',
  'unabridged',
  'unabrogated',
  'unabsolved',
  'unacademic',
  'unaccented',
  'unaccentuated',
  'unacceptable',
  'unaccommodated',
  'unaccommodating',
  'unaccompanied',
  'unaccomplished',
  'unaccountable',
  'unaccounted',
  'unaccounted-for',
  'unaccredited',
  'unaccusable',
  'unaccused',
  'unaccustomed',
  'unachievable',
  'unaching',
  'unacknowledged',
  'unacquainted',
  'unactable',
  'unacted',
  'unactuated',
  'unadaptable',
  'unadapted',
  'unaddressed',
  'unadjusted',
  'unadmired',
  'unadmiring',
  'unadmitted',
  'unadmonished',
  'unadopted',
  'unadored',
  'unadorned',
  'unadulterate',
  'unadulterated',
  'unadventurous',
  'unadvertised',
  'unadvisable',
  'unadvised',
  'unaffected',
  'unaffecting',
  'unaffiliated',
  'unafraid',
  'unaidable',
  'unaided',
  'unaimed',
  'unaired',
  'unalienable',
  'unaligned',
  'unalike',
  'unallayed',
  'unallied',
  'unallotted',
  'unallowable',
  'unalloyed',
  'unalterable',
  'unaltered',
  'unaltering',
  'unamazed',
  'unambiguous',
  'unambitious',
  'unamenable',
  'unamendable',
  'unamended',
  'unamerced',
  'unamiable',
  'unamusable',
  'unamused',
  'unamusing',
  'unanalytic',
  'unanalytical',
  'unanalyzable',
  'unanalyzed',
  'unanchored',
  'unaneled',
  'unanimated',
  'unanimous',
  'unannealed',
  'unannotated',
  'unannounced',
  'unanswerable',
  'unanswered',
  'unanticipated',
  'unanxious',
  'unapologetic',
  'unappalled',
  'unapparelled',
  'unapparent',
  'unappealable',
  'unappealing',
  'unappeasable',
  'unappeased',
  'unappetising',
  'unappetizing',
  'unapplausive',
  'unapplicable',
  'unapplied',
  'unappointed',
  'unappreciated',
  'unappreciative',
  'unapprehended',
  'unapprehensible',
  'unapprehensive',
  'unapprised',
  'unapproachable',
  'unapproached',
  'unappropriated',
  'unapproved',
  'unapproving',
  'unapt',
  'unarguable',
  'unargued',
  'unarmed',
  'unarmoured',
  'unarranged',
  'unartful',
  'unarticulate',
  'unarticulated',
  'unartificial',
  'unartistic',
  'unary',
  'unascendable',
  'unascended',
  'unascertainable',
  'unascertained',
  'unashamed',
  'unasked',
  'unaspirated',
  'unaspiring',
  'unassailable',
  'unassailed',
  'unassayed',
  'unassertive',
  'unassignable',
  'unassigned',
  'unassimilable',
  'unassimilated',
  'unassisted',
  'unassisting',
  'unassociated',
  'unassuageable',
  'unassumed',
  'unassuming',
  'unassured',
  'unatoned',
  'unattached',
  'unattainable',
  'unattained',
  'unattainted',
  'unattempted',
  'unattended',
  'unattentive',
  'unattested',
  'unattired',
  'unattractive',
  'unaugmented',
  'unauspicious',
  'unauthentic',
  'unauthenticated',
  'unauthorised',
  'unauthoritative',
  'unauthorized',
  'unavailable',
  'unavailing',
  'unavenged',
  'unavoidable',
  'unavowed',
  'unawakened',
  'unawakening',
  'unaware',
  'unawed',
  'unbacked',
  'unbaffled',
  'unbagged',
  'unbailable',
  'unbaked',
  'unbalanced',
  'unbanded',
  'unbaptised',
  'unbaptized',
  'unbarbed',
  'unbarbered',
  'unbarking',
  'unbarred',
  'unbashful',
  'unbated',
  'unbathed',
  'unbattered',
  'unbearable',
  'unbearded',
  'unbearing',
  'unbeatable',
  'unbeaten',
  'unbeautiful',
  'unbecoming',
  'unbedimmed',
  'unbefitting',
  'unbefriended',
  'unbegged',
  'unbeguiled',
  'unbeguiling',
  'unbeholden',
  'unbeknown',
  'unbeknownst',
  'unbelievable',
  'unbelieving',
  'unbeloved',
  'unbelted',
  'unbendable',
  'unbending',
  'unbeneficed',
  'unbeneficial',
  'unbenefited',
  'unbenign',
  'unbenignant',
  'unbent',
  'unbeseeming',
  'unbesought',
  'unbespoken',
  'unbestowed',
  'unbetrayed',
  'unbettered',
  'unbewailed',
  'unbiased',
  'unbiassed',
  'unbid',
  'unbidden',
  'unblamable',
  'unblamed',
  'unbleached',
  'unblemished',
  'unblenched',
  'unblenching',
  'unblended',
  'unblent',
  'unblessed',
  'unblindfolded',
  'unblinding',
  'unblinking',
  'unblissful',
  'unblocked',
  'unbloodied',
  'unbloody',
  'unblotted',
  'unblown',
  'unblunted',
  'unblushing',
  'unboastful',
  'unbodied',
  'unboding',
  'unbolted',
  'unboned',
  'unbonneted',
  'unbooked',
  'unbookish',
  'unbooted',
  'unborn',
  'unborne',
  'unborrowed',
  'unbought',
  'unbound',
  'unbounded',
  'unbowed',
  'unbraced',
  'unbranched',
  'unbreachable',
  'unbreached',
  'unbreakable',
  'unbreathable',
  'unbreathed',
  'unbreathing',
  'unbred',
  'unbreeched',
  'unbribable',
  'unbridged',
  'unbridled',
  'unbroke',
  'unbroken',
  'unbrotherly',
  'unbruised',
  'unbrushed',
  'unbudgeted',
  'unbundled',
  'unburdened',
  'unburied',
  'unburned',
  'unburnished',
  'unburnt',
  'unburrowed',
  'unbusinesslike',
  'unbuttered',
  'unbuttoned',
  'uncaged',
  'uncalculated',
  'uncalculating',
  'uncalled',
  'uncalled-for',
  'uncandid',
  'uncanny',
  'uncanonical',
  'uncapable',
  'uncapped',
  'uncapsizable',
  'uncared-for',
  'uncaring',
  'uncarpeted',
  'uncarted',
  'uncashed',
  'uncatalogued',
  'uncaught',
  'uncaused',
  'unceasing',
  'uncelebrated',
  'uncensored',
  'uncensorious',
  'uncensured',
  'unceremonious',
  'uncertain',
  'uncertificated',
  'uncertified',
  'unchained',
  'unchallengeable',
  'unchallenged',
  'unchancy',
  'unchangeable',
  'unchanged',
  'unchanging',
  'unchaperoned',
  'uncharacteristic',
  'uncharge',
  'uncharged',
  'uncharitable',
  'uncharmed',
  'uncharming',
  'uncharted',
  'unchartered',
  'unchary',
  'unchaste',
  'unchastened',
  'unchastisable',
  'unchastised',
  'uncheckable',
  'unchecked',
  'uncheered',
  'uncheerful',
  'unchewed',
  'unchildlike',
  'unchivalrous',
  'unchosen',
  'unchristened',
  'unchristian',
  'unchristianly',
  'unchronicled',
  'uncial',
  'unciform',
  'uncinate',
  'uncircumcised',
  'uncircumscribed',
  'uncited',
  'uncivil',
  'uncivilized',
  'unclad',
  'unclaimed',
  'unclassed',
  'unclassical',
  'unclassifiable',
  'unclassified',
  'unclean',
  'uncleaned',
  'uncleanly',
  'uncleansed',
  'unclear',
  'uncleared',
  'unclerical',
  'unclimbable',
  'unclimbed',
  'unclipped',
  'unclogged',
  'uncloistered',
  'unclothed',
  'unclouded',
  'uncloudy',
  'uncloven',
  'unclutched',
  'uncluttered',
  'unco',
  'uncoated',
  'uncocked',
  'uncoiled',
  'uncoined',
  'uncollected',
  'uncoloured',
  'uncombed',
  'uncombined',
  'uncombining',
  'uncomely',
  'uncomfortable',
  'uncomforted',
  'uncommendable',
  'uncommercial',
  'uncommitted',
  'uncommon',
  'uncommunicative',
  'uncommuted',
  'uncompanionable',
  'uncompanioned',
  'uncompassionate',
  'uncompelled',
  'uncompensated',
  'uncompetitive',
  'uncomplaining',
  'uncomplaisant',
  'uncompleted',
  'uncompliant',
  'uncomplicated',
  'uncomplimentary',
  'uncomplying',
  'uncomposable',
  'uncompounded',
  'uncomprehended',
  'uncomprehending',
  'uncomprehensive',
  'uncompromising',
  'unconcealed',
  'unconcealing',
  'unconceived',
  'unconcerned',
  'unconcerted',
  'unconciliatory',
  'unconditional',
  'unconditioned',
  'unconfederated',
  'unconfessed',
  'unconfinable',
  'unconfined',
  'unconfining',
  'unconfirmed',
  'unconformable',
  'unconforming',
  'unconfused',
  'uncongenial',
  'unconjectured',
  'unconjugal',
  'unconjunctive',
  'unconnected',
  'unconniving',
  'unconquerable',
  'unconquered',
  'unconscientious',
  'unconscionable',
  'unconscious',
  'unconsecrated',
  'unconsentaneous',
  'unconsenting',
  'unconsidered',
  'unconsidering',
  'unconsoled',
  'unconsolidated',
  'unconstant',
  'unconstitutional',
  'unconstrainable',
  'unconstrained',
  'unconsumed',
  'unconsummated',
  'uncontaminated',
  'uncontemned',
  'uncontemplated',
  'uncontentious',
  'uncontestable',
  'uncontested',
  'uncontradicted',
  'uncontrived',
  'uncontrollable',
  'uncontrolled',
  'uncontroversial',
  'uncontroverted',
  'uncontrovertible',
  'unconventional',
  'unconversable',
  'unconversant',
  'unconverted',
  'unconvertible',
  'unconvicted',
  'unconvinced',
  'unconvincing',
  'uncooked',
  'uncooperative',
  'uncoordinated',
  'uncoquettish',
  'uncordial',
  'uncorrected',
  'uncorroborated',
  'uncorrupt',
  'uncorrupted',
  'uncorseted',
  'uncostly',
  'uncounselled',
  'uncountable',
  'uncounted',
  'uncoupled',
  'uncourtly',
  'uncouth',
  'uncovenanted',
  'uncovered',
  'uncrated',
  'uncreated',
  'uncreditable',
  'uncritical',
  'uncropped',
  'uncross',
  'uncrossed',
  'uncrowded',
  'uncrowned',
  'uncrumpled',
  'uncrumpling',
  'uncrushable',
  'uncrystallisable',
  'uncrystallizable',
  'uncrystallized',
  'unctuous',
  'unculled',
  'uncultivable',
  'uncultivatable',
  'uncultivated',
  'uncultured',
  'uncumbered',
  'uncurable',
  'uncurbable',
  'uncurbed',
  'uncurdled',
  'uncured',
  'uncurious',
  'uncurled',
  'uncurrent',
  'uncursed',
  'uncursing',
  'uncurtailed',
  'uncurtained',
  'uncurved',
  'uncustomary',
  'uncut',
  'undamaged',
  'undamped',
  'undated',
  'undaunted',
  'undawning',
  'undazzled',
  'undazzling',
  'undeaf',
  'undealt',
  'undebased',
  'undebauched',
  'undecayed',
  'undeceivable',
  'undeceived',
  'undecided',
  'undecipherable',
  'undecked',
  'undeclared',
  'undeclining',
  'undecomposable',
  'undecomposed',
  'undeeded',
  'undefaced',
  'undefeated',
  'undefended',
  'undefied',
  'undefiled',
  'undefinable',
  'undefined',
  'undeified',
  'undelayed',
  'undelaying',
  'undelectable',
  'undelegated',
  'undeliberate',
  'undelighted',
  'undelightful',
  'undeliverable',
  'undelivered',
  'undeluded',
  'undemanding',
  'undemocratic',
  'undemonstrable',
  'undemonstrative',
  'undeniable',
  'undenominational',
  'undependable',
  'undeplored',
  'undepraved',
  'undepreciated',
  'undepressed',
  'undeprived',
  'under',
  'under-the-counter',
  'underarm',
  'underbred',
  'underclad',
  'underclass',
  'underclothed',
  'undercoated',
  'undercover',
  'underdeveloped',
  'underdone',
  'underdressed',
  'underemployed',
  'underfed',
  'underfired',
  'underfloor',
  'underglaze',
  'undergraduette',
  'underground',
  'undergrown',
  'underhand',
  'underhanded',
  'underhung',
  'underlaid',
  'underlying',
  'undermanned',
  'undermasted',
  'undermentioned',
  'undermost',
  'undernamed',
  'underneath',
  'undernoted',
  'undernourished',
  'underpeopled',
  'underpowered',
  'underproof',
  'undersea',
  'undersealed',
  'undersexed',
  'undershot',
  'undersigned',
  'undersized',
  'underslung',
  'underspent',
  'understaffed',
  'understandable',
  'understanding',
  'understated',
  'understood',
  'underwater',
  'underweight',
  'underwrought',
  'undescendable',
  'undescended',
  'undescendible',
  'undescribable',
  'undescribed',
  'undescried',
  'undeserved',
  'undeserving',
  'undesigned',
  'undesigning',
  'undesirable',
  'undesired',
  'undesiring',
  'undesirous',
  'undespairing',
  'undespoiled',
  'undestroyed',
  'undetectable',
  'undetected',
  'undeterminable',
  'undetermined',
  'undeterred',
  'undeveloped',
  'undeviating',
  'undevout',
  'undiagnosed',
  'undifferentiated',
  'undigested',
  'undignified',
  'undiluted',
  'undiminishable',
  'undiminished',
  'undimmed',
  'undiplomatic',
  'undipped',
  'undirected',
  'undisappointing',
  'undiscerned',
  'undiscernible',
  'undiscerning',
  'undischarged',
  'undisciplinable',
  'undisciplined',
  'undisclosed',
  'undiscomfited',
  'undiscordant',
  'undiscording',
  'undiscouraged',
  'undiscoverable',
  'undiscovered',
  'undiscriminating',
  'undiscussable',
  'undiscussed',
  'undisguisable',
  'undisguised',
  'undismantled',
  'undismayed',
  'undisordered',
  'undispatched',
  'undispensed',
  'undisposed',
  'undisputed',
  'undissembled',
  'undissociated',
  'undissolved',
  'undissolving',
  'undistempered',
  'undistilled',
  'undistinguishable',
  'undistinguished',
  'undistinguishing',
  'undistorted',
  'undistracted',
  'undistracting',
  'undistributed',
  'undisturbed',
  'undisturbing',
  'undiversified',
  'undiverted',
  'undivested',
  'undividable',
  'undivided',
  'undivorced',
  'undivulged',
  'undocked',
  'undoctored',
  'undocumented',
  'undomestic',
  'undomesticated',
  'undone',
  'undoubtable',
  'undoubted',
  'undoubtful',
  'undoubting',
  'undrainable',
  'undrained',
  'undramatic',
  'undraped',
  'undrawn',
  'undreaded',
  'undreading',
  'undreamed',
  'undreamed-of',
  'undreaming',
  'undreamt',
  'undress',
  'undressed',
  'undried',
  'undrilled',
  'undrinkable',
  'undriven',
  'undrooping',
  'undrossy',
  'undrowned',
  'undrunk',
  'undubbed',
  'undue',
  'undug',
  'undulant',
  'undulate',
  'undulatory',
  'undulled',
  'unduteous',
  'undutiful',
  'undyed',
  'undying',
  'unearned',
  'unearthly',
  'uneasy',
  'uneatable',
  'uneaten',
  'uneclipsed',
  'uneconomic',
  'uneconomical',
  'unedifying',
  'unedited',
  'uneducable',
  'uneducated',
  'uneffaced',
  'uneffected',
  'unelaborate',
  'unelaborated',
  'unelated',
  'unelected',
  'unelectrified',
  'unembarrassed',
  'unembellished',
  'unembittered',
  'unembodied',
  'unemotional',
  'unemotioned',
  'unemphatic',
  'unemployable',
  'unemployed',
  'unemptied',
  'unenchanted',
  'unenclosed',
  'unencumbered',
  'unendangered',
  'unendeared',
  'unending',
  'unendowed',
  'unendurable',
  'unenforceable',
  'unenforced',
  'unengaged',
  'unenjoyable',
  'unenlightened',
  'unenquiring',
  'unenriched',
  'unenslaved',
  'unentailed',
  'unentered',
  'unenterprising',
  'unentertained',
  'unentertaining',
  'unenthralled',
  'unenthusiastic',
  'unentitled',
  'unenviable',
  'unenvied',
  'unenvious',
  'unenvying',
  'unequable',
  'unequal',
  'unequaled',
  'unequalled',
  'unequipped',
  'unequivocal',
  'unerasable',
  'unerring',
  'unescapable',
  'unescorted',
  'unespied',
  'unessayed',
  'unessential',
  'unestablished',
  'unethical',
  'unevangelical',
  'uneven',
  'uneventful',
  'unevidenced',
  'unexacting',
  'unexaggerated',
  'unexalted',
  'unexamined',
  'unexampled',
  'unexcavated',
  'unexcelled',
  'unexceptionable',
  'unexceptional',
  'unexcitable',
  'unexcited',
  'unexciting',
  'unexcluded',
  'unexclusive',
  'unexecuted',
  'unexercised',
  'unexhausted',
  'unexpanded',
  'unexpectant',
  'unexpected',
  'unexperienced',
  'unexpiated',
  'unexpired',
  'unexplainable',
  'unexplained',
  'unexploited',
  'unexplored',
  'unexposed',
  'unexpressed',
  'unexpressible',
  'unexpressive',
  'unexpurgated',
  'unextended',
  'unextenuated',
  'unextinct',
  'unextinguishable',
  'unextinguished',
  'unextreme',
  'unfabled',
  'unfadable',
  'unfaded',
  'unfading',
  'unfailing',
  'unfair',
  'unfaithful',
  'unfallen',
  'unfaltering',
  'unfamiliar',
  'unfanned',
  'unfashionable',
  'unfashioned',
  'unfastened',
  'unfastidious',
  'unfathered',
  'unfatherly',
  'unfathomable',
  'unfathomed',
  'unfaulty',
  'unfavorable',
  'unfavourable',
  'unfeared',
  'unfearful',
  'unfearing',
  'unfeasible',
  'unfeathered',
  'unfeatured',
  'unfed',
  'unfeeling',
  'unfeigned',
  'unfeigning',
  'unfelled',
  'unfelt',
  'unfeminine',
  'unfenced',
  'unfermented',
  'unfertilised',
  'unfertilized',
  'unfettered',
  'unfeudal',
  'unfiled',
  'unfilial',
  'unfilled',
  'unfilmed',
  'unfilterable',
  'unfiltered',
  'unfine',
  'unfinished',
  'unfired',
  'unfirm',
  'unfished',
  'unfit',
  'unfitted',
  'unfitting',
  'unfixed',
  'unflagging',
  'unflappable',
  'unflattering',
  'unflavoured',
  'unflawed',
  'unfledged',
  'unfleshly',
  'unflinching',
  'unflushed',
  'unflustered',
  'unfocused',
  'unfocussed',
  'unfolded',
  'unfooled',
  'unfooling',
  'unforbidden',
  'unforced',
  'unforcible',
  'unfordable',
  'unforeboding',
  'unforeknowable',
  'unforeknown',
  'unforeseeable',
  'unforeseeing',
  'unforeseen',
  'unforested',
  'unforetold',
  'unforewarned',
  'unforfeited',
  'unforged',
  'unforgettable',
  'unforgivable',
  'unforgiven',
  'unforgiving',
  'unforgotten',
  'unformalised',
  'unformalized',
  'unformed',
  'unformidable',
  'unformulated',
  'unforsaken',
  'unfortified',
  'unfortunate',
  'unfossiliferous',
  'unfossilised',
  'unfossilized',
  'unfostered',
  'unfought',
  'unfound',
  'unfounded',
  'unframed',
  'unfranchised',
  'unfraught',
  'unfree',
  'unfrequent',
  'unfrequented',
  'unfretted',
  'unfriended',
  'unfriendly',
  'unfrighted',
  'unfrightened',
  'unfrozen',
  'unfructuous',
  'unfruitful',
  'unfuelled',
  'unfulfilled',
  'unfunded',
  'unfunny',
  'unfurnished',
  'unfurred',
  'unfurrowed',
  'ungainful',
  'ungainly',
  'ungainsaid',
  'ungallant',
  'ungalled',
  'ungarbled',
  'ungarmented',
  'ungarnered',
  'ungarnished',
  'ungathered',
  'ungauged',
  'ungeared',
  'ungenerous',
  'ungenial',
  'ungenteel',
  'ungentle',
  'ungentlemanlike',
  'ungentlemanly',
  'ungenuine',
  'ungermane',
  'ungetatable',
  'unghostly',
  'ungifted',
  'ungilded',
  'ungilt',
  'ungirthed',
  'ungiving',
  'unglad',
  'unglazed',
  'unglossed',
  'ungloved',
  'ungodlier',
  'ungodliest',
  'ungodlike',
  'ungodly',
  'ungored',
  'ungorged',
  'ungotten',
  'ungovernable',
  'ungoverned',
  'ungowned',
  'ungraced',
  'ungraceful',
  'ungracious',
  'ungraded',
  'ungrammatical',
  'ungrassed',
  'ungrateful',
  'ungratified',
  'ungrazed',
  'ungroomed',
  'unground',
  'ungrounded',
  'ungrown',
  'ungrudged',
  'ungrudging',
  'ungual',
  'unguarded',
  'unguentary',
  'unguerdoned',
  'unguessed',
  'unguiculate',
  'unguiculated',
  'unguided',
  'ungulate',
  'unguled',
  'unguligrade',
  'ungummed',
  'ungyved',
  'unhabitable',
  'unhabituated',
  'unhacked',
  'unhackneyed',
  'unhailed',
  'unhallowed',
  'unhampered',
  'unhandled',
  'unhandseled',
  'unhandsome',
  'unhanged',
  'unhanging',
  'unhappier',
  'unhappy',
  'unharboured',
  'unhardened',
  'unharmed',
  'unharmful',
  'unharming',
  'unharmonious',
  'unharvested',
  'unhasting',
  'unhasty',
  'unhatched',
  'unhaunted',
  'unhazarded',
  'unhazardous',
  'unheaded',
  'unhealable',
  'unhealed',
  'unhealthful',
  'unhealthier',
  'unhealthiest',
  'unhealthy',
  'unheard',
  'unheard-of',
  'unheated',
  'unheeded',
  'unheedful',
  'unheeding',
  'unhelmeted',
  'unhelpable',
  'unhelped',
  'unhelpful',
  'unheralded',
  'unheroic',
  'unheroical',
  'unhesitating',
  'unhewn',
  'unhidden',
  'unhindered',
  'unhinged',
  'unhired',
  'unhistoric',
  'unhistorical',
  'unhoarded',
  'unhoarding',
  'unholy',
  'unhomely',
  'unhonoured',
  'unhooped',
  'unhoped',
  'unhoped-for',
  'unhopeful',
  'unhorsed',
  'unhouseled',
  'unhuman',
  'unhumbled',
  'unhung',
  'unhunted',
  'unhurried',
  'unhurrying',
  'unhurt',
  'unhurtful',
  'unhusbanded',
  'unhusked',
  'unhygienic',
  'unhyphenated',
  'uniaxial',
  'unicameral',
  'unicellular',
  'unicolor',
  'unicostate',
  'unideal',
  'unidealistic',
  'unidentifiable',
  'unidentified',
  'unidiomatic',
  'unidirectional',
  'unific',
  'unified',
  'unifilar',
  'uniflorous',
  'unifoliate',
  'unifoliolate',
  'uniform',
  'uniformed',
  'uniformitarian',
  'unifying',
  'unilateral',
  'unilateralist',
  'unilingual',
  'uniliteral',
  'unillumed',
  'unilluminated',
  'unilluminating',
  'unillustrated',
  'unilobed',
  'unilocular',
  'unimaginable',
  'unimaginative',
  'unimagined',
  'unimbued',
  'unimpaired',
  'unimparted',
  'unimpassioned',
  'unimpeachable',
  'unimpeached',
  'unimpeded',
  'unimplored',
  'unimportant',
  'unimportuned',
  'unimposed',
  'unimposing',
  'unimpregnated',
  'unimpressed',
  'unimpressible',
  'unimpressionable',
  'unimpressive',
  'unimprisoned',
  'unimproved',
  'unimpugnable',
  'uninaugurated',
  'unincited',
  'uninclosed',
  'unincorporated',
  'unincumbered',
  'unindexed',
  'uninfected',
  'uninflamed',
  'uninflammable',
  'uninflated',
  'uninflected',
  'uninfluenced',
  'uninfluential',
  'uninformative',
  'uninformed',
  'uninforming',
  'uninhabitable',
  'uninhabited',
  'uninhibited',
  'uninitiated',
  'uninjured',
  'uninquiring',
  'uninquisitive',
  'uninscribed',
  'uninspired',
  'uninspiring',
  'uninstructed',
  'uninstructive',
  'uninsured',
  'unintegrated',
  'unintellectual',
  'unintelligent',
  'unintelligible',
  'unintended',
  'unintentional',
  'uninterested',
  'uninteresting',
  'unintermitted',
  'unintermitting',
  'uninterpretable',
  'uninterrupted',
  'unintoxicating',
  'unintroduced',
  'uninucleate',
  'uninured',
  'uninventive',
  'uninvested',
  'uninvidious',
  'uninvited',
  'uninviting',
  'uninvolved',
  'union',
  'unionist',
  'unionized',
  'uniparous',
  'unipersonal',
  'uniplanar',
  'unipolar',
  'unique',
  'uniramous',
  'unironed',
  'unisex',
  'unisexual',
  'unitary',
  'united',
  'unitive',
  'univalent',
  'univalve',
  'universal',
  'universalist',
  'universalistic',
  'universitarian',
  'univocal',
  'unjaded',
  'unjaundiced',
  'unjealous',
  'unjointed',
  'unjoyful',
  'unjoyous',
  'unjust',
  'unjustifiable',
  'unjustified',
  'unkempt',
  'unkenned',
  'unkept',
  'unkind',
  'unkindled',
  'unkindly',
  'unkinged',
  'unkinglike',
  'unkingly',
  'unkissed',
  'unknelled',
  'unknighted',
  'unknightly',
  'unknowable',
  'unknowing',
  'unknown',
  'unlabelled',
  'unlaborious',
  'unlaboured',
  'unlabouring',
  'unlaced',
  'unladen',
  'unladylike',
  'unlaid',
  'unlamented',
  'unlatched',
  'unlawful',
  'unleaded',
  'unlearned',
  'unlearning',
  'unlearnt',
  'unleased',
  'unleavened',
  'unled',
  'unleisured',
  'unleisurely',
  'unlet',
  'unlettered',
  'unlibidinous',
  'unlicensed',
  'unlidded',
  'unlifelike',
  'unlighted',
  'unlightened',
  'unlikable',
  'unlike',
  'unlikeable',
  'unlikely',
  'unlimed',
  'unlimited',
  'unlineal',
  'unlined',
  'unliquefied',
  'unliquidated',
  'unlisted',
  'unlistening',
  'unlit',
  'unliterary',
  'unlivable',
  'unliveable',
  'unlively',
  'unliving',
  'unloaded',
  'unloading',
  'unlocated',
  'unlockable',
  'unlocked',
  'unlogical',
  'unlooked',
  'unlooked-for',
  'unlopped',
  'unlosable',
  'unlost',
  'unlovable',
  'unloveable',
  'unloved',
  'unlovely',
  'unloving',
  'unluckier',
  'unluckiest',
  'unlucky',
  'unluxuriant',
  'unluxurious',
  'unmacadamized',
  'unmade',
  'unmaidenly',
  'unmailable',
  'unmailed',
  'unmaimed',
  'unmaintainable',
  'unmaintained',
  'unmakable',
  'unmalicious',
  'unmalleable',
  'unmanacled',
  'unmanageable',
  'unmanaged',
  'unmanlike',
  'unmanly',
  'unmanned',
  'unmannered',
  'unmannerly',
  'unmantled',
  'unmanufactured',
  'unmarked',
  'unmarketable',
  'unmarred',
  'unmarriageable',
  'unmarried',
  'unmarrying',
  'unmasculine',
  'unmasked',
  'unmasking',
  'unmastered',
  'unmatchable',
  'unmatched',
  'unmated',
  'unmaterial',
  'unmaterialised',
  'unmaterialized',
  'unmaternal',
  'unmathematical',
  'unmatriculated',
  'unmatured',
  'unmeaning',
  'unmeant',
  'unmeasurable',
  'unmeasured',
  'unmechanical',
  'unmechanised',
  'unmechanized',
  'unmeditated',
  'unmeet',
  'unmellowed',
  'unmelodious',
  'unmelted',
  'unmemorable',
  'unmentionable',
  'unmentioned',
  'unmercenary',
  'unmerchantable',
  'unmerciful',
  'unmeritable',
  'unmerited',
  'unmeriting',
  'unmet',
  'unmetalled',
  'unmetaphysical',
  'unmeted',
  'unmethodical',
  'unmethodised',
  'unmethodized',
  'unmetrical',
  'unmilitary',
  'unmilked',
  'unmilled',
  'unmindful',
  'unmingled',
  'unministerial',
  'unmiraculous',
  'unmiry',
  'unmissable',
  'unmissed',
  'unmistakable',
  'unmistrustful',
  'unmitigable',
  'unmitigated',
  'unmixed',
  'unmoaned',
  'unmodernised',
  'unmodernized',
  'unmodifiable',
  'unmodified',
  'unmodish',
  'unmodulated',
  'unmolested',
  'unmoral',
  'unmoralising',
  'unmoralizing',
  'unmortgaged',
  'unmortified',
  'unmotherly',
  'unmotivated',
  'unmounted',
  'unmounting',
  'unmourned',
  'unmovable',
  'unmoveable',
  'unmoved',
  'unmoving',
  'unmown',
  'unmunitioned',
  'unmurmuring',
  'unmusical',
  'unmutilated',
  'unnamable',
  'unnameable',
  'unnamed',
  'unnative',
  'unnatural',
  'unnaturalized',
  'unnavigable',
  'unnavigated',
  'unnecessariness',
  'unnecessary',
  'unneeded',
  'unneedful',
  'unneighbourly',
  'unnerved',
  'unnerving',
  'unnetted',
  'unnoted',
  'unnoticeable',
  'unnoticed',
  'unnoticing',
  'unnourished',
  'unnourishing',
  'unnumbered',
  'unnurtured',
  'unobeyed',
  'unobjectionable',
  'unobnoxious',
  'unobscured',
  'unobservable',
  'unobservant',
  'unobserved',
  'unobserving',
  'unobstructed',
  'unobstructive',
  'unobtainable',
  'unobtained',
  'unobtrusive',
  'unobvious',
  'unoccupied',
  'unoffended',
  'unoffending',
  'unoffensive',
  'unoffered',
  'unofficered',
  'unofficial',
  'unofficious',
  'unoiled',
  'unopened',
  'unoperative',
  'unopposed',
  'unoppressive',
  'unordained',
  'unordered',
  'unorderly',
  'unordinary',
  'unorganized',
  'unoriginal',
  'unornamental',
  'unornamented',
  'unorthodox',
  'unorthodoxy',
  'unossified',
  'unostentatious',
  'unovercome',
  'unoverthrown',
  'unowned',
  'unoxidised',
  'unoxidized',
  'unpaced',
  'unpacified',
  'unpaged',
  'unpaid',
  'unpained',
  'unpainful',
  'unpaintable',
  'unpainted',
  'unpaired',
  'unpalatable',
  'unpalsied',
  'unpampered',
  'unpanelled',
  'unpapered',
  'unparallel',
  'unparalleled',
  'unpardonable',
  'unpardoned',
  'unpardoning',
  'unpared',
  'unparental',
  'unparented',
  'unparliamentary',
  'unpassable',
  'unpassionate',
  'unpassioned',
  'unpasteurised',
  'unpasteurized',
  'unpastoral',
  'unpastured',
  'unpatented',
  'unpathetic',
  'unpatriotic',
  'unpatronized',
  'unpatterned',
  'unpaved',
  'unpavilioned',
  'unpayable',
  'unpaying',
  'unpeaceable',
  'unpeaceful',
  'unpedigreed',
  'unpeeled',
  'unpennied',
  'unpensioned',
  'unpent',
  'unpeopled',
  'unpeppered',
  'unperceivable',
  'unperceived',
  'unperceptive',
  'unperched',
  'unperfect',
  'unperforated',
  'unperformed',
  'unperforming',
  'unperfumed',
  'unperilous',
  'unperishable',
  'unperished',
  'unperishing',
  'unperjured',
  'unperpetrated',
  'unperplexed',
  'unperplexing',
  'unpersecuted',
  'unpersuadable',
  'unpersuaded',
  'unpersuasive',
  'unperturbed',
  'unperverted',
  'unphilosophic',
  'unphilosophical',
  'unphonetic',
  'unpickable',
  'unpicked',
  'unpierced',
  'unpillared',
  'unpillowed',
  'unpiloted',
  'unpitied',
  'unpitiful',
  'unpitying',
  'unplaced',
  'unplagued',
  'unplanked',
  'unplanned',
  'unplanted',
  'unplausible',
  'unplayable',
  'unplayed',
  'unpleasant',
  'unpleased',
  'unpleasing',
  'unpleasurable',
  'unpleated',
  'unpledged',
  'unpliable',
  'unpliant',
  'unploughed',
  'unplucked',
  'unplumb',
  'unplumbed',
  'unplumed',
  'unpoetic',
  'unpoetical',
  'unpointed',
  'unpoised',
  'unpoisoned',
  'unpolarised',
  'unpolarized',
  'unpoliced',
  'unpolishable',
  'unpolished',
  'unpolite',
  'unpolitic',
  'unpolitical',
  'unpolled',
  'unpolluted',
  'unpopular',
  'unpopulated',
  'unpopulous',
  'unportioned',
  'unposed',
  'unpossessed',
  'unpossessing',
  'unposted',
  'unpotable',
  'unpowdered',
  'unpracticable',
  'unpractical',
  'unpracticed',
  'unpractised',
  'unpraised',
  'unpraiseworthy',
  'unpraising',
  'unpraying',
  'unpreached',
  'unpreaching',
  'unprecedented',
  'unprecise',
  'unpredictable',
  'unpreferred',
  'unpregnant',
  'unprejudiced',
  'unpremeditated',
  'unpreoccupied',
  'unprepared',
  'unpreparing',
  'unprepossessing',
  'unprescribed',
  'unpresentable',
  'unpressed',
  'unpresuming',
  'unpresumptuous',
  'unpretentious',
  'unpretty',
  'unprevailing',
  'unpreventable',
  'unprevented',
  'unpriced',
  'unpriestly',
  'unprimed',
  'unprincely',
  'unprincipled',
  'unprintable',
  'unprinted',
  'unprivileged',
  'unprizable',
  'unprized',
  'unprocessed',
  'unproclaimed',
  'unprocurable',
  'unproduced',
  'unproductive',
  'unprofaned',
  'unprofessed',
  'unprofessional',
  'unprofitable',
  'unprofited',
  'unprofiting',
  'unprogressive',
  'unprohibited',
  'unprojected',
  'unprolific',
  'unpromised',
  'unpromising',
  'unprompted',
  'unpronounceable',
  'unpronounced',
  'unproper',
  'unpropertied',
  'unprophetic',
  'unprophetical',
  'unpropitious',
  'unproportionable',
  'unproportionate',
  'unproportioned',
  'unproposed',
  'unpropped',
  'unprosperous',
  'unprotected',
  'unprotested',
  'unprotesting',
  'unprovable',
  'unproved',
  'unproven',
  'unprovided',
  'unprovident',
  'unprovisioned',
  'unprovocative',
  'unprovoked',
  'unprovoking',
  'unpruned',
  'unpublished',
  'unpuckered',
  'unpunctual',
  'unpunctuated',
  'unpunishable',
  'unpunished',
  'unpurchasable',
  'unpurchased',
  'unpurged',
  'unpurified',
  'unpurposed',
  'unpursued',
  'unputdownable',
  'unqualifiable',
  'unqualified',
  'unqualifying',
  'unquantified',
  'unquarried',
  'unqueenly',
  'unquelled',
  'unquenchable',
  'unquenched',
  'unquestionable',
  'unquestioned',
  'unquestioning',
  'unquickened',
  'unquiet',
  'unquieted',
  'unquieting',
  'unquotable',
  'unquoted',
  'unraised',
  'unraked',
  'unraking',
  'unransomed',
  'unrated',
  'unratified',
  'unravished',
  'unrazored',
  'unreachable',
  'unreached',
  'unreactive',
  'unread',
  'unreadable',
  'unready',
  'unreal',
  'unrealistic',
  'unrealized',
  'unreaped',
  'unreasonable',
  'unreasoned',
  'unreasoning',
  'unreaving',
  'unrebated',
  'unrebuked',
  'unrecallable',
  'unrecalled',
  'unreceipted',
  'unreceptive',
  'unreciprocated',
  'unreckonable',
  'unreckoned',
  'unreclaimable',
  'unreclaimed',
  'unrecognisable',
  'unrecognizable',
  'unrecognized',
  'unrecognizing',
  'unrecollected',
  'unrecommendable',
  'unrecommended',
  'unrecompensed',
  'unreconcilable',
  'unreconciled',
  'unreconstructed',
  'unrecorded',
  'unrecounted',
  'unrecoverable',
  'unrectified',
  'unredeemable',
  'unredeemed',
  'unredressed',
  'unreduced',
  'unreducible',
  'unreeling',
  'unrefined',
  'unreflected',
  'unreflecting',
  'unreflective',
  'unreformable',
  'unreformed',
  'unrefracted',
  'unrefreshed',
  'unrefreshing',
  'unrefuted',
  'unregarded',
  'unregenerate',
  'unregenerated',
  'unregimented',
  'unregistered',
  'unregulated',
  'unrehearsed',
  'unreined',
  'unrejoiced',
  'unrejoicing',
  'unrelated',
  'unrelative',
  'unrelaxed',
  'unreleased',
  'unrelenting',
  'unreliable',
  'unrelievable',
  'unrelieved',
  'unreligious',
  'unrelished',
  'unreluctant',
  'unremaining',
  'unremarkable',
  'unremarked',
  'unremedied',
  'unremembered',
  'unremembering',
  'unremitted',
  'unremitting',
  'unremorseful',
  'unremovable',
  'unremoved',
  'unremunerative',
  'unrendered',
  'unrenewed',
  'unrenowned',
  'unrent',
  'unrepaid',
  'unrepaired',
  'unrepealable',
  'unrepealed',
  'unrepeatable',
  'unrepeated',
  'unrepelled',
  'unrepentant',
  'unrepented',
  'unrepenting',
  'unrepining',
  'unreplaceable',
  'unreplenished',
  'unreportable',
  'unreported',
  'unreposeful',
  'unreposing',
  'unrepresentative',
  'unrepresented',
  'unreprievable',
  'unreprieved',
  'unreprimanded',
  'unreproached',
  'unreproachful',
  'unreproaching',
  'unreproducible',
  'unreprovable',
  'unreproved',
  'unreproving',
  'unrepugnant',
  'unrequired',
  'unrequisite',
  'unrequited',
  'unrescinded',
  'unresented',
  'unresentful',
  'unresenting',
  'unreserved',
  'unresisted',
  'unresistible',
  'unresisting',
  'unresolvable',
  'unresolved',
  'unrespected',
  'unrespited',
  'unresponsive',
  'unrestful',
  'unresting',
  'unrestored',
  'unrestrainable',
  'unrestrained',
  'unrestricted',
  'unretarded',
  'unretentive',
  'unretouched',
  'unreturnable',
  'unreturned',
  'unreturning',
  'unrevealable',
  'unrevealed',
  'unrevealing',
  'unrevenged',
  'unrevengeful',
  'unreverent',
  'unreversed',
  'unreverted',
  'unrevised',
  'unrevoked',
  'unrewarded',
  'unrewarding',
  'unrhymed',
  'unrhythmical',
  'unribbed',
  'unridable',
  'unridden',
  'unriddled',
  'unrifled',
  'unrigged',
  'unrighteous',
  'unrightful',
  'unrimed',
  'unripe',
  'unripened',
  'unrisen',
  'unrivalled',
  'unriven',
  'unriveted',
  'unriveting',
  'unrolled',
  'unromantic',
  'unroofed',
  'unroped',
  'unrotted',
  'unrotten',
  'unrouged',
  'unrounded',
  'unroused',
  'unrubbed',
  'unrude',
  'unruffable',
  'unruffled',
  'unruled',
  'unruly',
  'unrumpled',
  'unsaddled',
  'unsafe',
  'unsaid',
  'unsailed',
  'unsainted',
  'unsaintly',
  'unsalable',
  'unsalaried',
  'unsaleable',
  'unsalted',
  'unsaluted',
  'unsalvageable',
  'unsanctified',
  'unsanctifying',
  'unsanctioned',
  'unsandalled',
  'unsanitary',
  'unsapped',
  'unsashed',
  'unsatable',
  'unsated',
  'unsatiable',
  'unsatiated',
  'unsatiating',
  'unsating',
  'unsatirical',
  'unsatisfactory',
  'unsatisfiable',
  'unsatisfied',
  'unsatisfying',
  'unsaturated',
  'unsaved',
  'unsavoury',
  'unsayable',
  'unscalable',
  'unscaled',
  'unscaling',
  'unscanned',
  'unscarred',
  'unscathed',
  'unscented',
  'unsceptred',
  'unscheduled',
  'unscholarlike',
  'unscholarly',
  'unschooled',
  'unscientific',
  'unscissored',
  'unscorched',
  'unscoured',
  'unscratched',
  'unscreened',
  'unscripted',
  'unscriptural',
  'unscrupled',
  'unscrupulous',
  'unscrutinised',
  'unscrutinized',
  'unsculptured',
  'unsealed',
  'unseamed',
  'unsearchable',
  'unsearched',
  'unseasonable',
  'unseasoned',
  'unseaworthy',
  'unseconded',
  'unsectarian',
  'unsecular',
  'unsecured',
  'unseduced',
  'unseeable',
  'unseeded',
  'unseeing',
  'unseemly',
  'unseen',
  'unsegmented',
  'unsegregated',
  'unseizable',
  'unseized',
  'unselfconscious',
  'unselfish',
  'unsensational',
  'unsensed',
  'unsensible',
  'unsensing',
  'unsensitive',
  'unsensualised',
  'unsensualized',
  'unsent',
  'unsentenced',
  'unsentimental',
  'unseparable',
  'unseparated',
  'unserious',
  'unserviceable',
  'unset',
  'unsetting',
  'unsettled',
  'unsevered',
  'unsexed',
  'unsexual',
  'unshackled',
  'unshaded',
  'unshadowable',
  'unshadowed',
  'unshakable',
  'unshakeable',
  'unshaken',
  'unshamed',
  'unshaped',
  'unshapely',
  'unshapen',
  'unshaping',
  'unshared',
  'unsharpened',
  'unshaved',
  'unshaven',
  'unsheathed',
  'unshed',
  'unshedding',
  'unsheltered',
  'unshielded',
  'unshifting',
  'unshingled',
  'unshipped',
  'unshockable',
  'unshocked',
  'unshod',
  'unshoed',
  'unshorn',
  'unshouted',
  'unshouting',
  'unshowered',
  'unshown',
  'unshrinkable',
  'unshrinking',
  'unshrived',
  'unshriven',
  'unshunnable',
  'unshunned',
  'unshut',
  'unshuttered',
  'unsicker',
  'unsifted',
  'unsighing',
  'unsight',
  'unsighted',
  'unsightly',
  'unsigned',
  'unsinewed',
  'unsinewing',
  'unsinkable',
  'unsistered',
  'unsisterly',
  'unsizable',
  'unsizeable',
  'unsized',
  'unskilful',
  'unskilled',
  'unskillful',
  'unskimmed',
  'unskinned',
  'unslain',
  'unslaked',
  'unsleeping',
  'unsliced',
  'unslipping',
  'unsluiced',
  'unslumbering',
  'unslumbrous',
  'unslung',
  'unsmiling',
  'unsmirched',
  'unsmitten',
  'unsmooth',
  'unsmoothed',
  'unsmotherable',
  'unsnarled',
  'unsnuffed',
  'unsoaped',
  'unsociable',
  'unsocial',
  'unsocialised',
  'unsocialized',
  'unsocially',
  'unsocketed',
  'unsoft',
  'unsoftening',
  'unsoiled',
  'unsolaced',
  'unsold',
  'unsoldierlike',
  'unsoldierly',
  'unsolemn',
  'unsolicited',
  'unsolicitous',
  'unsolid',
  'unsolvable',
  'unsolved',
  'unsonsy',
  'unsophisticated',
  'unsorted',
  'unsought',
  'unsound',
  'unsoundable',
  'unsounded',
  'unsoured',
  'unsown',
  'unspared',
  'unsparing',
  'unsparred',
  'unspeakable',
  'unspecialised',
  'unspecialized',
  'unspecific',
  'unspecified',
  'unspectacled',
  'unspectacular',
  'unspeculative',
  'unspelled',
  'unspent',
  'unspied',
  'unspilled',
  'unspilt',
  'unspirited',
  'unspiritual',
  'unspiritualised',
  'unspiritualising',
  'unspiritualized',
  'unspiritualizing',
  'unspoiled',
  'unspoilt',
  'unspoken',
  'unsporting',
  'unsportsmanlike',
  'unspotted',
  'unsprinkled',
  'unsprung',
  'unspun',
  'unsquared',
  'unstable',
  'unstack',
  'unstacked',
  'unstaid',
  'unstainable',
  'unstained',
  'unstamped',
  'unstanchable',
  'unstarched',
  'unstated',
  'unstatesmanlike',
  'unstaunchable',
  'unstaying',
  'unsteadfast',
  'unsteady',
  'unsterile',
  'unsterilized',
  'unstifled',
  'unstigmatised',
  'unstigmatized',
  'unstilled',
  'unstimulated',
  'unstinted',
  'unstinting',
  'unstirred',
  'unstitched',
  'unstitching',
  'unstocked',
  'unstockinged',
  'unstooping',
  'unstoppable',
  'unstopped',
  'unstoppered',
  'unstrained',
  'unstratified',
  'unstreamed',
  'unstrengthened',
  'unstressed',
  'unstriated',
  'unstringed',
  'unstriped',
  'unstripped',
  'unstructured',
  'unstrung',
  'unstuck',
  'unstudied',
  'unstuffed',
  'unstuffy',
  'unsubduable',
  'unsubdued',
  'unsubject',
  'unsubjected',
  'unsublimated',
  'unsublimed',
  'unsubmerged',
  'unsubmissive',
  'unsubmitting',
  'unsubscribed',
  'unsubsidized',
  'unsubstantial',
  'unsubstantiated',
  'unsubtle',
  'unsucceeded',
  'unsuccessful',
  'unsuccessive',
  'unsucked',
  'unsufferable',
  'unsuitable',
  'unsuited',
  'unsuiting',
  'unsullied',
  'unsummoned',
  'unsung',
  'unsunny',
  'unsuperfluous',
  'unsupervised',
  'unsupple',
  'unsupplied',
  'unsupportable',
  'unsupported',
  'unsupposable',
  'unsuppressed',
  'unsure',
  'unsurfaced',
  'unsurmised',
  'unsurmountable',
  'unsurpassable',
  'unsurpassed',
  'unsurprised',
  'unsurveyed',
  'unsusceptible',
  'unsuspected',
  'unsuspecting',
  'unsuspended',
  'unsuspicious',
  'unsustainable',
  'unsustained',
  'unsustaining',
  'unswaddled',
  'unswaddling',
  'unswallowed',
  'unswayable',
  'unswayed',
  'unsweet',
  'unsweetened',
  'unswept',
  'unswerving',
  'unsworn',
  'unsyllabled',
  'unsymmetrical',
  'unsymmetrized',
  'unsympathetic',
  'unsympathising',
  'unsympathizing',
  'unsystematic',
  'unsystematical',
  'unsystematised',
  'unsystematized',
  'untackling',
  'untailed',
  'untainted',
  'untainting',
  'untalented',
  'untamable',
  'untame',
  'untameable',
  'untamed',
  'untangible',
  'untangled',
  'untanned',
  'untapped',
  'untarnished',
  'untarred',
  'untasted',
  'untasteful',
  'untaught',
  'untaxed',
  'untaxing',
  'unteachable',
  'unteamed',
  'untearable',
  'untechnical',
  'untellable',
  'untempered',
  'untempering',
  'untempted',
  'untenable',
  'untenantable',
  'untenanted',
  'untended',
  'untendered',
  'unterminated',
  'unterrestrial',
  'unterrified',
  'unterrifying',
  'untested',
  'untethered',
  'untethering',
  'unthanked',
  'unthankful',
  'unthawed',
  'unthawing',
  'untheological',
  'unthinkable',
  'unthinking',
  'unthorough',
  'unthought',
  'unthought-of',
  'unthoughtful',
  'unthreaded',
  'unthrifty',
  'untidied',
  'untidier',
  'untidiest',
  'untidy',
  'untidying',
  'untied',
  'untiled',
  'untillable',
  'untilled',
  'untimbered',
  'untimely',
  'untimeous',
  'untinctured',
  'untinged',
  'untinned',
  'untired',
  'untiring',
  'untitled',
  'untoiling',
  'untold',
  'untombed',
  'untoned',
  'untormented',
  'untorn',
  'untortured',
  'untouchable',
  'untouched',
  'untoward',
  'untraceable',
  'untraced',
  'untracked',
  'untractable',
  'untraded',
  'untrained',
  'untrammelled',
  'untrampled',
  'untranquil',
  'untransferable',
  'untransformed',
  'untranslatable',
  'untranslated',
  'untransmigrated',
  'untransmissible',
  'untransmitted',
  'untransmutable',
  'untransmuted',
  'untransparent',
  'untravelled',
  'untraversable',
  'untraversed',
  'untreasured',
  'untreatable',
  'untreated',
  'untrembling',
  'untremendous',
  'untremulous',
  'untrenched',
  'untrespassing',
  'untressed',
  'untried',
  'untrimmed',
  'untrod',
  'untrodden',
  'untroubled',
  'untrue',
  'untrustful',
  'untrustworthy',
  'untrusty',
  'untruthful',
  'untucked',
  'untumbled',
  'untumultuous',
  'untunable',
  'untuneable',
  'untuneful',
  'unturbid',
  'unturfed',
  'unturnable',
  'unturned',
  'unturning',
  'untutored',
  'untwisted',
  'untypical',
  'unurged',
  'unusable',
  'unused',
  'unuseful',
  'unushered',
  'unusual',
  'unutilized',
  'unutterable',
  'unuttered',
  'unvaccinated',
  'unvaluable',
  'unvalued',
  'unvanquishable',
  'unvanquished',
  'unvariable',
  'unvaried',
  'unvariegated',
  'unvarnished',
  'unvarying',
  'unveiled',
  'unvendible',
  'unvenerable',
  'unvented',
  'unventilated',
  'unveracious',
  'unverifiable',
  'unverified',
  'unversed',
  'unvexed',
  'unviable',
  'unviewed',
  'unviolated',
  'unvirtuous',
  'unvisitable',
  'unvisited',
  'unvisored',
  'unvital',
  'unvitiated',
  'unvitrifiable',
  'unvitrified',
  'unvizarded',
  'unvocal',
  'unvocalised',
  'unvocalized',
  'unvoiced',
  'unvulgar',
  'unvulnerable',
  'unwaked',
  'unwakened',
  'unwandering',
  'unwanted',
  'unwarlike',
  'unwarmed',
  'unwarned',
  'unwarped',
  'unwarrantable',
  'unwarranted',
  'unwary',
  'unwashed',
  'unwasted',
  'unwatched',
  'unwatchful',
  'unwatered',
  'unwatery',
  'unwavering',
  'unweakened',
  'unweaned',
  'unweaponed',
  'unwearable',
  'unwearied',
  'unweary',
  'unwearying',
  'unweathered',
  'unwebbed',
  'unwed',
  'unwedded',
  'unweeded',
  'unweened',
  'unweighed',
  'unweighing',
  'unwelcome',
  'unwelcomed',
  'unwell',
  'unwept',
  'unwet',
  'unwetted',
  'unwhipped',
  'unwholesome',
  'unwieldy',
  'unwifelike',
  'unwifely',
  'unwilled',
  'unwilling',
  'unwinding',
  'unwinged',
  'unwinking',
  'unwinnowed',
  'unwiped',
  'unwired',
  'unwise',
  'unwished',
  'unwished-for',
  'unwishful',
  'unwitched',
  'unwithdrawing',
  'unwithered',
  'unwithering',
  'unwithheld',
  'unwithholding',
  'unwithstood',
  'unwitnessed',
  'unwitting',
  'unwitty',
  'unwomanly',
  'unwon',
  'unwonted',
  'unwooded',
  'unwooed',
  'unworkable',
  'unworked',
  'unworking',
  'unworkmanlike',
  'unworldly',
  'unworn',
  'unworried',
  'unworshipped',
  'unworthy',
  'unwound',
  'unwounded',
  'unwoven',
  'unwrapped',
  'unwrinkled',
  'unwriting',
  'unwritten',
  'unwrought',
  'unwrung',
  'unyielding',
  'unzealous',
  'up-and-coming',
  'up-and-down',
  'up-and-over',
  'up-market',
  'up-to-date',
  'up-to-the-minute',
  'upbeat',
  'upbound',
  'upbraiding',
  'upcast',
  'upcoming',
  'upcurved',
  'upended',
  'upgrade',
  'uphill',
  'upland',
  'uplifted',
  'upmost',
  'upper',
  'upper-case',
  'upper-class',
  'uppermost',
  'uppish',
  'uppity',
  'upraised',
  'upright',
  'upriver',
  'uproarious',
  'upscale',
  'upset',
  'upsetting',
  'upside-down',
  'upstage',
  'upstaged',
  'upstaging',
  'upstair',
  'upstairs',
  'upstanding',
  'upstart',
  'upstate',
  'upstream',
  'uptight',
  'uptown',
  'upturned',
  'upward',
  'upwind',
  'uraemia',
  'uranic',
  'uranitic',
  'uranographic',
  'uranographical',
  'uranous',
  'uranylic',
  'urban',
  'urbane',
  'urbanistic',
  'urbanized',
  'urceolate',
  'urdy',
  'uredinial',
  'uremic',
  'ureteral',
  'ureteric',
  'urethral',
  'urethritic',
  'urethroscopic',
  'uretic',
  'urgent',
  'uric',
  'urinant',
  'urinary',
  'uriniferous',
  'urinogenital',
  'urinous',
  'urnfield',
  'urochord',
  'urochordal',
  'urodele',
  'urogenital',
  'urogenous',
  'urolithic',
  'uropygial',
  'ursine',
  'urticaceous',
  'urticant',
  'urticate',
  'usable',
  'useable',
  'used',
  'used-up',
  'useful',
  'useless',
  'usual',
  'usufruct',
  'usufructuary',
  'usurious',
  'usurpative',
  'usurped',
  'uterine',
  'utile',
  'utilitarian',
  'utility',
  'utilizable',
  'utilized',
  'utmost',
  'utopian',
  'utricular',
  'utter',
  'utterable',
  'uttered',
  'utterless',
  'uttermost',
  'uveal',
  'uveous',
  'uvular',
  'uxorial',
  'uxoricidal',
  'uxorilocal',
  'uxorious',
  'vacant',
  'vacationless',
  'vaccinal',
  'vaccinated',
  'vaccinial',
  'vacillant',
  'vacillating',
  'vacillatory',
  'vacuolar',
  'vacuolate',
  'vacuolated',
  'vacuous',
  'vacuum-packed',
  'vadose',
  'vagabond',
  'vagabondish',
  'vagal',
  'vagarious',
  'vagile',
  'vaginal',
  'vaginate',
  'vagrant',
  'vagrom',
  'vague',
  'vaguer',
  'vaguest',
  'vain',
  'vainglorious',
  'valanced',
  'valedictory',
  'valerianaceous',
  'valetudinarian',
  'valgus',
  'valiant',
  'valid',
  'validated',
  'validating',
  'vallecular',
  'valleculate',
  'valorous',
  'valuable',
  'value-added',
  'valued',
  'valueless',
  'valval',
  'valvar',
  'valvate',
  'valved',
  'valvular',
  'vambraced',
  'vampiric',
  'vanadic',
  'vanadous',
  'vaned',
  'vaneless',
  'vanished',
  'vanishing',
  'vanquishable',
  'vanquished',
  'vanward',
  'vapid',
  'vaporific',
  'vaporing',
  'vaporizable',
  'vaporized',
  'vaporous',
  'vapory',
  'vapouring',
  'vapourish',
  'vapoury',
  'variable',
  'variant',
  'variational',
  'variative',
  'varicelloid',
  'varicolored',
  'varicoloured',
  'varicose',
  'varied',
  'variegated',
  'varietal',
  'variform',
  'variolate',
  'variolitic',
  'varioloid',
  'variolous',
  'variorum',
  'various',
  'varnished',
  'varus',
  'varying',
  'vascular',
  'vasiform',
  'vasoconstrictive',
  'vasoconstrictor',
  'vasodilator',
  'vasomotor',
  'vassal',
  'vast',
  'vasty',
  'vatic',
  'vaticinal',
  'vaulted',
  'vaulting',
  'vaunted',
  'vaunting',
  'vaunty',
  'vectorial',
  'vee',
  'vegetable',
  'vegetal',
  'vegetarian',
  'vegetative',
  'vegetive',
  'vehement',
  'vehicular',
  'veiled',
  'veilless',
  'veined',
  'veinier',
  'veiniest',
  'veiny',
  'velar',
  'velate',
  'vellum',
  'veloce',
  'velutinous',
  'velvet',
  'velvety',
  'venal',
  'venatic',
  'venational',
  'vendible',
  'venerable',
  'venerated',
  'venerating',
  'venereal',
  'vengeful',
  'venial',
  'venomed',
  'venomous',
  'venose',
  'venous',
  'vented',
  'ventilable',
  'ventilated',
  'ventilative',
  'ventose',
  'ventral',
  'ventricose',
  'ventricous',
  'ventricular',
  'ventriloquial',
  'ventriloquistic',
  'venturesome',
  'venturous',
  'veracious',
  'verbal',
  'verbalized',
  'verbatim',
  'verbenaceous',
  'verbless',
  'verbose',
  'verboten',
  'verdant',
  'verecund',
  'veridical',
  'verier',
  'veriest',
  'verifiable',
  'verificatory',
  'verified',
  'verifying',
  'verisimilar',
  'verism',
  'verist',
  'veristic',
  'veritable',
  'vermicidal',
  'vermicular',
  'vermiculate',
  'vermiculated',
  'vermiform',
  'vermifuge',
  'vermilion',
  'vermillion',
  'verminous',
  'vermivorous',
  'vernacular',
  'vernal',
  'verrucose',
  'verrucous',
  'versatile',
  'versed',
  'versicular',
  'versional',
  'vertebral',
  'vertebrate',
  'vertebrated',
  'vertical',
  'verticillate',
  'verticillated',
  'vertiginous',
  'very',
  'vesical',
  'vesicant',
  'vesicatory',
  'vesicular',
  'vesiculate',
  'vespertine',
  'vespine',
  'vestal',
  'vested',
  'vestiary',
  'vestibular',
  'vestigial',
  'vestmental',
  'vestmented',
  'vestral',
  'veteran',
  'veterinary',
  'vexatious',
  'vexed',
  'vexillary',
  'vexing',
  'viable',
  'vibrant',
  'vibrating',
  'vibrational',
  'vibrationless',
  'vibrative',
  'vibratory',
  'vibronic',
  'vicarial',
  'vicarious',
  'vice',
  'vice-presidential',
  'vicegerent',
  'viceless',
  'vicenary',
  'vicennial',
  'vicinal',
  'vicious',
  'vicissitudinous',
  'victimized',
  'victorious',
  'victoryless',
  'victualless',
  'viewable',
  'viewier',
  'viewiest',
  'viewless',
  'viewy',
  'vigesimal',
  'vigesimo-quarto',
  'vigilant',
  'vigorous',
  'vile',
  'villager',
  'villainous',
  'villatic',
  'villiform',
  'villose',
  'villous',
  'vimineous',
  'vinaceous',
  'vinaigrette',
  'vincible',
  'vindicable',
  'vindicated',
  'vindicatory',
  'vindictive',
  'vinegarish',
  'vinegary',
  'vinicultural',
  'vinous',
  'vintage',
  'viny',
  'violable',
  'violaceous',
  'violate',
  'violated',
  'violative',
  'violent',
  'violet',
  'violinistic',
  'viperine',
  'viperish',
  'viperous',
  'viral',
  'virescent',
  'virgate',
  'virgin',
  'virginal',
  'virgulate',
  'viricidal',
  'viridescent',
  'virile',
  'virological',
  'virtual',
  'virtueless',
  'virtuosic',
  'virtuosity',
  'virtuoso',
  'virtuous',
  'virucidal',
  'virulent',
  'visaged',
  'visceral',
  'viscerotonic',
  'viscid',
  'viscoelastic',
  'viscometric',
  'viscose',
  'viscosimetric',
  'viscous',
  'visible',
  'visional',
  'visionary',
  'visionless',
  'visitant',
  'visitatorial',
  'visiting',
  'visitorial',
  'visored',
  'vistaless',
  'visual',
  'visualized',
  'vital',
  'vitalism',
  'vitalizing',
  'vitelline',
  'vitiable',
  'vitiated',
  'vitreous',
  'vitrescent',
  'vitrescible',
  'vitreum',
  'vitric',
  'vitrifiable',
  'vitrified',
  'vitriform',
  'vitriolic',
  'vituline',
  'vituperative',
  'vivace',
  'vivacious',
  'viverrine',
  'vivid',
  'viviparous',
  'vivisectional',
  'vivo',
  'vixen',
  'vixenish',
  'vixenly',
  'vizarded',
  'vizierial',
  'vizirial',
  'vocable',
  'vocal',
  'vocalic',
  'vocational',
  'vocative',
  'vociferant',
  'vociferous',
  'voetstoots',
  'vogie',
  'vogue',
  'voguish',
  'voiced',
  'voiceful',
  'voiceless',
  'void',
  'voidable',
  'voided',
  'volant',
  'volante',
  'volar',
  'volatile',
  'volatilisable',
  'volatilizable',
  'volatilized',
  'volcanic',
  'volcanological',
  'volitant',
  'volitational',
  'volitional',
  'volitionary',
  'volitionless',
  'volitive',
  'voltaic',
  'voluble',
  'volumed',
  'volumetric',
  'volumetrical',
  'voluminous',
  'voluntarism',
  'voluntary',
  'volunteer',
  'voluptuary',
  'voluptuous',
  'volute',
  'voluted',
  'volvate',
  'vomerine',
  'vomitory',
  'voodooistic',
  'voracious',
  'vorant',
  'vortical',
  'vorticose',
  'vortiginous',
  'votary',
  'voteless',
  'votive',
  'vowelless',
  'voyeuristic',
  'vulcanian',
  'vulcanisable',
  'vulcanizable',
  'vulcanized',
  'vulcanological',
  'vulgar',
  'vulgate',
  'vulned',
  'vulnerable',
  'vulnerary',
  'vulpine',
  'vulturine',
  'vulturous',
  'vulval',
  'vulvar',
  'vying',
  'wackier',
  'wackiest',
  'wacky',
  'wafer-thin',
  'wafery',
  'wage-earning',
  'wageless',
  'waggish',
  'waggly',
  'wailful',
  'wailing',
  'wainscoted',
  'waist-deep',
  'waisted',
  'waiting',
  'wakeful',
  'wakeless',
  'wakerife',
  'waking',
  'wale',
  'walk-in',
  'walk-on',
  'walk-up',
  'walking',
  'wall-less',
  'wall-to-wall',
  'walled',
  'walloping',
  'wally',
  'walnut',
  'wambly',
  'wan',
  'wanchancy',
  'wandering',
  'wandle',
  'waney',
  'wanier',
  'waniest',
  'waning',
  'wanner',
  'wannest',
  'wanning',
  'wannish',
  'wanted',
  'wanting',
  'wanton',
  'war',
  'war-torn',
  'war-worn',
  'warded',
  'ware',
  'warier',
  'wariest',
  'warlike',
  'warm',
  'warm-blooded',
  'warm-hearted',
  'warmed',
  'warmed-over',
  'warming',
  'warmish',
  'warning',
  'warped',
  'warragal',
  'warrantable',
  'warranted',
  'warrigal',
  'warring',
  'wartier',
  'wartiest',
  'wartless',
  'wartlike',
  'warty',
  'wary',
  'wash-and-wear',
  'washable',
  'washed',
  'washed-out',
  'washed-up',
  'washier',
  'washiest',
  'washy',
  'wasp-waisted',
  'waspier',
  'waspiest',
  'waspish',
  'waspy',
  'wastable',
  'waste',
  'wasted',
  'wasteful',
  'wasting',
  'watchful',
  'water-cooled',
  'water-gas',
  'water-repellent',
  'water-resistant',
  'water-soluble',
  'water-supply',
  'watercress',
  'watered',
  'watered-down',
  'waterish',
  'waterless',
  'waterlog',
  'waterlogged',
  'waterproof',
  'waterproofed',
  'watertight',
  'watery',
  'wattle',
  'waugh',
  'waur',
  'waved',
  'waveless',
  'wavelike',
  'wavering',
  'wavier',
  'waviest',
  'waving',
  'wavy',
  'waxed',
  'waxen',
  'waxier',
  'waxiest',
  'waxing',
  'waxy',
  'way-out',
  'wayfarer',
  'wayfaring',
  'wayless',
  'wayward',
  'wayworn',
  'weak',
  'weak-kneed',
  'weak-minded',
  'weak-willed',
  'weakened',
  'weakening',
  'weakly',
  'wealthier',
  'wealthiest',
  'wealthy',
  'weaned',
  'weaponed',
  'weaponless',
  'wearable',
  'wearied',
  'wearier',
  'weariest',
  'weariful',
  'weariless',
  'wearing',
  'wearish',
  'wearisome',
  'weary',
  'wearying',
  'weather',
  'weather-beaten',
  'weather-bound',
  'weather-wise',
  'weathered',
  'weatherly',
  'weaving',
  'web-footed',
  'web-toed',
  'webbed',
  'webbier',
  'webbiest',
  'webby',
  'wed',
  'wedded',
  'wedge-shaped',
  'wedged',
  'wedgy',
  'wee',
  'weeded',
  'weedier',
  'weediest',
  'weedless',
  'weedy',
  'weekday',
  'weekly',
  'weeny',
  'weepier',
  'weepiest',
  'weeping',
  'weepy',
  'weer',
  'weest',
  'weighable',
  'weighted',
  'weightier',
  'weightiest',
  'weightless',
  'weightlessness',
  'weighty',
  'weird',
  'welcome',
  'welcomed',
  'welcoming',
  'weldable',
  'weldless',
  'welfare',
  'welfarist',
  'well',
  'well-acquainted',
  'well-advised',
  'well-affected',
  'well-aimed',
  'well-appointed',
  'well-balanced',
  'well-becoming',
  'well-behaved',
  'well-beloved',
  'well-bred',
  'well-built',
  'well-chosen',
  'well-conditioned',
  'well-conducted',
  'well-connected',
  'well-coupled',
  'well-covered',
  'well-defined',
  'well-derived',
  'well-deserved',
  'well-desired',
  'well-developed',
  'well-directed',
  'well-disposed',
  'well-dressed',
  'well-earned',
  'well-educated',
  'well-endowed',
  'well-entered',
  'well-established',
  'well-favoured',
  'well-fed',
  'well-formed',
  'well-found',
  'well-founded',
  'well-gotten',
  'well-groomed',
  'well-grounded',
  'well-heeled',
  'well-hung',
  'well-informed',
  'well-intentioned',
  'well-judged',
  'well-kept',
  'well-knit',
  'well-known',
  'well-lined',
  'well-made',
  'well-mannered',
  'well-marked',
  'well-meaning',
  'well-meant',
  'well-off',
  'well-oiled',
  'well-ordered',
  'well-paid',
  'well-placed',
  'well-prepared',
  'well-preserved',
  'well-proportioned',
  'well-read',
  'well-regulated',
  'well-respected',
  'well-rounded',
  'well-set',
  'well-spent',
  'well-spoken',
  'well-stacked',
  'well-tempered',
  'well-thought-of',
  'well-thought-out',
  'well-timed',
  'well-to-do',
  'well-tried',
  'well-trodden',
  'well-turned',
  'well-upholstered',
  'well-warranted',
  'well-wishing',
  'well-won',
  'well-worn',
  'welsh',
  'wersh',
  'west',
  'westbound',
  'westering',
  'westerly',
  'western',
  'westernmost',
  'westmost',
  'westward',
  'westwardly',
  'wet',
  'wetter',
  'wetting',
  'wettish',
  'whacked',
  'whackier',
  'whackiest',
  'whacking',
  'whacky',
  'whapping',
  "whate'er",
  'whatever',
  "whatsoe'er",
  'whatsoever',
  'wheaten',
  'wheeled',
  'wheeling',
  'wheezier',
  'wheeziest',
  'wheezing',
  'wheezy',
  'whelked',
  'whelped',
  'wheyey',
  'whilom',
  'whimsical',
  'whinier',
  'whiniest',
  'whining',
  'whinny',
  'whiny',
  'whip-tailed',
  'whiplike',
  'whipping',
  'whippy',
  'whirling',
  'whirring',
  'whiskered',
  'whiskery',
  'whispered',
  'whispering',
  'whist',
  'whistleable',
  'white',
  'white-collar',
  'white-faced',
  'white-haired',
  'white-hot',
  'white-livered',
  'whited',
  'whitened',
  'whitewashed',
  'whitish',
  'whittling',
  'whity',
  'whole',
  'whole-souled',
  'whole-wheat',
  'wholesale',
  'wholesome',
  'wholistic',
  'whopping',
  'whoreson',
  'whorish',
  'whorled',
  'wick',
  'wicked',
  'wicker',
  'wide',
  'wide-angle',
  'wide-awake',
  'wide-eyed',
  'wide-open',
  'wide-ranging',
  'wide-screen',
  'wider',
  'widespread',
  'widest',
  'widish',
  'widowed',
  'wieldable',
  'wieldier',
  'wieldiest',
  'wieldy',
  'wifeless',
  'wifely',
  'wigged',
  'wigglier',
  'wiggliest',
  'wiggling',
  'wiggly',
  'wight',
  'wigless',
  'wiglike',
  'wild',
  'wild-eyed',
  'wilful',
  'wilier',
  'wiliest',
  'will-less',
  'willable',
  'willed',
  'willful',
  'willing',
  'willowy',
  'willy-nilly',
  'willyard',
  'wilted',
  'wily',
  'wimpish',
  'wimpy',
  'wind-borne',
  'wind-broken',
  'wind-shaken',
  'windburned',
  'winded',
  'windier',
  'windiest',
  'winding',
  'windless',
  'windowless',
  'windproof',
  'windswept',
  'windward',
  'windy',
  'winey',
  'wing-footed',
  'winged',
  'wingless',
  'winglike',
  'winier',
  'winiest',
  'winking',
  'winnable',
  'winning',
  'winsome',
  'winter',
  'winterier',
  'winteriest',
  'winterweight',
  'wintery',
  'wintrier',
  'wintriest',
  'wintry',
  'winy',
  'wire-haired',
  'wired',
  'wireless',
  'wiretap',
  'wiring',
  'wiry',
  'wise',
  'wised',
  'wiser',
  'wisest',
  'wishful',
  'wishy-washy',
  'wising',
  'wispier',
  'wispiest',
  'wispy',
  'wistful',
  'witch-hunt',
  'witching',
  'witchlike',
  'witchy',
  'withdrawing',
  'withdrawn',
  'withered',
  'withering',
  'withy',
  'witless',
  'witnessed',
  'witted',
  'wittier',
  'wittiest',
  'witting',
  'witty',
  'wizard',
  'wizardly',
  'wizen',
  'wizened',
  'woaded',
  'wobbling',
  'wobbly',
  'wobegone',
  'woebegone',
  'woeful',
  'woesome',
  'wolfish',
  'womanish',
  'womanless',
  'womanly',
  'wombed',
  'won',
  'wonder-stricken',
  'wonder-struck',
  'wonderful',
  'wondering',
  'wondrous',
  'wonky',
  'wonted',
  'wood',
  'wooded',
  'wooden',
  'wooden-headed',
  'woodier',
  'woodiest',
  'woodless',
  'woods',
  'woodsy',
  'woodwind',
  'woodworking',
  'woody',
  'wool-stapler',
  'woolen',
  'woollen',
  'woolly',
  'woolly-headed',
  'woozier',
  'wooziest',
  'woozy',
  'word-blind',
  'word-for-word',
  'word-of-mouth',
  'word-perfect',
  'wordier',
  'wordiest',
  'wordless',
  'wordy',
  'work-shy',
  'workable',
  'workaday',
  'worked',
  'working',
  'working-class',
  'workless',
  'workmanlike',
  'world',
  'world-beater',
  'world-shaking',
  'world-shattering',
  'world-weary',
  'worldly',
  'worldly-minded',
  'worldly-wise',
  'worldwide',
  'worm-eaten',
  'worm-wheel',
  'wormy',
  'worn',
  'worn-out',
  'worried',
  'worrisome',
  'worrying',
  'worse',
  'worsened',
  'worsening',
  'worser',
  'worshipful',
  'worshipless',
  'worshipped',
  'worshipping',
  'worst',
  'worth',
  'worthful',
  'worthless',
  'worthwhile',
  'worthy',
  'would-be',
  'wound',
  'wound-up',
  'wounded',
  'wounding',
  'woundless',
  'woven',
  'wraparound',
  'wrapped',
  'wrapround',
  'wrath',
  'wrathful',
  'wrathless',
  'wreathed',
  'wreathless',
  'wrecked',
  'wreckful',
  'wrenching',
  'wretched',
  'wrier',
  'wriest',
  'wriggling',
  'wriggly',
  'wrinkled',
  'wrinklier',
  'wrinkliest',
  'wrinkly',
  'write-in',
  'writhed',
  'writhen',
  'writhing',
  'written',
  'wrong',
  'wrong-headed',
  'wronged',
  'wrongful',
  'wroth',
  'wrought',
  'wrought-iron',
  'wrought-up',
  'wry',
  'wry-necked',
  'wud',
  'wuthering',
  'xanthic',
  'xanthochroid',
  'xanthous',
  'xenogenetic',
  'xenomorphic',
  'xenophobic',
  'xerarch',
  'xeric',
  'xerographic',
  'xeromorphic',
  'xerophilous',
  'xerophytic',
  'xerotic',
  'xiphoid',
  'xiphosuran',
  'xylic',
  'xylographic',
  'xylographical',
  'xyloid',
  'xylophagous',
  'xylotomous',
  'yare',
  'yarer',
  'yarest',
  'yauld',
  'yawning',
  'yclept',
  'year-end',
  'year-round',
  'yearling',
  'yearlong',
  'yearly',
  'yearning',
  'yeastlike',
  'yeasty',
  'yeld',
  'yelled',
  'yelling',
  'yellow',
  'yellow-bellied',
  'yellowed',
  'yellowish',
  'yeomanly',
  'yester',
  'yestern',
  'yielding',
  'yogic',
  'yokelish',
  'yolky',
  'yon',
  'yonder',
  'young',
  'young-eyed',
  'younger',
  'youngish',
  'youthful',
  'yttric',
  'yttriferous',
  'yucky',
  'yuletide',
  'yummy',
  'zanier',
  'zanies',
  'zaniest',
  'zany',
  'zealous',
  'zebrine',
  'zenithal',
  'zeolitic',
  'zero',
  'zero-rated',
  'zeroth',
  'zestful',
  'zesty',
  'zeugmatic',
  'zibeline',
  'zig',
  'zigzag',
  'zillion',
  'zinciferous',
  'zincky',
  'zincographic',
  'zincographical',
  'zincoid',
  'zincous',
  'zincy',
  'zingiberaceous',
  'zingy',
  'zinky',
  'zippered',
  'zippy',
  'zirconic',
  'zodiacal',
  'zoic',
  'zonal',
  'zonary',
  'zonate',
  'zoning',
  'zonked',
  'zonular',
  'zoochemical',
  'zoographic',
  'zoographical',
  'zoolatrous',
  'zoological',
  'zoometric',
  'zoomorphic',
  'zoonal',
  'zoophagous',
  'zoophilous',
  'zoophobous',
  'zoophoric',
  'zooplastic',
  'zygodactyl',
  'zygomorphic',
  'zygomorphous',
  'zygophyllaceous',
  'zygotic',
  'zymogenic',
  'zymolysis',
  'zymolytic',
  'zymotic',
];

export { nouns, adjectives };
